import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  view: "details",
  selectedClient: null,
  clientID: null,
  modalOn: null,
  contact: null,
  importInvoice: null,
  companyName: null,
  invoiceList: [],
};

export const counterSlice = createSlice({
  name: "clients",
  initialState,

  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setClientID: (state, action) => {
      state.clientID = action.payload;
    },
    setModalOn: (state, action) => {
      state.modalOn = action.payload;
    },
    setContact: (state, action) => {
      state.contact = action.payload;
    },
    setImportInvoice: (state, action) => {
      state.importInvoice = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
    },
  },
});

export const {
  setView,
  setClient,
  setClientID,
  setModalOn,
  setContact,
  setImportInvoice,
  setCompanyName,
  setInvoiceList,
} = counterSlice.actions;

export const selectView = (state) => state.client.view;
export const selectClient = (state) => state.client.selectedClient;
export const selectClientID = (state) => state.client.clientID;
export const selectModalOn = (state) => state.client.modalOn;
export const selectContact = (state) => state.client.contact;
export const selectImportInvoice = (state) => state.client.importInvoice;
export const selectCompanyName = (state) => state.client.companyName;
export const selectInvoiceList = (state) => state.client.invoiceList;
export default counterSlice.reducer;
