/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  selectParameters,
  selectSubstances,
  setView,
  setBeethoven,
  setParameters,
  setSubstances,
  setBeethovenProducts,
  selectView,
} from "./estimateSlice";
import {
  useQuery,
  useLazyQuery,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import { XIcon } from "@heroicons/react/solid";
import { v4 as uuidv4 } from "uuid";
import useConfigureEstimate from "./useConfigureEstimate";
//COOKIES
import { useCookies } from "react-cookie";

import {
  CONTAINERS,
  GET_BUILD,
  GET_PAGE,
  GET_FORM,
  GET_ITEMS,
} from "./queries";

// COMPONENTS
import DropDown from "../../components/FormComponents/dropdown/simple";

const useEstimateParameters = () => {
  const dispatch = useDispatch();
  const {
    register,
    setValue,
    getValues,
    formState,
    control,
    handleSubmit,
    clearErrors,
    setError,
    unregister,
  } = useForm();
  const { errors } = formState;
  const [cookies] = useCookies(["user"]);
  const pageView = useSelector(selectView);
  const estimateParameters = useSelector(selectParameters);
  const estimateSubstances = useSelector(selectSubstances);
  const [parametersForm, setParametersForm] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { configureBuildStepByStep } = useConfigureEstimate();
  const [containerOptions, setContainerOptions] = useState(null);
  const containers = useQuery(CONTAINERS);
  const [getBuild, build] = useLazyQuery(GET_BUILD, {
    onCompleted: (d) => completeBuild(d),
    onError: (e) => alert(e),
  });
  const watchAllFields = useWatch({
    control,
  });

  async function completeBuild(data) {
    let flow = {};
    configureBuildStepByStep(data.calculateBeethovenFlow);
    for (const build of data.calculateBeethovenFlow) {
      flow[build.buildNumber] = {};
      let standardPages = JSON.parse(build.buildPages);
      let ProductPages = JSON.parse(build.productsSetup);
      for (const standards of Object.entries(standardPages)) {
        delete standards[1].addon_default;
        flow[build.buildNumber][parseInt(standards[0])] = standards[1];
      }
      for (const specific of Object.entries(ProductPages)) {
        for (const specificPages of Object.entries(specific[1])) {
          flow[build.buildNumber][parseInt(specificPages[0])] = flow[
            build.buildNumber
          ][parseInt(specificPages[0])]
            ? Object.assign(
                flow[build.buildNumber][parseInt(specificPages[0])],
                specificPages[1]
              )
            : specificPages[1];
        }
      }
      flow[build.buildNumber] = Object.keys(flow[build.buildNumber])
        .sort()
        .reduce((obj, key) => {
          obj[key] = flow[build.buildNumber][key];
          return obj;
        }, {});
    }
    setPage(flow, "page");
  }

  async function setPage(flow, location) {
    setIsSubmitting(true);
    let beethoven = {};
    const client = new ApolloClient({
      uri: process.env.REACT_APP_URI,
      credentials: "same-origin",
      cache: new InMemoryCache(),
      headers: {
        "Content-Type": "application/json",
        authorization:
          cookies.user !== "null" ? `JWT ${cookies.user.token}` : "",
      },
    });
    for (const [entry, value] of Object.entries(flow)) {
      beethoven[entry] = {};
      for (const [page, forms] of Object.entries(value)) {
        beethoven[entry][page] = {};
        const pageRequest = (apolloClient, pageId) =>
          apolloClient.query({
            query: GET_PAGE,
            variables: { id: pageId },
          });
        const pageResponse = await pageRequest(client, page.toString());
        const { title, id, description, layout } = pageResponse.data.page;
        beethoven[entry][page].title = title;
        beethoven[entry][page].id = id;
        beethoven[entry][page].description = description;
        beethoven[entry][page].columns = layout.numColumns;
        beethoven[entry][page].row = layout.numRows;
        beethoven[entry][page].forms = {};
        beethoven[entry][page].defaults = [];
        beethoven[entry][page].total = 0;
        let addOns = forms.addon_default ? true : false;
        for (const formId of Object.keys(forms)) {
          if (formId !== "addon_default") {
            beethoven[entry][page].forms[formId] = {};
            const formRequest = (apolloClient, formId) =>
              apolloClient.query({
                query: GET_FORM,
                variables: { id: formId, page: page.toString(), addon: addOns },
              });
            const formResponse = await formRequest(client, formId.toString());
            const {
              id,
              description,
              label,
              column,
              row,
              input,
              productGroup,
              tags,
            } = formResponse.data.form;
            const itemRequest = (apolloClient, productGroup, tags) =>
              apolloClient.query({
                query: GET_ITEMS,
                variables: {
                  group: productGroup,
                  tags: tags && tags,
                },
              });
            const itemResponse = await itemRequest(
              client,
              productGroup.accessor,
              tags.map((entry) => entry.accessor)
            );
            if (formResponse.data.pageFormDefaults.length > 0) {
              let defaultValues = formResponse.data.pageFormDefaults.map(
                (e) => {
                  beethoven[entry][page].total += parseFloat(
                    e.item.price.pricePerUnit
                  );
                  let table =
                    input.name === "ModularTable" ||
                    input.name === "TraysTable";
                  return {
                    form: formId,
                    skuNumber: e.item.skuNumber,
                    quantity: e.quantity,
                    table,
                  };
                }
              );
              beethoven[entry][page].defaults.push(...defaultValues);
              beethoven[entry][page].forms[formId].default = defaultValues;
            }
            beethoven[entry][page].forms[formId].id = id;
            beethoven[entry][page].forms[formId].description = description;
            beethoven[entry][page].forms[formId].label = label;
            beethoven[entry][page].forms[formId].column = column;
            beethoven[entry][page].forms[formId].row = row;
            beethoven[entry][page].forms[formId].input = input.name;
            beethoven[entry][page].forms[formId].items =
              input.name === "TraysTable" &&
              beethoven[entry][page].forms[formId].items
                ? beethoven[entry][page].forms[formId].items.concat(
                    itemResponse.data.items
                  )
                : itemResponse.data.items;
          }
        }
      }
    }
    dispatch(setBeethoven(beethoven));
    dispatch(setView(location));
    setIsSubmitting(false);
  }
  if (containers.data && !containerOptions) {
    let options = {};
    for (const entry of containers.data.allContainerTypes) {
      options[entry.frontendIdentifier] = {
        label: entry.name,
        value: entry.frontendIdentifier,
        min: entry.minValue,
        max: entry.maxValue,
        units: entry.unitOfMeasurement?.units,
        fields: entry.inputFields,
      };
    }
    setContainerOptions(options);
  }

  let heating = [
    {
      label: "Required",
      value: true,
    },
    {
      label: "Not Required",
      value: false,
    },
  ];

  async function validateVolume(value, name, min, max, units) {
    clearErrors(name);
    if (
      parseFloat(value) > parseFloat(max) ||
      parseFloat(value) < parseFloat(min)
    ) {
      setError(name, {
        type: "manual",
        message: `Container must have volume between ${min}${units} - ${max}${units}`,
      });
    }
  }

  async function handleAddParameters() {
    let index = estimateParameters.length + 1;
    let params = [
      ...estimateParameters,
      {
        container: { name: `container-${index}`, value: null },
        volume: { name: `volume-${index}`, value: null, label: null },
        heating: { name: `heating-${index}`, value: null },
        product: { name: `product-${index}`, value: null },
        system: { name: `system-${index}`, value: null },
        machine: { name: `machine-${index}`, value: null },
      },
    ];
    dispatch(setParameters(params));
  }

  async function handleRemoveSelection(index) {
    if (estimateParameters.length > 1) {
      let params = [...estimateParameters];
      params.splice(index, 1);
      dispatch(setParameters(params));
    }
  }

  useEffect(() => {
    if (estimateParameters && estimateParameters.length > 0) {
      for (const entry of estimateParameters) {
        setValue(
          entry.container.name,
          watchAllFields[entry.container.name]
            ? watchAllFields[entry.container.name]
            : entry.container.value
        );
        setValue(
          entry.volume.name,
          watchAllFields[entry.volume.name]
            ? watchAllFields[entry.volume.name]
            : entry.volume.value
        );
        setValue(
          entry.heating.name,
          watchAllFields[entry.heating.name]
            ? watchAllFields[entry.heating.name]
            : entry.heating.value
        );
        setValue(
          entry.machine.name,
          watchAllFields[entry.machine.name]
            ? watchAllFields[entry.machine.name]
            : entry.machine.value
        );
        setValue(
          entry.product.name,
          watchAllFields[entry.product.name]
            ? watchAllFields[entry.product.name]
            : entry.product.value
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateParameters]);

  useEffect(() => {
    async function setParams() {
      if (!estimateParameters || estimateParameters.length === 0) return;
      let Prams = estimateParameters.map((entry, index) => {
        if (entry && Object.keys(entry !== 0)) {
          let machineAddons = [];
          getValues(entry.volume?.name) &&
            validateVolume(
              getValues(entry.volume?.name),
              entry.volume?.name,
              containerOptions[getValues(entry.container?.name)].min,
              containerOptions[getValues(entry.container?.name)].max,
              containerOptions[getValues(entry.container?.name)].units
            );
          if (index) {
            let value = parseInt(getValues(`machine-${index}`)) + 1;
            for (let count = 1; count < value + 1; count++) {
              machineAddons.push({
                label: `Machine #${count}`,
                value: count,
              });
            }
          }
          if (entry.machine?.name === "machine-1")
            setValue(entry.machine?.name, 1);
          return (
            <div
              key={index}
              className="grid grid-cols-1 gap-2 lg:gap-5 sm:grid-cols-11 py-2 "
            >
              <div className="flex flex-row justify-start items-start col-span-2">
                <p className="text-lg font-medium mr-1 self-end ">
                  {index + 1}.
                </p>
                <div className="w-full self-end">
                  <DropDown
                    props={{
                      name: entry.product?.name,
                      options: estimateSubstances,
                    }}
                    register={register}
                  />
                </div>
              </div>
              <div className="self-end col-span-2">
                <DropDown
                  props={{
                    name: entry.container?.name,
                    options: containerOptions
                      ? Object.values(containerOptions)
                      : [],
                  }}
                  register={register}
                />
              </div>
              <div className="col-span-2 self-end">
                {errors[entry.volume?.name] && (
                  <p className="text-red-600 text-xs">
                    {errors[entry.volume?.name].message}
                  </p>
                )}
                {getValues(entry.container?.name) ? (
                  <div className="flex flex-row">
                    <input
                      type="number"
                      step=".01"
                      id={entry.volume?.name}
                      {...register(entry.volume?.name, {
                        required: true,
                      })}
                      disabled={getValues(entry.container?.name) ? false : true}
                      onBlur={(e) =>
                        validateVolume(
                          e.target.value,
                          entry.volume?.name,
                          containerOptions[getValues(entry.container?.name)]
                            .min,
                          containerOptions[getValues(entry.container?.name)]
                            .max,
                          containerOptions[getValues(entry.container?.name)]
                            .units
                        )
                      }
                      className="shadow-sm sm:text-sm p-1 border border-gray-300 rounded-l-md w-3/5 "
                    />
                    <span className="flex w-2/5 items-center px-3 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-sm">
                      {
                        containerOptions[getValues(entry.container?.name)]
                          ?.units
                      }
                    </span>
                  </div>
                ) : (
                  <div className="relative mt-1 self-end col-span-2">
                    <input
                      disabled={true}
                      type="text"
                      value={"Select Container"}
                      className="self-end shadow-sm sm:text-sm p-1 border border-gray-300 w-full rounded-md text-gray-400"
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col self-end col-span-2">
                {getValues(entry.container?.name) ? (
                  containerOptions[
                    getValues(entry.container?.name)
                  ].fields.findIndex((entry) => entry.name === "heat") > -1 ? (
                    <DropDown
                      props={{
                        name: entry.heating?.name,
                        options: heating,
                      }}
                      register={register}
                    />
                  ) : (
                    <input
                      disabled={true}
                      type="text"
                      value={"Not Required"}
                      className="self-end shadow-sm sm:text-sm p-1 border border-gray-300 w-full rounded-md text-gray-400"
                    />
                  )
                ) : (
                  <input
                    disabled={true}
                    type="text"
                    value={"Select Container"}
                    className="self-end shadow-sm sm:text-sm p-1 border border-gray-300 w-full rounded-md text-gray-400"
                  />
                )}
              </div>
              <div className="flex flex-col self-end col-span-2">
                <DropDown
                  props={{
                    name: entry.machine?.name,
                    options: machineAddons.length
                      ? machineAddons
                      : [
                          {
                            label: "Machine #1",
                            value: 1,
                          },
                        ],
                  }}
                  register={register}
                />
              </div>
              <div className="self-end col-span-1">
                <button
                  className="flex flex-row w-min justify-start items-center text-red-400 hover:text-red-500 focus:outline-none"
                  onClick={() => {
                    handleRemoveSelection(index);
                    unregister(entry.container?.name);
                    unregister(entry.volume?.name);
                    unregister(entry.heating?.name);
                    unregister(entry.product?.name);
                    unregister(entry.machine?.name);
                  }}
                  type="button"
                >
                  <XIcon className="h-5 w-5 ml-4" aria-hidden="true" />
                </button>
              </div>
            </div>
          );
        }
      });
      setParametersForm(Prams);
    }
    if (pageView !== "review") setParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerOptions, watchAllFields, errors, estimateParameters]);

  async function handleBuildForMe() {
    setIsSubmitting(true);
    let dataObj = [];
    let filledParams = [];
    let index = 1;
    for (const entry of estimateParameters) {
      let obj = {
        containerType: watchAllFields[entry.container.name],
        amount: watchAllFields[entry.volume.name],
        fillingMachine: {
          type: "automatic",
          number: parseInt(watchAllFields[entry.machine.name]),
        },
        productUuid: uuidv4(),
      };
      if (watchAllFields[entry.heating.name]) obj.heat = true;
      dataObj.push(obj);
      filledParams.push({
        container: {
          name: `container-${index}`,
          value: watchAllFields[`container-${index}`],
        },
        volume: {
          name: `volume-${index}`,
          value: watchAllFields[`volume-${index}`],
          label: watchAllFields[`volume-${index}`],
        },
        heating: {
          name: `heating-${index}`,
          value: watchAllFields[`heating-${index}`],
        },
        product: {
          name: `product-${index}`,
          value: watchAllFields[`product-${index}`],
        },
        system: {
          name: `system-${index}`,
          value: watchAllFields[`system-${index}`],
        },
        machine: {
          name: `machine-${index}`,
          value: watchAllFields[`machine-${index}`],
        },
      });
      index++;
    }
    dispatch(setParameters(filledParams));
    dispatch(setBeethovenProducts(dataObj));

    getBuild({
      variables: {
        data: dataObj,
      },
    });
  }

  async function handleFluids(value) {
    let fluids = parseInt(value);
    if (fluids < 1) return;
    let index = 1;
    let substances = [];
    let parameters = [];
    do {
      let prod = `product-${index}`;
      let substanceObj = {
        product: { name: prod, value: index },
        label: `Liquid ${index}`,
        value: index,
      };
      let parametersObj = {
        container: { name: `container-${index}`, value: null },
        volume: { name: `volume-${index}`, value: null, label: null },
        heating: { name: `heating-${index}`, value: null },
        product: { name: `product-${index}`, value: index },
        system: { name: `system-${index}`, value: null },
        machine: { name: `machine-${index}`, value: null },
      };
      substances.push(substanceObj);
      parameters.push(parametersObj);
      index++;
    } while (index <= fluids);

    dispatch(setSubstances(substances));
    dispatch(setParameters(parameters));
  }

  return {
    isSubmitting,
    parametersForm,
    handleFluids,
    handleBuildForMe,
    handleSubmit,
    handleAddParameters,
  };
};
export default useEstimateParameters;
