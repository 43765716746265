import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
  query orders($year: Int!, $month: Int!) {
    paymentTransactions(yearReceived: $year, monthReceived: $month) {
      amountReceived
      receivedDate
      totalPercentage
      order {
        id
        id
        salesType
        currentStatus {
          name
        }
        client {
          name
          id
        }
        selectedEstimate {
          number
          id
        }
        total
        profit
        currency
        vendorShipDate
        vendorHandOffDate
      }
    }
  }
`;

export const QUOTE = gql`
  query quoteLevels {
    quotaLevels {
      id
      commissionPrecentage
      thresholdValue
    }
  }
`;

export const INT_CURRENCY = gql`
  query internalCurrency($month: Int!, $year: Int!) {
    internalCurrency(month: $month, year: $year) {
      conversionRateFromCad
    }
  }
`;

export const ADD_CURRENCY = gql`
  mutation addInternalCurrencyForMonth(
    $month: Int!
    $year: Int!
    $conversion: Decimal!
  ) {
    addInternalCurrencyForMonth(
      month: $month
      year: $year
      conversionRateFromCad: $conversion
      name: "metrics"
    ) {
      internalCurrency {
        id
      }
    }
  }
`;

export const METRICS = gql`
  query metrics($year: Int!, $month: [Int]!, $repId: ID) {
    metrics(year: $year, months: $month, salesRepId: $repId) {
      salesRep {
        id
      }
      currentOrdersCount {
        value
      }
      paymentReceivedOrdersCount {
        value
      }
      averageTimeToCloseInDays {
        value
      }
      commissionGainedWithFullPaymentCad {
        value
      }
      subtotalRevenueCad {
        value
      }
      createdOrders {
        id
        salesType
        daysToCloseSale
        total
        profitMargin
        predictedEstimate {
          total
        }
        currency
        currentStatus {
          name
        }
        client {
          name
        }
        lead {
          companyName
        }
        createdAt
        vendors {
          name
        }
      }
      paymentReceivedOrders {
        id
        salesType
        daysToCloseSale
        total
        profitMargin
        predictedEstimate {
          total
        }
        currency
        currentStatus {
          name
        }
        client {
          name
        }
        lead {
          companyName
        }
        vendors {
          name
        }
      }
    }
  }
`;

export const PERIOD = gql`
  query period($year: Int!, $month: [Int]!) {
    periodRevenueReport(year: $year, months: $month) {
      year
      months
      salesRep {
        firstName
        lastName
      }
      createdOrdersCount
      handedOffOrdersCount
      shippedOrdersCount
      incomingUsRevenue
      incomingCadRevenue
      shippedUsRevenue
      shippedCadRevenue
      incomingTotalCadRevenue
      commissionQuotaReached
      shippedTotalCadRevenue
      commissionDueTotalCad
      commissionPotentialTotalCad
      handedOffOrders {
        id
        dueDate
        salesType
        currentStatus {
          name
        }
        lead {
          companyName
        }
        predictedEstimate {
          taxPercentage
          total
        }
      }
      shippedOrders {
        id
        dueDate
        salesType
        currentStatus {
          name
        }
        lead {
          companyName
        }
        predictedEstimate {
          taxPercentage
          total
        }
      }
    }
  }
`;

export const SALES_REPS = gql`
  query users {
    users(roleCodename: "sales_rep") {
      firstName
      lastName
      id
    }
  }
`;
