/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Helmet } from "react-helmet";
import { useQuery, gql } from "@apollo/client";
import { useForm } from "react-hook-form";

// COMPONENTS
import Header from "./header";
import { PencilAltIcon } from "@heroicons/react/outline";
import Nav from "../nav";
const PRODUCTS = gql`
  query samples {
    allSamples {
      id
      containerType {
        frontendIdentifier
      }
      name
      status
      receivedQuantity
      expectedQuantity
    }
  }
`;
const InventoryProductsView = () => {
  const prod = useQuery(PRODUCTS);
  const { loading, data } = prod;
  const [open, setOpen] = useState(false);
  const [activeProduct, SetActiveProduct] = useState(null);
  const [skipValue, setSkipValue] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();
  const cancelButtonRef = useRef(null);

  const ProductHeaders = [
    "ID",
    "TYPE",
    "STATUS",
    "EXPECTED QUANTITY",
    "RECEIVED QUANTITY",
    "Details",
  ];

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none h-full "
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Samples | One Supply Connect</title>
      </Helmet>
      <Nav />
      <div className="flex flex-col px-4 sm:px-6 lg:px-8 h-full ">
        {/* <Header
          getProducts={getProducts}
          skipValue={skipValue}
          setSkipValue={setSkipValue}
        /> */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed z-50 inset-0 overflow-y-auto"
            open={open}
            initialFocus={cancelButtonRef}
            onClose={setOpen}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:mt-8 sm:align-middle sm:max-w-3xl sm:w-full sm:pt-6">
                  <form
                  // onSubmit={handleSubmit(submitDimensions)}
                  >
                    <div className="px-4 sm:px-6">
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-xl mb-2 leading-6 font-bold text-gray-900"
                        >
                          Sample Details Form
                        </Dialog.Title>
                        <div className="w-full flex flex-col items-start border-b my-2 ">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Dimensions
                          </h3>
                          <p className="mt-1 pb-3 max-w-2xl text-xs text-gray-500">
                            Enter specific dimensions to help design filling
                            trays accurately
                          </p>
                        </div>
                        {/* <div className="grid grid-cols-4 gap-2 py-4 border-b">
                            {activeProduct.map((entry, index) => {
                            return (
                              <React.Fragment>
                                <label
                                  key={index}
                                  className="text-gray-800 text-left ml-2"
                                >
                                  {entry.name}
                                </label>
                                <div className="relative rounded-md shadow-sm border border-gray-300">
                                  <input
                                    type="number"
                                    name={entry.name}
                                    ref={register({
                                      required: "An Input is Required",
                                    })}
                                    className="focus:ring-blue-500 focus:border-blue-500 block w-full p-1 pl-2 pr-12 sm:text-sm  rounded-md"
                                    placeholder="0"
                                  />
                                  <div className="absolute inset-y-0 right-0 flex items-center">
                                    <p className="focus:ring-blue-500 focus:border-blue-500 h-full py-1 pl-4 pr-8 border-l border-gray-300 bg-gray-50 text-gray-500 sm:text-sm  rounded-r-md">
                                      {entry.unit_of_measure}
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })} 
                        </div>*/}
                        <div className="w-full flex flex-row items-start border-b my-2 ">
                          <div className="flex flex-col items-start justify-end">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                              Received Quantity
                            </h3>
                            <p className="mt-1 pb-3 max-w-2xl text-xs text-gray-500">
                              Please Identify how many samples for this
                              container were received.
                            </p>
                          </div>
                          <div className="relative rounded-md shadow-sm border border-gray-300">
                            <input
                              type="number"
                              min="10"
                              name="quantity"
                              // ref={register({
                              //   required: "An Input is Required",
                              // })}
                              className="focus:ring-blue-500 focus:border-blue-500 block w-full p-1 pl-2 pr-12 sm:text-sm  rounded-md"
                              placeholder="0"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center">
                              <p className="focus:ring-blue-500 focus:border-blue-500 h-full py-1 pl-4 pr-8 border-l border-gray-300 bg-gray-50 text-gray-500 sm:text-sm  rounded-r-md">
                                units
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-full flex flex-col items-start border-b my-2 ">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Images
                          </h3>
                          <p className="mt-1 pb-3 max-w-2xl text-xs text-gray-500">
                            Uploading Images of the samples help us identify
                            your assets to avoid errors along the pipeline
                          </p>
                        </div>
                        <div className="grid grid-cols-3 gap-2 py-4">
                          <label className="text-gray-800 text-left ml-2">
                            <span>Top View:</span>
                            <span className="block text-xs text-gray-400">
                              A perspective directly above the sample is seen.
                            </span>
                            <span className="block text-xs text-blue-400 animate-pulse pt-0.5 font-bold">
                              {/* <a>Example</a> */}
                            </span>
                          </label>
                          <div className="col-span-2 flex justify-center px-6 pt-3 pb-5 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                              {watch("top_image")?.length ? (
                                <p className="text-gray-700 font-medium my-1 border border-gray-200 rounded-md">
                                  File:{" "}
                                  <span className="text-green-700 font-semibold">
                                    {watch("top_image")[0]?.name}
                                  </span>
                                </p>
                              ) : (
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="top_image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-atgBlue hover:text-blue-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    name="top_image"
                                    id="top_image"
                                    type="file"
                                    // ref={register({
                                    //   required: "An Input is Required",
                                    // })}
                                    className="sr-only"
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          </div>
                          <label className="text-gray-800 text-left ml-2">
                            <span>Front View:</span>
                            <span className="block text-xs text-gray-400">
                              A perspective where the sample is viewed from the
                              front.
                            </span>
                            <span className="block text-xs text-blue-400 animate-pulse pt-0.5 font-bold">
                              {/* <a>Example</a> */}
                            </span>
                          </label>
                          <div className="col-span-2 flex justify-center px-6 pt-3 pb-5 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                              {watch("front_image")?.length ? (
                                <p className="text-gray-700 font-medium my-1 border border-gray-200 rounded-md">
                                  File:{" "}
                                  <span className="text-green-700 font-semibold">
                                    {watch("front_image")[0]?.name}
                                  </span>
                                </p>
                              ) : (
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="front_image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-atgBlue hover:text-blue-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    name="front_image"
                                    id="front_image"
                                    type="file"
                                    // ref={register({
                                    //   required: "An Input is Required",
                                    // })}
                                    className="sr-only"
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          </div>
                          <label className="text-gray-800 text-left ml-2">
                            <span>Isometric View:</span>
                            <span className="block text-xs text-gray-400">
                              A perspective where front facing angles can be
                              seen.
                            </span>
                            <span className="block text-xs text-blue-400 animate-pulse pt-0.5 font-bold">
                              {/* <a>Example</a> */}
                            </span>
                          </label>
                          <div className="col-span-2 flex justify-center px-6 pt-3 pb-5 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                              {watch("isometric_image")?.length ? (
                                <p className="text-gray-700 font-medium my-1 border border-gray-200 rounded-md">
                                  File:{" "}
                                  <span className="text-green-700 font-semibold">
                                    {watch("isometric_image")[0]?.name}
                                  </span>
                                </p>
                              ) : (
                                <svg
                                  className="mx-auto h-12 w-12 text-gray-400"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 48 48"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              )}
                              <div className="flex text-sm text-gray-600">
                                <label
                                  htmlFor="isometric_image"
                                  className="relative cursor-pointer bg-white rounded-md font-medium text-atgBlue hover:text-blue-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                >
                                  <span>Upload a file</span>
                                  <input
                                    name="isometric_image"
                                    id="isometric_image"
                                    type="file"
                                    // ref={register({
                                    //   required: "An Input is Required",
                                    // })}
                                    className="sr-only"
                                  />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                PNG, JPG, GIF up to 10MB
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-atgBlue text-base font-medium text-white hover:bg-blue-800 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                      >
                        {isSubmitting && (
                          <svg
                            className="animate-spin h-5 w-5 text-white self-center mr-2"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40 40"
                            enableBackground="new 0 0 40 40"
                            xml="preserve"
                          >
                            <path
                              opacity="0.2"
                              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                            />
                            <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                          </svg>
                        )}
                        Continue
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Return
                      </button>
                    </div>
                  </form>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        <div className="flex h-4/5 overflow-auto flex-col mt-4">
          <table className="border rounded-lg">
            <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
              <tr>
                {ProductHeaders.map((entry, index) => {
                  return (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {entry}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {loading || !data ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <svg
                    className="animate-spin h-24 w-24 text-blue-200 self-center mr-2 text-center"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    enableBackground="new 0 0 40 40"
                    xml="preserve"
                  >
                    <path
                      opacity="0.2"
                      d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                    />
                    <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                  </svg>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data.allSamples.map((product, pIndex) => (
                  <tr
                    key={product?.name}
                    className={
                      pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {product?.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {product?.containerType?.frontendIdentifier}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                      <span className="w-min ml-auto flex justify-end items-center  py-2 px-4 text-xs  animate-pulse leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {product?.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {product?.expectedQuantity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {product?.receivedQuantity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        type="button"
                        onClick={() => {
                          SetActiveProduct(product);
                          setOpen(true);
                        }}
                        className=" flex flex-row items-center justify-end gap-3 w-full text-blue-600 hover:text-blue-900"
                      >
                        <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {/* {data && (
          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm  bg-gray-50 border-gray-200 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium text-blue-900 ">
                  {1 + skipValue}
                </span>{" "}
                to{" "}
                <span className="font-medium text-blue-900 ">
                  {20 + skipValue}
                </span>{" "}
                of <span className="font-medium text-green-900">625</span> Items
              </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
              {skipValue > 19 && (
                <button
                  onClick={() => setSkipValue(skipValue - 20)}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Previous
                </button>
              )}
              {data.items.length === 20 && (
                <button
                  onClick={() => setSkipValue(skipValue + 20)}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                >
                  Next
                </button>
              )}
            </div>
          </nav>
        )} */}
      </div>
    </main>
  );
};

export default InventoryProductsView;
