import axios from "axios";
import React, { Fragment } from "react";
import { Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "/roboto.ttf",
      fontWeight: 400,
    },
  ],
});

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "start",
    // fontStyle: "bold",
    marginTop: "6px",
  },
  description: {
    width: "45%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "10px",
    fontFamily: "Helvetica-Bold",
  },
  promo: {
    fontSize: "9px",
    fontFamily: "Helvetica-Bold",
    color: "#0aa12f",
  },
  qty: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "12%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: "9px",
    paddingTop: "2px",
  },
  rate: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: "9px",
    paddingTop: "2px",
  },
  amount: {
    width: "28%",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    textAlign: "right",
    fontSize: "9px",
    paddingTop: "2px",
  },
  bom: {
    display: "flex",
    flexDirection: "row",
  },
  discount: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "between",
  },
  bomText: {
    fontSize: 9,
    lineHeight: 1.1,
  },
  discountAmount: {
    color: "#008640",
  },
  HeaderS: {
    fontFamily: "Helvetica-Bold",
    color: "#1BABF2",
    fontSize: 11,
  },
  HeaderB: {
    fontFamily: "Helvetica",
    color: "#1BABF2",
    fontSize: 11,
  },
  textS: {
    fontFamily: "Roboto",
    fontSize: 9,
  },
  copy: {
    // fontFamily: "Roboto",
    fontSize: 7,
    color: "#92969E",
  },
  price: {
    textAlign: "right",
    fontFamily: "Roboto",
    fontSize: 15,
  },
  logo: {
    maxWidth: "50%",
    height: "auto",
    // marginRight: "auto",
  },
});

const InvoiceTableRow = ({ products, items }) => {
  const rows = items?.map((item, index) => {
    const isTray =
      item.item.productGroups && item.item.productGroups[0]?.name === "Tray";
    const productID = item.productUuid;
    const parsedProducts = JSON.parse(products);
    let product = parsedProducts && parsedProducts[productID];
    let image;
    return (
      <View style={styles.row} key={index}>
        {image && <Image src={image} />}
        <View style={styles.description}>
          <Text style={styles.HeaderS}>
            {item.item.title}
            {isTray &&
              product &&
              ` [${product?.container_type}-${product?.amount}${
                product?.container_type !== "cartridges" ? "ml" : "g"
              }]`}
          </Text>
          {item.item?.accountingDescription && (
            <Text style={styles.copy}>{item.item?.accountingDescription}</Text>
          )}
          {item.appliedPromotion && (
            <Text style={styles.promo}>
              PROMO: {item.appliedPromotion.name}
            </Text>
          )}
        </View>
        <View style={styles.qty}>
          <Text style={styles.HeaderB}>Quantity</Text>
          <Text>{item.itemQuantity}</Text>
        </View>
        <View style={styles.rate}>
          <Text style={styles.HeaderB}>Rate</Text>
          <Text>
            {Number.parseFloat(item.pricePerUnit)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
          {item.discountPerUnit && parseFloat(item.discountPerUnit) > 0 && (
            <Text style={styles.discount}>
              <span style={styles.discountAmount}>
                -{" "}
                {(
                  Number.parseFloat(item.pricePerUnit) *
                  parseFloat(item.discountPerUnit)
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </Text>
          )}
        </View>
        <View style={styles.amount}>
          <Text style={styles.HeaderB}>Amount</Text>
          <Text style={styles.price}>
            ${" "}
            {Number.parseFloat(
              (item.pricePerUnit -
                (item.discountPerUnit
                  ? parseFloat(item.pricePerUnit) *
                    parseFloat(item.discountPerUnit)
                  : 0)) *
                item.itemQuantity
            )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        </View>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
