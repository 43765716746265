import Button from "../inputs/button/simple";
export default function Page({ data, map, condition }) {
  return (
    <div className="border-b border-gray-200 py-4 mb-2 flex flex-row  justify-between items-center ">
      <h3 className="text-2xl leading-6 font-medium text-gray-900">
        {data[map.titleAccessor]}
      </h3>
      {condition && (
        <div className="flex flex-row  items-center justify-end col-span-2">
          <Button data={data.button} />
        </div>
      )}
    </div>
  );
}
