import LoadingTable from "../loading/page";
import NotAvailable from "../notAvailable/page";
import Price from "../display/price";
import Date from "../display/date";
import Link from "../inputs/link/simple";
import Button from "../inputs/button/linkStyle";

export default function Simple({ data, TableStructure, loading, label }) {
  if (loading) return <LoadingTable />;
  if (!data || !data.length) return <NotAvailable label={label} />;

  return (
    <div className="flex flex-col px-6 py-6">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-2">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {TableStructure.map((entry, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {entry.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {data.map((dataPoint, pointIndex) => (
                  <tr
                    key={pointIndex}
                    className={`${
                      pointIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}
                  >
                    {TableStructure.map((entry, index) => {
                      const { type, precedes, style, depth1, depth2, depth3 } =
                        entry;
                      let render = null;
                      let value = dataPoint[entry.accessor];
                      value && depth1 && (value = value[depth1]);
                      value && depth2 && (value = value[depth2]);
                      value && depth3 && (value = value[depth3]);
                      type === "name" &&
                        (value =
                          dataPoint?.firstName + " " + dataPoint?.lastName);

                      if (type === "link") {
                        render = (
                          <Link value={`${precedes}${value}`} text={value} />
                        );
                      } else if (type === "price") {
                        render = value ? (
                          <Price
                            value={value}
                            currency={dataPoint[entry.currency]}
                          />
                        ) : (
                          <p>Not Available</p>
                        );
                      } else if (type === "button") {
                        render = (
                          <Button
                            data={{ ...entry.button, value: dataPoint }}
                          />
                        );
                      } else if (type === "date") {
                        render = <Date value={value} />;
                      } else if (type === "loop") {
                        const { loopDepth1, compare } = entry;
                        let Summation = value?.reduce(
                          (pSum, line) => pSum + parseFloat(line[loopDepth1]),
                          0
                        );
                        let renderValue = (
                          (Summation / parseFloat(dataPoint[compare])) *
                          100
                        ).toFixed(2);
                        render = Summation ? (
                          <span className="text-green-700 font-medium">{`${renderValue} %`}</span>
                        ) : (
                          <span className="text-red-600 font-medium">
                            Not Paid
                          </span>
                        );
                      } else if (type === "email") {
                        render = (
                          <a
                            href={`mailto:${value}`}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            {value}
                          </a>
                        );
                      } else if (type === "phone") {
                        render = (
                          <a
                            href={`tel:+${value}`}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            {value}
                          </a>
                        );
                      } else {
                        render = value ? (
                          <span className={style}>{value}</span>
                        ) : (
                          <span className={entry.altStyle}>{entry.alt}</span>
                        );
                      }
                      return (
                        <td
                          key={index}
                          className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                        >
                          {render}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
