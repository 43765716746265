/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useQuery, ApolloClient, InMemoryCache } from "@apollo/client";
import {
  setOrderList,
  selectSortBy,
  selectRequiredShipping,
  setHiddenColumns,
} from "./saleSlice";
import { useDispatch, useSelector } from "react-redux";
import { USER, VENDOR_ORDERS } from "./queries";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

const UseSpare = () => {
  const userResponse = useQuery(USER);
  const dispatch = useDispatch();
  const sortBy = useSelector(selectSortBy);
  const requiredShipping = useSelector(selectRequiredShipping);
  const [cookies] = useCookies();
  const [skipValue, setSkipValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stauses, setStatuses] = useState([
    "partially_shipped",
    "ready_for_production",
  ]);
  const client = new ApolloClient({
    uri: process.env.REACT_APP_URI,
    credentials: "same-origin",
    cache: new InMemoryCache(),
    headers: {
      "Content-Type": "application/json",
      authorization: cookies.user !== "null" ? `JWT ${cookies.user.token}` : "",
    },
  });

  const orderResponse = useQuery(VENDOR_ORDERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      skip: skipValue,
      first: 25,
      order: sortBy,
      statusS: stauses,
      vendor: userResponse.data?.user?.vendor?.id,
    },
    onCompleted: (d) => {
      let hiddenColumns = {
        commission: true,
        currency: true,
        invoice: true,
        // vendorName: true,
      };
      dispatch(setHiddenColumns(hiddenColumns));
      handleSortOrder(d.orders);
    },
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  async function handleSearch(term) {
    if (!term) {
      orderResponse.refetch({ skip: 0, searchTerm: "" });
      return;
    }
    let search = term.trim();
    if (!search) return;
    setIsLoading(true);
    let startsWithNumber = /^\d/.test(search);
    let toQuery = {
      idS: startsWithNumber ? search : "",
      orgS: search,
      estimateS: startsWithNumber ? search : "",
    };
    const fetchOrders = (apolloClient, variables) =>
      apolloClient.query({
        query: VENDOR_ORDERS,
        variables: variables,
      });
    let orders = [];
    for (const [k, value] of Object.entries(toQuery)) {
      if (!value) continue;
      let variables = {};
      variables[k] = value;
      const orderResponse = await fetchOrders(client, variables);
      orders = [...orders, ...orderResponse.data.orders];
    }
    handleSortOrder(orders);
  }

  async function handleSortOrder(data) {
    setIsLoading(true);
    let tableData = [];
    if (!data || data.length === 0) {
      setIsLoading(false);
      dispatch(setOrderList(tableData));
      return;
    }
    for (const entry of data) {
      tableData.push({
        id: entry.id,
        organization: entry.client.name,
        clientID: entry.client?.id,
        pipedrive: entry.lead?.crmId,
        totalPrice:
          entry.selectedEstimate &&
          parseFloat(entry.selectedEstimate.total).toFixed(2),
        currency: entry.currency,
        createdBy: `${entry.createdBy?.firstName} ${entry.createdBy?.lastName}`,
        invoice: entry.hasInvoice,
        expected: entry.expectedCloseDate || entry.dueDate,
        created: entry.createdAt,
        vendor: entry.vendorOrderNumber,
        payments: entry.paymentTransactions,
        commission: entry.revenueReport?.fullCommission,
        status: entry.currentStatus?.name,
        selectedEstimate: entry.selectedEstimate?.number,
        vendorName: entry.vendors[0]?.name,
      });
    }
    dispatch(setOrderList(tableData));
    setIsLoading(false);
  }

  return {
    SpareHeaders,
    refetch: orderResponse.refetch,
    skipValue,
    setSkipValue,
    setSearchTerm,
    setStatuses,
    requiredShipping,
    isLoading: isLoading || orderResponse.loading,
  };
};
export default UseSpare;

/************************************************** */
// TABLE HEADERS
const SpareHeaders = [
  {
    Header: "ID",
    accessor: "id",
    isSortable: true,
    sortField: "id",
    Cell: (props) => (
      <Link
        to={`/orders/${props.row.original.id}`}
        className="text-blue-700 hover:text-blue-900"
      >
        {props.row.original.id}
      </Link>
    ),
  },
  {
    Header: "Organization",
    accessor: "organization",
    Cell: (props) => (
      <p className="font-medium">{props.row.original.organization}</p>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    isSortable: true,
    sortField: "status",
    searchable: "multiSelect",
    searchField: "statusS",
    options: [
      {
        name: "partially_shipped",
      },
      {
        name: "ready_for_production",
      },
    ],
    Cell: (props) => {
      let color = "bg-green-100 text-green-800";
      switch (props.row.original.status) {
        case "Awaiting Payment":
          color = "bg-red-100 text-red-800";
          break;
        case "Ready For Production":
          color = "bg-yellow-100 text-yellow-800";
          break;
        case "Awaiting Response":
          color = "bg-blue-100 text-blue-800";
          break;
        case "Archived":
        case "Expired":
          color = "bg-yellow-900 text-white";
          break;
        default:
          break;
      }
      return (
        <p
          className={`px-3 py-0.5  inline-flex text-xs leading-5 font-semibold rounded-full ${color}`}
        >
          {props.row.original.status}
        </p>
      );
    },
  },
  {
    Header: "Created On",
    accessor: "created",
    isSortable: true,
    sortField: "created_at",
    Cell: (props) => (
      <p className="font-medium text-red-900">
        {props.row.original.created?.split("T")[0]}
      </p>
    ),
  },
  {
    Header: "Selected Estimate",
    accessor: "selectedEstimate",
    isSortable: true,
    sortField: "selected_estimate",
    Cell: (props) => (
      <p className="font-medium">{props.row.original.selectedEstimate}</p>
    ),
  },
  // {
  //   Header: "Vendor",
  //   accessor: "vendorName",
  //   Cell: (props) => (
  //     <p className="font-medium">{props.row.original.vendorName}</p>
  //   ),
  // },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Total",
    accessor: "totalPrice",
    Cell: (props) =>
      parseFloat(props.row.original.totalPrice) ? (
        <p>
          <span className="text-gray-600">{`${props.row.original.currency} $`}</span>
          <span className="text-gray-900 font-medium">
            {parseFloat(props.row.original.totalPrice)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "Pending"}
          </span>
        </p>
      ) : (
        <p className="text-red-700">Pending</p>
      ),
  },
];
