import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

//HOOKS
import useClient from "../../hooks/useClient";
import { selectActiveAddress } from "../../hooks/clientSlice";

//COMPONENTS
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Text from "../../../global/components/inputs/text/simple";
import Toggle from "../../../global/components/inputs/toggle/simple.jsx";

const AddAddress = ({ modalOn, setModal }) => {
  const methods = useForm();
  const activeAddress = useSelector(selectActiveAddress);
  const { handleSubmit, setValue } = methods;
  const { handleAddAddress, addingAddress } = useClient();
  const [isBilling, setIsBilling] = useState(false);

  useEffect(() => {
    if (modalOn === "add-address" && activeAddress) {
      setValue("lineOne", activeAddress.lineOne);
      setValue("city", activeAddress.city);
      setValue("state", activeAddress.state);
      setValue("countryCode", activeAddress.countryCode);
      setValue("postalCode", activeAddress.postalCode);
      setValue("isBilling", activeAddress.isBilling);
      setValue("managerName", activeAddress.receiverName);
      setValue("managerNumber", activeAddress.receiverPhoneNumber);
    }
  }, [activeAddress]);

  return (
    <ModalFrame
      open={modalOn === "add-address"}
      close={setModal}
      title={activeAddress ? "Edit Address " : "Add New Address"}
    >
      <form
        onSubmit={handleSubmit(handleAddAddress)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the form below for address creation for this client.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="grid grid-cols-3 gap-4">
            <Text
              data={{
                label: "Line One",
                id: "lineOne",
                textType: "street Address",
                placeholder: "Enter street address",
                methods: methods,
                registerValue: "lineOne",
                registerOptions: { required: true },
              }}
            />
            <Text
              data={{
                label: "City",
                id: "city",
                textType: "city",
                placeholder: "Enter city name",
                methods: methods,
                registerValue: "city",
                registerOptions: { required: true },
              }}
            />
            <Text
              data={{
                label: "State",
                id: "state",
                textType: "state",
                placeholder: "Enter state abbreviation",
                methods: methods,
                registerValue: "state",
                registerOptions: { required: true },
              }}
            />
            <Text
              data={{
                label: "Country Code",
                id: "countyCode",
                textType: "country",
                placeholder: "Enter country [CA, US]",
                methods: methods,
                registerValue: "countryCode",
                registerOptions: { required: true },
              }}
            />
            <Text
              data={{
                label: "Postal Code",
                id: "postalCode",
                textType: "postal",
                placeholder: "Enter postal code",
                methods: methods,
                registerValue: "postalCode",
                registerOptions: { required: true },
              }}
            />
            {activeAddress && (
              <Toggle
                state={isBilling}
                setState={setIsBilling}
                data={{
                  label: "Type",
                  off: "Regular",
                  on: "Billing",
                  methods: methods,
                  registerValue: "isBilling",
                }}
              />
            )}
            <Text
              data={{
                label: "Manager Name",
                id: "managerName",
                textType: "text",
                placeholder: "Enter manager name",
                methods: methods,
                registerValue: "managerName",
                registerOptions: { required: false },
              }}
            />
            <Text
              data={{
                label: "Manager Number",
                id: "managerNumber",
                textType: "text",
                placeholder: "Enter manager number",
                methods: methods,
                registerValue: "managerNumber",
                registerOptions: { required: false },
              }}
            />
          </div>
          <ModalCTA cancel={setModal} submitting={addingAddress} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddAddress;
