import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//HOOKS
import useClient from "../../hooks/useClient";
import { selectClient } from "../../hooks/clientSlice";

//COMPONENTS
import LoadingPage from "../../../global/components/loading/page";
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Select from "../../../global/components/inputs/select/simple.jsx";

const AddClientModal = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const [alert, setAlert] = useState(false);
  const { loadClientView, createNewOrder, loadingClient, loadingLead } =
    useClient();
  const ClientDetails = useSelector(selectClient);
  const { id } = useParams();

  useEffect(() => {
    if (modalOn === "new-order" && !ClientDetails) loadClientView(id);
  }, [modalOn]);

  useEffect(() => {
    if (watch("contact")) {
      const contact = ClientDetails?.contacts?.find(
        (client) => client.id === watch("contact")
      );
      if (contact && !contact.crmId) setAlert(true);
    }
  }, [watch("contact")]);

  const options = ClientDetails?.contacts?.map((c) => ({
    label: `${c.firstName} ${c.lastName}`,
    value: c.id,
  }));

  if (loadingClient || loadingLead)
    return (
      <ModalFrame
        open={modalOn === "new-order"}
        close={setModal}
        title={"Select Order Contact"}
      >
        <LoadingPage />
      </ModalFrame>
    );

  return (
    <ModalFrame
      open={modalOn === "new-order"}
      close={setModal}
      title={"Select Order Contact"}
    >
      <form
        onSubmit={handleSubmit(createNewOrder)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the dropdown to determine the contact for this new order.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <Select
            label="Contact"
            id="contact"
            methods={methods}
            registerValue="contact"
            registerOptions={{ required: true }}
            options={options}
          />
          {alert ? (
            <p className=" text-sm text-red-600 mt-4">
              Customer Chosen Does not have a PipeDrive CRM ID. Please Update
              the customer first.
            </p>
          ) : (
            <ModalCTA cancel={setModal} />
          )}
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddClientModal;
