/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import {
  CREATE_PAYMENT,
  LOG_PAYMENT,
  REVISE_ORDER,
  UPDATE_ADDRESS,
  ARCHIVE_ORDER,
  UPDATE_RECIEVED_DATE,
  LOST_REASONS,
} from "../requests";
import { selectManualProgression } from "../hooks/saleSlice";
//COOKIES
import { useCookies } from "react-cookie";

// COMPONENTS
import Table from "../../components/Table/estimateSelector";
import PaymentsTable from "./orderPayments";
import EmailsTable from "./orderEmails";
import StatusesTable from "./orderStatuses";
import Header from "./orderHeader";
import SpareLines from "./orderSpareLines";
import ManualProgression from "./orderManualProgression";
import LogPaymentModal from "../../components/modals/orders/logPayment";
import UpdateDateModal from "../../components/modals/orders/updateDate";
import ReviseOrderModal from "../../components/modals/orders/revise";
import SubmitPaymentModal from "../../components/modals/orders/submitPayment";
import EditAddressModal from "../../components/modals/orders/editAddress";
import EstimatePopover from "../../components/PopeOver/estimate";
import ArchiveModal from "../../components/modals/orders/archive";
import DueModal from "../../components/modals/orders/confirmNew";
import LoadingPage from "../../components/Loading/page";

const SampleHeaders = ["Estimate Number", "Total", "Created At", "PDF"];

const UniqueOrdersView = ({
  setOnDetails,
  setSelectEstimate,
  setIsSpare,
  data,
  refetch,
  vendorID,
}) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const onManualProgression = useSelector(selectManualProgression);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reviseOrderModal, setReviseOrder] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const [alerting, setAlert] = useState(false);
  const [payment, setPayment] = useState(null);
  const [logPayment, setLogPayment] = useState(false);
  const [dateO, openDate] = useState(false);
  const [transactionID, setTransactionID] = useState(null);
  const [searchParams] = useSearchParams();
  const [showPopover, setShowPopover] = useState(false);
  const currentDate = new Date();
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  let navigate = useNavigate();
  const lostReasons = useQuery(LOST_REASONS);
  const view = searchParams.get("view") || null;
  let { id } = useParams();

  useEffect(() => {
    refetch();
  }, [showPopover]);

  const [archiveOrder] = useMutation(ARCHIVE_ORDER, {
    onCompleted: (d) => {
      window.location = `/orders${window.location.search}`;
    },
    onError: (e) => alert(e),
  });

  const [makePayment] = useMutation(CREATE_PAYMENT, {
    onCompleted: (d) => {
      window.location.reload();
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [logPaymentTransaction] = useMutation(LOG_PAYMENT, {
    onCompleted: (d) => {
      refetch();
      setLogPayment(false);
      setIsSubmitting(false);
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [editAddress] = useMutation(UPDATE_ADDRESS, {
    onCompleted: (d) => {
      refetch();
      setOpenAddress(false);
      setIsSubmitting(false);
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [reviseOrder] = useMutation(REVISE_ORDER, {
    onCompleted: (d) => {
      refetch();
      setReviseOrder(false);
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [updateDateM] = useMutation(UPDATE_RECIEVED_DATE, {
    onCompleted: (d) => {
      refetch();
      openDate(false);
      setIsSubmitting(false);
    },
    onError: (e) => {
      alert(e);
    },
  });

  async function submitForm() {
    setOnDetails(false);
  }

  async function submitPayment(form) {
    setIsSubmitting(true);
    makePayment({
      variables: {
        id: data.linkUuid,
        creditID: form.creditID,
        type: Array.isArray(payment) ? "credit_memo" : payment.name,
        confirmation: Array.isArray(payment) ? "applied credit" : form.number,
        value: form.amount,
      },
    });
  }

  async function handleLogPayment(form) {
    setIsSubmitting(true);
    let variables = {
      id: transactionID,
      amount: form.amount,
      date: form.date,
    };
    logPaymentTransaction({
      variables: variables,
    });
  }

  async function handleEditAddress(form) {
    let variables = {
      id: form.id,
      addressData: {
        lineOne: form.lineOne,
        countryCode: form.countryCode,
        city: form.city,
        state: form.state,
        postalCode: form.postalCode,
      },
    };
    editAddress({
      variables: variables,
    });
  }

  async function updateDate(data) {
    setIsSubmitting(true);
    let variables = {
      id: transactionID,
      date: data.date,
    };
    updateDateM({ variables });
  }

  if (!data) return <LoadingPage />;
  setIsSpare(data.salesType === "SPARE_PARTS");

  return (
    <div>
      <EstimatePopover
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        forBuild={data.selectedEstimate?.id}
      />
      <UpdateDateModal
        logPayment={dateO}
        setLogPayment={openDate}
        handleLogPayment={updateDate}
        isSubmitting={isSubmitting}
      />
      <LogPaymentModal
        logPayment={logPayment}
        setLogPayment={setLogPayment}
        handleLogPayment={handleLogPayment}
        payment={payment}
        isSubmitting={isSubmitting}
        currency={data.lead?.fundingCurrencySymbol}
      />
      <ReviseOrderModal
        openRevise={reviseOrderModal}
        setOpenRevise={setReviseOrder}
        reviseOrder={reviseOrder}
        id={id}
      />
      <SubmitPaymentModal
        openPayment={openPayment}
        setOpenPayment={setOpenPayment}
        submitPayment={submitPayment}
        payment={payment}
        isSubmitting={isSubmitting}
        currency={data.lead?.fundingCurrencySymbol}
      />
      <EditAddressModal
        openAddress={openAddress}
        setOpenAddress={setOpenAddress}
        handleEditAddress={handleEditAddress}
        address={data?.shippingAddress}
        isSubmitting={isSubmitting}
      />
      <ArchiveModal
        openArchive={openArchive}
        setOpenArchive={setOpenArchive}
        archiveOrder={archiveOrder}
        id={id}
        lostReasons={lostReasons}
      />
      <DueModal alert={alerting} setAlert={setAlert} id={id} />
      <Header
        data={data}
        setIsSpare={setIsSpare}
        setSelectEstimate={setSelectEstimate}
        refetch={refetch}
        setOpenAddress={setOpenAddress}
        setOnDetails={setOnDetails}
        setReviseOrder={setReviseOrder}
        vendorID={vendorID}
      />
      {onManualProgression && (
        <ManualProgression data={data} refetch={refetch} />
      )}
      {data.clientRequestedReQuoteOf && (
        <div className="bg-blue-50 overflow-hidden shadow border border-gray-200 rounded-lg mr-8 ml-8 mt-6">
          <div className="border-b border-gray-200 py-4 mb-2 flex flex-row justify-between items-center mx-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Requested Estimate
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                the estimate the client requested a re-quote from
              </p>
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            <Table
              headers={SampleHeaders}
              data={data.clientRequestedReQuoteOf}
              linkTo="/estimate"
              linkTitle="View"
              orderID={id}
              submitOrder={submitForm}
              setSelectEstimate={setSelectEstimate}
            />
          </div>
        </div>
      )}
      {view === "spare" ? (
        <SpareLines
          data={data}
          setShowPopover={setShowPopover}
          refetch={refetch}
        />
      ) : (
        <div className="bg-white overflow-hidden shadow border border-gray-100 rounded-lg mr-8 ml-8 mt-6">
          <div className="border-b border-gray-200 py-4 mb-2 flex flex-row justify-between items-center mx-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {cookies.user?.user?.role?.name !== "Vendor Admin"
                  ? "Estimate"
                  : "Purchase Order"}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {cookies.user?.user?.role?.name !== "Vendor Admin"
                  ? " All Estimates"
                  : "Purchase Order"}{" "}
                linked to an Order for the Client
              </p>
              {/* {cookies.user?.user?.role?.name === "Vendor Admin" &&
                data?.selectedEstimate && (
                  <p className="mt-1 text-red-600">
                    Viewing a PO for Orders that are "Ready for Production" will
                    push the status to "In Production".
                  </p>
                )} */}
            </div>
            {(data.currentStatus?.name === "New" ||
              data.currentStatus?.name === "Needs Revision" ||
              data.currentStatus?.name === "Re_quote Requested") &&
              (cookies.user?.user?.role?.name === "Executive" ||
                cookies.user?.user?.role?.name === "Sales Representative") && (
                <div className="mt-3 flex sm:mt-0 sm:ml-4">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setCookie("lead", data.lead, {
                        path: "/",
                        secure: true,
                        sameSite: true,
                      });
                      let due = new Date(data.dueDate);
                      const utc1 = Date.UTC(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate()
                      );
                      const utc2 = Date.UTC(
                        due.getFullYear(),
                        due.getMonth(),
                        due.getDate()
                      );

                      const difference = Math.floor(
                        (utc2 - utc1) / _MS_PER_DAY
                      );

                      // if (data.dueDate && difference < 30) {
                      //   setAlert(true);
                      // } else
                      navigate("/estimate");
                    }}
                    className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Add New
                  </button>
                </div>
              )}
          </div>
          <div className="px-4 py-5 sm:p-6">
            <Table
              headers={SampleHeaders}
              data={data}
              linkTo="/estimate"
              linkTitle="View"
              orderID={id}
              submitOrder={submitForm}
              setSelectEstimate={setSelectEstimate}
              vendorID={vendorID}
            />
          </div>
        </div>
      )}
      {cookies.user?.user?.role?.name !== "Vendor Admin" && (
        <PaymentsTable
          data={data}
          paymentTransactions={data.paymentTransactions}
          setOpenPayment={setOpenPayment}
          setPayment={setPayment}
          setLogPayment={setLogPayment}
          setTransactionID={setTransactionID}
          openDate={openDate}
          display={
            data.selectedEstimate && data.currentStatus?.name !== "Archived"
          }
        />
      )}
      {cookies.user?.user?.role?.name !== "Vendor Admin" && (
        <EmailsTable emails={data.emails} />
      )}
      {cookies.user?.user?.role?.name !== "Vendor Admin" && (
        <StatusesTable statuses={data.statusHistory} />
      )}
      {data.currentStatus?.name !== "Archived" &&
        data.currentStatus?.name !== "Shipped" &&
        data.currentStatus?.name !== "Partially Shipped" &&
        (cookies.user?.user?.role?.name === "Executive" ||
          cookies.user?.user?.role?.name === "Sales Representative" ||
          cookies.user?.user?.role?.name ===
            "Customer Success Representative") && (
          <div className="py-4 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col w-full">
              <h3 className="mt-2 w-full text-xl font-bold leading-7 text-gray-900  sm:truncate pb-2  border-b border-gray-200">
                Dangerous
              </h3>
            </div>
            <p className=" mt-4 font-medium text-gray-500 flex flex-row items-center justify-start gap-8">
              Archive Order:{" "}
              <button
                type="button"
                onClick={() => setOpenArchive(true)}
                className="inline-flex items-center justify-center px-3 py-1 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <span>Archive</span>
              </button>
            </p>
          </div>
        )}
    </div>
  );
};
export default UniqueOrdersView;
