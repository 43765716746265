/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { setPopOver } from "../../../../hooks/sales/saleSlice";
import { setImportInvoice } from "../../../../hooks/client/clientSlice";
// HOOKS
import useClientImport from "../../../../hooks/client/useClientImport";

const InvoiceDetails = ({ importInvoice, setLineNumber, setShowPopover }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, setValue } = useForm();
  const { handleSubmitInvoice, TableHeaders } = useClientImport();
  useEffect(() => {
    if (importInvoice) {
      setValue(
        "amount",
        parseFloat(importInvoice.totalAmount) -
          parseFloat(importInvoice.balance) || 0
      );
    }
  }, [importInvoice]);
  if (!importInvoice) return null;
  if (
    !(parseFloat(importInvoice.totalAmount) - parseFloat(importInvoice.balance))
  ) {
    alert("Unpaid Invoice");
    dispatch(setImportInvoice(null));
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="col-span-2">
        <h3 className="text-xl font-medium  py-4">Invoice Details</h3>
        <form
          onSubmit={handleSubmit(handleSubmitInvoice)}
          className="grid grid-cols-2 gap-2"
        >
          <div>
            <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
              Invoice #:{" "}
              <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                {importInvoice.number}
              </span>
            </p>
            <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
              Currency:{" "}
              <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                {importInvoice.currency}
              </span>
            </p>
            <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
              Invoice Balance:{" "}
              <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full text-green-900">
                <span>$</span>
                {parseFloat(importInvoice.balance)
                  ?.toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            </p>
          </div>
          <div>
            <p className="mb-2  font-medium text-gray-500 grid grid-cols-3">
              <span>Shipped On : </span>
              <input
                type="date"
                {...register("shipped", {
                  required: true,
                })}
                defaultValue={importInvoice.dueDate}
                className="col-span-2 focus:ring-blue-500 focus:border-blue-500  border border-gray-300 block p-1 px-2 sm:text-sm  rounded-md"
              />
            </p>
            <p className=" mb-2 font-medium text-gray-500 grid grid-cols-3">
              <span>Pay Amount: </span>

              <input
                type="number"
                step=".01"
                {...register("amount")}
                className="col-span-2 focus:ring-blue-500 focus:border-blue-500  border border-gray-300 block p-1 px-2 sm:text-sm  rounded-md"
              />
            </p>
          </div>
          <div className="flex items-end justify-end mt-2 col-span-2">
            <button
              type="submit"
              className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Submit Invoice
            </button>
          </div>
        </form>
      </div>
      <div className="col-span-4">
        <div className="flex flex-row justify-between items-end">
          <h3 className="text-xl font-medium  py-4">Invoice LineItems</h3>
          <h3 className="text-xl font-medium  py-4">
            Total:{"  "}
            <span className="text-green-900">
              $
              {parseFloat(importInvoice.totalAmount)
                ?.toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </span>
          </h3>
        </div>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {TableHeaders.map((header, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {importInvoice.lineItems.map((entry, i) => {
                return (
                  <tr key={i} className={`${!entry.skuNumber && "bg-red-50"}`}>
                    <td className="flex flex-row items-center  pl-6 py-4 ">
                      {entry.skuNumber ? (
                        <span>
                          <button
                            onClick={() =>
                              dispatch(
                                setPopOver({
                                  view: true,
                                  item: [{ skuNumber: entry.skuNumber }],
                                })
                              )
                            }
                            className="text-blue-600 hover:text-blue-900"
                          >
                            {entry.skuNumber}
                          </button>
                          {" | "}
                          <span className="text-green-800 whitespace-nowrap">
                            {entry.itemName}
                          </span>
                          {" | "}
                          <button
                            onClick={() => {
                              let deepCopy = JSON.parse(
                                JSON.stringify(importInvoice)
                              );
                              deepCopy.lineItems.splice(i, 1);
                              dispatch(setImportInvoice(deepCopy));
                            }}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </button>
                        </span>
                      ) : (
                        <div className="w-full flex flex-row justify-start gap-8 items-end">
                          <span>
                            <span className="text-red-800 whitespace-nowrap">
                              {entry.itemName}
                            </span>
                            <span className="block text-gray-700 font-bold">
                              Description:
                            </span>
                            <div
                              className="block text-gray-700 "
                              dangerouslySetInnerHTML={{
                                __html: entry?.description?.replace(
                                  /(\r\n|\n|\r)/gm,
                                  "<br>"
                                ),
                              }}
                            />
                          </span>
                          <div>
                            <button
                              type="button"
                              onClick={() => {
                                setShowPopover(true);
                                setLineNumber(entry.lineNumber);
                              }}
                              className="justify-center py-1 px-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              Add Item(s)
                            </button>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span>$</span>
                      {parseFloat(entry.pricePerUnit)
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {entry.quantity}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-gray-600 text-right">
                      <span>$</span>
                      {(
                        parseFloat(entry.pricePerUnit) *
                        parseFloat(entry.quantity)
                      )
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
