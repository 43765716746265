import React from "react";
import { useFormContext } from "react-hook-form";

// FORM COMPONENTS
import SimpleText from "../../components/FormComponents/text/simple";
import SimpleRadio from "../../components/FormComponents/radio/simple";
import SimpleDropdown from "../../components/FormComponents/dropdown/simpleWlabel";
import ModularTable from "../../components/FormComponents/table/modular";
import TextWTag from "../../components/FormComponents/text/wTag";
import SimpleCheckbox from "../../components/FormComponents/checkbox/simple";
import TraysTable from "../../components/FormComponents/table/traysTable";

const formComponents = {
  SimpleText,
  SimpleRadio,
  SimpleDropdown,
  ModularTable,
  TextWTag,
  SimpleCheckbox,
  TraysTable,
};

const FormComponentSelection = ({ props, build }) => {
  const methods = useFormContext();
  const SelectedComponent = formComponents[props.input];
  return <SelectedComponent props={props} build={build} {...methods} />;
};

export default FormComponentSelection;

export { formComponents };
