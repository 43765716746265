import React, { useRef } from "react";
import Button from "../inputs/button/simple";
export default function CTA({ cancel, submitting }) {
  const cancelButtonRef = useRef(null);
  return (
    <div className="mt-4 w-full border-t border-gray-200 gap-4 py-2 flex flex-col sm:flex sm:flex-row-reverse">
      <Button
        data={{
          type: "submit",
          label: "Submit",
          coloring: "green",
        }}
        submitting={submitting}
      />
      <Button
        data={{
          type: "button",
          label: "Cancel",
          event: cancel,
          coloring: "red",
          ref: cancelButtonRef,
        }}
      />
    </div>
  );
}
