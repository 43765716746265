import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

//HOOKS
import useClient from "../../hooks/useClient";
import { selectSaleReps } from "../../hooks/clientSlice";

//COMPONENTS
import LoadingPage from "../../../global/components/loading/page";
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Select from "../../../global/components/inputs/select/simple.jsx";

const AddClientModal = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { loadingSalesReps, updatingClient, handleAssignAE } = useClient();
  const SaleReps = useSelector(selectSaleReps);

  if (loadingSalesReps) return <LoadingPage />;
  if (!SaleReps) return null;

  const options = SaleReps.map((s) => ({
    label: `${s.firstName} ${s.lastName}`,
    value: s.id,
  }));

  return (
    <ModalFrame
      open={modalOn === "assign-AE"}
      close={setModal}
      title={"Assign New Account Executive"}
    >
      <form
        onSubmit={handleSubmit(handleAssignAE)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the dropdown to determine the Account Executive for this
          client.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <Select
            label="Account Executive"
            id="AE"
            methods={methods}
            registerValue="AE"
            registerOptions={{ required: true }}
            options={options}
          />
          <ModalCTA cancel={setModal} submitting={updatingClient} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddClientModal;
