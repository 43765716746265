import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableFooter from "./InvoiceTableFooter";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 0,
    borderColor: "#fffff",
  },
});

const InvoiceItemsTable = ({ estimate, orderDetails }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader />
    <InvoiceTableRow
      products={estimate?.productsJson}
      items={estimate?.lineItems}
    />
    <InvoiceTableFooter estimate={estimate} />
  </View>
);

export default InvoiceItemsTable;
