/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import { useEffect, useState } from "react";
import { useMutation, useLazyQuery } from "@apollo/client";
import { FETCH_CUSTOMER, FETCH_INVOICE, INVOICE_UPLOAD } from "./queries";
import { useSelector, useDispatch } from "react-redux";

import {
  setImportInvoice,
  selectImportInvoice,
  selectClient,
  selectCompanyName,
  setInvoiceList,
  selectInvoiceList,
} from "./clientSlice";
const useClientDetails = () => {
  const dispatch = useDispatch();
  const selectedClient = useSelector(selectClient);
  const importInvoice = useSelector(selectImportInvoice);
  const companyName = useSelector(selectCompanyName);
  const invoiceList = useSelector(selectInvoiceList);
  const [isLoading, setIsLoading] = useState(false);

  const TableHeaders = ["Item", "Price Per Unit", "Quantity", "Total"];

  useEffect(() => {
    if (selectedClient) {
      dispatch(setImportInvoice({}));
      dispatch(setInvoiceList([]));
    }
  }, [selectedClient]);

  const [fetchCustomer] = useLazyQuery(FETCH_CUSTOMER, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      dispatch(setInvoiceList(d.fetchCustomerInvoices));
      setIsLoading(false);
    },
    onError: ({ message }) => {
      alert(message);
      setIsLoading(false);
    },
  });

  const [fetchInvoice] = useLazyQuery(FETCH_INVOICE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      handleInvoiceImport(d.fetchCustomerInvoiceDetails);
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [submitInvoice] = useMutation(INVOICE_UPLOAD, {
    onCompleted: (d) => {
      fetchCustomer({
        variables: {
          company: companyName || selectedClient?.client.name,
        },
      });
      dispatch(setImportInvoice(null));
    },
    onError: (e) => alert(e),
  });

  async function handleInvoiceImport(data) {
    dispatch(setImportInvoice(data));
    setIsLoading(false);
  }

  async function handleSubmitInvoice(data) {
    let parsedLineItems = [];
    let forIndex = 1;
    let lines = importInvoice.lineItems.filter((e) => e.for === forIndex);
    let counter = 1;
    let multiFactor =
      data.amount &&
      parseFloat(data.amount) > parseFloat(importInvoice.totalAmount)
        ? Math.ceil(
            parseFloat(data.amount) / parseFloat(importInvoice.totalAmount)
          )
        : 1;
    do {
      let item = importInvoice.lineItems.find((e) => e.lineNumber === forIndex);
      let itemTotal =
        (item.pricePerUnit * parseFloat(item.quantity) * multiFactor) /
        (1 + parseFloat(selectedClient.client.salesTax?.taxPercentage));
      let forItemsTotal = 0;
      lines.forEach((e) => (forItemsTotal += parseFloat(e.pricePerUnit)));
      for (const line of lines) {
        let price =
          (parseFloat(line.pricePerUnit / forItemsTotal) * itemTotal) /
          parseFloat(line.quantity);
        parsedLineItems.push({
          lineNumber: counter,
          skuNumber: line.skuNumber,
          pricePerUnit: price,
          quantity: line.quantity,
        });
        counter++;
      }
      forIndex++;
      lines = importInvoice.lineItems.filter((e) => e.for === forIndex);
    } while (lines.length > 0);
    let location =
      selectedClient.client.locations[0]?.countryCode === "CA"
        ? selectedClient.client.locations[0]?.state
        : "US";

    let variables = {
      client: selectedClient.client.id,
      dateCreated: importInvoice.dateCreated.split("T")[0],
      dateShipped: data.shipped,
      invoiceNumber: importInvoice.number,
      lineItems: parsedLineItems,
      orderCurrencySymbol: importInvoice.currency,
      salesRepId: selectedClient.client.accountExecutive.id,
      location: location,
      amount:
        data.amount ||
        parseFloat(importInvoice.totalAmount) -
          parseFloat(importInvoice.balance),
      method: "wire_transfer",
    };
    submitInvoice({ variables });
  }

  return {
    fetchCustomer,
    fetchInvoice,
    isLoading,
    setIsLoading,
    invoiceList,
    importInvoice,
    TableHeaders,
    handleSubmitInvoice,
  };
};
export default useClientDetails;
