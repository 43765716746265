import React from "react";

//COMPONENTS
import NotAvailable from "../../global/components/notAvailable/page";
import Directory from "./directory";

const ClientPort = () => {
  return (
    <div className="flex-1 relative z-0 flex overflow-hidden pb-5 px-4 py-4 sm:pb-0 sm:px-6 lg:px-8">
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none order-last m-4">
        <NotAvailable label="Client Details" />
      </main>
      <aside className="relative order-first flex flex-col flex-shrink-0 w-1/4 pr-2 border-r border-gray-200">
        <Directory />
      </aside>
    </div>
  );
};

export default ClientPort;
