/* eslint-disable array-callback-return */
import React from "react";
import { Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import logo from "./logo.jpg";
import atgLogo from "./atg.jpg";

Font.register(
  {
    family: "Roboto",
    src: "/roboto.ttf",
  },
  {
    family: "Open Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
    ],
  }
);

const styles = StyleSheet.create({
  border: {
    width: "100%",
    borderBottom: "0.7px solid #808080",
    marginTop: "-18px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: 0,
  },
  reportTitle: {
    color: "#000000",
    fontFamily: "Roboto",
    letterSpacing: 0.5,
    fontSize: 30,
    textAlign: "center",
    marginTop: 8,
  },
  subText: {
    color: "#1BABF2",
    fontFamily: "Helvetica-Bold",
    letterSpacing: 0.2,
    fontSize: 10,
    textAlign: "right",
    marginTop: 2,
    marginLeft: "auto",
  },
  logo: {
    maxWidth: "60%",
    height: "auto",
    marginLeft: "auto",
    // marginRight: "auto",
  },
});

const InvoiceTitle = ({ estimate, isATG }) => {
  return (
    <View style={styles.block}>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <Text style={styles.reportTitle}>Estimate</Text>
        </View>
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={isATG ? atgLogo : logo} />
          <Text style={styles.subText}>
            {isATG ? "Fill Confident" : "Automation Supplier"}
          </Text>
        </View>
      </View>
      <View style={styles.border} />
    </View>
  );
};

export default InvoiceTitle;
