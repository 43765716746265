/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  METRICS,
  QUOTE,
  SALES_REPS,
  INT_CURRENCY,
  ADD_CURRENCY,
} from "./queries";
import { CheckIcon } from "@heroicons/react/solid";

import { Stats, Headers } from "./static";
import Loading from "../../Loading/page";

//COOKIES
import { useCookies } from "react-cookie";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SalesRepHomePanel({
  year,
  month,
  update,
  setUpdate,
  user,
}) {
  const [cookies] = useCookies(["user"]);
  const [reportObject, setReportObject] = useState(
    localStorage.getItem("report")
      ? JSON.parse(localStorage.getItem("report"))
      : null
  );
  const [allOrders, setAllOrders] = useState(false);
  const [rate, setRate] = useState(null);
  const [execAlt, setExecAlt] = useState(user === "Executive");
  const salesReps = useQuery(SALES_REPS);
  const quoteResponse = useQuery(QUOTE);
  const [getReport, reportResponse] = useLazyQuery(METRICS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => handleCommissions(d.metrics),
    onError: (e) => alert(e),
  });

  const [getCurrency, currencyResponse] = useLazyQuery(INT_CURRENCY, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => setRate(d.internalCurrency.conversionRateFromCad),
    onError: (e) => alert(e),
  });

  const [updateCurrency, updateRespnse] = useMutation(ADD_CURRENCY, {
    onCompleted: (d) =>
      getCurrency({ variables: { month: month, year: year } }),
    onError: (e) => alert(e),
  });

  useEffect(() => {
    if (update) setReportObject(null);
    if (salesReps.data && (update || !reportObject)) {
      let repId = salesReps.data.users.find(
        (rep) => rep.lastName === cookies.user?.user?.lastName
      )?.id;
      let variables = {
        year: year,
        month: [month],
        repId: repId || null,
      };
      if (
        JSON.stringify(variables) === JSON.stringify(reportResponse.variables)
      )
        handleCommissions(reportResponse.data?.metrics);
      else if (!reportResponse.loading) {
        getReport({ variables });
      }
      setUpdate(false);
    }
    if (document.getElementById("rep"))
      document.getElementById("rep").value = reportObject?.id;
    getCurrency({ variables: { month: month, year: year } });
  }, [salesReps, reportObject, update]);

  async function handleCommissions(data) {
    let id = data.salesRep?.id || "";
    if (document.getElementById("rep"))
      document.getElementById("rep").value = id;
    const currentDate = new Date();
    const dateTime =
      "Last Sync: " +
      currentDate.getDate() +
      "/" +
      (currentDate.getMonth() + 1) +
      "/" +
      currentDate.getFullYear() +
      " @ " +
      currentDate.getHours() +
      ":" +
      currentDate.getMinutes() +
      ":" +
      currentDate.getSeconds();
    let report = {
      id: id,
      year: year,
      month: month,
      timeStamp: dateTime,
      averageTimeToCloseInDays: data.averageTimeToCloseInDays.value,
      commissionGainedWithFullPaymentCad:
        data.commissionGainedWithFullPaymentCad.value,
      currentOrdersCount: data.currentOrdersCount.value,
      paymentReceivedOrdersCount: data.paymentReceivedOrdersCount.value,
      subtotalRevenueCad: data.subtotalRevenueCad.value,
      handedOrders: data.paymentReceivedOrders,
      orders: data.createdOrders,
    };
    setReportObject(report);
    localStorage.setItem("report", JSON.stringify(report));
  }

  if (!reportObject || reportResponse.loading)
    return (
      <div className="w-full flex flex-row items-center justify-center">
        <Loading />
      </div>
    );
  return (
    <div className="bg-connectGray px-4 sm:px-6 lg:max-w-7xl lg:mx-auto">
      <main className="">
        <div className="p-4">
          <div className="grid grid-cols-3 justify-between items-end pb-2">
            {!execAlt ? (
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Commission Tier
                </h3>
                <p className="text-sm text-gray-600">
                  Based on{" "}
                  <span className="text-green-900 font-semibold">
                    $
                    {parseFloat(reportObject?.subtotalRevenueCad)
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>{" "}
                  of Paid Orders
                </p>
              </div>
            ) : (
              <div />
            )}
            {user === "Executive" ? (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Sales Rep
                </label>
                <select
                  id="rep"
                  defaultValue={reportObject?.id}
                  onChange={(e) => {
                    if (e.target.value) setExecAlt(false);
                    else setExecAlt(true);
                    getReport({
                      variables: {
                        year: year,
                        month: [month],
                        repId: e.target.value || null,
                      },
                    });
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="">All</option>
                  {salesReps.data &&
                    salesReps.data.users.map((entry, index) => (
                      <option key={index} value={entry.id}>
                        {entry.firstName} {entry.lastName}
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <div />
            )}
            <div className="flex flex-col items-end">
              <h3 className="text-2xl text-right leading-6 font-semibold text-indigo-900">
                {reportObject?.month}-{reportObject?.year}
              </h3>
              <div className="flex flex-row gap-2">
                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Tax Rate
                  </label>
                  <input
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                    type="number"
                    step="0.01"
                    className="appearance-none w-min px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  />
                </div>
                <div className="flex items-end justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      updateCurrency({
                        variables: {
                          month: month,
                          year: year,
                          conversion: parseFloat(rate),
                        },
                      });
                    }}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>

          {!execAlt && (
            <div>
              <nav className="mx-4">
                <ol className="flex items-center w-full mt-4 ">
                  {quoteResponse.data &&
                    quoteResponse.data.quotaLevels.map((step, stepIdx) => (
                      <li
                        key={stepIdx}
                        className={classNames(
                          stepIdx !== quoteResponse.data.quotaLevels.length - 1
                            ? "pr-8 sm:pr-20"
                            : "",
                          "relative w-full"
                        )}
                      >
                        {stepIdx === 0 ||
                        parseFloat(reportObject?.subtotalRevenueCad) >
                          parseFloat(step.thresholdValue) ? (
                          <>
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="h-1 w-full bg-green-600" />
                            </div>
                            <div className="relative w-12 h-12 flex items-center justify-center bg-green-600 rounded-full ">
                              <CheckIcon
                                className="w-7 h-7 text-white"
                                aria-hidden="true"
                              />
                            </div>
                          </>
                        ) : parseFloat(reportObject?.subtotalRevenueCad) >
                            parseFloat(
                              quoteResponse?.data?.quotaLevels[stepIdx - 1]
                                .thresholdValue
                            ) &&
                          parseFloat(reportObject?.subtotalRevenueCad) <
                            parseFloat(step.thresholdValue) ? (
                          <>
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="h-1 w-full bg-gray-200" />
                            </div>
                            <div className="relative w-12 h-12 flex items-center justify-center bg-white border-2 border-green-600 rounded-full">
                              <span
                                className="h-3 w-3 bg-green-600 rounded-full"
                                aria-hidden="true"
                              />
                              <span className="sr-only">{step.name}</span>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="h-1 w-full bg-gray-200" />
                            </div>
                            <div className="group relative w-12 h-12 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full ">
                              <span
                                className="h-2.5 w-2.5 bg-transparent rounded-full "
                                aria-hidden="true"
                              />
                              <span className="sr-only">{step.name}</span>
                            </div>
                          </>
                        )}
                        <div className="absolute flex flex-col text-sm mt-2 text-gray-600 ml-2 font-medium">
                          {(
                            parseFloat(step.commissionPrecentage) * 100
                          ).toFixed(0)}
                          % <span>{step.thresholdValue?.substring(0, 3)}K</span>
                        </div>
                      </li>
                    ))}
                </ol>
              </nav>
            </div>
          )}

          <div className={execAlt ? "mt-8" : "mt-20"}>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Metrics
            </h3>
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5">
              {Stats.map((item, index) => (
                <div
                  key={index}
                  className="relative bg-white pt-5 px-4 pb-10 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
                >
                  <dt>
                    <p className="text-sm font-medium text-gray-500 truncate mb-1">
                      {execAlt && item.execAlt ? item.execAlt : item.name}
                    </p>
                  </dt>
                  <dd className="pb-3 flex items-baseline  justify-between  ">
                    <p className="text-2xl font-semibold text-gray-900">
                      {item.currency
                        ? `$${parseFloat(reportObject[item.stat])
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                        : reportObject[item.stat]}{" "}
                      {item.unit && item.unit}
                    </p>
                    <div className="absolute bottom-0 inset-x-0 border-t border-gray-100 bg-gray-50 py-2 px-6">
                      <div className="text-sm">
                        <button
                          onClick={() => {
                            if (item.allOrders) setAllOrders(true);
                            else setAllOrders(false);
                          }}
                          className="font-medium text-blue-600 hover:text-blue-500"
                        >
                          View Orders
                          <span className="sr-only"> {item.name} stats</span>
                        </button>
                      </div>
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {allOrders ? "All Orders" : "Completed Orders"}
            </h3>
            <div className="-mx-4 mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {Headers.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`py-3.5 pl-4 pr-3 text-${item.align} text-sm font-semibold text-gray-900 sm:pl-6`}
                      >
                        {execAlt && item.execAlt ? item.execAlt : item.name}
                      </th>
                    ))}
                    {execAlt && (
                      <th className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6">
                        Profit Margin
                      </th>
                    )}
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-right text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      {allOrders ? "Created At" : "Closed In"}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {reportObject &&
                    reportObject[allOrders ? "orders" : "handedOrders"]?.map(
                      (order, index) => (
                        <tr key={index}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <a
                              href={`/orders/${order.id}?view=${
                                order.salesType === "SPARE_PARTS"
                                  ? "spare"
                                  : "custom"
                              }`}
                              className="text-blue-700 hover:text-blue-900"
                            >
                              {order.id}
                            </a>
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-medium sm:table-cell">
                            {order.client?.name || order.lead?.companyName}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-yellow-800 font-medium sm:table-cell">
                            {order.currentStatus?.name}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {order.vendors && order.vendors[0]?.name}
                          </td>
                          <td className="whitespace-nowrap pr-3 py-4 text-sm  text-right font-medium text-green-900">
                            {order.currency} $
                            {order.total ? (
                              parseFloat(order.total)
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            ) : order.predictedEstimate ? (
                              <span className="text-yellow-500">
                                {parseFloat(order.predictedEstimate.total)
                                  ?.toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </span>
                            ) : (
                              "-"
                            )}
                          </td>
                          {execAlt && (
                            <td className="whitespace-nowrap pr-3 py-4 text-sm  text-right font-medium">
                              {order.profitMargin ? (
                                <span
                                  className={`${
                                    parseFloat(order.profitMargin) < 0.2
                                      ? "text-red-600"
                                      : "text-green-900"
                                  }`}
                                >
                                  {(
                                    parseFloat(order.profitMargin) * 100
                                  ).toFixed(1)}{" "}
                                  %
                                </span>
                              ) : (
                                <span className="text-yellow-800">Pending</span>
                              )}
                            </td>
                          )}
                          {/* <td className="whitespace-nowrap pr-3 py-4 text-sm  text-right font-medium text-green-900">
                            {order.commission
                              ? `CAD $
                          ${parseFloat(order.commission)
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                              : "-"}
                          </td> */}
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-900">
                            {allOrders
                              ? order.createdAt?.split("T")[0]
                              : `${order.daysToCloseSale} Days`}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
