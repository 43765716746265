import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

const UniqueOrderView = () => {
  return (
    <main
      className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Order | One Supply Connect</title>
      </Helmet>
      <Outlet />
    </main>
  );
};

export default UniqueOrderView;
