import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { gql, useQuery, useMutation } from "@apollo/client";

const tabs = [
  { name: "General", href: "#", current: true },
  { name: "Team Members", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const USER = gql`
  query user {
    user {
      vendor {
        id
        accountingApiToken {
          name
        }
      }
    }
  }
`;

const GET_XERO_URL = gql`
  query xeroAuthUrl {
    xeroAuthUrl
  }
`;

const ADD_XERO = gql`
  mutation addXeroApiToVendor($vendorID: ID!, $code: String!, $state: String!) {
    addXeroApiToVendor(vendorId: $vendorID, xeroApiCode: $code, state: $state) {
      success
    }
  }
`;

const SettingsView = () => {
  const xeroUrl = useQuery(GET_XERO_URL);
  const user = useQuery(USER);
  const location = window.location.href.split("?")[1];
  const [createXeroAuth] = useMutation(ADD_XERO, {
    onCompleted: (d) => (window.location = "/settings"),
  });

  useEffect(() => {
    async function authenticateQBO() {
      if (location && user.data) {
        let locations = location.split("&");
        let code = locations[0].split("=")[1];
        let state = locations[1].split("=")[1];
        createXeroAuth({
          variables: {
            code: code,
            state: state,
            vendorID: user.data?.user?.vendor?.id,
          },
        });
      }
    }
    authenticateQBO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Settings | One Supply Connect</title>
      </Helmet>
      <div className="flex-1 flex flex-col">
        <main className="flex-1 overflow-y-auto focus:outline-none">
          <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
            <div className="pt-10 pb-16">
              <div className="px-4 sm:px-6 md:px-0">
                <h1 className="text-3xl font-extrabold text-gray-900">
                  Settings
                </h1>
              </div>
              <div className="px-4 sm:px-6 md:px-0">
                <div className="py-6">
                  {/* Tabs */}
                  <div className="lg:hidden">
                    <label htmlFor="selected-tab" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="selected-tab"
                      name="selected-tab"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      defaultValue={tabs.find((tab) => tab.current).name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden lg:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            className={classNames(
                              tab.current
                                ? "border-blue-500 text-blue-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            )}
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>

                  {/* Description list with inline editing */}

                  <div className="mt-10 divide-y divide-gray-200">
                    <div className="space-y-1">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Account
                      </h3>
                      <p className="max-w-2xl text-sm text-gray-500">
                        Manage internal account.
                      </p>
                    </div>
                    {/* <div className="mt-6">
                      <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-40 items-center">
                          <dt className="text-sm font-medium text-gray-500">
                            Xero Connection
                          </dt>
                          <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="flex-grow">
                              {user.data?.user?.vendor?.accountingApiToken ? (
                                <CheckCircleIcon
                                  className="h-9 w-9 text-green-600"
                                  aria-hidden="true"
                                />
                              ) : (
                                <div>
                                  <a
                                    href={xeroUrl.data?.xeroAuthUrl}
                                    className="inline-flex justify-center rounded-md border-2 border-transparent shadow-sm px-4 py-2 hover:text-gray-900  hover:border-gray-900  bg-QBGreen hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                                  >
                                    Connect to Xero
                                  </a>
                                </div>
                              )}
                            </span>
                          </dd>
                        </div>
                      </dl>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SettingsView;
