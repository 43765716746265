/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { usePDF } from "@react-pdf/renderer";
import createInvoice from "../../helper/createVendorInvoice";
import { useDispatch, useSelector } from "react-redux";
import {
  setVendorInvoice,
  selectVendorInvoice,
  setOrderDetails,
} from "../../hooks/sales/saleSlice";
import { VENDOR, VENDORS, INVOICE } from "./queries";

const headers = ["Number", "Currency", "Date Issued", "Due Date", "View"];
const InvoiceView = ({ exec }) => {
  const dispatch = useDispatch();
  const selectedInvoice = useSelector(selectVendorInvoice);
  const [tableData, setTableData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [reClick, setReClick] = useState(false);
  const { PDF } = createInvoice();
  const [instance, updateInstance] = usePDF({ document: PDF });

  useEffect(() => {
    updateInstance();
  }, [selectedInvoice]);

  useEffect(() => {
    // if (clicked) window.open(instance.url, "_blank").focus();
    if (clicked || reClick) {
      let link = document.createElement("a");
      link.href = instance.url;
      link.download = `${selectedInvoice.number}.pdf`;
      link.click();
      setClicked(false);
      setReClick(false);
    }
  }, [instance.url, reClick]);

  const vendorResponse = useQuery(exec ? VENDORS : VENDOR, {
    onCompleted: (d) => {
      handleSortTable(d[exec ? "vendors" : "vendor"]);
    },
  });

  const [fetchInvoice, invoiceResponse] = useLazyQuery(INVOICE, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      dispatch(setVendorInvoice(d.commissionDueInvoice));
      setClicked(true);
    },
  });
  async function handleSortTable(data) {
    let order = exec ? data.find((e) => e.name === "ATG Pharma Inc") : data;
    dispatch(setOrderDetails(order));
    setTableData(exec ? data : [data]);
  }

  if (vendorResponse.loading || invoiceResponse.loading) {
    return (
      <div className="flex flex-col items-center justify-center p-8 max-w-6xl mx-auto w-full">
        <svg
          className="animate-spin h-12 w-12 text-blue-600 self-center"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 40"
          enableBackground="new 0 0 40 40"
          xml="preserve"
        >
          <path
            opacity="0.2"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
        </svg>
      </div>
    );
  }
  return (
    <div className="flex flex-col p-8 max-w-6xl mx-auto w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className=" align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-100 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-start"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableData.map((data, index) => (
                  <Fragment key={index}>
                    {data.commissionInvoices.map((entry, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                          {entry.number}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                          {entry.currency?.symbol}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                          {entry.dateIssued?.split("T")[0]}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-600">
                          {entry.dueDate?.split("T")[0]}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                          <button
                            type="button"
                            className="text-blue-600 hover:text-blue-900 focus:outline-none focus:shadow-outline"
                            onClick={async () => {
                              if (
                                selectedInvoice &&
                                selectedInvoice.id === entry.id
                              ) {
                                setReClick(true);
                              } else {
                                fetchInvoice({ variables: { id: entry.id } });
                              }
                            }}
                          >
                            Download invoice PDF{" "}
                            <span aria-hidden="true"> &rarr;</span>
                          </button>
                          {/* {exec && (
                            <a
                              href={`/invoices/${entry.id}`}
                              className="inline-flex justify-center  ml-4 rounded-md border-2 border-transparent shadow-sm px-2 py-1.5 hover:text-gray-900  hover:border-gray-900  bg-green-500 hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                            >
                              Payment Received
                            </a>
                          )} */}
                        </td>
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;
