import { gql } from "@apollo/client";

export const GET_ITEMS = gql`
  query getItems(
    $vendor: ID
    $skip: Int
    $first: Int
    $search: String
    $group: [String]
    $tags: [String]
  ) {
    items(
      vendorId: $vendor
      skip: $skip
      first: $first
      search: $search
      productGroups: $group
      productTags: $tags
    ) {
      id
      skuNumber
      title
      description
      versionNumber
      productGroups {
        name
      }
      unitOfMeasurement {
        name
        units
      }
      price {
        pricePerUnit
      }
      ecommercePrice {
        pricePerUnit
      }
    }
  }
`;

export const GROUPQUERY = gql`
  query getProductGroups {
    productGroups {
      id
      name
      accessor
      productGroupsCommission {
        percentage
      }
      associatedTags {
        name
        accessor
      }
    }
  }
`;

export const CREATEITEM = gql`
  mutation createItem(
    $boms: [BOMInput]!
    $item: ItemInput!
    $tags: [String]
    $groups: [ID]!
  ) {
    createItem(
      productGroupsIdList: $groups
      tagsAccessorList: $tags
      bomItemsList: $boms
      itemData: $item
    ) {
      item {
        id
      }
    }
  }
`;

export const VENDORS = gql`
  query vendors {
    vendors {
      name
      id
      nextSkuNumber
    }
  }
`;

export const CREATE_TAG = gql`
  mutation createProductTag($name: String!, $accessor: String!) {
    createProductTag(name: $name, accessor: $accessor) {
      tag {
        id
      }
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation createProductGroup(
    $name: String!
    $accessor: String!
    $associatedTags: [ID]
    $commission_percentage: Decimal
  ) {
    createProductGroup(
      name: $name
      accessor: $accessor
      associatedTagIds: $associatedTags
      commissionPercentage: $commission_percentage
    ) {
      productGroup {
        id
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateProductGroup($id: ID!, $associatedTags: [ID]) {
    updateProductGroup(productGroupId: $id, associatedTagIds: $associatedTags) {
      productGroup {
        id
      }
    }
  }
`;
