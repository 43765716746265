import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";

const SubmitPayment = ({
  openPayment,
  setOpenPayment,
  submitPayment,
  payment,
  isSubmitting,
  currency,
}) => {
  const { register, handleSubmit, getValues, setValue } = useForm();
  if (Array.isArray(payment) && !getValues("number"))
    setValue("number", "credit");

  return (
    <Transition.Root show={openPayment} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={openPayment}
        onClose={setOpenPayment}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:mt-8 sm:align-middle sm:max-w-xl sm:w-full sm:pt-6">
              <form onSubmit={handleSubmit(submitPayment)}>
                {!getValues("quantity") &&
                  openPayment &&
                  setValue("quantity", 10)}
                <div className="px-4 sm:px-6">
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-xl mb-4 leading-6 font-bold text-gray-900"
                    >
                      Payment Made Via:{" "}
                      {Array.isArray(payment) ? "Credit" : payment?.name}
                    </Dialog.Title>
                    {Array.isArray(payment) && (
                      <div className="w-full grid grid-cols-3 items-center gap-4 my-2 ">
                        <div className="col-span-2 flex flex-col items-start justify-end">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Credit
                          </h3>
                        </div>
                        <select
                          {...register("creditID", {
                            required: true,
                          })}
                          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none sm:text-sm rounded-md`}
                          defaultValue=""
                        >
                          <option value="">Select.... </option>
                          {payment.map((entry, index) => {
                            if (currency === entry.currency.symbol)
                              return (
                                <option key={index} value={entry.id}>
                                  ${entry.amountRemaining}-{entry.reasoning}
                                </option>
                              );
                          })}
                        </select>
                      </div>
                    )}
                    <div className="w-full grid grid-cols-3 items-center gap-4 my-2 ">
                      <div className="col-span-2 flex flex-col items-start justify-end">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Amount [{currency}]
                        </h3>
                        <p className="mt-1 pb-3 max-w-2xl text-xs text-gray-500 text-left ">
                          Please select the installment amount paid.
                        </p>
                      </div>
                      <input
                        type="number"
                        step="0.01"
                        {...register("amount", {
                          required: true,
                        })}
                        className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300 block p-1 px-2 sm:text-sm  rounded-md"
                      />
                    </div>
                    {!Array.isArray(payment) && (
                      <div className="w-full grid grid-cols-3 items-center gap-4 my-2 ">
                        <div className="col-span-2 flex flex-col items-start justify-end">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Confirmation
                          </h3>
                          <p className="mt-1 pb-3 max-w-2xl text-xs text-gray-500 text-left ">
                            Please provide the confirmation entry.
                          </p>
                        </div>
                        <input
                          type="text"
                          {...register("number", {
                            required: true,
                          })}
                          className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300 block p-1 px-2 sm:text-sm  rounded-md"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-700 text-base font-medium text-white hover:bg-blue-800 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {isSubmitting && (
                      <svg
                        className="animate-spin h-5 w-5 text-white self-center mr-2"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40 40"
                        enableBackground="new 0 0 40 40"
                        xml="preserve"
                      >
                        <path
                          opacity="0.2"
                          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                        />
                        <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                      </svg>
                    )}
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenPayment(false)}
                  >
                    Return
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SubmitPayment;
