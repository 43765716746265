import React, { Fragment } from "react";
import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "start",
    fontStyle: "bold",
    marginTop: "6px",
  },
  description: {
    width: "20%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "10px",
    fontFamily: "Helvetica-Bold",
  },
  est: {
    width: "20%",
    textAlign: "center",
    fontSize: "9px",
    paddingTop: "2px",
  },
  rate: {
    width: "20%",
    textAlign: "right",
    paddingRight: 12,
    fontSize: "9px",
    paddingTop: "2px",
  },
  amount: {
    width: "20%",
    textAlign: "right",
    paddingRight: 12,
    fontSize: "9px",
    paddingTop: "2px",
  },
  bom: {
    display: "flex",
    flexDirection: "row",
  },
  discount: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "between",
  },
  bomText: {
    fontSize: 9,
    lineHeight: 1.1,
  },
  discountAmount: {
    color: "#008640",
  },
  link: {
    fontSize: 8,
    textColor: "#0066ff",
  },
});

const InvoiceTableRow = ({ items }) => {
  const rows = items?.map((item, index) => {
    return (
      <View style={styles.row} key={index}>
        <View style={styles.description}>
          <Text style={styles.est}>
            EST#:{" "}
            <Link
              style={styles.link}
              src={`https://staff.onesupply.co/orders/${item.paymentTransaction?.order?.id}/estimates/${item.paymentTransaction?.order?.selectedEstimate?.id}`}
            >
              {item.paymentTransaction?.order?.selectedEstimate?.number}
            </Link>
          </Text>
          <Text style={styles.title}>
            {item.paymentTransaction?.order?.client?.name}
          </Text>
        </View>
        <Text style={styles.est}>
          {item.paymentTransaction?.receivedDate?.split("T")[0]}
        </Text>
        <View style={styles.rate}>
          <Text>
            ${" "}
            {Number.parseFloat(item.orderSubtotal)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        </View>
        <Text style={styles.amount}>
          ${" "}
          {Number.parseFloat(item.paymentTransaction?.amount)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          {/* ({Number.parseFloat(item.paymentTransaction?.totalPercentage) * 100}%) */}
        </Text>
        <Text style={styles.amount}>
          ${" "}
          {Number.parseFloat(item?.ncCostDue)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
        </Text>
        <Text style={styles.amount}>
          ${" "}
          {Number.parseFloat(item.commissionDue)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
