/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { ArrowLeftIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import ResolveUndefined from "../../../global/utils/manipulate/undefined";

//COOKIES
import { useCookies } from "react-cookie";

const GET_ORDER = gql`
  query getOrder($id: Int!) {
    order(id: $id) {
      currency
      client {
        name
      }
      selectedEstimate {
        taxPercentage
        id
        createdAt
        lineItems {
          id
          itemQuantity
          item {
            skuNumber
            title
            weightPounds
          }
          shipment {
            createdAt
          }
        }
      }
      shippingAddress {
        lineOne
        state
        city
        postalCode
        countryCode
      }
    }
  }
`;

const ADD_SHIPPING = gql`
  mutation shipOrder(
    $line: [ID]
    $orderId: ID!
    $shippedOn: DateTime!
    $trackingNumber: String
    $shippedWith: String
    $updateShippingDateOnly: Boolean
  ) {
    shipOrder(
      includedLineItemsIds: $line
      orderId: $orderId
      shippedOn: $shippedOn
      trackingNumber: $trackingNumber
      shippedWith: $shippedWith
      updateShippingDateOnly: $updateShippingDateOnly
    ) {
      success
    }
  }
`;

const UniqueOrderView = () => {
  const [cookies, setCookie] = useCookies();
  const [orderDetails, setOrderDetails] = useState(null);
  const [lineItems, setLineItems] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let { id } = useParams();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();
  const order = useQuery(GET_ORDER, {
    variables: {
      id: parseInt(id),
    },
    onCompleted: (d) => {
      setCurrency(d.order.currency);
      setOrderDetails(d.order.selectedEstimate);
      setShippingAddress(d.order.shippingAddress);
      setLineItems(d.order.selectedEstimate.lineItems);
      let total = 0;
      d.order.selectedEstimate.lineItems.forEach((item) => {
        total += parseFloat(item.item.weightPounds);
      });
      setTotalWeight(total);
      // setValue("date", date.split("T")[0]);
    },
  });

  const [addShipping, shippingResponse] = useMutation(ADD_SHIPPING, {
    onCompleted: (d) => (window.location = `/orders/${id}`),
    onError: (e) => alert(e),
  });

  async function handleShipping(data) {
    setIsSubmitting(true);
    let variables = {
      orderId: parseInt(id),
      shippedOn: data.date,
      trackingNumber: data.number,
      line: data.lineItems,
      shippedWith: data.carrier,
      updateShippingDateOnly: false,
    };
    addShipping({ variables });
  }

  async function handleExecShipping(data) {
    setIsSubmitting(true);
    let variables = {
      orderId: parseInt(id),
      shippedOn: data.date,
      updateShippingDateOnly: true,
    };
    addShipping({ variables });
  }
  if (!orderDetails) return <h1>loading...</h1>;

  if (cookies.user?.user?.role?.name !== "Vendor Admin") {
    return (
      <div
        className={`${
          isSubmitting && "opacity-40"
        } bg-gray-50 h-full border-t border-gray-300 mt-2`}
      >
        <div className="py-4 px-4 sm:px-6 lg:px-8 mx-4 sm:mx-6 lg:mx-8  flex items-center justify-between border-b">
          <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Shipping <span className="font-normal">|</span>{" "}
            {order?.data?.order?.client?.name}
          </h1>
          <div className="mt-5 flex xl:mt-0 xl:ml-4"></div>
        </div>
        <div>
          <form
            onSubmit={handleSubmit(handleExecShipping)}
            className="grid grid-cols-3 py-4 px-4 sm:px-6 lg:px-8 mx-4 sm:mx-6 lg:mx-8 border-b"
          >
            <div className="">
              <h3 className="text-left text-xl pb-2 font-medium">
                Shipping Address:
              </h3>
              <a
                href={`https://www.google.ca/maps/place/${shippingAddress?.lineOne}+${shippingAddress?.city}`}
                target="_blank"
                rel="noreferrer"
                className="text-blue-600 hover:text-blue-800 text-lg"
              >
                <p>
                  {ResolveUndefined(shippingAddress?.lineOne)},{" "}
                  {shippingAddress?.city},
                </p>
                <p>
                  {shippingAddress?.postalCode}, {shippingAddress?.state},{" "}
                  {shippingAddress?.countryCode}
                </p>
              </a>
              <h3 className="text-base grid grid-cols-2 mt-4 leading-6 font-medium text-gray-900">
                # of Entries:{" "}
                <span className="text-gray-600 font-bold">
                  {lineItems.length}
                </span>
              </h3>
            </div>
            <div className="flex flex-col">
              <div className="pt-4 w-1/2">
                <label
                  htmlFor="email"
                  className="text-base leading-6 font-medium text-gray-900"
                >
                  Shipped On
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="datetime-local"
                    {...register("date", {
                      required: true,
                    })}
                    className={`block w-full pr-10 focus:outline-none ${
                      errors.shipping
                        ? "border-red-500 text-red-900  focus:ring-red-500 focus:border-red-500"
                        : "border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500"
                    } sm:text-sm rounded-md`}
                  />
                  {errors.shipping && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <ExclamationCircleIcon
                        className="h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-end">
              <span className="sm:ml-3 relative z-0 flex flex-row items-center">
                <a
                  type="button"
                  href={`/orders/${id}`}
                  className="inline-flex items-center h-min justify-center w-min whitespace-nowrap mt-4 px-4 py-2 mr-4 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

                  <span>Back</span>
                </a>
              </span>
              <button
                type="submit"
                className="inline-flex items-center h-min justify-center w-min whitespace-nowrap mt-4 px-4 py-2 mr-4 shadow-sm text-lg font-medium rounded-md text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <span>Submit Tracking</span>
              </button>
            </div>
          </form>

          {lineItems.length > 0 && (
            <div className="-my-2 overflow-x-auto py-4 mx-4 sm:mx-6 lg:mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <h3 className="text-left text-lg pb-2 font-medium">
                  Order Line Items:
                </h3>
                <div className="shadow border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-200">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          SKU
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          Item
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                        >
                          QUANTITY
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {lineItems &&
                        lineItems.map((item, index) => (
                          <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.item.skuNumber}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.item.title}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item.itemQuantity}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${
        isSubmitting && "opacity-40"
      } bg-gray-50 h-full border-t border-gray-300 mt-2`}
    >
      <div className="py-4 px-4 sm:px-6 lg:px-8 mx-4 sm:mx-6 lg:mx-8  flex items-center justify-between border-b">
        <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Shipping <span className="font-normal">|</span>{" "}
          {order?.data?.order?.client?.name}
        </h1>
        <div className="mt-5 flex xl:mt-0 xl:ml-4"></div>
      </div>
      <div>
        <form
          onSubmit={handleSubmit(handleShipping)}
          className="grid grid-cols-3 py-4 px-4 sm:px-6 lg:px-8 mx-4 sm:mx-6 lg:mx-8 border-b"
        >
          <div className="">
            <h3 className="text-left text-xl pb-2 font-medium">
              Shipping Address:
            </h3>
            <a
              href={`https://www.google.ca/maps/place/${shippingAddress?.lineOne}+${shippingAddress?.city}`}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 hover:text-blue-800 text-lg"
            >
              <p>
                {ResolveUndefined(shippingAddress?.lineOne)},{" "}
                {shippingAddress?.city},
              </p>
              <p>
                {shippingAddress?.postalCode}, {shippingAddress?.state},{" "}
                {shippingAddress?.countryCode}
              </p>
            </a>
            <h3 className="text-base grid grid-cols-2 pt-4 leading-6 font-medium text-gray-900">
              Loading Dock:{" "}
              <span className="text-gray-500 font-bold">Unavailable</span>
            </h3>
            <h3 className="text-base leading-6  grid grid-cols-2 font-medium text-gray-900">
              Total Weight:{" "}
              <span className="text-gray-600 font-bold">
                {totalWeight.toFixed(2)} lbs
              </span>
            </h3>
            <h3 className="text-base grid grid-cols-2 leading-6 font-medium text-gray-900">
              # of Entries:{" "}
              <span className="text-gray-600 font-bold">
                {lineItems.length}
              </span>
            </h3>
          </div>
          <div className="flex flex-col">
            <div className="pt-4 w-1/2">
              <label
                htmlFor="email"
                className="text-base leading-6 font-medium text-gray-900"
              >
                Tracking Number
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  {...register("number", {
                    required: true,
                  })}
                  placeholder="XXXX"
                  className={`block w-full pr-10 focus:outline-none ${
                    errors.shipping
                      ? "border-red-500 text-red-900  focus:ring-red-500 focus:border-red-500"
                      : "border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500"
                  } sm:text-sm rounded-md`}
                />
                {errors.shipping && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pt-4 w-1/2">
              <label
                htmlFor="email"
                className="text-base leading-6 font-medium text-gray-900"
              >
                Shipped With
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  {...register("carrier", {
                    required: true,
                  })}
                  placeholder="Type.."
                  className={`block w-full pr-10 focus:outline-none ${
                    errors.shipping
                      ? "border-red-500 text-red-900  focus:ring-red-500 focus:border-red-500"
                      : "border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500"
                  } sm:text-sm rounded-md`}
                />
                {errors.shipping && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="pt-4 w-1/2">
              <label
                htmlFor="email"
                className="text-base leading-6 font-medium text-gray-900"
              >
                Shipped On
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="datetime-local"
                  {...register("date", {
                    required: true,
                  })}
                  className={`block w-full pr-10 focus:outline-none ${
                    errors.shipping
                      ? "border-red-500 text-red-900  focus:ring-red-500 focus:border-red-500"
                      : "border-gray-300 text-gray-900  focus:ring-blue-500 focus:border-blue-500"
                  } sm:text-sm rounded-md`}
                />
                {errors.shipping && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start justify-end">
            <span className="sm:ml-3 relative z-0 flex flex-row items-center">
              <a
                type="button"
                href={`/orders/${id}`}
                className="inline-flex items-center h-min justify-center w-min whitespace-nowrap mt-4 px-4 py-2 mr-4 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

                <span>Back</span>
              </a>
            </span>
            <button
              type="submit"
              className="inline-flex items-center h-min justify-center w-min whitespace-nowrap mt-4 px-4 py-2 mr-4 shadow-sm text-lg font-medium rounded-md text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <span>Submit Tracking</span>
            </button>
          </div>
        </form>

        {lineItems.length > 0 && (
          <div className="-my-2 overflow-x-auto py-4 mx-4 sm:mx-6 lg:mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <h3 className="text-left text-lg pb-2 font-medium">
                Order Line Items:
              </h3>
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        SKU
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Item
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        QUANTITY
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Weight
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-700 uppercase tracking-wider"
                      >
                        Shipment
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {lineItems &&
                      lineItems.map((item, index) => (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.item.skuNumber}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.item.title}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {item.itemQuantity}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {item.item.weightPounds}
                            lbs
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {item.shipment ? (
                              <p className="text-green-600">
                                Shipped on:{" "}
                                {item.shipment.createdAt.split("T")[0]}
                              </p>
                            ) : (
                              <div className="mt-1">
                                <input
                                  {...register("lineItems", {
                                    required: true,
                                  })}
                                  type="checkbox"
                                  value={item.id}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UniqueOrderView;
