import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

const InvoiceView = () => {
  return (
    <main
      className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Invoices | One Supply Connect</title>
      </Helmet>
      <div className="border-b border-gray-200 py-4 mb-2 grid grid-cols-8  justify-between items-center mx-8">
        <div className="col-span-6">
          <h3 className="text-2xl leading-6 font-medium text-gray-900 py-2">
            Commission Invoices
          </h3>
        </div>
      </div>
      <Outlet />
    </main>
  );
};

export default InvoiceView;
