import { gql } from "@apollo/client";

export const CLIENTS = gql`
  query getAllClients($src: String) {
    clients(search: $src) {
      id
      name
      isSeedValidated
      type
      crmId
    }
  }
`;

export const CLIENT = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      name
      crmId
      logoImageUrl
      type
      taxId
      publicEcommerceCode
      partnerPricingCostPlusPercentage
      salesTax {
        id
        provinceStateName
        taxPercentage
      }
      accountExecutive {
        id
        firstName
        lastName
      }
      contacts {
        id
        firstName
        lastName
        email
        phoneNumber
        crmId
        type
      }
      locations {
        id
        lineOne
        countryCode
        city
        state
        isPrimary
        postalCode
        isBilling
        receiverName
        receiverPhoneNumber
        shippingDetail {
          hasLoadingDock
        }
      }
      credit {
        id
        amount
        currency {
          symbol
        }
        receivedDate
        reasoning
        createdAt
        amountRemaining
      }
    }
  }
`;

export const CLIENT_ORDERS = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      name
      type
      orders {
        currency
        id
        total
        dueDate
        createdAt
        expectedCloseDate
        salesType
        paymentTransactions {
          amountReceived
        }
        currentStatus {
          name
        }
      }
    }
  }
`;

export const SALES_REPS = gql`
  query users {
    users(roleCodename: "sales_rep") {
      firstName
      lastName
      id
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: ID!
    $code: String
    $rep: ID
    $tax: String
    $validated: Boolean
    $taxNumber: String
    $pricePercentage: Decimal
    $name: String
  ) {
    updateClient(
      id: $id
      crmId: $code
      salesRepId: $rep
      salesTaxState: $tax
      isSeedValidated: $validated
      taxId: $taxNumber
      partnerPricingCostPlusPercentage: $pricePercentage
      name: $name
    ) {
      client {
        id
        name
        crmId
        logoImageUrl
        type
        taxId
        publicEcommerceCode
        salesTax {
          id
          provinceStateName
          taxPercentage
        }
        accountExecutive {
          id
          firstName
          lastName
        }
        contacts {
          id
          firstName
          lastName
          email
          phoneNumber
          crmId
          type
        }
        locations {
          id
          lineOne
          countryCode
          city
          state
          isPrimary
          postalCode
          isBilling
          shippingDetail {
            hasLoadingDock
          }
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateCustomer(
    $crmID: String
    $email: String
    $firstName: String
    $id: ID!
    $lastName: String
    $phoneNumber: String
    $type: String
  ) {
    updateCustomer(
      crmId: $crmID
      email: $email
      firstName: $firstName
      id: $id
      lastName: $lastName
      phoneNumber: $phoneNumber
      type: $type
    ) {
      customer {
        id
        firstName
        lastName
        email
        phoneNumber
        crmId
        type
      }
    }
  }
`;

export const SALES_TAX = gql`
  query salesTaxes {
    salesTaxes {
      id
      provinceState
      provinceStateName
      taxPercentage
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation addLocationToClient($id: ID!, $address: AddressInput!) {
    addLocationToClient(clientId: $id, addressData: $address) {
      locationId
    }
  }
`;

export const ADD_CREDIT = gql`
  mutation issueClientCredit(
    $id: ID!
    $amount: Decimal!
    $currency: String!
    $dateReceived: Date!
    $reasoning: String!
  ) {
    issueClientCredit(
      clientId: $id
      amount: $amount
      currencySymbol: $currency
      dateReceived: $dateReceived
      reasoning: $reasoning
    ) {
      credit {
        id
      }
    }
  }
`;

export const FETCH_PD_ORG = gql`
  query fetchPipedriveOrgData($id: String!) {
    fetchPipedriveOrgData(crmId: $id) {
      contacts {
        firstName
        lastName
        email
        phoneNumber
        crmId
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation addCustomerToClient(
    $company: ID!
    $crm: String!
    $type: String
    $email: String!
    $first: String!
    $last: String!
    $phone: String
  ) {
    addCustomerToClient(
      companyId: $company
      crmId: $crm
      customerType: $type
      email: $email
      firstName: $first
      lastName: $last
      phoneNumber: $phone
    ) {
      createdCustomer
    }
  }
`;

export const CREATE_ECOM_CODE = gql`
  mutation createClientEcommerceCode($id: ID!, $refresh: Boolean) {
    createClientEcommerceCode(clientId: $id, refresh: $refresh) {
      publicEcommerceCode
    }
  }
`;

export const FETCH_CUSTOMER_INVOICE = gql`
  query fetchCustomerInvoices($company: String!) {
    fetchCustomerInvoices(companyName: $company) {
      id
      number
      alreadyImported
    }
  }
`;

export const FETCH_INVOICE = gql`
  query fetchCustomerInvoiceDetails($number: String!) {
    fetchCustomerInvoiceDetails(invoiceNumber: $number) {
      id
      dateCreated
      dueDate
      number
      companyName
      totalTax
      totalAmount
      balance
      currency
      lineItems {
        lineNumber
        itemName
        description
        quantity
        pricePerUnit
        totalAmount
        skuNumber
      }
      shippingAddress {
        lineOne
        city
        postalCode
        country
      }
      billingAddress {
        lineOne
        city
        postalCode
        country
      }
    }
  }
`;

export const INVOICE_UPLOAD = gql`
  mutation importQboInvoiceToConnect(
    $client: ID!
    $dateCreated: Date!
    $dateShipped: Date!
    $invoiceNumber: String!
    $lineItems: [LineItemInput]
    $orderCurrencySymbol: String!
    $salesRepId: ID!
    $location: String!
    $amount: Decimal!
    $method: String!
  ) {
    importQboInvoiceToConnect(
      clientId: $client
      dateCreated: $dateCreated
      dateShipped: $dateShipped
      invoiceNumber: $invoiceNumber
      lineItems: $lineItems
      orderCurrencySymbol: $orderCurrencySymbol
      orderType: "imported"
      salesRepId: $salesRepId
      location: $location
      paidAmount: $amount
      paymentMethod: $method
    ) {
      orderNumber
    }
  }
`;

export const CREATE_LEAD = gql`
  mutation createLead($input: LeadInput!) {
    createLead(leadData: $input) {
      lead {
        id
        companyName
        crmId
        customerCrmId
        contactName
        email
        status
        phoneNumber
        productionGoal
        funding
        fundingCurrencySymbol
        createdAt
        customer {
          id
        }
        client {
          id
          salesTax {
            id
            provinceStateName
            taxPercentage
            provinceState
          }
        }
      }
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($id: ID!, $locationID: ID!, $address: AddressInput!) {
    updateAddress(
      clientId: $id
      addressId: $locationID
      addressData: $address
    ) {
      address {
        id
      }
    }
  }
`;

export const EMAIL_INSTRUCTIONS = gql`
  query emailClientEcommerceInstructions($id: ID!, $ccEm: [String]!) {
    emailClientEcommerceInstructions(clientId: $id, ccEmails: $ccEm)
  }
`;
