/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  setClientModal,
  setClient,
  setTaxPercentage,
  setCurrency,
  setContact,
  selectClientModal,
  selectClient,
  selectContact,
} from "../../../hooks/sales/saleSlice";
const ValidateCompany = ({ clientResponse }) => {
  const dispatch = useDispatch();
  const clientModal = useSelector(selectClientModal);
  const client = useSelector(selectClient);
  const selectedContact = useSelector(selectContact);
  const { register, watch } = useForm();
  const [contactList, setContactList] = useState([]);
  const watchCompany = watch("company");
  useEffect(() => {
    if (watchCompany) {
      const selectedClient = clientResponse.data?.clients.find((client) => {
        return client.name === watchCompany;
      });
      if (selectedClient) {
        const { contacts, salesTax } = selectedClient;
        dispatch(setClient(selectedClient));
        setContactList(contacts);
        if (salesTax) {
          const { provinceState, taxPercentage } = salesTax;
          dispatch(setTaxPercentage(taxPercentage));
          if (provinceState !== "US" && provinceState !== "WLD") {
            dispatch(setCurrency("CAD"));
          } else {
            dispatch(setTaxPercentage(0));
            dispatch(setCurrency("USD"));
          }
        } else {
          dispatch(setTaxPercentage(0));
          dispatch(setCurrency("USD"));
        }
      }
    }
  }, [watchCompany]);
  return (
    <Transition.Root show={clientModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              {clientResponse.loading ? (
                <svg
                  className="animate-spin h-12 w-12 text-blue-700 self-center mt-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                  xml="preserve"
                >
                  <path
                    opacity="0.2"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                </svg>
              ) : (
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Select An Appropriate Client
                    </Dialog.Title>
                    <div className="mt-1 pb-1 border-b border-gray-100">
                      <p className="text-sm text-gray-500">
                        Select the Client for which the spare parts order is to
                        be ussed for.
                      </p>
                    </div>
                    <div className="mt-4 mx-12">
                      <p className="text-sm text-left">Company:</p>
                      <input
                        type="text"
                        id="company"
                        {...register("company")}
                        placeholder="Search for Company"
                        className="h-8 w-full focus:ring-blue-500 focus:border-blue-500 shadow-sm sm:text-sm p-1 bg-white border border-gray-300 rounded-md"
                        list="categories"
                      />
                      <datalist id="categories">
                        {clientResponse.data &&
                          clientResponse.data.clients.map((option, index) => {
                            return (
                              <option key={index} value={option.name}></option>
                            );
                          })}
                      </datalist>
                    </div>
                    <div className="mt-4 mx-12">
                      <p className="text-sm text-left">Contact:</p>
                      <select
                        id="contacts"
                        onChange={(e) => {
                          dispatch(setContact(contactList[e.target.value]));
                        }}
                        className="focus:ring-blue-500 focus:border-blue-500 w-full font-medium text-left pl-2 rounded-md sm:text-sm border py-2 border-gray-300"
                      >
                        <option value="">Select...</option>
                        {contactList.map((entry, index) => (
                          <option value={index}>
                            {entry?.firstName} {entry?.lastName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-5 sm:mt-6">
                {client &&
                  client.crmId &&
                  selectedContact &&
                  selectedContact.crmId && (
                    <button
                      type="button"
                      onClick={() => dispatch(setClientModal(false))}
                      className="inline-flex justify-center w-full rounded-md border-2 border-transparent shadow-sm px-4 py-2 hover:text-gray-900  hover:border-gray-900  bg-green-500 hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                    >
                      Confirm
                    </button>
                  )}
                {((client.id && !client.crmId) ||
                  (selectedContact.id && !selectedContact.crmId)) && (
                  <a
                    type="button"
                    href={`/clients/${client.id}`}
                    className="inline-flex justify-center w-full rounded-md border-2 border-transparent shadow-sm px-4 py-2 hover:text-gray-900  hover:border-gray-900  bg-red-500 hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                  >
                    Validate Client
                  </a>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ValidateCompany;
