/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectClient } from "../hooks/clientSlice";
import { gql, useQuery } from "@apollo/client";

// HOOKS
import useClientImport from "../../hooks/client/useClientImport";

//COMPONENTS
import ItemPopeOver from "../../components/PopeOver/item";
import LineItemPopover from "../../components/PopeOver/replaceItem";
import Header from "../../views/Clients/directory/qboImport/header";
import InvoiceList from "../../views/Clients/directory/qboImport/list";
import InvoiceDetails from "../../views/Clients/directory/qboImport/importDetails";

const QBOAuth = gql`
  query refreshQBO {
    refreshQboToken
  }
`;

const ClientView = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [lineNumber, setLineNumber] = useState(null);
  const selectedClient = useSelector(selectClient);
  const authQBO = useQuery(QBOAuth, {
    onError: (e) => alert(e),
  });
  const { isLoading, invoiceList, importInvoice, fetchCustomer, setIsLoading } =
    useClientImport();

  return (
    <main
      className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full"
      tabIndex="0"
    >
      <ItemPopeOver />
      <LineItemPopover
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        invoice={importInvoice}
        lineNumber={lineNumber}
      />
      <div className="flex flex-col px-4 sm:px-6 lg:px-8 h-full ">
        <Header
          selectedClient={selectedClient}
          setIsLoading={setIsLoading}
          fetchCustomer={fetchCustomer}
          importInvoice={importInvoice}
        />
        {isLoading && (
          <svg
            className="animate-spin h-12 w-12 text-blue-700 self-center mt-2"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
            xml="preserve"
          >
            <path
              opacity="0.2"
              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
            />
            <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
          </svg>
        )}
        <InvoiceList invoices={invoiceList} importInvoice={importInvoice} />
        <InvoiceDetails
          importInvoice={importInvoice}
          setLineNumber={setLineNumber}
          setShowPopover={setShowPopover}
        />
      </div>
    </main>
  );
};

export default ClientView;
