import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: null,
  clients: [],
  modalOn: null,
  orders: [],
  saleReps: [],
  tax: [],
  contactCRM: null,
  importInvoice: null,
  invoiceList: [],
  companyName: null,
  activeContact: null,
  activeAddress: null,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,

  reducers: {
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    setModal: (state, action) => {
      state.modalOn = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    setReps: (state, action) => {
      state.saleReps = action.payload;
    },
    setTax: (state, action) => {
      state.tax = action.payload;
    },
    setContactCRM: (state, action) => {
      state.contactCRM = action.payload;
    },
    setImportInvoice: (state, action) => {
      state.importInvoice = action.payload;
    },
    setInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setActiveContact: (state, action) => {
      state.activeContact = action.payload;
    },
    setActiveAddress: (state, action) => {
      state.activeAddress = action.payload;
    },
  },
});

export const {
  setClient,
  setClients,
  setModal,
  setOrders,
  setReps,
  setTax,
  setContactCRM,
  setImportInvoice,
  setInvoiceList,
  setCompanyName,
  setActiveContact,
  setActiveAddress,
} = clientSlice.actions;

export const selectClients = (state) => state.client.clients;
export const selectClient = (state) => state.client.client;
export const selectModal = (state) => state.client.modalOn;
export const selectOrders = (state) => state.client.orders;
export const selectSaleReps = (state) => state.client.saleReps;
export const selectSalesTax = (state) => state.client.tax;
export const selectContactCRM = (state) => state.client.contactCRM;
export const selectImportInvoice = (state) => state.client.importInvoice;
export const selectInvoiceList = (state) => state.client.invoiceList;
export const selectCompanyName = (state) => state.client.companyName;
export const selectActiveContact = (state) => state.client.activeContact;
export const selectActiveAddress = (state) => state.client.activeAddress;

export default clientSlice.reducer;
