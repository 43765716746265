import React from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { useSearchParams } from "react-router-dom";

//COOKIES
import { useCookies } from "react-cookie";

//HOOKS
import { useSelector } from "react-redux";
import { selectOrderList } from "../../../hooks/sales/saleSlice";
import useShipments from "../../../hooks/sales/userOrderShipments";

// COMPONENTS
import Table from "../../../global/components/tables/filterable";

const ShipmentsView = () => {
  const {
    SpareHeaders,
    refetch,
    skipValue,
    setSkipValue,
    setSearchTerm,
    setStatuses,
    isLoading,
  } = useShipments();
  const orderList = useSelector(selectOrderList);
  return (
    <div>
      <div className="bg-white overflow-hidden  border-gray-100 mx-1">
        <div className="border-b border-gray-200 py-4 mb-2 grid grid-cols-8  justify-between items-center mx-6">
          <div className="col-span-6">
            <h3 className="text-2xl leading-6 font-medium text-gray-900 py-2">
              Shipment Orders
            </h3>
          </div>
        </div>
        <div className="max-w-7xl mx-auto flex-1 md:px-8 lg:px-0 xl:col-span-6">
          <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
            <div className="w-full">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="search"
                  onChange={(e) => {
                    if (e.target.value.length > 2)
                      setSearchTerm(e.target.value);
                    if (e.target.value.length === 0) setSearchTerm(null);
                  }}
                  className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 py-6">
          {isLoading ? (
            <div>
              <span className="flex justify-center w-full">
                <svg
                  className="animate-spin h-10 w-10 text-blue-600 self-center mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                  xml="preserve"
                >
                  <path
                    opacity="0.2"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                </svg>
              </span>
            </div>
          ) : (
            <Table
              columns={SpareHeaders}
              data={orderList}
              refetch={refetch}
              skipValue={skipValue}
              setSkipValue={setSkipValue}
              setStatuses={setStatuses}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipmentsView;
