/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";

//COOKIES
import { useCookies } from "react-cookie";

const MultiLineWLink = ({ data, headers, linkTo, setStatus }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  return (
    <div className="flex flex-col">
      <div className="-my-2 sm:-mx-6 lg:-mx-8 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8 z-40 ">
          <div className="shadow border-b border-gray-200 sm:rounded-lg ">
            <table className="min-w-full divide-y divide-gray-200 z-20 ">
              <thead className="bg-gray-50">
                <tr>
                  {headers.map((entry, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className={`px-6 py-3 text-left text-xs font-medium text-gray-500 whitespace-nowrap tracking-wider uppercase 
                        ${
                          (entry === "Funding" || entry === "ID") &&
                          "flex flex-row items-end gap-2"
                        }
                        `}
                      >
                        <span className="whitespace-no-wrap">{entry}</span>
                        {entry === "ID" && (
                          <div className="relative z-10 flex flex-col justify-center items-center group">
                            <svg
                              className="w-5 h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <div className="absolute top-10 w-full z-50 flex flex-col items-center hidden  mb-6 group-hover:flex">
                              <div className="w-3 h-3 -mt-3 transform rotate-45 bg-gray-600" />
                              <span className="relative lowercase -mt-2  p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                                Directs to Pipedrive Deal.
                              </span>
                            </div>
                          </div>
                        )}
                        {entry === "Funding" && (
                          <div className="relative z-40 flex flex-col justify-center items-center group">
                            <svg
                              className="w-5 h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <div className="absolute top-10 w-full z-50 flex flex-col items-center hidden  mb-6 group-hover:flex">
                              <div className="w-3 h-3 -mt-3 transform rotate-45 bg-gray-600" />
                              <span className="relative lowercase -mt-2  p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                                The money allocated for automatization equipment
                              </span>
                            </div>
                          </div>
                        )}
                      </th>
                    );
                  })}
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data &&
                  data.length !== 0 &&
                  data.map((entry) => (
                    <tr key={entry.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        <a
                          href={`https://atgpharma.pipedrive.com/deal/${entry.crmId}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-700 hover:text-blue-900 hover:font-bold"
                        >
                          {entry.id}
                        </a>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-gray-900 font-semibold">
                          {entry.companyName}
                        </div>
                        <div className="text-sm text-gray-900">
                          {entry.contactName}
                        </div>
                        <div className="text-sm text-gray-500">
                          <a
                            className="text-blue-700 hover:text-blue-900 hover:font-bold"
                            href={`mailto:${entry.email}`}
                          >
                            {entry.email}
                          </a>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {entry.fundingCurrencySymbol}{" "}
                        {nFormatter(entry.funding, 2)}
                      </td>
                      <td className="px-6 py-4  text-sm text-gray-500">
                        {entry.productionGoal}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap  text-sm text-gray-500">
                        {entry.createdAt.split("T")[0]}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        {entry.email && entry.email !== "" ? (
                          <a
                            id="create-estimate"
                            onClick={() => {
                              setCookie("lead", entry, {
                                path: "/",
                                secure: true,
                                sameSite: true,
                              });
                              setCookie(
                                "leadCurrency",
                                entry.fundingCurrencySymbol,
                                { path: "/" }
                              );
                            }}
                            href="/estimate"
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Add Estimate
                          </a>
                        ) : (
                          <p className="text-sm text-red-700">
                            NO CUSTOMER EMAIL
                          </p>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiLineWLink;

function nFormatter(num, digits) {
  let si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
