import React, { Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
//COOKIES
import { useCookies } from "react-cookie";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const methods = [
  {
    name: "Wire Transfer",
    referenceAttribute: "Confirmation Number",
    description:
      "Please identify the confirmation number on the wire transfer.",
  },
  {
    name: "Physical Cheque",
    referenceAttribute: "Cheque Number",
    description:
      "Please identify the confirmation number on the cheque payment.",
  },
];

const paymentHeaders = [
  "Confirmation Number",
  "Method",
  "Total",
  "Created At",
  "Received On",
  "Verified",
];

const PaymentsTable = ({
  data,
  paymentTransactions,
  setOpenPayment,
  setPayment,
  setLogPayment,
  setTransactionID,
  openDate,
  display,
}) => {
  const [active] = useState(null);
  const [cookies, setCookie] = useCookies(["user"]);

  return (
    <div className="bg-white overflow-hidden shadow border border-gray-100 rounded-lg mr-8 ml-8 mt-6">
      <div className="border-b border-gray-200 py-4 mb-2 flex flex-row justify-between items-center mx-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Payments
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            All payment transactions completed for this order.
          </p>
        </div>
        <div className="flex flex-row items-end  gap-4 justify-start">
          <div className="col-span-2 flex justify-center px-2 pt-1 pb-2">
            <div className="space-y-1 text-center">
              <div>
                <Menu
                  as="div"
                  className={`${
                    display ? "relative" : "hidden"
                  } inline-block text-left z-40`}
                >
                  <div>
                    <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-3 py-3 bg-blue-600  text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                      {active ? active.name : "Payment Received"}
                      <ChevronDownIcon
                        className="-mr-1 ml-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {methods.map((method, index) => {
                          return (
                            <Menu.Item key={index}>
                              {({ active }) => (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setOpenPayment(true);
                                    setPayment(method);
                                  }}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-700",
                                    method.color &&
                                      "text-green-700 font-medium",
                                    "block px-4 py-2 text-sm w-full text-left"
                                  )}
                                >
                                  {method.name}
                                </button>
                              )}
                            </Menu.Item>
                          );
                        })}
                        {data?.client?.credit.length && (
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                onClick={() => {
                                  setOpenPayment(true);
                                  setPayment(data?.client?.credit);
                                }}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm w-full text-left"
                                )}
                              >
                                Credit
                              </button>
                            )}
                          </Menu.Item>
                        )}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-col">
          <div className="-my-2  sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <div>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {paymentHeaders.map((entry, index) => {
                          return (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {entry}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {paymentTransactions.map((entry, index) => {
                        return (
                          <tr key={index}>
                            <td className="px-6 py-3 text-left text-sm  text-gray-700 font-bold uppercase tracking-wider">
                              {entry.referenceAttribute}
                            </td>
                            <td className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase tracking-wider">
                              {entry.method}
                            </td>
                            <td className="px-6 py-3 text-left text-sm font-medium text-green-700 uppercase tracking-wider">
                              ${" "}
                              {parseFloat(entry.amount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {entry.createdAt.split("T")[0]}
                            </td>
                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {entry.receivedDate?.split("T")[0]}
                            </td>
                            <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              {entry.success ? (
                                <span className="text-green-800">
                                  Verified
                                  {data.paymentReceivedDate?.split("T")[0] ===
                                    entry.receivedDate?.split("T")[0] &&
                                    (cookies.user?.user?.role?.name ===
                                      "Customer Success Representative" ||
                                      cookies.user?.user?.role?.name ===
                                        "Executive") && (
                                      <button
                                        onClick={() => {
                                          setTransactionID(entry.id);
                                          openDate(true);
                                        }}
                                        className="ml-2 text-blue-700 hover:text-blue-900"
                                      >
                                        Edit Date
                                      </button>
                                    )}
                                </span>
                              ) : (
                                <button
                                  onClick={() => {
                                    setLogPayment(true);
                                    setTransactionID(entry.id);
                                  }}
                                  className="animate-pulse text-red-600 hover:text-red-800"
                                >
                                  Verify
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentsTable;
