import React from "react";
import { Helmet } from "react-helmet";

const ShipmentView = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orders | One Supply Connect</title>
      </Helmet>
      <h1>unique shipments</h1>
    </div>
  );
};

export default ShipmentView;
