/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment, useEffect } from "react";
import { ArrowsExpandIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { ReactSortable } from "react-sortablejs";
import { useSelector, useDispatch } from "react-redux";
import {
  selectEstimate,
  selectTaxes,
  selectConversion,
  setForBuild,
  setActiveMenuIndex,
  setSampleModal,
  setForItem,
  setExpiry,
  selectSampleError,
  setSampleError,
  selectCratingPresent,
  selectPrimaryVendor,
  selectExpiry,
} from "../hooks/estimateSlice";
import { setPopOver } from "../hooks/saleSlice";
import { useParams } from "react-router-dom";

// HOOKS
import useEstimatePages from "../hooks/useEstimatePages";
import useConfigureEstimate from "../hooks/useConfigureEstimate";

// COMPONENTS
import SampleModal from "../../components/modals/estimate/selectSample";
import NoCratingModal from "../../components/modals/estimate/noCrating";
import DiscountExpiryModal from "../../components/modals/estimate/discountExpiry";

const sortableOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: "ghost",
  group: "shared",
};

const ReviewEstimate = () => {
  const dispatch = useDispatch();
  const estimate = useSelector(selectEstimate);
  const taxes = useSelector(selectTaxes);
  const conversion = useSelector(selectConversion);
  const [blocks, setBlocks] = useState(null);
  const sampleError = useSelector(selectSampleError);
  const cratingPresent = useSelector(selectCratingPresent);
  const expiry = useSelector(selectExpiry);
  const primaryVendor = useSelector(selectPrimaryVendor);
  const [alertCrating, setAlertCrating] = useState(false);
  const [showExpiry, setExpiryModal] = useState(false);

  const {
    handleSubmitEstimate,
    handlePromotion,
    getEstimateEdit,
    submitting,
    promos,
  } = useEstimatePages();
  const [onEdit, setEdit] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    let deepCopy = JSON.parse(JSON.stringify(estimate));
    let buildBlock = [];
    for (const entry of Object.keys(deepCopy)) {
      buildBlock.push({
        total: deepCopy[entry].total,
        build: [],
      });
      for (const line of deepCopy[entry].build) {
        buildBlock[entry].build.push({
          lineNumber: line.lineNumber,
          pricePerUnit: line.pricePerUnit,
          originalPrice: line.originalPrice,
          discountPerUnit: line.discountPerUnit,
          isTimedDiscount: line.isTimedDiscount,
          productUuid: line.productUuid,
          quantity: line.quantity,
          skuNumber: line.skuNumber,
          title: line.title,
        });
      }
    }
    setBlocks(buildBlock);
  }, [estimate]);

  useEffect(() => {
    if (id) {
      if (!onEdit && (!estimate || estimate.length === 0)) {
        getEstimateEdit(id);
        setEdit(true);
      }
    }
  }, [id]);

  const tableHeaders = [
    "Component",
    `Unit price ${conversion === 1 ? "[CAD]" : "[USD]"}`,
    "Quantity",
    "Discount",
    "Price",
    "Edit",
  ];

  return (
    <div>
      <SampleModal />
      <NoCratingModal
        setAlertCrating={setAlertCrating}
        alertCrating={alertCrating}
        handleSubmitEstimate={handleSubmitEstimate}
        blocks={blocks}
        submitting={submitting}
      />
      <DiscountExpiryModal
        setExpiryModal={setExpiryModal}
        showExpiry={showExpiry}
        handleSubmitEstimate={handleSubmitEstimate}
        setAlertCrating={setAlertCrating}
        blocks={blocks}
        alertCondition={
          !cratingPresent && primaryVendor?.name === "ATG Pharma Inc"
        }
        submitting={submitting}
      />
      <div className="flex w-full flex-col overflow-auto mb-4">
        <div
          className={` border-2 border-gray-200 divide-y divide-gray-200 ${
            false && "opacity-40"
          }`}
        >
          <div className="bg-gray-200 grid grid-cols-8">
            {tableHeaders.map((header, index) => (
              <th
                key={index}
                scope="col"
                className={`px-2  ${
                  index === 0 && "col-span-3 px-6 "
                } py-4 text-center text-sm font-medium text-gray-900 uppercase tracking-wider`}
              >
                {header}
              </th>
            ))}
          </div>
          <tbody className="bg-white flex flex-col w-full divide-y divide-gray-200">
            {blocks &&
              blocks.map((entry, index) => (
                <BlockWrapper
                  key={index}
                  block={entry}
                  blockIndex={[index]}
                  setBlocks={setBlocks}
                  blocks={blocks}
                />
              ))}
            <tr className="bg-gray-50 grid grid-cols-6">
              <td className="col-span-5 py-2 px-6 text-xl text-gray-900 font-medium">
                Taxes:
              </td>
              <td className="px-6 py-2 whitespace-nowrap font-bold leading-2 text-green-800 text-right text-lg">
                $
                {(parseFloat(taxes) / parseFloat(conversion))
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td />
            </tr>
          </tbody>
        </div>
      </div>
      <div className=" border-t border-gray-200 w-full grid grid-cols-4 mb-2 ">
        <div className="col-span-1 ">
          <label
            htmlFor="description"
            className="block  font-medium pt-2 text-gray-800"
          >
            Expiring Discount:
          </label>
          <div className="relative flex items-start ml-4 mt-2">
            <div className="flex items-center h-5">
              <input
                type="checkbox"
                onChange={(e) => e.target.checked && dispatch(setExpiry(true))}
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="comments" className="font-medium text-gray-700">
                Discount Shipping
              </label>
            </div>
          </div>
        </div>
      </div>
      {promos && promos.vendor.promotions.length > 0 && (
        <div className=" border-t border-gray-200 w-full grid grid-cols-4">
          <div className="col-span-1 ">
            <label
              htmlFor="description"
              className="block  font-medium pt-2 text-gray-800"
            >
              Available Promotions:
            </label>
            <div className="mt-1">
              <select
                onChange={(e) => handlePromotion(e.target.value)}
                className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
              >
                <option value="">Select...</option>
                {promos.vendor.promotions.map((promo, index) => {
                  if (promo.currentStatus)
                    return (
                      <option key={index} value={promo.id}>
                        {promo.name}
                      </option>
                    );
                })}
              </select>
            </div>
          </div>
        </div>
      )}
      {sampleError && (
        <div className="flex flex-col items-end justify-end">
          <div className="text-right text-red-600 text-sm">
            Samples are required for this estimate.
          </div>
        </div>
      )}
      {estimate && (
        <div className="w-ful flex flex-col items-end justify-end mb-8">
          <button
            onClick={() => {
              if (expiry) setExpiryModal(true);
              else if (
                !cratingPresent &&
                primaryVendor.name === "ATG Pharma Inc"
              )
                setAlertCrating(true);
              else handleSubmitEstimate(blocks);
            }}
            disabled={submitting || sampleError}
            className="inline-flex justify-center rounded-md border-2 border-transparent shadow-sm px-4 py-2 hover:text-gray-900  hover:border-gray-900  bg-green-500 hover:bg-transparent text-lg font-medium  text-white focus:outline-none "
          >
            {submitting && (
              <svg
                className="animate-spin h-5 w-5 text-white self-center mr-2"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                enableBackground="new 0 0 40 40"
                xml="preserve"
              >
                <path
                  opacity="0.2"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
              </svg>
            )}
            Save Estimate
          </button>
        </div>
      )}
    </div>
  );
};

export default ReviewEstimate;

function BlockWrapper({ block, blockIndex, setBlocks, blocks }) {
  const [openNote, setOpenNote] = useState(false);
  const dispatch = useDispatch();
  const conversion = useSelector(selectConversion);
  const sampleError = useSelector(selectSampleError);
  const primaryVendor = useSelector(selectPrimaryVendor);
  const { trayItems } = useEstimatePages();
  const {
    handleDelete,
    handleDiscount,
    handleLineExpiry,
    handlePriceQuantityChange,
    handleNote,
  } = useConfigureEstimate();

  if (!block) return null;
  if (block.hasOwnProperty("total")) {
    return (
      <Fragment>
        <tr className="bg-gray-50 w-full grid grid-cols-8 border-b items-center">
          {" "}
          <td className="py-2 col-span-6 px-6 text-xl text-gray-900 font-semibold">
            {`Build #${parseInt(blockIndex) + 1}`}:
          </td>
          <td className="px-4 py-4 whitespace-nowrap font-extrabold leading-2 text-green-800 text-right text-lg">
            $
            {(parseFloat(block.total) / parseFloat(conversion))
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </td>
          <td className="px-4 py-4 whitespace-nowrap flex flex-col items-end">
            <button
              type="button"
              onClick={() => {
                dispatch(setForBuild(parseInt(blockIndex)));
                dispatch(setActiveMenuIndex("Store"));
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              className="text-green-600 flex flex-row gap-1 items-center hover:text-green-700"
            >
              <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
              <span>Add</span>
            </button>
          </td>
        </tr>
        <Container
          block={block}
          setBlocks={setBlocks}
          blockIndex={blockIndex}
          blocks={blocks}
        />
      </Fragment>
    );
  } else {
    let alert = false;
    if (trayItems && !block.productUuid && trayItems[block.skuNumber]) {
      alert = true;
      if (!sampleError) dispatch(setSampleError(true));
    }
    return (
      <tr key={blockIndex} className="w-full grid grid-cols-8 border-b">
        <td className="flex flex-col items-start col-span-3">
          <div className="px-4 py-4  flex flex-row items-center gap-1">
            <ArrowsExpandIcon className="h-5 w-5 mr-1" aria-hidden="true" />
            <button
              onClick={() =>
                dispatch(setPopOver({ view: true, item: [block] }))
              }
              className={`${
                !alert
                  ? "text-blue-600 hover:text-blue-900"
                  : "text-red-600 hover:text-red-900"
              }`}
            >
              {block.title}
            </button>
            {alert && (
              <button
                type="button"
                onClick={() => {
                  dispatch(setSampleModal(true));
                  dispatch(setForItem(block));
                  dispatch(setForBuild(parseInt(blockIndex)));
                }}
                className="ml-2 inline-flex justify-center rounded-md border-2 border-transparent shadow-sm px-2 py-1 hover:text-gray-900  hover:border-gray-900  bg-red-500 hover:bg-transparent text-sm font-medium  text-white focus:outline-none "
              >
                Add Sample
              </button>
            )}
          </div>
          <button
            type="button"
            onClick={() => setOpenNote(!openNote)}
            className="bg-blue-600 px-2 py-1 rounded-lg hover:bg-blue-800 text-white text-xs ml-6 mb-1"
          >
            {openNote ? "Remove Note" : "Add Note"}
          </button>
          {openNote && (
            <textarea
              onChange={(e) => handleNote(e, blockIndex[0], blockIndex[1])}
              className="w-full border border-gray-400 ml-6 my-1 rounded-lg"
            />
          )}
        </td>
        <td className="px-4 py-4 whitespace-nowrap">
          <input
            type="number"
            step="0.01"
            min="0"
            disabled={primaryVendor?.name === "ATG Pharma Inc"}
            name={`price-${blockIndex[0]}-${blockIndex[1]}`}
            id={`price-${blockIndex[0]}-${blockIndex[1]}`}
            // disabled={block.skuNumber === "700002"}
            defaultValue={parseFloat(
              (parseFloat(block.pricePerUnit) / parseFloat(conversion)).toFixed(
                2
              )
            )}
            onChange={(e) =>
              handlePriceQuantityChange(
                e,
                block,
                blockIndex[0],
                blockIndex[1],
                "price"
              )
            }
            className={`flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm p-1 border ${
              block.originalPrice
                ? block.originalPrice.toFixed(0) !==
                  block.pricePerUnit.toFixed(0)
                  ? "border-yellow-300"
                  : "border-gray-300"
                : "border-gray-300"
            }`}
          />
        </td>
        <td className="px-4 py-4 whitespace-nowrap">
          <input
            type="number"
            step="1"
            min="0"
            name={`quantity-${blockIndex[0]}-${blockIndex[1]}`}
            id={`quantity-${blockIndex[0]}-${blockIndex[1]}`}
            defaultValue={parseFloat(block.quantity)}
            onChange={(e) =>
              handlePriceQuantityChange(
                e,
                block,
                blockIndex[0],
                blockIndex[1],
                "quantity"
              )
            }
            className="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm p-1 border border-gray-300"
          />
        </td>
        <td className="px-4 py-4 whitespace-nowrap">
          <span className=" mr-2 text-gray-600">$</span>
          <input
            type="number"
            step="0.01"
            min="0"
            max="100"
            name={`discount-${blockIndex[0]}-${blockIndex[1]}`}
            id={`discount-${blockIndex[0]}-${blockIndex[1]}`}
            // disabled={primaryVendor?.name === "ATG Pharma Inc"}
            defaultValue={
              block.discountPerUnit &&
              parseInt(
                JSON.parse(block.discountPerUnit) / parseFloat(conversion)
              )
            }
            onKeyUp={(e) => {
              if (e.target.value < 0) {
                e.target.value = "0";
              }
            }}
            onChange={(e) =>
              handleDiscount(
                blockIndex[0],
                blockIndex[1],
                e.target.value,
                blocks
              )
            }
            className="flex-1  focus:ring-blue-500 w-full focus:border-blue-500 rounded-md sm:text-sm p-1 border border-gray-300"
          />
          {block.discountPerUnit && (
            <div className="relative flex items-start ml-4 mt-2">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  onChange={(e) =>
                    handleLineExpiry(
                      blockIndex[0],
                      blockIndex[1],
                      e.target.checked
                    )
                  }
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  Discount Expires
                </label>
              </div>
            </div>
          )}
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-gray-600 text-right">
          <span>
            $
            {(
              ((parseFloat(block.pricePerUnit) -
                (parseFloat(block.discountPerUnit) || 0)) *
                parseFloat(block.quantity)) /
              parseFloat(conversion)
            )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        </td>
        <td className="px-4 py-4 whitespace-nowrap text-right">
          <button
            type="button"
            onClick={() => {
              dispatch(setSampleModal(false));
              handleDelete(block, blockIndex[0], blockIndex[1], blocks);
            }}
            className="text-red-600 hover:text-red-700"
          >
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

function Container({ block, blockIndex, setBlocks, blocks }) {
  const { reArrangeEstimate } = useConfigureEstimate();
  if (!block || !block.build.length === 0) return null;
  return (
    <>
      <ReactSortable
        key={block.id}
        list={block.build}
        setList={(currentList) => {
          setBlocks((sourceList) => {
            const tempList = [...sourceList];
            const _blockIndex = [...blockIndex];
            const lastIndex = _blockIndex.pop();
            const lastArr = _blockIndex.reduce(
              (arr, i) => arr[i]["build"],
              tempList
            );
            lastArr[lastIndex]["build"] = currentList;
            reArrangeEstimate(tempList);
            return tempList;
          });
        }}
        {...sortableOptions}
      >
        {block.build &&
          block.build.map((childBlock, index) => {
            return (
              <BlockWrapper
                key={childBlock.id}
                block={childBlock}
                blockIndex={[...blockIndex, index]}
                setBlocks={setBlocks}
                blocks={blocks}
              />
            );
          })}
      </ReactSortable>
    </>
  );
}
