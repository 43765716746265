import React from "react";
import { setPopOver } from "../../../hooks/sales/saleSlice";
import { useDispatch } from "react-redux";
const headers = ["Item", "Price per Unit", "Quantity"];

const ModularTable = ({ props, register, build }) => {
  const dispatch = useDispatch();
  const TableHeaders = headers.map((option, index) => {
    return (
      <th
        key={index}
        className="p-3 text-left text-xs font-medium  uppercase text-gray-500  hidden lg:table-cell"
      >
        {option}
      </th>
    );
  });

  const TableEntries = props.items?.map((option, index) => {
    return (
      <tr
        key={index}
        className={`${
          index % 2 === 0 ? "bg-white" : "bg-gray-50"
        } flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 w-full`}
      >
        <td>
          <div className="flex pl-2 text-left flex-col justify-start items-start">
            <span className="text-lg">{option.title}</span>
            <button
              onClick={() =>
                dispatch(setPopOver({ view: true, item: [option] }))
              }
              type="button"
              className="animate-pulse text-blue-400 hover:text-blue-900 text-base font-semibold"
            >
              View Details
            </button>
          </div>
        </td>
        <td>
          <h4>
            ${option.price.pricePerUnit.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h4>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
          <input
            {...register(`${props.id}-${build}-${option.skuNumber}`)}
            type="number"
            step="0.01"
            min={0}
            className="focus:ring-blue-500 focus:border-blue-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300"
          />
        </td>
      </tr>
    );
  });

  return (
    <div className={`px-4 my-2 mb-3 `}>
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900 pb-2">
          {props.label}
        </h3>
        <p className="my-1 max-w-2xl text-sm text-gray-500">
          {props.description}
        </p>
      </div>
      <div className="max-h-72 overflow-auto">
        <table className="relative h-full border rounded-lg w-full">
          <thead className="sticky z-10 top-0 bg-gray-50 border border-gray-300 rounded-lg">
            {TableHeaders}
          </thead>
          <tbody>{TableEntries}</tbody>
          <tbody></tbody>
        </table>
      </div>
    </div>
  );
};

export default ModularTable;
