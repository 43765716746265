/* eslint-disable import/no-anonymous-default-export */
const routes = [
  {
    icon_code: "-",
    is_main: false,
    label: "Home",
    parent_route: null,
    path: "/",
    route_order: 18,
  },
  {
    icon_code: "CurrencyDollarIcon",
    is_main: true,
    label: "Sales",
    parent_route: null,
    path: "/leads",
    route_order: 4,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "SalesPort",
    parent_route: "Sales",
    path: "",
    route_order: 7,
  },
  {
    icon_code: "UsersIcon",
    is_main: false,
    label: "Invoices",
    parent_route: null,
    path: "/invoices",
    route_order: 10,
  },
  {
    icon_code: "UsersIcon",
    is_main: false,
    label: "Commission",
    parent_route: null,
    path: "/commission",
    route_order: 10,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "InvoicePort",
    parent_route: "Invoices",
    path: "",
    route_order: 7,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueInvoice",
    parent_route: "Invoices",
    path: ":id",
    route_order: 11,
  },
  {
    icon_code: "UsersIcon",
    is_main: false,
    label: "Vendors",
    parent_route: null,
    path: "/vendors",
    route_order: 10,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "VendorPort",
    parent_route: "Vendors",
    path: "",
    route_order: 7,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "newVendor",
    parent_route: "Vendors",
    path: "new",
    route_order: 11,
  },
  {
    icon_code: "UsersIcon",
    is_main: true,
    label: "Clients",
    parent_route: null,
    path: "/clients",
    route_order: 10,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "ClientsPort",
    parent_route: "Clients",
    path: "",
    route_order: 7,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueClient",
    parent_route: "Clients",
    path: ":id",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueClientPort",
    parent_route: "UniqueClient",
    path: "",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueClientOrders",
    parent_route: "UniqueClient",
    path: "orders",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueClientImport",
    parent_route: "UniqueClient",
    path: "import",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "newClient",
    parent_route: "Clients",
    path: "new",
    route_order: 11,
  },
  {
    icon_code: "ArchiveIcon",
    is_main: true,
    label: "Products",
    parent_route: null,
    path: "/products",
    route_order: 10,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "InventoryPort",
    parent_route: "Products",
    path: "",
    route_order: 7,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "NewProduct",
    parent_route: "Products",
    path: "new",
    route_order: 11,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "InventorySamples",
    parent_route: null,
    path: "samples",
    route_order: 7,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "VendorPromo",
    parent_route: null,
    path: "promo",
    route_order: 11,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "Estimate",
    parent_route: null,
    path: "estimate",
    route_order: 7,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueEstimate",
    parent_route: "Estimate",
    path: ":id",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "SalesOrder",
    parent_route: null,
    path: "orders",
    route_order: 8,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "SalesOrderPort",
    parent_route: "SalesOrder",
    path: "",
    route_order: 12,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueSalesOrder",
    parent_route: "SalesOrder",
    path: ":id",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueSalesOrderPortView",
    parent_route: "UniqueSalesOrder",
    path: "",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "OrderEstimates",
    parent_route: "UniqueSalesOrder",
    path: "estimates",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueOrderEstimate",
    parent_route: "OrderEstimates",
    path: ":id",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueOrderEstimatePortView",
    parent_route: "UniqueOrderEstimate",
    path: "",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "OrderManualShipping",
    parent_route: "UniqueSalesOrder",
    path: "shipping-cost",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "OrderShipment",
    parent_route: "UniqueSalesOrder",
    path: "track-shipment",
    route_order: 11,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "SalesLeads",
    parent_route: null,
    path: "leads",
    route_order: 9,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "Shipments",
    parent_route: null,
    path: "ship",
    route_order: 9,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "ShipmentPort",
    parent_route: "Shipments",
    path: "",
    route_order: 12,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "UniqueShipment",
    parent_route: "Shipments",
    path: ":id",
    route_order: 11,
  },
  {
    icon_code: "1",
    is_main: false,
    label: "SaleSpareStore",
    parent_route: null,
    path: "store",
    route_order: 11,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "Settings",
    parent_route: null,
    path: "/settings",
    route_order: 18,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "Reset",
    parent_route: null,
    path: "/sign-up",
    route_order: 18,
  },
  {
    icon_code: "-",
    is_main: false,
    label: "NotFound",
    parent_route: null,
    path: "*",
    route_order: 18,
  },
];

export default { routes };
