import React from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";

import {
  selectReview,
  selectClient,
  selectContact,
  setClientModal,
  setReview,
} from "../../../hooks/sales/saleSlice";

//COMPONENTS
import Store from "./store";
import Checkout from "./checkout";

import { ArrowLeftIcon } from "@heroicons/react/outline";

const SalesView = () => {
  const dispatch = useDispatch();
  const review = useSelector(selectReview);
  const client = useSelector(selectClient);
  const contact = useSelector(selectContact);

  let renderedComponent;
  if (!review) renderedComponent = <Store />;
  else renderedComponent = <Checkout />;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spare | One Supply Connect</title>
      </Helmet>
      <div className="border-b border-gray-200 py-2 mx-12 flex flex-row justify-between items-end">
        <div>
          <h1 className="text-2xl font-extrabold tracking-tight text-gray-900">
            {client.name}
          </h1>
          <p className="text-gray-500 ">
            {contact.firstName} {contact.lastName}
          </p>
        </div>
        {!review ? (
          <button
            onClick={() => dispatch(setClientModal(true))}
            className="text-sm border border-red-500  hover:bg-red-500 hover:text-white rounded-full px-2 py-1 font-medium text-red-500 "
          >
            Change Client
          </button>
        ) : (
          <span className="sm:ml-3 relative z-0 flex flex-row items-center">
            <button
              onClick={() => dispatch(setReview())}
              className="inline-flex items-center justify-center px-4 py-2 mr-4 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

              <span>Back</span>
            </button>
          </span>
        )}
      </div>
      {renderedComponent}
    </div>
  );
};

export default SalesView;
