/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon, CheckIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { useMutation, gql } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { usePDF } from "@react-pdf/renderer";
import {
  setSelectedEstimate,
  selectEstimate,
} from "../../hooks/sales/saleSlice";
import createPDF from "../../helper/createPDF";
import createPO from "../../helper/createPO";

//COOKIES
import { useCookies } from "react-cookie";

const DELETE_ESTIMATE = gql`
  mutation deleteEs($id: String!) {
    deleteEstimate(id: $id) {
      estimateNumber
    }
  }
`;

const SimpleEstimateSelector = ({
  data,
  headers,
  linkTitle,
  orderID,
  submitOrder,
  selected,
  setSelectEstimate,
  vendorID,
}) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const selectedEstimate = useSelector(selectEstimate);
  const [open, setOpen] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const { handleSubmit, register, setValue, formState } = useForm();
  const cancelButtonRef = useRef(null);
  const [estimate, setEstimateSelected] = useState(null);
  const { errors } = formState;
  const estimates = data.estimates || [data.selectedEstimate];
  const { PDF } =
    cookies.user?.user?.role?.name !== "Vendor Admin"
      ? createPDF()
      : createPO();
  const [instance, updateInstance] = usePDF({ document: PDF });
  const [deleteEstimate, deleted] = useMutation(DELETE_ESTIMATE, {
    onCompleted: (d) => window.location.reload(),
    onError: (e) => alert(e),
  });

  if (estimates.length === 1 || data?.predictedEstimate?.id) {
    setSelectEstimate(estimates[0].number);
    setValue("estimate_predicted", estimates[0].number);
  }
  useEffect(() => {
    updateInstance();
  }, [selectedEstimate]);
  return (
    <div className="flex flex-col">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Delete Estimate
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to Delete the Estimate?This
                          action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() =>
                      deleteEstimate({
                        variables: {
                          id: estimate.id,
                        },
                      })
                    }
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openPdf} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          open={openPdf}
          onClose={setOpenPdf}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:align-middle sm:max-w-7xl sm:w-full ">
                <div className="p-4 sm:p-6">
                  {instance.url && (
                    <iframe
                      style={{ width: "100%", height: "90vh" }}
                      src={instance.url || null}
                      type="application/pdf"
                      title="title"
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="-my-2  sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {errors.estimate_predicted && (
            <p className="text-red-600 mb-1 text-right animate-pulse ">
              A predicted estimate selection is required to process Order!
            </p>
          )}
          <div className="shadow border-b border-gray-200 sm:rounded-lg">
            <form id="estimate-Selector" onSubmit={handleSubmit(submitOrder)}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {headers.map((entry, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {entry}
                        </th>
                      );
                    })}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {data.selectedEstimate?.id ? (
                        "Selected"
                      ) : (
                        <div className="flex flex-row items-end  gap-2 ">
                          Predicted
                          <div className="relative z-40 flex flex-col justify-center items-center group">
                            <svg
                              className="w-5 h-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <div className="absolute bottom-0 w-full z-50 flex flex-col items-center  hidden  mb-6 group-hover:flex">
                              <span className="relative lowercase p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md">
                                The anticipated estimate{" "}
                                <span className="block">to be selected</span>
                                <span className="block">by the client</span>
                              </span>
                              <div className="w-3 h-3 -mt-2 transform rotate-45 bg-gray-600" />
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    {data.commissionQuotaReached && !vendorID && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        COMMISSION QUOTA
                      </th>
                    )}
                    {data.revisionComments && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        REVISION
                      </th>
                    )}
                    {vendorID && (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Payment Received
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {estimates &&
                    estimates.length !== 0 &&
                    [...estimates]
                      .sort(function (a, b) {
                        return a.number - b.number;
                      })
                      .map((entry, index) => {
                        if (
                          (vendorID &&
                            data.selectedEstimate?.id === entry.id) ||
                          !vendorID
                        ) {
                          return (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                                {cookies.user?.user?.role?.name !==
                                "Vendor Admin" ? (
                                  <a
                                    href={`${orderID}/estimates/${entry.id}${window.location.search}`}
                                    className={`text-blue-600 hover:text-blue-900 text-right ${
                                      selected && "ml-4"
                                    }`}
                                  >
                                    {entry.number}
                                  </a>
                                ) : (
                                  <span> {entry.number}</span>
                                )}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-green-900">
                                $
                                {!vendorID
                                  ? parseFloat(entry.total)
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : (
                                      entry?.lineItems
                                        .filter(
                                          (i) =>
                                            i?.item?.vendor?.id === vendorID
                                        )
                                        .reduce(
                                          (sum, r) =>
                                            sum + parseFloat(r.totalCost),
                                          0
                                        ) *
                                      (parseFloat(entry?.taxPercentage) + 1)
                                    )
                                      .toFixed(2)
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                                {entry.createdAt.split("T")[0]}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-blue-700 hover:text-gray-700">
                                {data.currentStatus?.name !== "New" && (
                                  <button
                                    type="button"
                                    onClick={async () => {
                                      let estimate = JSON.parse(
                                        JSON.stringify(entry)
                                      );
                                      if (vendorID) {
                                        estimate.lineItems =
                                          entry.lineItems.filter(
                                            (e) =>
                                              e.item?.vendor?.id === vendorID
                                          );
                                      }
                                      await dispatch(
                                        setSelectedEstimate(estimate)
                                      );
                                      updateInstance();
                                      setOpenPdf(true);
                                    }}
                                  >
                                    {linkTitle}{" "}
                                  </button>
                                )}
                                {(data.currentStatus?.name === "New" ||
                                  data.currentStatus?.name ===
                                    "Needs Revision") && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setOpen(true);
                                      setEstimateSelected(entry);
                                    }}
                                    className="text-red-600 hover:text-red-900 text-right"
                                  >
                                    <span className="text-gray-800 "> | </span>
                                    Delete
                                  </button>
                                )}
                              </td>
                              {data.selectedEstimate ? (
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                  {data.selectedEstimate?.id === entry.id && (
                                    <CheckIcon
                                      className="h-6 w-6 text-green-600"
                                      aria-hidden="true"
                                    />
                                  )}
                                </td>
                              ) : data.predictedEstimate ? (
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                  {data.predictedEstimate?.id === entry.id && (
                                    <CheckIcon
                                      className="h-6 w-6 text-green-600"
                                      aria-hidden="true"
                                    />
                                  )}
                                </td>
                              ) : (
                                <td className="flex justify-start px-12 py-4">
                                  <input
                                    type="radio"
                                    id="predictEstimate"
                                    value={entry.number}
                                    {...register("estimate_predicted", {
                                      required: true,
                                    })}
                                    onClick={() =>
                                      setSelectEstimate(entry.number)
                                    }
                                  />
                                </td>
                              )}
                              {data.commissionQuotaReached &&
                                data.selectedEstimate?.id === entry.id &&
                                !vendorID && (
                                  <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    {parseFloat(data.commissionQuotaReached) *
                                      100}
                                    %
                                  </td>
                                )}
                              {data.revisionComments &&
                                data.selectedEstimate?.id === entry.id && (
                                  <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                                    {data.revisionComments || "Not Provided"}
                                  </td>
                                )}
                              {vendorID && data.paymentTransactions && (
                                <td className="px-6 py-3 text-left text-xs  font-medium text-gray-600 tracking-wider">
                                  {(
                                    (data.paymentTransactions?.reduce(
                                      (pSum, line) =>
                                        pSum + parseFloat(line.amountReceived),
                                      0
                                    ) /
                                      parseFloat(data.selectedEstimate?.total) >
                                    1
                                      ? 1
                                      : data.paymentTransactions?.reduce(
                                          (pSum, line) =>
                                            pSum +
                                            parseFloat(line.amountReceived),
                                          0
                                        ) /
                                        parseFloat(
                                          data.selectedEstimate?.total
                                        )) * 100
                                  ).toFixed(2)}{" "}
                                  %
                                </td>
                              )}
                            </tr>
                          );
                        }
                      })}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleEstimateSelector;
