/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLazyQuery, ApolloClient, InMemoryCache } from "@apollo/client";
import {
  setOrderList,
  setHiddenColumns,
  selectSortBy,
  setRequiredShipping,
  selectRequiredShipping,
  setVendorID,
  selectVendorID,
} from "./saleSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  ALL_ORDERS,
  CUSTOM_ORDERS,
  SPARE_ORDERS,
  VENDOR_ORDERS,
  USER,
} from "../requests";
import { useCookies } from "react-cookie";
import { useSearchParams, Link } from "react-router-dom";
import { ShoppingCartIcon } from "@heroicons/react/outline";

const UseSpare = () => {
  const dispatch = useDispatch();
  const sortBy = useSelector(selectSortBy);
  const requiredShipping = useSelector(selectRequiredShipping);
  const vendorID = useSelector(selectVendorID);
  const [cookies] = useCookies();
  const [skipValue, setSkipValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stauses, setStatuses] = useState([]);
  useEffect(() => {
    if (requiredShipping === null) {
      dispatch(
        setRequiredShipping(
          cookies.user?.user?.role?.name === "Vendor Admin" ? true : false
        )
      );
    }
  }, [requiredShipping]);

  const [searchParams, setSearchParams] = useSearchParams();
  const view = searchParams.get("view") || null;
  if (!view) setSearchParams({ view: "custom" });
  const onlyMine =
    cookies.user?.user?.role?.name === "Sales Representative" ? true : false;

  const client = new ApolloClient({
    uri: process.env.REACT_APP_URI,
    credentials: "same-origin",
    cache: new InMemoryCache(),
    headers: {
      "Content-Type": "application/json",
      authorization: cookies.user !== "null" ? `JWT ${cookies.user.token}` : "",
    },
  });

  const [fetchOrders, orderResponse] = useLazyQuery(
    cookies.user?.user?.role?.name === "Vendor Admin"
      ? VENDOR_ORDERS
      : view === "custom"
      ? CUSTOM_ORDERS
      : SPARE_ORDERS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        mine: onlyMine,
        skip: skipValue,
        first: 25,
        order: sortBy,
        statusS: stauses,
        paid: requiredShipping,
        vendor: vendorID || null,
      },
      onCompleted: (d) => {
        handleSortOrder(d.orders);
      },
    }
  );

  const [fetchVendor] = useLazyQuery(USER, {
    onCompleted: (d) => {
      dispatch(setVendorID(d.user?.vendor?.id));
    },
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchTerm);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (sortBy && orderResponse.refetch)
      orderResponse.refetch({ order: sortBy, shippingOnly: requiredShipping });
  }, [sortBy, requiredShipping]);

  useEffect(() => {
    const user = cookies.user?.user?.role?.name;
    let hiddenColumns = {};
    switch (user) {
      case "Sales Representative":
        hiddenColumns = {
          currency: true,
          invoice: true,
          selectedEstimate: true,
        };
        onlyMine && (hiddenColumns.createdBy = true);
        break;
      case "Customer Success Representative":
        hiddenColumns = {
          commission: true,
          currency: true,
        };
        break;
      case "Vendor Admin":
        hiddenColumns = {
          commission: true,
          currency: true,
          totalPrice: true,
          invoice: true,
          vendorName: true,
        };
        break;
      default:
        hiddenColumns = {
          currency: true,
          invoice: true,
        };
        break;
    }
    view === "spare" && (hiddenColumns.vendorName = true);

    dispatch(setHiddenColumns(hiddenColumns));
    if (cookies.user?.user?.role?.name === "Vendor Admin") fetchVendor();
  }, [view]);

  async function handleSearch(term) {
    if (!term && orderResponse.refetch) {
      orderResponse.refetch({ skip: 0, searchTerm: "" });
      return;
    }
    let search = term.trim();
    if (!search) return;
    setIsLoading(true);
    let startsWithNumber = /^\d/.test(search);
    let isUUId = search.length === 36;
    let toQuery = {
      idS: startsWithNumber && !isUUId ? search : "",
      orgS: !isUUId && search,
      estimateS: startsWithNumber && !isUUId ? search : "",
      uuidSearch: isUUId ? search : "",
    };
    const fetchOrders = (apolloClient, variables) =>
      apolloClient.query({
        query: ALL_ORDERS,
        variables: variables,
      });
    let orders = [];
    for (const [k, value] of Object.entries(toQuery)) {
      if (!value) continue;
      let variables = {};
      variables[k] = value;
      const orderResponse = await fetchOrders(client, variables);
      orders = [...orders, ...orderResponse.data.orders];
    }
    handleSortOrder(orders);
  }
  async function handleSortOrder(data) {
    setIsLoading(true);
    let tableData = [];
    if (!data || data.length === 0) {
      setIsLoading(false);
      dispatch(setOrderList(tableData));
      return;
    }
    for (const entry of data) {
      tableData.push({
        id: entry.id,
        organization: entry.lead?.companyName || entry.client?.name,
        isEcom: entry.salesType === "E_COMMERCE",
        clientID: entry.client?.id,
        isVendor: cookies.user?.user?.role?.name === "Vendor Admin",
        pipedrive: entry.lead?.crmId,
        totalPrice:
          entry.selectedEstimate &&
          parseFloat(entry.selectedEstimate.total).toFixed(2),
        predictedPrice:
          entry.predictedEstimate &&
          parseFloat(entry.predictedEstimate.total).toFixed(2),
        currency: entry.currency,
        createdBy: entry.createdBy
          ? `${entry.createdBy?.firstName} ${entry.createdBy?.lastName}`
          : "N/A",
        invoice: entry.hasInvoice,
        expected: entry.expectedCloseDate || entry.dueDate,
        created: entry.createdAt,
        payments: entry.paymentTransactions,
        commission: entry.revenueReport?.fullCommission,
        status: entry.currentStatus?.name,
        selectedEstimate: entry.selectedEstimate?.number,
        vendorName: entry.vendors,
      });
    }
    dispatch(setOrderList(tableData));
    setIsLoading(false);
  }

  async function switchRequiresShipping(payload) {
    dispatch(setRequiredShipping(payload));
  }

  async function loadOrders() {
    fetchOrders();
  }

  return {
    SpareHeaders,
    refetch: orderResponse.refetch,
    skipValue,
    setSkipValue,
    setSearchTerm,
    setStatuses,
    switchRequiresShipping,
    loadOrders,
    requiredShipping,
    isLoading: isLoading || orderResponse.loading,
    addSpare:
      (cookies.user?.user?.role?.name === "Sales Representative" ||
        cookies.user?.user?.role?.name === "Executive") &&
      view === "spare",
  };
};
export default UseSpare;

/************************************************** */
// TABLE HEADERS
const SpareHeaders = [
  {
    Header: "ID",
    accessor: "id",
    isSortable: true,
    sortField: "id",
    Cell: (props) =>
      !props.row.original.isVendor ||
      (props.row.original.isVendor && props.row.original.selectedEstimate) ? (
        <Link
          to={`/orders/${props.row.original.id}${window.location.search}`}
          className="text-blue-700 hover:text-blue-900 flex flex-row gap-2 items-center"
        >
          {props.row.original.id}
          {props.row.original.isEcom && (
            <ShoppingCartIcon
              className="h-4 w-4 text-green-800 "
              aria-hidden="true"
            />
          )}
        </Link>
      ) : (
        <p className="font-medium"> {props.row.original.id}</p>
      ),
  },
  {
    Header: "Organization",
    accessor: "organization",
    Cell: (props) =>
      props.row.original.clientID && !props.row.original.isVendor ? (
        <Link
          to={`/clients/${props.row.original.clientID}`}
          className="text-blue-700 hover:text-blue-900"
        >
          {props.row.original.organization}
        </Link>
      ) : (
        <p className="font-medium">{props.row.original.organization}</p>
      ),
  },
  {
    Header: "Status",
    accessor: "status",
    isSortable: true,
    sortField: "status",
    searchable: "multiSelect",
    searchField: "statusS",
    options: [
      {
        name: "archived",
      },
      {
        name: "awaiting_payment",
      },
      {
        name: "awaiting_response",
      },
      {
        name: "expired",
      },
      {
        name: "needs_revision",
      },
      {
        name: "new",
      },
      {
        name: "payment_sent",
      },
      {
        name: "ready_for_production",
      },
      {
        name: "shipped",
      },
    ],
    Cell: (props) => {
      let color = "bg-green-100 text-green-800";
      switch (props.row.original.status) {
        case "Awaiting Payment":
          color = "bg-red-100 text-red-800";
          break;
        case "Ready For Production":
          color = "bg-yellow-100 text-yellow-800";
          break;
        case "Awaiting Response":
          color = "bg-blue-100 text-blue-800";
          break;
        case "Archived":
        case "Expired":
          color = "bg-yellow-900 text-white";
          break;
        default:
          break;
      }
      return (
        <p
          className={`px-3 py-0.5  inline-flex text-xs leading-5 font-semibold rounded-full ${color}`}
        >
          {props.row.original.status}
        </p>
      );
    },
  },
  // {
  //   Header: "Type",
  //   accessor: "type",
  //   isSortable: true,
  //   sortField: "status",
  //   searchable: "multiSelect",
  //   searchField: "statusS",
  //   options: [
  //     {
  //       name: "custom",
  //     },
  //     {
  //       name: "spare_parts",
  //     },
  //     {
  //       name: "e_commerce",
  //     },
  //   ],
  //   Cell: (props) => {
  //     return <p className="font-medium">{props.row.original.type}</p>;
  //   },
  // },
  {
    Header: "Created On",
    accessor: "created",
    isSortable: true,
    sortField: "created_at",
    Cell: (props) => (
      <p className="font-medium text-red-900">
        {props.row.original.created?.split("T")[0]}
      </p>
    ),
  },
  {
    Header: "Selected Estimate",
    accessor: "selectedEstimate",
    isSortable: true,
    sortField: "selected_estimate",
    Cell: (props) => (
      <p className="font-medium">{props.row.original.selectedEstimate}</p>
    ),
  },
  {
    Header: "Vendors",
    accessor: "vendorName",
    isSortable: true,
    sortField: "status",
    searchable: "select",
    searchField: "statusS",
    options: [
      {
        name: "ATG Pharma Inc",
        id: 1,
      },
      {
        name: "WayFast",
        id: 2,
      },
      {
        name: "SaintyCo",
        id: 3,
      },
      {
        name: "Medisca Inc.",
        id: 7,
      },
      {
        name: "STM Canna",
        id: 8,
      },
      {
        name: "Growhaus Supply Co",
        id: 9,
      },
      {
        name: "FTLD ",
        id: 10,
      },
      {
        name: "KungTech Inc.",
        id: 11,
      },
      {
        name: "Procepack",
        id: 12,
      },
    ],
    Cell: (props) => {
      if (typeof props.row.original.vendorName === "string")
        return <p className="font-medium">{props.row.original.vendorName}</p>;

      if (!props.row.original.vendorName?.length)
        return <p className="font-medium">N/A</p>;

      return (
        <div className="list-decimal">
          {props.row.original.vendorName?.map((item, index) => (
            <p key={index} className="font-medium">
              {item.name}
            </p>
          ))}
        </div>
      );
    },
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Total",
    accessor: "totalPrice",
    Cell: (props) =>
      parseFloat(props.row.original.totalPrice) ? (
        <p>
          <span className="text-gray-600">{`${props.row.original.currency} $`}</span>
          <span className="text-gray-900 font-medium">
            {parseFloat(props.row.original.totalPrice)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "Pending"}
          </span>
        </p>
      ) : parseFloat(props.row.original.predictedPrice) ? (
        <p className="text-yellow-500">
          <span>{`${props.row.original.currency} $`}</span>
          <span className="font-medium">
            {parseFloat(props.row.original.predictedPrice)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        </p>
      ) : (
        <p className="text-red-600">Pending</p>
      ),
  },
  {
    Header: "Invoice",
    accessor: "invoice",
    Cell: (props) => {
      return props.row.original.invoice ? (
        <a
          href={`/orders/${props.row.original.id}${window.location.search}`}
          rel="keep-params"
          className="text-blue-600 hover:text-blue-900"
        >
          View
        </a>
      ) : (
        <a
          href={`/orders/${props.row.original.id}${window.location.search}`}
          rel="keep-params"
          className="text-green-600 hover:text-green-900"
        >
          Add
        </a>
      );
    },
  },
  {
    Header: "Payments",
    accessor: (data) => data.payments.map((item) => item.amount).join(" "),
    Cell: (props) => {
      if (!props.row.original.payments.length)
        return <span className="text-red-600 font-medium">Not Paid</span>;
      let summation = props.row.original.payments?.reduce(
        (pSum, line) => pSum + parseFloat(line.amountReceived),
        0
      );
      if (!summation)
        return (
          <span className="text-yellow-500 font-medium">Not Received</span>
        );
      let value = (
        (summation / parseFloat(props.row.original.totalPrice)) *
        100
      ).toFixed(2);
      return <span className="text-green-700 font-medium">{`${value} %`}</span>;
    },
  },
  {
    Header: "Belongs To",
    accessor: "createdBy",
  },
];
