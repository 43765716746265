import React from "react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

// HOOKS
import useClientImport from "../../../../hooks/client/useClientImport";

const Headers = ["Invoice #", "Imported", "Action"];
const ListView = ({ invoices, importInvoice }) => {
  const { fetchInvoice, setIsLoading } = useClientImport();

  if (!invoices.length || importInvoice) return null;
  return (
    <div className="w-full">
      <h3 className="text-xl font-medium  py-4">QBO Invoices</h3>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {Headers.map((header, index) => (
                      <th
                        key={index}
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {invoice.number}
                      </td>
                      <td className="py-4 whitespace-nowrap text-sm px-6 text-gray-500">
                        {invoice.alreadyImported ? (
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <XIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                        {invoice.alreadyImported ? (
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              setIsLoading(true);
                              fetchInvoice({
                                variables: {
                                  number: invoice.number,
                                },
                              });
                            }}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Import
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListView;
