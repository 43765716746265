/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { PlusCircleIcon } from "@heroicons/react/outline";

import useEstimateParameters from "../../hooks/useEstimateParameters";

const BuildStepByStep = () => {
  const {
    isSubmitting,
    parametersForm,
    handleFluids,
    handleBuildForMe,
    handleSubmit,
    handleAddParameters,
  } = useEstimateParameters();

  return (
    <div>
      <div>
        <h3 className="ml-6 mb-4 text-xl leading-6 font-semibold text-gray-900">
          Production Selection
        </h3>
      </div>
      <form
        onSubmit={handleSubmit(handleBuildForMe)}
        className={`xl:mx-6 ${isSubmitting && "opacity-40"}`}
      >
        <div className="border-b border-gray-200 pb-4 flex flex-col md:flex-row justify-between items-center mb-4 ">
          <div className="max-w-xs  mr-auto mb-2">
            <label
              htmlFor="fluids"
              className="block text-sm font-medium text-gray-700"
            >
              # of Fluids
            </label>
            <div className="mt-1">
              <input
                type="number"
                name="fluids"
                id="fluids"
                defaultValue={1}
                onChange={(e) => handleFluids(e.target.value)}
                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div className="mt-3 flex sm:mt-0 sm:ml-4 flex-row gap-4">
            <button
              type="submit"
              disabled={isSubmitting}
              className="inline-flex mt-4 items-center px-3 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Build Step-By-Step
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2 lg:gap-5 sm:grid-cols-11 font-medium">
          <h3 className="ml-4 col-span-2">Liquid</h3>
          <h3 className="col-span-2">Filling Into:</h3>
          <h3 className="col-span-2">Volume</h3>
          <h3 className="col-span-2">Requires Heating?</h3>
          <h3 className="col-span-2">On Machine:</h3>
          <h3 className="col-span-1">Remove</h3>
        </div>
        {parametersForm}
        <div className="border-t border-gray-200 pb-4 mt-8 flex flex-row justify-between items-center mx-6">
          <button
            type="button"
            onClick={handleAddParameters}
            className="inline-flex mt-4 items-center px-2 py-1 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <PlusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            Add Setup
          </button>
        </div>
      </form>
    </div>
  );
};

export default BuildStepByStep;
