/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import {
  ADD_INVOICE,
  DELETE_INVOICE,
  PUSH_PRODUCTION,
  UPDATE_LEAD,
} from "../requests";
import { setManualProgression } from "../hooks/saleSlice";
import ResolveUndefined from "../../global/utils/manipulate/undefined";
import { PencilIcon } from "@heroicons/react/outline";

//COOKIES
import { useCookies } from "react-cookie";
const TOKEN = process.env.REACT_APP_PIPEDRIVE_TOKEN;

const Header = ({
  data,
  setIsSpare,
  refetch,
  setOpenAddress,
  setOnDetails,
  setReviseOrder,
  vendorID,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState(null);
  const [dealOwner, setDealOwner] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [profit, setProfit] = useState(0);
  const [cookies] = useCookies(["user"]);
  const [uploaded, setUploaded] = useState(null);
  const [track, setTrack] = useState(false);
  const [viewShippingCost, setViewCost] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [name, setName] = useState(
    data ? data.lead?.companyName || data.client?.name : null
  );
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || null;
  let { id } = useParams();
  const date = new Date();

  useEffect(() => {
    async function getDeal() {
      const url = `https://api.pipedrive.com/v1/deals/${data.lead?.crmId}?api_token=${TOKEN}`;
      const response = await axios.get(url);

      setDealOwner(response.data?.data?.owner_name);
    }
    getDeal();
  }, [data]);

  useEffect(() => {
    async function assignCostView() {
      if (!data.selectedEstimate) return;
      const sum = data.selectedEstimate.lineItems?.reduce(
        (accumulator, object) => {
          return accumulator + parseFloat(object.totalProfit);
        },
        0
      );
      setProfit(sum);
      if (
        data.selectedEstimate.shippingCost &&
        cookies.user?.user?.role?.name !== "Sales Representative" &&
        (data.currentStatus?.name === "Ready For Production" ||
          data.currentStatus?.name === "In Production" ||
          data.currentStatus?.name === "Shipped")
      )
        setTrack(true);

      if (cookies.user?.user?.role?.name !== "Vendor Admin") return;
      if (!vendorID) return;
      let vendorCosts = data.selectedEstimate.vendorsShippingCosts.find(
        (e) => e.vendor?.id === vendorID
      );
      if (!vendorCosts) return;
      if (!vendorCosts.costCad && !data.selectedEstimate.shippingCost)
        setViewCost(true);
    }

    assignCostView();
  }, [data, vendorID]);

  const [uploadForm] = useMutation(ADD_INVOICE, {
    onCompleted: (d) => {
      setUploaded(true);
      refetch();
    },
    onError: (e) => alert(e),
  });

  const [pushProduction, pushed] = useMutation(PUSH_PRODUCTION, {
    onCompleted: (d) => window.location.reload(),
    onError: (e) => alert(e),
  });

  const [updateLead, updated] = useMutation(UPDATE_LEAD, {
    onCompleted: (d) => window.location.reload(),
    onError: (e) => alert(e),
  });

  const [deleteInvoice] = useMutation(DELETE_INVOICE, {
    onCompleted: (d) => {
      setSubmitting(false);
      setFileName(null);
      setUploaded(false);
      refetch();
    },
    onError: (e) => alert(e),
  });

  async function submitForm() {
    setOnDetails(false);
  }

  const handleInvoiceUpload = React.useCallback(
    (event) => {
      setSubmitting(true);
      event.preventDefault();
      const [file] = event.target.fileUpload.files || [];
      if (file)
        uploadForm({
          variables: {
            id: id,
            form: file,
          },
        });
    },
    [ADD_INVOICE]
  );

  setIsSpare(data.salesType === "SPARE_PARTS");
  let buttonRender;
  switch (data.currentStatus?.name) {
    case "Awaiting Response":
      if (
        cookies.user?.user?.role?.name === "Executive" ||
        cookies.user?.user?.role?.name === "Sales Representative"
      )
        buttonRender = (
          <button
            type="button"
            onClick={() => setReviseOrder(true)}
            className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-gray-50 bg-yellow-500 hover:bg-yellow-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            <span>Revise Order</span>
          </button>
        );
      break;
    default:
      break;
  }

  return (
    <div>
      <div className="py-4 px-4 sm:px-6 lg:px-8 flex flex-col-reverse lg:grid grid-cols-3">
        <div className="flex flex-col col-span-2">
          <div className="flex flex-row items-end">
            {cookies.user?.user?.role?.name !== "Vendor Admin" &&
            (data.lead?.client?.id || data.client?.id) ? (
              <a
                href={`/clients/${data.lead?.client?.id || data.client?.id}`}
                className="mt-2 text-2xl font-bold leading-7 text-blue-700 hover:text-blue-900 sm:text-3xl sm:truncate"
              >
                {data.lead?.companyName || data.client?.name}
              </a>
            ) : (
              <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate w-4/5 flex flex-row">
                {onEdit ? (
                  <>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      className={`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
                      defaultValue={data.lead?.companyName || data.client?.name}
                    />

                    <button
                      onClick={() => {
                        updateLead({
                          variables: {
                            input: {
                              id: data.lead.id,
                              companyName: name,
                              contactName: data.lead.contactName,
                              email: data.lead.email,
                              productionGoal: data.lead.productionGoal,
                              funding: data.lead.funding,
                              fundingCurrencySymbol:
                                data.lead.fundingCurrencySymbol,
                            },
                          },
                        });
                        setOnEdit(!onEdit);
                      }}
                      className="text-sm ml-4 bg-blue-700 hover:bg-white hover:border-blue-600 border p-1 px-2 rounded-lg text-white hover:text-blue-600"
                    >
                      <span>Save</span>
                    </button>
                  </>
                ) : (
                  <>
                    {data.lead?.companyName || data.client?.name}
                    {cookies.user?.user?.role?.name !== "Vendor Admin" && (
                      <button
                        onClick={() => setOnEdit(!onEdit)}
                        className="ml-4 bg-blue-700 hover:bg-white hover:border-blue-600 border p-2 rounded-lg text-white hover:text-blue-600"
                      >
                        <PencilIcon className="h-4 w-4 " aria-hidden="true" />
                      </button>
                    )}
                  </>
                )}
              </h1>
            )}
          </div>
          <div class="flex flex-col md:grid md:grid-cols-2 gap-12">
            <div class="flex flex-col">
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Order Number:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.id}
                </span>
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Order Type:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.salesType?.replaceAll("_", " ")}
                </span>
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Currency:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.currency}
                </span>
              </p>
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Status:{" "}
                <span className="my-1 px-4 py-1.5 inline-flex text-sm leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  {data.currentStatus?.name?.replace(/_/g, " ")?.toUpperCase()}
                </span>{" "}
              </p>
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Sales Rep:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.createdBy
                    ? `${data.createdBy?.firstName} ${data.createdBy?.lastName}`
                    : `${data.client?.accountExecutive?.firstName} ${data.client?.accountExecutive?.lastName}`}
                </span>
              </p>
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                PD Deal Owner:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {dealOwner}
                </span>
              </p>
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Contact Email:{" "}
                <a
                  href={`mailto:${
                    data.lead?.email || data.client?.contacts[0]?.email
                  }}`}
                  className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full text-blue-700 hover:text-blue-900 "
                >
                  {data.lead?.email || data.client?.contacts[0]?.email}
                </a>
              </p>
              {data.affiliate && (
                <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                  Affiliate:{" "}
                  <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                    {data.affiliate.firstName} {data.affiliate.lastName}
                  </span>
                </p>
              )}
              {data.purchaseTermsSigner && (
                <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                  PTC Signer:{" "}
                  <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                    {data.purchaseTermsSigner.replace(/\b\w/g, (c) =>
                      c.toUpperCase()
                    )}
                  </span>
                </p>
              )}
              {!data.invoiceLink &&
                cookies.user?.user?.role?.name !== "Vendor Admin" &&
                data.selectedEstimate && (
                  <form
                    className="flex w-full flex-row items-center  gap-4 justify-between pt-2"
                    onSubmit={handleInvoiceUpload}
                  >
                    <h2 className="font-medium text-gray-500  flex flex-row items-center justify-between">
                      Invoice:
                    </h2>
                    <div className="flex flex-col items-end">
                      <link
                        href="https://cdn.jsdelivr.net/npm/@tailwindcss/custom-forms@0.2.1/dist/custom-forms.css"
                        rel="stylesheet"
                      />
                      <label className="inline-flex items-center  justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 gap-2">
                        {uploaded ? (
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                            />
                          </svg>
                        )}
                        <span className="text-sm leading-normal">
                          {fileName ? fileName : "Upload Invoice"}
                        </span>
                        <input
                          id="fileUpload"
                          name="fileUpload"
                          type="file"
                          onChange={(e) =>
                            setFileName(e.target.files["0"]?.name)
                          }
                          className="hidden"
                        />
                      </label>
                      {!uploaded && (
                        <button
                          type="submit"
                          disabled={submitting}
                          className={`${
                            fileName ? "flex" : "hidden"
                          } text-green-800 hover:text-gray-600 font-bold mt-2`}
                        >
                          {submitting && (
                            <svg
                              className="animate-spin h-5 w-5 text-green-800 self-center mr-2"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 40 40"
                              enableBackground="new 0 0 40 40"
                              xml="preserve"
                            >
                              <path
                                opacity="0.2"
                                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                              />
                              <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                            </svg>
                          )}
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                )}
              {data.invoiceLink &&
                cookies.user?.user?.role?.name !== "Vendor Admin" && (
                  <div className="flex w-full flex-row items-start  gap-4 justify-between pt-2">
                    <h2 className="font-medium text-gray-500  flex flex-row items-center justify-between">
                      Invoice:
                    </h2>
                    <div className="flex flex-col gap-2 text-right">
                      <a
                        href={data.invoiceLink}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-700 hover:text-blue-900"
                      >
                        View Invoice
                      </a>
                      <button
                        onClick={() => {
                          deleteInvoice({
                            variables: {
                              id: parseInt(id),
                            },
                          });
                        }}
                        className="text-red-700 hover:text-red-900"
                      >
                        Delete Invoice
                      </button>
                    </div>
                  </div>
                )}
              {data.purchaseOrderLink &&
                cookies.user?.user?.role?.name !== "Vendor Admin" && (
                  <div className="flex w-full flex-row items-start  gap-4 justify-between pt-3">
                    <h2 className="font-medium text-gray-500  flex flex-row items-center justify-between">
                      Purchase Order:
                    </h2>
                    <a
                      href={data.purchaseOrderLink}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-700 hover:text-blue-900 mt-0"
                    >
                      View PO
                    </a>
                  </div>
                )}
            </div>
            <div class="flex flex-col">
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Vendor(s):{" "}
                <span className="px-4 py-2  leading-5 font-semibold rounded-full block">
                  {data.vendors.map((vendor, index) => (
                    <span className="block" key={index}>
                      {vendor.name}
                    </span>
                  ))}
                </span>
              </p>
              {cookies.user?.user?.role?.name !== "Vendor Admin" && (
                <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                  PipeDrive:{" "}
                  {data.lead ? (
                    <a
                      href={`https://atgpharma.pipedrive.com/deal/${data.lead.crmId}`}
                      target="_blank"
                      rel="noreferrer"
                      className="px-4 py-2 inline-flex leading-5 rounded-full text-blue-700 hover:text-blue-900 hover:font-bold"
                    >
                      {data.lead.crmId}
                    </a>
                  ) : (
                    <span className="px-4 py-2 inline-flex leading-5 text-red-500 font-light rounded-full">
                      Unavailable
                    </span>
                  )}
                </p>
              )}
              <p className=" font-medium text-gray-500 ">
                {data.vendors[0]?.name === "ATG Pharma Inc" && (
                  <div className="flex flex-row items-center justify-between">
                    MRPEasy:{" "}
                    {data.vendorOrderNumber ? (
                      <a
                        href={`https://app.mrpeasy.com/customers/orders/view/${data.vendorOrderNumber}`}
                        target="_blank"
                        rel="noreferrer"
                        className="px-4 py-2 inline-flex leading-5 rounded-full text-blue-700 hover:text-blue-900 hover:font-bold"
                      >
                        {data.vendorOrderNumber}
                      </a>
                    ) : (
                      <span className="px-4 py-2 inline-flex leading-5 text-red-500 font-light rounded-full">
                        Pending
                      </span>
                    )}
                  </div>
                )}
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Sent To Production:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.vendorHandOffDate?.split("T")[0] || (
                    <span className="text-red-500 font-light">Pending</span>
                  )}
                </span>
              </p>
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Shipped:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.vendorShipDate?.split("T")[0] || (
                    <span className="text-red-500 font-light">Pending</span>
                  )}
                </span>
              </p>
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Payment Received:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.paymentReceivedDate?.split("T")[0] || (
                    <span className="text-red-500 font-light">Pending</span>
                  )}
                </span>
              </p>
              {(cookies.user?.user?.role?.name === "Executive" ||
                vendorID === "1") &&
                profit !== 0 && (
                  <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                    Owed to AIR:{" "}
                    <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                      {data.selectedEstimate ? (
                        <span className="font-medium pl-3">
                          ${" "}
                          {parseFloat(profit)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </span>
                      ) : (
                        <span className="text-red-500 font-light">Pending</span>
                      )}
                    </span>
                  </p>
                )}
              {cookies.user?.user?.role?.name === "Executive" &&
                profit !== 0 && (
                  <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                    Profit Margin:{" "}
                    <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                      {data.profitMargin ? (
                        <span
                          className={`${
                            parseFloat(data.profitMargin) < 0.2
                              ? "text-red-600"
                              : "text-green-900"
                          }`}
                        >
                          {(parseFloat(data.profitMargin) * 100).toFixed(1)} %
                        </span>
                      ) : (
                        <span className="text-yellow-800">Pending</span>
                      )}
                    </span>
                  </p>
                )}
            </div>
          </div>
        </div>
        <div className="mt-5 flex flex-col items-end justify-between">
          <div>
            <div className="sm:ml-3 relative z-0 flex flex-row items-center gap-2">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

                <span>Back</span>
              </button>
              {(data.currentStatus?.name === "New" ||
                data.currentStatus?.name === "Needs Revision") && (
                <button
                  type="submit"
                  // onClick={() => setOnDetails(false)}
                  onClick={() => submitForm}
                  id="submitOrder"
                  form="estimate-Selector"
                  className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <CheckIcon className="h-5 w-5 mr-1 " aria-hidden="true" />

                  <span>Submit Order</span>
                </button>
              )}
              {data.currentStatus?.name !== "New" &&
                data.currentStatus?.name !== "Needs Revision" && (
                  <a
                    href={
                      window.location.host === "localhost:3000"
                        ? `http://localhost:3001/order/review/${data.linkUuid}`
                        : `http://www.customer.onesupply.co/order/review/${data.linkUuid}`
                    }
                    type="button"
                    target="_blank"
                    rel="noreferrer"
                    id="viewClientOrder"
                    form="estimate-Selector"
                    className="inline-flex items-center  justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                  >
                    <span>Client View</span>
                  </a>
                )}
              {buttonRender}
              {viewShippingCost && (
                <a
                  href={`${id}/shipping-cost`}
                  type="button"
                  className="inline-flex items-center  justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <span>Add Shipping Cost</span>
                </a>
              )}
              {track && (
                <a
                  href={`${id}/track-shipment`}
                  type="button"
                  className="inline-flex items-center  justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  {data.currentStatus?.name === "Shipped" ? (
                    <span>Update Ship Date</span>
                  ) : (
                    <span>Mark Shipped</span>
                  )}
                </a>
              )}
            </div>
            <div className="mt-3">
              {data.currentStatus?.name === "Ready For Production" &&
                vendorID && (
                  <div className="flex justify-end items-end col-span-3">
                    <button
                      type="button"
                      onClick={() => {
                        pushProduction({
                          variables: {
                            id: id,
                            vendorID: vendorID,
                          },
                        });
                      }}
                      className="inline-flex items-center  justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-indigo-900 hover:bg-gray-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    >
                      <span>Mark In-Production</span>
                    </button>
                  </div>
                )}
            </div>
            <div>
              <p className=" mt-2 text-right font-medium text-gray-500 flex flex-row items-center justify-end gap-2">
                Created On:{" "}
                <span className="px-2 inline-flex leading-5 font-semibold rounded-full">
                  {data.createdAt?.split("T")[0]}
                </span>
              </p>
              {data.dueDate && (
                <p className=" mt-2 text-right font-medium text-gray-500 flex flex-row items-center justify-end gap-2">
                  Due Date:{" "}
                  <span className="px-2 inline-flex leading-5 font-semibold rounded-full">
                    {data.dueDate?.split("T")[0]}
                  </span>
                </p>
              )}
            </div>
          </div>
          {data.currentStatus?.name === "Awaiting Response" &&
            cookies.user?.user?.role?.name !== "Vendor Admin" && (
              <div>
                <button
                  type="button"
                  onClick={() => dispatch(setManualProgression(true))}
                  className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  <span>Manual Progression</span>
                </button>
              </div>
            )}
        </div>
      </div>
      <div className="mx-4 sm:mx-6 lg:mx-8  grid md:grid-cols-3 gap-12 border-t border-gray-300">
        {data.shippingAddress && (
          <div className="flex flex-col items-start justify-start">
            <div className="col-span-2 flex justify-center px-2 pt-1 pb-1">
              <div className="space-y-1 text-left ">
                <div>
                  <h2 className="text-lg py-1 text-left font-medium">
                    Shipping Address:
                  </h2>
                  <div className="relative z-10 flex flex-col justify-start items-start group">
                    <p>{ResolveUndefined(data.shippingAddress?.lineOne)},</p>
                    <p>
                      {data.shippingAddress?.city},{data.shippingAddress?.state}
                    </p>
                    <p>
                      {data.shippingAddress?.countryCode},
                      {data.shippingAddress?.postalCode},
                    </p>
                    <div className="absolute bottom-0 w-full z-50 flex flex-col items-center hidden  mb-6 group-hover:flex">
                      <span className="relative p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                        Address ID: {data.shippingAddress?.id}
                      </span>
                      <div className="w-3 h-3 -mt-2 transform rotate-45 bg-gray-600" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data.currentStatus?.name === "Awaiting Payment" &&
              cookies.user?.user?.role?.name !== "Vendor Admin" && (
                <div>
                  <button
                    type="button"
                    onClick={() => setOpenAddress(true)}
                    className="inline-flex items-center justify-center  text-sm underline px-2 font-medium rounded-md   text-blue-600 hover:text-blue-800  focus:outline-none "
                  >
                    <span>Edit Address</span>
                  </button>
                </div>
              )}
          </div>
        )}
        {data.lead && vendorID && (
          <div className="flex flex-col items-start justify-start">
            <div className="col-span-2 flex justify-center px-2 pt-1 pb-1">
              <div className="space-y-1 text-left ">
                <div>
                  <h2 className="text-lg py-1 text-left font-medium">
                    Client Information:
                  </h2>
                  <div className="relative z-10 flex flex-col justify-start items-start group">
                    <p>{data.lead.contactName}</p>
                    <a
                      className="text-blue-600 hover:text-blue-800"
                      href={`mailto:${data.lead.email}`}
                    >
                      {data.lead.email}
                    </a>

                    <a
                      className="text-blue-600 hover:text-blue-800"
                      href={`tel:+${data.lead.phoneNumber}`}
                    >
                      {data.lead.phoneNumber}
                    </a>
                    <p>
                      TAX ID:{" "}
                      <span className="font-medium">
                        {data.client?.taxId ? data.client?.taxId : "N/A"}
                      </span>
                    </p>
                    <p>
                      Loading Dock:{" "}
                      <span className="font-medium">
                        {data.shippingAddress?.shippingDetail?.hasLoadingDock
                          ? data.shippingAddress?.shippingDetail?.hasLoadingDock
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {view === "spare" && (
          <div className="flex flex-row items-start  gap-4 justify-start">
            <div className="col-span-2 flex justify-center px-2 pt-1 pb-2">
              <div className="space-y-1 text-left ">
                <div>
                  <h2 className="text-lg py-1 font-medium text-left">
                    Price Breakdown:
                  </h2>
                  <div>
                    <p className=" flex flex-row justify-between items-center">
                      Subtotal:{" "}
                      <span className="font bold pl-3">
                        ${" "}
                        {(
                          parseFloat(data.selectedEstimate?.subtotal) -
                          parseFloat(data.selectedEstimate?.shippingCost || 0)
                        )
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </p>
                    <p className=" flex flex-row justify-between items-center">
                      Shipping:{" "}
                      <span className="font bold pl-3">
                        {" "}
                        ${" "}
                        {parseFloat(data.selectedEstimate?.shippingCost || 0)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </p>
                    <p className=" flex flex-row justify-between items-center">
                      Taxes:{" "}
                      <span className="font bold pl-3">
                        {" "}
                        ${" "}
                        {parseFloat(data.selectedEstimate?.taxTotal)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </p>
                    <p className="border-t border-gray-500 flex flex-row justify-between">
                      Total:{" "}
                      <span className={`font bold pl-3 `}>
                        ${" "}
                        {parseFloat(data.selectedEstimate?.total)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {cookies.user?.user?.role?.name !== "Vendor Admin" &&
          data.selectedEstimate && (
            <div className="flex flex-row items-start  gap-4 justify-start">
              <div className="col-span-2 flex justify-center px-2 pt-1 pb-2">
                <div className="space-y-1 text-left ">
                  <div>
                    <h2 className="text-lg py-1 font-medium text-left">
                      Vendor Shipping Costs:
                    </h2>
                    <div>
                      {data.selectedEstimate?.vendorsShippingCosts?.map(
                        (vendor, index) => (
                          <p
                            key={index}
                            className=" flex flex-row justify-between items-center"
                          >
                            <span className="font-medium text-gray-500  flex flex-row items-center justify-between">
                              {vendor?.vendor?.name}:{" "}
                            </span>
                            <span className="font-medium pl-3">
                              {parseFloat(
                                data?.selectedEstimate?.currencyConversionRate
                              ) > 1
                                ? "USD"
                                : "CAD"}{" "}
                              $
                              {(
                                parseFloat(vendor?.costCad) /
                                parseFloat(
                                  data?.selectedEstimate?.currencyConversionRate
                                )
                              )
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </p>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default Header;
