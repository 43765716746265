import { useSelector, useDispatch } from "react-redux";
import {
  selectConversion,
  selectProvince,
  setEstimate,
  setTotal,
  setTaxes,
  setTemplate,
  setExpiry,
  setBeethovenProducts,
  selectEstimate,
  selectTotal,
  selectTaxes,
  setConversion,
  setPrimaryVendor,
  setCratingPresent,
  selectPrimaryVendor,
} from "./estimateSlice";

import { FROM_USD } from "./queries";
import { useQuery } from "@apollo/client";

const useEstimatePages = () => {
  const dispatch = useDispatch();
  const province = useSelector(selectProvince);
  const estimateVendor = useSelector(selectPrimaryVendor);
  const conversion = useSelector(selectConversion);
  const currentEstimate = useSelector(selectEstimate);
  const currentTotal = useSelector(selectTotal);
  const currentTaxes = useSelector(selectTaxes);
  const fromUSD = useQuery(FROM_USD);

  async function configurePrice(runningTotal) {
    let taxes = 0;
    if (province) taxes = parseFloat(province.split("-")[1]) * runningTotal;
    runningTotal += taxes;
    dispatch(setTotal(runningTotal));
    dispatch(setTaxes(taxes));
  }

  async function configureBuildStepByStep(data) {
    let builds = {};
    let totalPrice = 0;
    for (const [index, entry] of data.entries()) {
      let workingPrice = 0;
      for (const item of entry.estimateItems) {
        workingPrice +=
          parseFloat(item.pricePerUnit) * parseFloat(item.quantity);
      }
      totalPrice += workingPrice;
      builds[index] = {
        build: entry.estimateItems,
        total: workingPrice,
      };
    }
    dispatch(setEstimate(builds));
    configurePrice(totalPrice);
  }

  async function configureTemplate(template) {
    const { lineItems, taxPercentage } = template;
    let estimate = { 0: { build: [], total: 0 } };
    let buildTotal = 0;
    let USDFactor = parseFloat(fromUSD.data?.currency.conversionRateFromCad);
    let forBuild = 0;
    let conversionFactor = taxPercentage === "0.00000" ? USDFactor : 1;
    let primaryVendor = null;
    for (const entry of lineItems) {
      if (entry.item.skuNumber === "700002") dispatch(setCratingPresent(true));
      if (entry.item.skuNumber === "00001") continue;
      if (!primaryVendor) primaryVendor = entry.item.vendor;
      let price =
        Math.ceil(parseFloat(entry.pricePerUnit) * conversionFactor * 100) /
        100;
      let originalPrice =
        Math.ceil(
          parseFloat(entry.item.price.pricePerUnit) * conversionFactor * 100
        ) / 100;
      estimate[`${forBuild}`]?.build.push({
        lineNumber: entry.lineNumber,
        pricePerUnit: price,
        originalPrice: originalPrice,
        quantity: entry.itemQuantity,
        skuNumber: entry.item.skuNumber,
        title: entry.item.title,
      });
      let itemTotal = price * parseFloat(entry.itemQuantity);
      estimate[`${forBuild}`].total += itemTotal;
      buildTotal += itemTotal;
    }
    configurePrice(buildTotal);

    dispatch(setPrimaryVendor(primaryVendor));
    dispatch(setTemplate(template));
    dispatch(setEstimate(estimate));
  }

  async function reArrangeEstimate(tempList) {
    let constructedEstimate = {};
    for (const [i, value] of tempList.entries()) {
      let build = value.build;
      for (const [j, line] of build.entries()) {
        if (document.querySelector(`#discount-${i}-${j}`)) {
          document.querySelector(`#price-${i}-${j}`).value = parseFloat(
            (parseFloat(line.pricePerUnit) / parseFloat(conversion)).toFixed(2)
          );
          document.querySelector(`#discount-${i}-${j}`).value =
            line.discountPerUnit &&
            Math.round(
              parseFloat(line.discountPerUnit) / parseFloat(conversion)
            );
          document.querySelector(`#quantity-${i}-${j}`).value = line.quantity;
        }
      }
      constructedEstimate[i] = {
        build: build,
        total: value.total,
      };
    }
    dispatch(setEstimate(constructedEstimate));
  }

  async function configureStore(intermittent, forBuild, product) {
    let clone = JSON.parse(JSON.stringify(currentEstimate));
    let totalPrice = currentTotal - currentTaxes;
    let USDFactor = parseFloat(fromUSD.data?.currency.conversionRateFromCad);
    if (!clone[0]) clone[0] = { build: [], total: 0 };
    for (const entry of Object.values(intermittent)) {
      const { item, quantity } = entry;
      if (item.skuNumber === "700002") dispatch(setCratingPresent(true));
      if (!estimateVendor) await dispatch(setPrimaryVendor(item.vendor));
      else if (
        (estimateVendor.name === "ATG Pharma Inc" ||
          item.vendor.name === "ATG Pharma Inc") &&
        estimateVendor.name !== item.vendor.name
      ) {
        alert("Cannot add ATG items with different vendors");
        return;
      }
      let itemPrice =
        parseFloat(conversion) > 1
          ? parseFloat(item.usdPrice.pricePerUnit) * USDFactor
          : item.price.pricePerUnit;
      let itemTotal = itemPrice * parseFloat(quantity);
      clone[forBuild || 0].build.push({
        lineNumber: clone[0].build.length + 1,
        pricePerUnit: itemPrice,
        productUuid: product,
        quantity: quantity,
        skuNumber: item.skuNumber,
        title: item.title,
      });
      clone[0].total += itemTotal;
      totalPrice += itemTotal;
    }
    configurePrice(totalPrice);
    dispatch(setEstimate(clone));
  }

  async function handleDelete(entry, build, index) {
    if (entry.skuNumber === "700002") dispatch(setCratingPresent(false));
    let clone = JSON.parse(JSON.stringify(currentEstimate));
    let itemPrice = parseFloat(entry.pricePerUnit) * parseFloat(entry.quantity);
    let current = currentTotal;
    clone[build].build.splice(index, 1);
    clone[build].total -= itemPrice;
    let totalPrice = (current -= itemPrice + currentTaxes);
    configurePrice(totalPrice);
    dispatch(setEstimate(clone));
    let nullVendor = true;
    for (const build of Object.values(clone)) {
      if (build.build.length > 0) nullVendor = false;
    }
    if (nullVendor) dispatch(setPrimaryVendor(null));
  }

  async function handleDiscount(entry, index, value) {
    value < 0 && (value = 0);
    let clone = JSON.parse(JSON.stringify(currentEstimate));
    clone[entry].build[index].discountPerUnit =
      parseFloat(value) * parseFloat(conversion);
    let realTotal = 0;
    for (const line of Object.values(clone)) {
      let buildTotal = 0;
      for (const item of line.build) {
        buildTotal +=
          (parseFloat(item.pricePerUnit) -
            (parseFloat(item.discountPerUnit) || 0)) *
          parseFloat(item.quantity);
      }
      clone[entry].total = buildTotal;
      realTotal += buildTotal;
    }
    configurePrice(realTotal);
    dispatch(setEstimate(clone));
  }

  async function handleLineExpiry(entry, index, value) {
    let clone = JSON.parse(JSON.stringify(currentEstimate));
    clone[entry].build[index].isTimedDiscount = value;
    let expiry = false;
    for (const line of Object.values(clone)) {
      for (const item of line.build) {
        if (item.isTimedDiscount) expiry = true;
      }
    }
    console.log(expiry);
    console.log(clone);
    dispatch(setExpiry(expiry));
    dispatch(setEstimate(clone));
  }

  async function handleNote(event, index, buildKey) {
    let clone = JSON.parse(JSON.stringify(currentEstimate));
    let value = event.target.value;
    clone[index].build[buildKey].salesRepNote = value;
    dispatch(setEstimate(clone));
  }

  async function handlePriceQuantityChange(
    event,
    entry,
    index,
    buildKey,
    type
  ) {
    let clone = JSON.parse(JSON.stringify(currentEstimate));
    let itemPrice =
      (parseFloat(entry.pricePerUnit) -
        (parseFloat(entry.discountPerUnit) || 0)) *
      parseFloat(entry.quantity);
    let value = event.target.value;
    event.target.value = value;
    !value && (value = 0);
    let total = currentTotal;
    clone[index].total -= itemPrice;
    let newPrice = 0;
    if (type === "price") {
      clone[index].build[buildKey].pricePerUnit =
        value * parseFloat(conversion);
      newPrice =
        (parseFloat(value) - (parseFloat(entry.discountPerUnit) || 0)) *
        parseFloat(conversion) *
        parseFloat(entry.quantity);
    } else if ((type = "quantity")) {
      clone[index].build[buildKey].quantity = value;
      newPrice =
        (parseFloat(entry.pricePerUnit) -
          (parseFloat(entry.discountPerUnit) || 0)) *
        parseFloat(conversion) *
        parseFloat(value);
    }
    clone[index].total += newPrice;
    let totalPrice = (total -= itemPrice + currentTaxes);
    totalPrice += newPrice;
    configurePrice(totalPrice);
    dispatch(setEstimate(clone));
  }

  async function handleBeethovenEstimate(beethoven, beethovenProducts) {
    let estimate = {};
    let lineNumber = 1;
    for (const [key, value] of Object.entries(beethoven)) {
      estimate[key - 1] = { build: [], total: 0 };
      const product = beethovenProducts.find(
        (element) => element.fillingMachine.number === parseInt(key)
      );
      for (const page of Object.values(value)) {
        if (page.defaults.length > 0) {
          for (const entry of page.defaults) {
            if (entry.title)
              estimate[key - 1].build.push({
                lineNumber: lineNumber,
                skuNumber: entry.skuNumber,
                pricePerUnit: entry.price,
                quantity: entry.quantity,
                title: entry.title,
                form: entry.form,
                productUuid: page.id === "206" ? null : product.productUuid,
              });
            lineNumber++;
          }
        }
        estimate[key - 1].total += page.total;
      }
    }
    dispatch(setEstimate(estimate));
  }

  async function configureFetchedEstimate(data) {
    if (currentEstimate[0]) return null;
    let revisionEstimate = {};
    let primaryVendor = null;
    let shippingCost = 0;
    if (data.lineItems) {
      let counter = 0;
      for (const entry of data.lineItems) {
        if (!primaryVendor) primaryVendor = entry.item.vendor;
        if (entry.item.skuNumber === "700002")
          dispatch(setCratingPresent(true));
        if (entry.item.skuNumber === "00001") {
          shippingCost = parseFloat(entry.pricePerUnit);
          continue;
        }
        if (!revisionEstimate[counter]) {
          revisionEstimate[counter] = {
            build: [],
            total: 0,
          };
        }
        let total =
          parseFloat(entry.pricePerUnit) *
          parseFloat(entry.itemQuantity) *
          parseFloat(data.currencyConversionRate);
        if (
          entry.lineNumber === 1 &&
          revisionEstimate[counter].build.length !== 0
        ) {
          counter++;
          revisionEstimate[counter] = {
            build: [],
            total: 0,
          };
          revisionEstimate[counter].build.push({
            lineNumber: entry.lineNumber,
            skuNumber: entry.item.skuNumber,
            title: entry.item.title,
            pricePerUnit: entry.pricePerUnit,
            quantity: entry.itemQuantity,
            productUuid: entry.productUuid,
          });
          revisionEstimate[counter].total += total;
        } else {
          revisionEstimate[counter].build.push({
            lineNumber: entry.lineNumber,
            skuNumber: entry.item.skuNumber,
            title: entry.item.title,
            pricePerUnit:
              parseFloat(entry.pricePerUnit) *
              parseFloat(data.currencyConversionRate),
            quantity: entry.itemQuantity,
            productUuid: entry.productUuid,
          });
          revisionEstimate[counter].total += total;
        }
      }
    }
    let parsedProducts = JSON.parse(data.productsJson);
    let products = [];
    if (parsedProducts && Object.keys(parsedProducts).length > 0)
      for (const [key, value] of Object.entries(parsedProducts)) {
        products.push({
          containerType: value.container_type,
          amount: value.amount,
          fillingMachine: {
            type: "automatic",
            number: value.filling_machine.number,
          },
          productUuid: key,
        });
      }
    let startingTotal = parseFloat(data.total) - shippingCost;
    let taxes =
      startingTotal - startingTotal / (parseFloat(data.taxPercentage) + 1);

    dispatch(setPrimaryVendor(primaryVendor));
    dispatch(setTaxes(taxes));
    dispatch(setTotal(startingTotal * parseFloat(data.currencyConversionRate)));
    dispatch(setConversion(parseFloat(data.currencyConversionRate)));
    dispatch(setBeethovenProducts(products));
    dispatch(setEstimate(revisionEstimate));
  }

  async function handleBeethovenTotal(build) {
    let total = 0;
    for (const value of Object.values(build)) {
      for (const page of Object.values(value)) {
        total += page.total;
      }
    }
    configurePrice(total);
  }

  return {
    configureBuildStepByStep,
    reArrangeEstimate,
    configureTemplate,
    configureStore,
    handleDelete,
    handleDiscount,
    handleLineExpiry,
    handlePriceQuantityChange,
    handleBeethovenEstimate,
    configureFetchedEstimate,
    handleBeethovenTotal,
    handleNote,
  };
};
export default useEstimatePages;
