import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const GeneralHeader = ({ pageTitle, action, subMenus }) => {
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || null;
  const activeState = "border-blue-500 text-blue-600";
  const inactiveState =
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";

  let options = [];
  let links = [];
  let location = window.location.href.split("/").pop();
  if (subMenus) {
    options = subMenus.map((item, index) => (
      <option key={index}>{item.title}</option>
    ));

    links = subMenus.map((item, index) => {
      let link = item.link.slice(0, 1) === "/" ? item.link.slice(1) : item.link;
      if (!view && link === location && activeMenuIndex !== index)
        setActiveMenuIndex(index);
      else if (view === item.view && activeMenuIndex !== index)
        setActiveMenuIndex(index);
      // else if (item.default && !view &&  link !== location && activeMenuIndex !== index)
      //   setActiveMenuIndex(index);
      return (
        <Link
          to={item.link}
          key={index}
          onClick={() => setActiveMenuIndex(index)}
          className={`${
            activeMenuIndex === index ? activeState : inactiveState
          } hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`}
        >
          {item.title}
        </Link>
      );
    });
  }

  return (
    <div className="relative pb-5 px-4 py-4 sm:pb-0 sm:px-6 lg:px-8">
      <div className="md:flex md:items-center md:justify-between">
        {/* <h3 className="text-xl leading-6 font-bold text-gray-900">
          {pageTitle}
        </h3> */}
        <div className="mt-3 flex md:mt-0 md:absolute md:top-6 md:right-8">
          {action && (
            <button
              type="button"
              onClick={() => action.buttonEvent()}
              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {action.text}
            </button>
          )}
        </div>
      </div>
      <div className="mt-1">
        <div className="sm:hidden">
          <label htmlFor="selected-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="selected-tab"
            name="selected-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          >
            {options}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-8">{links}</nav>
        </div>
      </div>
    </div>
  );
};

export default GeneralHeader;
