import React from "react";

const SimpleDropDown = ({ props, register }) => {
  let dropDownOptions;
  if (props.options && props.options.length !== 0) {
    dropDownOptions = props.options.map((option, index) => {
      return (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      );
    });
  }

  return (
    <div className="col-span-1">
      <select
        {...register(props.name, {
          required: true,
        })}
        disabled={dropDownOptions ? false : true}
        id={props.name}
        name={props.name}
        className="w-full focus:ring-blue-500 focus:border-blue-500 shadow-sm sm:text-sm p-1 bg-white border border-gray-300 rounded-md"
      >
        <option value="">Select...</option>
        {dropDownOptions}
      </select>
    </div>
  );
};

export default SimpleDropDown;
