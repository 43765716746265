import React from "react";
import { StyleSheet, Document, Text, Page, Link } from "@react-pdf/renderer";
import Html from "react-pdf-html";

import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import Payment from "./Payment";

import {
  selectEstimate,
  selectOrderDetails,
} from "../../hooks/sales/saleSlice";
import { useSelector } from "react-redux";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  acceptance: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 40,
  },
  accept: {
    fontSize: 9,
  },
  date: {
    fontSize: 9,
    marginLeft: "175px",
  },
  pay: {
    fontSize: 9,
    paddingTop: 10,
  },
  link: {
    fontSize: 8,
    marginTop: 5,
    textColor: "#0066ff",
  },
  ptc: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    display: "flex",
    flexDirection: "row",
  },
});
const CreatePDF = () => {
  const estimate = useSelector(selectEstimate);
  const orderDetails = useSelector(selectOrderDetails);
  let isWayFast = estimate?.lineItems.some(
    (entry) => entry.item.vendor.name === "WayFast"
  );
  let isATG = estimate?.lineItems.some(
    (entry) =>
      entry.item.vendor?.name === "ATG Pharma Inc" &&
      entry.item.title !== "Shipping Cost"
  );
  console.log(estimate);
  console.log(orderDetails);

  const PDF = (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle estimate={estimate} isATG={isATG} />
        <BillTo orderDetails={orderDetails} estimate={estimate} isATG={isATG} />
        <InvoiceItemsTable
          orderDetails={orderDetails}
          estimate={estimate}
          isWayFast={isWayFast}
        />
        <Text style={styles.pay}>
          To issue payments please visit the custom order link and follow the
          instructions.
        </Text>
        <Text style={styles.link}>
          <Link
            src={`https://www.customer.onesupply.co/order/review/${orderDetails?.link}`}
          >
            https://www.customer.onesupply.co/order/review/{orderDetails?.link}
          </Link>
        </Text>
      </Page>
      {!isWayFast && (
        <Page size="A4" style={styles.ptc}>
          <Html style={styles.terms}>{orderDetails?.purchaseTerms}</Html>
        </Page>
      )}
      <Page size="A4" style={styles.page}>
        <Payment estimate={estimate} isATG={isATG} />
      </Page>
    </Document>
  );
  return {
    PDF,
  };
};
export default CreatePDF;
