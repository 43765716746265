import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

// COMPONENTS
import NavigationHeader from "../../global/components/headers/navigation";

// DATA
import { SUB_MENUS } from "../data/data_outlet";

const ClientOutlet = () => {
  return (
    <main className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clients | One Supply Connect</title>
      </Helmet>
      <NavigationHeader pageTitle={"Clients"} subMenus={SUB_MENUS} />
      <Outlet />
    </main>
  );
};

export default ClientOutlet;
