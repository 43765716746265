import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// HOOKS
import useClient from "../hooks/useClient";
import { selectClient, selectOrders, selectModal } from "../hooks/clientSlice";

//COMPONENTS
import LoadingPage from "../../global/components/loading/page";
import PageHeader from "../../global/components/headers/pageWButton";
import Table from "../../global/components/tables/simple";
import ModalNewOrder from "../components/modals/modalNewOrder";

//DATA
import { ORDERS_HEADER, CLIENT_ORDERS } from "../data/data_port";

const ClientPort = () => {
  const { loadClientOrders, handleSetModal, loadingOrders } = useClient();
  const ClientOrders = useSelector(selectOrders);
  const ClientDetails = useSelector(selectClient);
  const ModalOn = useSelector(selectModal);
  const { id } = useParams();

  useEffect(() => {
    if (id) loadClientOrders(id);
  }, [id]);

  if (loadingOrders) return <LoadingPage />;
  return (
    <div className="pb-5 px-4 py-4 sm:pb-0 sm:px-6 lg:px-8 ">
      <ModalNewOrder modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <PageHeader
        data={ORDERS_HEADER.data}
        map={ORDERS_HEADER.map}
        condition={ClientDetails?.type !== "BLACKLISTED"}
      />
      <Table
        TableStructure={CLIENT_ORDERS}
        data={ClientOrders}
        label="orders"
      />
    </div>
  );
};

export default ClientPort;
