export default function PaginationPanel({ skipValue, setSkipValue, rows }) {
  return (
    <nav
      className="bg-gray-50 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing{" "}
          <span className="font-medium text-blue-900 ">{1 + skipValue}</span> to{" "}
          <span className="font-medium text-blue-900 ">
            {rows.length < 25 ? rows.length + skipValue : 25 + skipValue}
          </span>{" "}
          Orders
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {skipValue > 24 && (
          <button
            onClick={() => setSkipValue(skipValue - 25)}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </button>
        )}
        {rows.length === 25 && (
          <button
            onClick={() => setSkipValue(skipValue + 25)}
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
          >
            Next
          </button>
        )}
      </div>
    </nav>
  );
}
