import React from "react";
import { FormProvider } from "react-hook-form";

// HOOKS
import useEstimatePages from "../../hooks/useEstimatePages";

// HELPER
import InputDistributer from "../../../helper/formInputDistributer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BuildStepByStep = () => {
  const {
    pageData,
    handleDecrement,
    handleIncrement,
    beethoven,
    params,
    pageState,
    methods,
  } = useEstimatePages();
  return (
    <FormProvider {...methods}>
      <form>
        <div className="border-b border-gray-200 pb-4 mb-2 flex flex-row justify-between items-start mx-6">
          <div>
            <h3 className="text-xl leading-6 font-medium text-gray-900">
              {pageData?.title}{" "}
              <span className=" text-xl font-bold">
                |{" Build #"}
                {pageState?.build}{" "}
                {
                  params.find((entry) => {
                    return entry.machine.value === pageState?.build;
                  })?.product.value
                }
                {" ]"}
              </span>
            </h3>
            <p className="mt-1 max-w-2xl text-base text-gray-500">
              {pageData?.description}
            </p>
          </div>
          <div className="mt-3 flex sm:mt-0 sm:ml-4">
            <button
              type="button"
              onClick={() => handleDecrement()}
              className="inline-flex items-center px-5 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {pageState?.build === 1 && pageState.page === 0
                ? "Production Details"
                : "Back"}
            </button>
            <button
              type="button"
              onClick={() => handleIncrement(pageData)}
              className={`inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                false && "disabled"
              }`}
            >
              {false && (
                <svg
                  className="animate-spin h-5 w-5 text-white self-center mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                  xml="preserve"
                >
                  <path
                    opacity="0.2"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                </svg>
              )}
              {!beethoven[pageState.build + 1] &&
              Object.keys(beethoven[pageState.build]).length ===
                pageState.page + 1
                ? "Review"
                : "Next"}
            </button>
          </div>
        </div>
        <div className={`grid grid-cols-${pageData?.columns}`}>
          {pageData &&
            Object.values(pageData.forms).map((entry) => (
              <Forms props={entry} build={pageState?.build} />
            ))}
        </div>
      </form>
    </FormProvider>
  );
};

export default BuildStepByStep;

const Forms = ({ props, build }) => {
  return (
    <div
      className={classNames(
        `row-start-${props.row} col-start-${props.column}`,
        "max-h-96	 overflow-auto"
      )}
    >
      <InputDistributer props={props} build={build} />
    </div>
  );
};
