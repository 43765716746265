import React from "react";
import { gql, useQuery } from "@apollo/client";
import { SearchIcon } from "@heroicons/react/solid";

const GROUPQUERY = gql`
  query getProductGroups {
    productGroups {
      id
      name
      accessor
      associatedTags {
        name
        accessor
      }
    }
  }
`;

const SearchHeader = ({
  register,
  setValue,
  setSkipValue,
  getValues,
  ProductTags,
  setProductTags,
  getProducts,
}) => {
  const groups = useQuery(GROUPQUERY);

  return (
    <div className="flex items-end gap-4  px-6 pb-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
      <div className="w-2/3">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Search"
            type="search"
            {...register("search")}
            autocomplete="off"
            onChange={(e) => {
              document.getElementById("productGroup").value = "";
              document.getElementById("productTag").value = "";
              setValue("productGroup", null);
              setValue("productTag", null);
              setSkipValue(0);
              e.target.value.length > 2 &&
                getProducts({
                  variables: {
                    skip: 0,
                    first: 5,
                    search: e.target.value,
                  },
                });
            }}
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          Categories
        </label>
        <select
          id="productGroup"
          onChange={(e) => {
            setValue(
              "productGroup",
              groups.data.productGroups[e.target.value]?.accessor
            );
            setProductTags(
              groups.data.productGroups[e.target.value]?.associatedTags
            );
            setValue("search", "");
            setSkipValue(0);
            getProducts({
              variables: {
                skip: 0,
                first: 5,
                group: e.target.value
                  ? [groups.data.productGroups[e.target.value]?.accessor]
                  : [],
                tags: getValues("productTag") ? [getValues("productTag")] : [],
              },
            });
          }}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        >
          <option value="">Select...</option>
          {groups.data &&
            groups.data.productGroups.map((entry, index) => (
              <option value={index}>{entry.name}</option>
            ))}
        </select>
      </div>
      <div>
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          Tags
        </label>
        <select
          id="productTag"
          onChange={(e) => {
            setValue("productTag", e.target.value);
            setValue("search", "");
            setSkipValue(0);
            getProducts({
              variables: {
                skip: 0,
                first: 5,
                group: [getValues("productGroup")],
                tags: e.target.value ? [e.target.value] : [],
              },
            });
          }}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        >
          <option value="">All</option>
          {ProductTags &&
            ProductTags.map((entry) => (
              <option value={entry.accessor}>{entry.name}</option>
            ))}
        </select>
      </div>
    </div>
  );
};

export default SearchHeader;
