import { useNavigate } from "react-router-dom";
export default function NotAvailable() {
  const navigate = useNavigate();
  return (
    <div className="h-full border-2 border-gray-200 border-dashed rounded-lg text-red-600">
      <div className="w-full my-48 flex flex-col justify-center items-center ">
        Page Not Found
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-20 w-20"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Return
        </button>
      </div>
    </div>
  );
}
