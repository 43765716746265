export default function LinkStyle({ data, input }) {
  if (!data) return null;
  const { label, clickEvent, value } = data;
  let event = () => {};
  if (clickEvent) {
    const { hook, func } = clickEvent;
    const { [func]: funcToCall } = hook();
    event = () => funcToCall(input || value);
  }
  return (
    <div>
      <button
        type="button"
        onClick={event}
        className="text-blue-600 items-center hover:text-blue-800"
      >
        {label}
      </button>
    </div>
  );
}
