import { gql } from "@apollo/client";

export const USER = gql`
  query user {
    user {
      id
      salesRepCommissionInvoices {
        number
        dateIssued
        dueDate
        currency {
          symbol
        }
        lineItems {
          commissionDue
          orderSubtotal
        }
      }
    }
  }
`;

export const INVOICE = gql`
  query commissionDueInvoice($id: ID!) {
    commissionDueInvoice(id: $id) {
      currency {
        symbol
      }
      taxRate {
        id
        taxPercentage
        provinceState
      }
      number
      id
      dateIssued
      dueDate
      lineItems {
        id
        commissionDue
        orderSubtotal
        paymentReceived
        ncCostDue
        paymentTransaction {
          amount
          receivedDate
          totalPercentage
          order {
            id
            client {
              name
            }
            selectedEstimate {
              id
              number
            }
          }
        }
      }
    }
  }
`;

export const VENDOR = gql`
  query vendor {
    vendor(id: 1) {
      id
      name
      locations {
        isBilling
        lineOne
        city
        postalCode
      }
      taxIndicator
      website
      commissionInvoices {
        currency {
          symbol
        }
        taxRate {
          id
          taxPercentage
          provinceState
        }
        number
        id
        dateIssued
        dueDate
        lineItems {
          commissionDue
          orderSubtotal
          paymentReceived
          paymentTransaction {
            amount
            receivedDate
            totalPercentage
            order {
              id
              client {
                name
              }
              selectedEstimate {
                id
                number
              }
            }
          }
        }
      }
    }
  }
`;

export const VENDORS = gql`
  query vendors {
    vendors {
      id
      name
      locations {
        isBilling
        lineOne
        city
        postalCode
      }
      taxIndicator
      website
      commissionInvoices {
        currency {
          symbol
        }
        number
        id
        dateIssued
        dueDate
      }
    }
  }
`;

export const LOG_PAYMENT = gql`
  mutation logCommissionInvoiceAsPaymentReceived($id: ID!, $lines: [ID]) {
    logCommissionInvoiceAsPaymentReceived(
      invoiceId: $id
      partialPaymentLineItemIds: $lines
    ) {
      success
    }
  }
`;
