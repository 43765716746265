/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { gql, useQuery } from "@apollo/client";
import {
  selectForItem,
  selectForBuild,
  selectBeethovenProducts,
  setSampleModal,
  selectSampleModal,
  selectEstimate,
  setEstimate,
  setBeethovenProducts,
  setSampleError,
} from "../../../hooks/estimate/estimateSlice";
import { v4 as uuidv4 } from "uuid";

const CONTAINERS = gql`
  query allContainerTypes {
    allContainerTypes {
      name
      frontendIdentifier
      minValue
      maxValue
      unitOfMeasurement {
        name
        units
      }
      inputFields {
        name
      }
    }
  }
`;

const ValidateCompany = () => {
  const dispatch = useDispatch();
  const [units, setUnits] = useState(null);
  const [productId, setId] = useState(null);
  const [addNew, setAddNew] = useState(false);
  const [error, setError] = useState(false);
  const currentProducts = useSelector(selectBeethovenProducts);
  const forItem = useSelector(selectForItem);
  const forBuild = useSelector(selectForBuild);
  const viewSample = useSelector(selectSampleModal);
  const estimate = useSelector(selectEstimate);
  const containers = useQuery(CONTAINERS);

  const { register, reset, handleSubmit } = useForm();

  async function assignProductId(data) {
    if (!productId && (!data.container || !data.volume)) {
      setError(true);
      return;
    }
    let clone = JSON.parse(JSON.stringify(estimate));
    const itemIndex = clone[forBuild].build.findIndex(
      (entry) =>
        entry.title === forItem.title && entry.lineNumber === forItem.lineNumber
    );
    if (productId) clone[forBuild].build[itemIndex].productUuid = productId;
    else {
      let beethovenProds = currentProducts ? [...currentProducts] : [];
      let newID = uuidv4();
      beethovenProds.push({
        containerType: data.container,
        amount: data.volume,
        fillingMachine: {
          type: "automatic",
          number: forBuild + 1,
        },
        productUuid: newID,
      });
      clone[forBuild].build[itemIndex].productUuid = newID;
      dispatch(setBeethovenProducts(beethovenProds));
    }
    dispatch(setEstimate(clone));
    dispatch(setSampleModal(false));
    dispatch(setSampleError(false));
    setAddNew(false);
    setId(null);
    reset();
    document.getElementById("sample-form").reset();
  }
  return (
    <Transition.Root show={viewSample} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => dispatch(setSampleModal(true))}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={handleSubmit(assignProductId)}
              id="sample-form"
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            >
              <div>
                <div className="mt-3 text-center sm:mt-3">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Select An Appropriate Sample
                  </Dialog.Title>
                  <div className="mt-1 pb-1 border-b border-gray-100">
                    <p className="text-xs text-gray-500">
                      A sample description if required for the tray selected.
                    </p>
                  </div>
                  {currentProducts && currentProducts.length > 0 && (
                    <div className="mt-4 w-full flex flex-row gap-4 items-end">
                      <div className="w-1/2">
                        <label className="block text-sm text-left  mb-0.5 font-medium text-gray-700">
                          Select from Existing:
                        </label>
                        <select
                          onChange={(e) => {
                            setId(e.target.value);
                          }}
                          className="focus:ring-blue-500 focus:border-blue-500 font-medium  w-full text-left rounded-md sm:text-sm border py-1.5  px-2 border-gray-300"
                        >
                          <option value="">Select...</option>
                          {currentProducts.map((entry) => (
                            <option key={entry.id} value={entry.productUuid}>
                              {entry.containerType}-{entry.amount}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="w-1/2">
                        <button
                          type="button"
                          onClick={() => {
                            setAddNew(true);
                            setId(null);
                          }}
                          className="inline-flex justify-center gap-1  px-4 py-1.5 hover:text-blue-900 font-medium  text-blue-500 focus:outline-none"
                        >
                          <PlusCircleIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          Add New
                        </button>
                      </div>
                    </div>
                  )}
                  {(!currentProducts ||
                    currentProducts?.length === 0 ||
                    addNew) && (
                    <div className="mt-4 w-full flex flex-row gap-4 items-end">
                      <div className="w-1/2">
                        <label className="block text-sm text-left  mb-0.5 font-medium text-gray-700">
                          Samples:
                        </label>
                        <select
                          {...register("container")}
                          onChange={(e) => {
                            setError(false);
                            const container =
                              containers.data.allContainerTypes.find(
                                (entry) => {
                                  return (
                                    entry.frontendIdentifier === e.target.value
                                  );
                                }
                              );
                            setUnits(container?.unitOfMeasurement.units);
                          }}
                          className="focus:ring-blue-500 focus:border-blue-500 font-medium  w-full text-left rounded-md sm:text-sm border py-1.5  px-2 border-gray-300"
                        >
                          <option value="">Select...</option>
                          {containers.data &&
                            containers.data.allContainerTypes.map((entry) => (
                              <option
                                key={entry.id}
                                value={entry.frontendIdentifier}
                              >
                                {entry.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="w-1/2">
                        <label className="block text-sm text-left mb-0.5 font-medium text-gray-700">
                          Volume:
                        </label>
                        <div className="flex flex-row">
                          <input
                            type="number"
                            step=".01"
                            {...register("volume")}
                            onChange={() => setError(false)}
                            className="shadow-sm sm:text-sm p-1 border border-gray-300 rounded-l-md w-3/5 "
                          />
                          <span className="flex w-2/5 items-center px-3 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-sm">
                            {units}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="mt-4 ">
                    {error && (
                      <p className="text-sm text-red-600">
                        sample selection is required!
                      </p>
                    )}
                    <button
                      type="submit"
                      className="inline-flex justify-center  rounded-md border-2 border-transparent shadow-sm px-4 py-1.5 hover:text-gray-900  hover:border-gray-900  bg-green-500 hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ValidateCompany;
