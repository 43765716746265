/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/outline";
import { useQuery, useMutation, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setPopOver } from "../../hooks/saleSlice";
import Card from "../../../components/Card/simple";
import PopeOver from "../../../components/PopeOver/item";
import { useCookies } from "react-cookie";
import { useParams, useNavigate } from "react-router-dom";
import DueModal from "../../../components/modals/orders/confirmDue";

const CREATE_TEMPLATE = gql`
  mutation createTemplate(
    $containers: [TemplateContainerTypeInput]!
    $id: ID!
    $estimateName: String!
  ) {
    createEstimateTemplate(
      containerTypes: $containers
      estimateId: $id
      name: $estimateName
    ) {
      templateId
    }
  }
`;
const GET_ESTIMATE = gql`
  query estimate($id: ID) {
    estimate(id: $id) {
      commissionQuotaReached
      createdAt
      currencyConversionRate
      totalCommission
      affiliateCommission
      affiliate {
        firstName
        lastName
      }
      order {
        status
        dueDate
        paymentTransactions {
          receivedDate
        }
      }
      lead {
        id
        companyName
        crmId
        customerCrmId
        contactName
        email
        status
        phoneNumber
        productionGoal
        funding
        fundingCurrencySymbol
        createdAt
        customer {
          id
        }
        client {
          id
          salesTax {
            id
            provinceStateName
            taxPercentage
            provinceState
          }
        }
      }
      id
      number
      dataJson
      productsJson
      template
      productsJson
      lineItems {
        id
        lineNumber
        item {
          commissionPercentage
          title
          skuNumber
          productGroups {
            name
          }
        }
        productUuid
        totalProfit
        totalPrice
        totalCost
        totalNetCost
        totalCommission
        pricePerUnit
        itemQuantity
        discountPerUnit
        appliedPromotion {
          name
        }
      }
      total
      createdAt
      appliedPromotions {
        name
      }
    }
  }
`;

const DELETE_ESTIMATE = gql`
  mutation deleteEs($id: String!) {
    deleteEstimate(id: $id) {
      estimateNumber
    }
  }
`;
const Headers = [
  "Component",
  "SKU",
  "Price per Unit",
  "Quantity",
  "Discount",
  "Total Price",
];

const UniqueEstimates = () => {
  const [profit, setProfit] = useState(0);
  const dispatch = useDispatch();
  const { register, getValues } = useForm();
  let { id } = useParams();
  let navigate = useNavigate();
  const currentDate = new Date();
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  let estimateId = window.location.href.split("/").pop().split("?")[0];
  let orderId = window.location.href.split("/")[4];
  const [estimate, setEstimate] = useState(null);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [cookies, setCookie] = useCookies(["user"]);
  const [productArray, setProductArray] = useState([]);
  const user = cookies.user?.user?.role?.name;
  const estimateQuery = useQuery(GET_ESTIMATE, {
    variables: {
      id: parseInt(estimateId),
    },
    onCompleted: (data) => {
      setEstimate(data.estimate);
      const products = JSON.parse(data.estimate.productsJson);
      const sum = data.estimate.lineItems?.reduce((accumulator, object) => {
        return accumulator + parseFloat(object.totalProfit);
      }, 0);
      setProfit(sum);

      if (products && productArray.length === 0) {
        let productsObject = {};
        let productsArray = [];
        for (const product of Object.values(products)) {
          productsObject[product.container_type]
            ? (productsObject[product.container_type] += 1)
            : (productsObject[product.container_type] = 1);
        }
        for (const [pKey, pValue] of Object.entries(productsObject)) {
          productsArray.push({
            frontendIdentifier: pKey,
            qty: pValue,
          });
        }
        setProductArray(productsArray);
      }
    },
  });
  const [deleteEstimate] = useMutation(DELETE_ESTIMATE, {
    onCompleted: (d) =>
      (window.location = `/orders/${orderId}${window.location.search}`),
    onError: (e) => alert(e),
  });
  const [createTemplateMutation] = useMutation(CREATE_TEMPLATE, {
    onCompleted: (d) => window.location.reload(),
    onError: (e) => alert(e),
  });

  async function createTemplate() {
    const templateName = getValues().template;
    if (!templateName) {
      setError(true);
    } else {
      setError(false);
      let variables = {
        containers: productArray,
        id: estimate.id,
        estimateName: templateName,
      };
      createTemplateMutation({
        variables: variables,
      });
    }
  }
  if (estimateQuery.loading && !estimate)
    return (
      <svg
        className="animate-spin h-20 w-20 text-blue-600 self-center"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 40 40"
        enableBackground="new 0 0 40 40"
        xml="preserve"
      >
        <path
          opacity="0.2"
          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
        />
        <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
      </svg>
    );
  return (
    <div>
      <PopeOver />
      <DueModal alert={alert} setAlert={setAlert} id={id} />
      <Card>
        <div className="py-5 flex flex-row items-start justify-between">
          <div>
            <h2
              id="applicant-information-title"
              className="text-lg leading-6 font-medium text-gray-900"
            >
              Estimate Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Estimate details and Overview.
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <a
              type="button"
              href={`/orders/${orderId}${window.location.search}`}
              className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

              <span>Back</span>
            </a>
            {(estimate?.order?.status === "new" ||
              estimate?.order?.status === "needs_revision") && (
              <button
                type="button"
                onClick={() => {
                  setCookie("lead", estimate?.lead, {
                    path: "/",
                    secure: true,
                    sameSite: true,
                  });
                  let due = new Date(estimate?.order?.dueDate);
                  const utc1 = Date.UTC(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate()
                  );
                  const utc2 = Date.UTC(
                    due.getFullYear(),
                    due.getMonth(),
                    due.getDate()
                  );

                  const difference = Math.floor((utc2 - utc1) / _MS_PER_DAY);
                  // if (estimate?.order?.dueDate && difference < 30) {
                  //   setAlert(true);
                  // } else
                  navigate(`/estimate/${id}`);
                }}
                className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <span>Edit Estimate</span>
              </button>
            )}
            {(estimate?.order?.status === "new" ||
              estimate?.order?.status === "needs_revision") && (
              <button
                type="button"
                onClick={() =>
                  deleteEstimate({
                    variables: {
                      id: estimate.id,
                    },
                  })
                }
                className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-red-600 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <span>Delete Estimate</span>
              </button>
            )}
          </div>
        </div>
        {(estimate?.order?.status !== "new" ||
          estimate?.order?.status !== "needs_revision") && (
          <p className="text-lg text-red-700">
            To alter this estimate, the order needs to be in revision
          </p>
        )}
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex flex-col md:grid md:grid-cols-3 gap-12">
            <div className="flex flex-col">
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Organization:
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full text-blue-700 hover:text-gray-900">
                  {estimate?.lead?.client?.id ? (
                    <a href={`/clients/${estimate?.lead?.client?.id}`}>
                      {estimate?.lead?.companyName}
                    </a>
                  ) : (
                    <p className="mt-1 text-base font-bold text-gray-900">
                      {estimate?.lead?.companyName}
                    </p>
                  )}
                </span>
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Estimate Number:
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full ">
                  {estimate?.number}
                </span>
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Created On
                <span className="px-4 py-2 inline-flex leading-5 font-bold rounded-full  ">
                  {estimate?.createdAt.split("T")[0]}
                </span>
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Estimate Total:
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full text-green-800 ">
                  ${" "}
                  {parseFloat(estimate?.total)
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                </span>
              </p>
              {estimate?.affiliate && (
                <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                  Affiliate:{" "}
                  <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                    {estimate.affiliate.firstName} {estimate.affiliate.lastName}
                  </span>
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Conversion Rate
                <span className="px-4 py-2 inline-flex leading-5 font-bold rounded-full  ">
                  {parseFloat(estimate?.currencyConversionRate).toFixed(2)}
                </span>
              </p>
              {estimate?.commissionQuotaReached && (
                <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                  Commission Quota
                  <span className="px-4 py-2 inline-flex leading-5 font-bold rounded-full  ">
                    {(
                      parseFloat(estimate?.commissionQuotaReached) * 100
                    ).toFixed(2)}{" "}
                    %
                  </span>
                </p>
              )}
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Total Commission
                <span className="px-4 py-2 inline-flex leading-5 font-bold rounded-full  ">
                  ${" "}
                  {parseFloat(estimate?.totalCommission)
                    ?.toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                </span>
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Payment Received
                <span className="px-4 py-2 inline-flex leading-5 font-bold rounded-full  ">
                  {estimate?.order?.paymentTransactions[0]?.receivedDate?.split(
                    "T"
                  )[0] || (
                    <span className="text-red-500 font-light">Pending</span>
                  )}
                </span>
              </p>
              {(user === "Vendor Admin" || user === "Executive") && (
                <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                  AIR Income
                  <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                    <span className="font-medium pl-3">
                      ${" "}
                      {parseFloat(profit)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </span>
                </p>
              )}
              {estimate?.affiliate && (
                <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                  Affiliate Commission
                  <span className="px-4 py-2 inline-flex leading-5 font-bold rounded-full  ">
                    ${" "}
                    {parseFloat(estimate?.affiliateCommission)
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  </span>
                </p>
              )}
              {estimate?.appliedPromotions.length > 0 && (
                <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                  Promotion
                  <span className="px-4 py-2 inline-flex leading-5 font-bold rounded-full  ">
                    {estimate?.appliedPromotions[0].name}
                  </span>
                </p>
              )}
            </div>
            <div className="flex flex-col">
              <dt className="text-sm font-medium text-gray-500">Template</dt>
              {error && (
                <p className="text-red-600 mb-1 text-left  animate-pulse ">
                  A template name is required!
                </p>
              )}
              {estimate?.template ? (
                <div className="flex flex-row items-end">
                  <CheckIcon
                    className="-ml-1 mr-2 h-5 w-5 text-green-500"
                    aria-hidden="true"
                  />
                  <p>{estimate?.template}</p>
                </div>
              ) : (
                <>
                  <input
                    type="text"
                    {...register("template", {
                      required: true,
                    })}
                    placeholder="Name..."
                    className="bloc w-1/2 shadow-sm sm:text-sm focus:ring-blue-500 mt-2  focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                  />
                  <div>
                    <button
                      type="submit"
                      onClick={() => createTemplate()}
                      className="inline-flex items-center justify-center px-3 py-1.5 mt-2 shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                    >
                      <span>Add as Template</span>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
      <Card>
        <h2 className="text-lg leading-6 font-medium text-gray-900 py-6 ">
          ESTIMATE BREAKDOWN:
        </h2>
        <table className="min-w-full border-2 border-gray-200 divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr>
              {Headers.map((header, index) => (
                <th
                  key={index}
                  className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider"
                >
                  {header}
                </th>
              ))}
              {(user === "Vendor Admin" || user === "Executive") && (
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider flex flex-row items-end justify-start">
                  Total Net Cost
                  <div className="relative z-10 flex flex-col justify-center items-center group">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div className="absolute top-10 w-full z-60 flex flex-col items-center hidden  mb-6 group-hover:flex">
                      <div className="w-3 h-3 -mt-3 transform rotate-45 bg-gray-600" />
                      <span className="relative  -mt-2  p-2 text-xs leading-none text-white bg-gray-600 shadow-lg rounded-md">
                        COST + NON-COMMISSION MARK UP
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {(user === "Vendor Admin" || user === "Executive") && (
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">
                  Commission Percentage
                </th>
              )}
              {(user === "Vendor Admin" || user === "Executive") && (
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider flex flex-row items-end justify-start">
                  Total Commission
                  <div className="relative z-10 flex flex-col justify-center items-center group">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <div className="absolute top-10 w-full z-60 flex flex-col items-center hidden  mb-6 group-hover:flex">
                      <div className="w-3 h-3 -mt-3 transform rotate-45 bg-gray-600" />
                      <span className="relative  -mt-2  p-2 text-xs leading-none text-white bg-gray-600 shadow-lg rounded-md">
                        PRICE * COMMISSION * QUOTA
                      </span>
                    </div>
                  </div>
                </th>
              )}
              {(user === "Vendor Admin" || user === "Executive") && (
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">
                  Total Due to AIR
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {estimate?.lineItems &&
              estimate.lineItems.length !== 0 &&
              estimate.lineItems.map((entry, i) => {
                const isTray = entry.item.productGroups[0]?.name === "Tray";
                const productID = entry.productUuid;
                const parsedProducts = JSON.parse(estimate?.productsJson);
                let product = parsedProducts && parsedProducts[productID];
                return (
                  <Fragment>
                    <tr key={i}>
                      <td className="flex flex-row items-center">
                        <span
                          className={`pl-6 ${
                            entry.lineNumber !== 1 ? "pl-6" : "font-semibold"
                          } py-4  text-gray-600`}
                        >
                          {entry.item.title}{" "}
                          {isTray &&
                            product &&
                            ` [${product?.container_type}-${product?.amount}${
                              product?.container_type !== "cartridges"
                                ? "ml"
                                : "g"
                            }]`}{" "}
                          {entry.appliedPromotion && (
                            <span className="block text-green-600">
                              PROMO: {entry.appliedPromotion.name}
                            </span>
                          )}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() =>
                            dispatch(
                              setPopOver({ view: true, item: [entry.item] })
                            )
                          }
                          className="text-blue-600 hover:text-blue-900"
                        >
                          {entry.item.skuNumber}
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span>$</span>
                        {parseFloat(entry.pricePerUnit)
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {entry.itemQuantity}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {entry.discountPerUnit &&
                          `$ ${(
                            parseFloat(entry.discountPerUnit) *
                            parseFloat(entry.pricePerUnit)
                          )
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-gray-600 ">
                        <span>$</span>
                        {parseFloat(entry.totalPrice)
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      {(user === "Vendor Admin" || user === "Executive") && (
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 ">
                          <span>$</span>
                          {parseFloat(entry.totalNetCost)
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      )}
                      {(user === "Vendor Admin" || user === "Executive") && (
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 ">
                          {parseFloat(entry.item?.commissionPercentage) * 100}%
                        </td>
                      )}
                      {(user === "Vendor Admin" || user === "Executive") && (
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 h-full">
                          <span>
                            <span>$</span>
                            {parseFloat(entry.totalCommission)
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </td>
                      )}
                      {(user === "Vendor Admin" || user === "Executive") && (
                        <td className="px-6 py-4 whitespace-nowrap text-gray-600 ">
                          <span>$</span>
                          {parseFloat(entry.totalProfit)
                            ?.toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      )}
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
export default UniqueEstimates;
