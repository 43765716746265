import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

//HOOKS
import useClient from "../../hooks/useClient";
import { selectSalesTax } from "../../hooks/clientSlice";

//COMPONENTS
import LoadingPage from "../../../global/components/loading/page";
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Select from "../../../global/components/inputs/select/simple.jsx";

const AddClientModal = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { loadingSalesTax, updatingClient, handleSetTax } = useClient();
  const SalesTax = useSelector(selectSalesTax);

  if (loadingSalesTax) return <LoadingPage />;
  if (!SalesTax) return null;

  const options = SalesTax.map((s) => ({
    label: `${s.provinceStateName} - ${parseFloat(s.taxPercentage) * 100}%`,
    value: s.provinceState,
  }));

  return (
    <ModalFrame
      open={modalOn === "set-tax"}
      close={setModal}
      title={"Set Sales Tax"}
    >
      <form
        onSubmit={handleSubmit(handleSetTax)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the dropdown to determine the Sales Tax for this client.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <Select
            label="Sales Tax"
            id="tax"
            methods={methods}
            registerValue="tax"
            registerOptions={{ required: true }}
            options={options}
          />
          <ModalCTA cancel={setModal} submitting={updatingClient} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddClientModal;
