/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useLazyQuery, useMutation, gql } from "@apollo/client";
import { ExclamationIcon, SearchCircleIcon } from "@heroicons/react/outline";
import { useCookies } from "react-cookie";

const USER = gql`
  query user {
    user {
      vendor {
        id
      }
    }
  }
`;
const PROMOS = gql`
  query vendor($id: ID!) {
    vendor(id: $id) {
      promotions {
        id
        code
        name
        startDate
        allowedUsageQuantityPerClient
        endDate
        isActive
        itemDependencies {
          minPrice
          item {
            skuNumber
            title
          }
        }
        items {
          discountPercentage
          maxQuantity
          item {
            skuNumber
            title
          }
        }
      }
    }
  }
`;

const ALL_PROMOS = gql`
  query vendors {
    vendors {
      promotions {
        vendor {
          name
        }
        id
        code
        name
        startDate
        allowedUsageQuantityPerClient
        endDate
        isActive
        itemDependencies {
          minPrice
          item {
            skuNumber
            title
          }
        }
        items {
          discountPercentage
          maxQuantity
          item {
            skuNumber
            title
          }
        }
      }
    }
  }
`;

const TOGGLE_PROMO = gql`
  mutation toggleVendorPromotionStatus($id: ID!) {
    toggleVendorPromotionStatus(promotionId: $id) {
      isActive
    }
  }
`;
const HEADERS = [
  "CODE",
  "TITLE",
  "ACTIVE STATUS",
  "USAGE PER",
  "START DATE",
  "END DATE",
  "VIEW",
];

export default function PromoView({ setListView }) {
  const userResponse = useQuery(USER);
  const [cookies] = useCookies();
  const [data, setData] = useState([]);
  const [getPromos, promoResponse] = useLazyQuery(
    cookies.user?.user?.role?.name === "Executive" ? ALL_PROMOS : PROMOS,
    {
      fetchPolicy: "cache-and-network",
      onCompleted: (d) => {
        console.log(d);
        let promos = [];
        if (d.vendor) promos = promos.concat(d.vendor.promotions);
        if (d.vendors)
          promos = promos.concat(
            d.vendors.reduce((acc, curr) => acc.concat(curr.promotions), [])
          );
        setData(promos);
      },
    }
  );
  const { loading, refetch } = promoResponse;
  const [promo, setPromo] = useState(null);
  const [open, setOpen] = useState(false);
  const [togglePromo] = useMutation(TOGGLE_PROMO, {
    onCompleted: () => {
      refetch();
      setOpen(false);
    },
    onError: (e) => alert(e),
  });
  useEffect(() => {
    let id = userResponse.data?.user?.vendor?.id;
    if (id)
      getPromos({
        variables: { id: id },
      });
    else getPromos();
  }, [userResponse.data]);
  return (
    <div className="h-full flex">
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${
                      promo?.isActive ? "bg-red-100" : "bg-green-100"
                    } sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    <ExclamationIcon
                      className={`h-6 w-6 ${
                        promo?.isActive ? "text-red-600" : "text-green-500"
                      }`}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {promo?.isActive ? "Deactivate" : "Activate"} Promotion
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to{" "}
                        {promo?.isActive ? "deactivate" : "activate"} the
                        selected promotion
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  ${
                      promo?.isActive
                        ? "bg-red-600 hover:bg-red-700 "
                        : "bg-green-600 hover:bg-green-700 "
                    } text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm`}
                    onClick={() =>
                      togglePromo({ variables: { id: promo?.id } })
                    }
                  >
                    {promo?.isActive ? "Deactivate" : "Activate"} Promotion
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex-1 flex flex-col overflow-y-auto">
        <div className="grid grid-cols-5">
          <main className="col-span-3 flex-1 overflow-y-auto">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Vendor Promotions
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the promotion campaigns initiated.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    type="button"
                    onClick={() => setListView(false)}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Add New
                  </button>
                </div>
              </div>
              <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      {cookies.user?.user?.role?.name === "Executive" && (
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                          Vendor
                        </th>
                      )}
                      {HEADERS.map((entry, index) => {
                        return (
                          <th
                            key={index}
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            {entry}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <svg
                          className="animate-spin h-24 w-24 text-blue-200 self-center mr-2 text-center"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 40 40"
                          enableBackground="new 0 0 40 40"
                          xml="preserve"
                        >
                          <path
                            opacity="0.2"
                            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                          />
                          <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                        </svg>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data.map((promo, pIndex) => (
                        <tr key={pIndex}>
                          {cookies.user?.user?.role?.name === "Executive" && (
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                              {promo.vendor?.name}
                            </td>
                          )}
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {promo.code}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {promo.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <button
                              onClick={() => {
                                setOpen(true);
                                setPromo(promo);
                              }}
                              className={`inline-flex  items-center px-3 py-1 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                                promo.isActive
                                  ? "bg-green-600 hover:bg-green-700"
                                  : "bg-red-600 hover:bg-red-700"
                              }`}
                            >
                              {promo.isActive ? "Active" : "Inactive"}
                            </button>
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {promo.allowedUsageQuantityPerClient}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {promo.startDate?.split("T")[0]}
                          </td>
                          <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            {promo.endDate?.split("T")[0]}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
                            <button
                              onClick={() => setPromo(promo)}
                              type="button"
                              className="text-blue-600 hover:text-blue-900"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </main>

          {/* Details sidebar */}
          <aside className="hidden col-span-2 bg-white p-8 border-l border-gray-200 overflow-y-auto lg:block">
            {promo ? (
              <div className="pb-16 space-y-6">
                <div>
                  <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg overflow-hidden">
                    <img src={promo.source} alt="" className="object-cover" />
                  </div>
                  <div className="mt-4 flex items-start justify-between">
                    <div>
                      <h2 className="text-lg font-medium text-gray-900">
                        <span className="sr-only">Details for </span>
                        {promo.name}
                      </h2>
                      <p className="text-sm font-medium text-gray-500">
                        {promo.startDate?.split("T")[0]} -{" "}
                        {promo.endDate?.split("T")[0]}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Items</h3>
                  <div className="mt-2 flex items-center justify-between">
                    <p className="text-sm text-gray-500 italic">
                      The list outlines the items the promotion is applied to
                    </p>
                  </div>
                  <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              SKU
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Max QTY
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Discount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {promo.items?.map((item, index) => (
                            <tr key={index}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {item.item?.skuNumber}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.item?.title}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.maxQuantity}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {parseFloat(item.discountPercentage) * 100 +
                                  "%"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </dl>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Dependant On</h3>
                  <div className="mt-2 flex items-center justify-between">
                    <p className="text-sm text-gray-500 italic">
                      the list outlines the items the promotion is dependant on
                      to be applied{" "}
                    </p>
                  </div>
                  <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              SKU
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Title
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Max Price
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {promo.itemDependencies?.map((item, index) => (
                            <tr key={index}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {item.item?.skuNumber}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {item.item?.title}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                CAD $
                                {parseFloat(item.minPrice)
                                  .toFixed(2)
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </dl>
                </div>
              </div>
            ) : (
              <div className="h-full border-2 border-gray-200 border-dashed rounded-lg">
                <div className="w-full my-48 flex flex-col justify-center items-center ">
                  <SearchCircleIcon
                    className="h-8 w-8 text-gray-500"
                    aria-hidden="true"
                  />
                  <h2 className="text-lg text-gray-500">
                    Select a Promotion to explore details.
                  </h2>
                </div>
              </div>
            )}
          </aside>
        </div>
      </div>
    </div>
  );
}
