/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import SampleModal from "../.././../components/modals/estimate/selectStoreSample";
//API
import { useSelector, useDispatch } from "react-redux";
import useSaleItems from "../../../hooks/sales/useSaleItems";
import {
  selectClient,
  selectCurrency,
  selectContact,
  selectLineItems,
  selectPrice,
  setLineItems,
  setPrice,
  setCart,
  selectTaxPercentage,
  selectCart,
} from "../../../hooks/sales/saleSlice";

import {
  setSampleModal,
  setForItem,
} from "../../../hooks/estimate/estimateSlice";
import ResolveUndefined from "../../../global/utils/manipulate/undefined";

//ASSETS
import { TrashIcon } from "@heroicons/react/outline";

const SpareView = () => {
  const client = useSelector(selectClient);
  const currency = useSelector(selectCurrency);
  const lineItems = useSelector(selectLineItems);
  const selectedContact = useSelector(selectContact);
  const totalPrice = useSelector(selectPrice);
  const cart = useSelector(selectCart);
  const taxPercentage = useSelector(selectTaxPercentage);
  const dispatch = useDispatch();
  const { handleIssue, isSubmitting } = useSaleItems();
  const [shippingError, setShippingError] = useState(false);
  const [shippingId, setShippingId] = useState(null);
  const [overrideValue, setOverRideValue] = useState(null);
  const { unregister, setValue, getValues, register } = useForm();
  const [newAddress, setNewAddress] = useState(false);

  useEffect(() => {
    let price = totalPrice.price;
    let tax = price * taxPercentage;
    dispatch(
      setPrice({
        price: price,
        tax: tax,
        total: price + tax,
      })
    );
  }, [taxPercentage]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spare | One Supply Connect</title>
      </Helmet>
      <SampleModal />
      <div
        className={`w-full h-full grid grid-cols-3 ${
          isSubmitting && "opacity-40"
        }`}
      >
        <div className="bg-gray-50 px-8 h-full col-span-2">
          <section
            aria-labelledby="summary-heading"
            className="bg-gray-50 pt-6 pb-10 px-4 sm:px-6 lg:px-0 lg:pb-16 lg:bg-transparent lg:row-start-1 lg:col-start-2"
          >
            <div className="max-w-lg mx-auto lg:max-w-none">
              <h2
                id="summary-heading"
                className="text-lg font-bold text-gray-900"
              >
                Order summary
              </h2>
              <ul
                role="list"
                className="text-sm font-medium text-gray-900 divide-y divide-gray-200"
              >
                {lineItems.map((product, index) => (
                  <li
                    key={product.id}
                    className="grid grid-cols-8 py-4 space-x-4"
                  >
                    <div className="col-span-2 flex-auto space-y-1">
                      <h3>
                        <span className="font-bold">{product.lineNumber}.</span>{" "}
                        {product.title}
                      </h3>
                      <p className="text-gray-500">{product.skuNumber}</p>
                    </div>
                    <div className="col-span-2 grid grid-cols-2 text-center flex-auto space-y-1">
                      <input
                        id="name"
                        type="number"
                        step="1"
                        defaultValue={product.quantity}
                        onChange={(e) => {
                          let value = e.target.value;
                          if (value[0] === "-") {
                            e.target.value = 0;
                            return;
                          }
                          let newItemPrice =
                            parseFloat(value) *
                              parseFloat(product.pricePerUnit) || 0;
                          let clone = JSON.parse(JSON.stringify(lineItems));

                          let currentCart = Object.assign({}, cart);
                          setValue(lineItems[index].skuNumber, value);
                          clone[index].quantity = value;
                          clone[index].total = newItemPrice;
                          currentCart[lineItems[index].skuNumber] = value;
                          let newPrice =
                            totalPrice.price -
                            parseFloat(product.total) +
                            newItemPrice;
                          let tax = taxPercentage * newPrice;
                          dispatch(setLineItems(clone));
                          dispatch(
                            setPrice({
                              price: newPrice,
                              tax: tax,
                              total: newPrice + tax,
                            })
                          );
                          dispatch(setCart(currentCart));
                        }}
                        className="col-span-1 block shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                      />{" "}
                      <p className="col-span-1"> QTY</p>
                      <p className="text-gray-500 col-span-1">
                        $
                        {parseFloat(product.pricePerUnit)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                        each
                      </p>
                    </div>
                    <div className="col-span-2 ">
                      {product.tray && !product.productUuid && (
                        <button
                          type="button"
                          onClick={() => {
                            dispatch(setSampleModal(true));
                            dispatch(setForItem(product));
                          }}
                          className="ml-2 inline-flex justify-center rounded-md border-2 border-transparent shadow-sm px-2 py-1 hover:text-gray-900  hover:border-gray-900  bg-red-500 hover:bg-transparent text-sm font-medium  text-white focus:outline-none "
                        >
                          Add Sample
                        </button>
                      )}
                    </div>
                    <p className="col-span-1 text-right text-base font-medium">
                      $
                      {parseFloat(product.total)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                    <button
                      onClick={() => {
                        let clone = [...lineItems];
                        let currentCart = Object.assign({}, cart);
                        unregister(lineItems[index].skuNumber);
                        delete currentCart[lineItems[index].skuNumber];
                        clone.splice(index, 1);
                        let newPrice =
                          totalPrice.price - parseFloat(product.total);
                        let tax = taxPercentage * newPrice;
                        dispatch(setLineItems(clone));
                        dispatch(
                          setPrice({
                            price: newPrice,
                            tax: tax,
                            total: newPrice + tax,
                          })
                        );
                        dispatch(setCart(currentCart));
                      }}
                      className="flex flex-row gap-2 justify-end items-start text-red-600 hover:text-red-700"
                    >
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                      delete
                    </button>
                  </li>
                ))}
              </ul>
              <dl className="text-sm font-medium text-gray-900 space-y-6 border-t border-gray-200 pt-6 block">
                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Subtotal</dt>
                  <dd>
                    {currency} ${" "}
                    {totalPrice.price
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">Shipping</dt>
                  <dd>
                    <input
                      id="name"
                      onChange={(e) => {
                        if (e.target.value) {
                          setOverRideValue(e.target.value);
                          let price = totalPrice.price;
                          let shipping = parseFloat(e.target.value);
                          let tax = (price + shipping) * taxPercentage;
                          dispatch(
                            setPrice({
                              price: price,
                              tax: tax,
                              total: price + shipping + tax,
                            })
                          );
                        } else {
                          setOverRideValue(null);
                          let price = totalPrice.price;
                          let tax = price * taxPercentage;
                          dispatch(
                            setPrice({
                              price: price,
                              tax: tax,
                              total: price + tax,
                            })
                          );
                        }
                      }}
                      type="number"
                      className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                        overrideValue ? "border-gray-300" : "border-red-500"
                      } rounded-md py-1 px-2`}
                    />
                  </dd>
                </div>

                <div className="flex items-center justify-between">
                  <dt className="text-gray-600">
                    Taxes {parseFloat(taxPercentage) * 100}%
                  </dt>
                  <dd>
                    {currency} ${" "}
                    {totalPrice.tax
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">
                    {currency} ${" "}
                    {totalPrice.total
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </dd>
                </div>
              </dl>
            </div>
          </section>
        </div>
        <div className=" pt-8 pb-10 px-4 sm:px-6 lg:px-8 lg:pb-16 ">
          <form className="sticky top-6">
            <div className="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
              <div>
                <h3
                  id="contact-info-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Contact information
                </h3>
                <p className="text-gray-500 text-sm py-1">
                  Confirm Client Information for estimate creation.
                </p>

                <div className="mt-2">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      type="email"
                      id="email-address"
                      name="email-address"
                      autoComplete="email"
                      defaultValue={selectedContact.email}
                      className="px-3 block w-full border border-gray-300  py-1 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <h3
                  id="shipping-heading"
                  className="text-lg font-medium text-gray-900"
                >
                  Shipping address
                </h3>
                <p className="text-gray-500 text-sm py-1">
                  Select a shipping address form the client's location.
                </p>
                {!newAddress && (
                  <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Address
                      </label>
                      <div className="mt-1">
                        <select
                          id="address"
                          name="address"
                          {...register("address")}
                          onChange={(e) => {
                            setShippingId(e.target.value.split("-")[0]);
                            setShippingError(false);
                          }}
                          className="focus:ring-blue-500 focus:border-blue-500 w-full font-medium text-left rounded-md sm:text-sm border px-3 py-1 border-gray-300"
                        >
                          <option value="">Select...</option>
                          {client &&
                            client?.locations.map((entry) => {
                              if (!entry.isBilling || entry.isPrimary)
                                return (
                                  <option
                                    value={`${entry.id}-${entry.countryCode}`}
                                  >
                                    {ResolveUndefined(entry.lineOne)},{" "}
                                    {entry.state}, {entry.postalCode},{" "}
                                    {entry.countryCode}
                                  </option>
                                );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-col xl:flex-row gap-2">
                  {newAddress ? (
                    <button
                      type="button"
                      onClick={() => {
                        setNewAddress(false);
                        setValue("crm", null);
                        setValue("name", null);
                        setValue("email", null);
                        setValue("phone", null);
                      }}
                      className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Cancel Creation
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        setNewAddress(true);
                        setValue("name", null);
                        setValue("email", null);
                        setValue("phone", null);
                        setShippingError(false);
                      }}
                      className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Add New Address
                    </button>
                  )}
                </div>
                {newAddress && (
                  <div className="pt-2 grid grid-cols-2 gap-x-4 gap-y-1">
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Line One:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("lineOne")}
                          type="text"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        City:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("city")}
                          type="text"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Postal Code:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("postalCode")}
                          type="text"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        State Code:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("state")}
                          type="text"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Country Code:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("country")}
                          type="text"
                          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {shippingError && !getValues("lineOne") && !shippingId && (
                <p className="text-sm text-red-500 mt-2">
                  Address Selection is required for shipping
                </p>
              )}
              <div className="mt-10 flex justify-end pt-6 border-t border-gray-200">
                <button
                  type="button"
                  disabled={isSubmitting}
                  onClick={() => {
                    if (getValues("lineOne") || shippingId) {
                      handleIssue(
                        shippingId,
                        newAddress,
                        getValues(),
                        overrideValue,
                        totalPrice.total
                      );
                    } else {
                      setShippingError(true);
                    }
                  }}
                  className="bg-green-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-sm font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500 flex flex-row gap-2"
                >
                  {isSubmitting && (
                    <svg
                      className="animate-spin h-5 w-5 text-white self-center mr-2"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40 40"
                      enableBackground="new 0 0 40 40"
                      xml="preserve"
                    >
                      <path
                        opacity="0.2"
                        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                      />
                      <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                    </svg>
                  )}
                  Issue Estimate
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SpareView;
