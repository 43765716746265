/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

const NewGroup = ({
  openImages,
  setOpenImages,
  addImage,
  methods,
  activeProduct,
  loading,
}) => {
  const [fileName, setFileName] = useState(null);
  const { setValue, register } = methods;

  useEffect(() => {
    if (activeProduct) {
      setValue("itemID", activeProduct?.id);
    }
  }, [activeProduct]);

  return (
    <Transition.Root show={openImages} as={Fragment}>
      <Dialog
        as="div"
        static
        className={`${
          loading && "opacity-80"
        } fixed z-50 inset-0 overflow-y-auto`}
        open={openImages}
        onClose={setOpenImages}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:mt-8 sm:align-middle sm:max-w-3xl sm:w-full sm:pt-6">
              <form onSubmit={addImage}>
                <div className="px-4 sm:px-6">
                  <div className="mt-1 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-xl mb-2 leading-6 font-bold pb-2 border-b  text-gray-900 grid grid-cols-3"
                    >
                      <span />
                      Product Images
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mx-8 grid grid-cols-2 gap-4 ">
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-2 text-gray-800"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("imageTitle")}
                        type="text"
                        className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-2 text-gray-800"
                    >
                      description
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("imageDescription")}
                        type="text"
                        className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="my-2 flex flex-row  items-center gap-4 px-3 py-3 bg-white rounded-md shadow-md tracking-wide  border border-blue cursor-pointer hover:bg-blue-600 hover:text-white text-blue-600 ease-linear transition-all duration-150 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                        />
                      </svg>

                      <span className="text-sm leading-normal">
                        {fileName ? fileName : "Upload Image"}
                      </span>
                      <input
                        id="fileUpload"
                        name="fileUpload"
                        type="file"
                        onChange={(e) => setFileName(e.target.files["0"]?.name)}
                        className="hidden"
                      />
                    </label>
                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-700 text-base font-medium text-white hover:bg-green-800 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenImages(false)}
                  >
                    Return
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewGroup;
