/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, gql } from "@apollo/client";

//COOKIES
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

import {
  selectTotal,
  selectConversion,
  selectProvince,
  setConversion,
  setActiveMenuIndex,
  selectActiveMenuIndex,
} from "../hooks/estimateSlice";

import PopeOver from "../../components/PopeOver/item";
import LineItems from "../components/estimateLineItems";
import Store from "../components/estimateStore";
import BuildStepByStep from "../components/estimateStepByStep";
import Templates from "../components/estimateTemplates";
import SelectTaxModal from "../../components/modals/estimate/selectTax";

const activeState = "border-blue-500 text-blue-600";
const inactiveState =
  "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";

let menus = ["Templates", "Build Step-By-Step", "Store"];

export const FROM_USD = gql`
  query fromUSD {
    currency(symbol: "USD") {
      conversionRateFromCad
    }
  }
`;

const EstimateView = () => {
  const conversion = useSelector(selectConversion);
  const total = useSelector(selectTotal);
  const activeMenuIndex = useSelector(selectActiveMenuIndex);
  const dispatch = useDispatch();
  const province = useSelector(selectProvince);
  const [cookies] = useCookies(["user"]);
  const [open, setOpen] = useState(false);
  const fromUSD = useQuery(FROM_USD);
  let { id } = useParams();

  useEffect(() => {
    if (!province) setOpen(true);
    else setOpen(false);
    if (
      province &&
      (province === "US-0.00000" || province === "WLD-0.00000") &&
      !id
    ) {
      dispatch(setConversion(fromUSD.data?.currency.conversionRateFromCad));
    }
  }, [province, fromUSD.data]);

  let links = menus.map((item, index) => {
    return (
      <button
        key={index}
        type="button"
        onClick={() => dispatch(setActiveMenuIndex(item))}
        className={`${
          activeMenuIndex === item ? activeState : inactiveState
        } hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm`}
      >
        {item}
      </button>
    );
  });

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Estimate | One Supply Connect</title>
      </Helmet>
      <SelectTaxModal open={open} setOpen={setOpen} />
      <PopeOver />
      <div className="flex flex-row justify-between">
        <div className="relative pb-5 px-4 py-4 sm:pb-0 sm:px-6 lg:px-8">
          <h1 className="text-xl leading-6 font-bold text-gray-900">
            {cookies.lead?.companyName}{" "}
            <span className="font-normal">Estimate</span>
          </h1>
          <div className="relative py-4 ">
            <div className="mt-1">
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">{links}</nav>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start pt-4 pl-8 md:px-8 pointer-events-none xl:px-12">
          <div className="max-w-xs bg-gray-50 xl:max-w-sm w-full  mr-8 xl:mr-0 border border-gray-400 rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
            <div className="px-4 py-5 sm:py-4 sm:px-6">
              <dd className="my-2 text-3xl font-semibold text-green-600  flex flex-row items-end justify-start gap-2 ">
                <span className="block truncate text-blue-600 ">
                  {conversion === 1 ? "CAD" : "USD"}{" "}
                </span>
                <span>
                  {(parseFloat(total) / parseFloat(conversion))
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
              </dd>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="bg-white mr-8 ml-8 mt-8">
          <div>
            <div className="space-y-4 ">
              <BuildStepByStep
                open={activeMenuIndex === "Build Step-By-Step"}
              />
              <Store open={activeMenuIndex === "Store"} />
              <Templates open={activeMenuIndex === "Templates"} />
              <LineItems />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimateView;
