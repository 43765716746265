export default function Link({ data }) {
  const { label, link } = data;
  return (
    <div>
      <a
        href={link}
        className="inline-flex mt-2 justify-center px-4 py-2 border border-transparent hover:border-gray-300 shadow-sm text-sm font-medium rounded-md text-white hover:text-gray-700 bg-blue-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {label}
      </a>
    </div>
  );
}
