import React from "react";
import { useForm } from "react-hook-form";

//HOOKS
import useClient from "../../hooks/useClient";

//COMPONENTS
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Text from "../../../global/components/inputs/text/simple";

const AddClientModal = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { addingCustomer, handleAddContact } = useClient();

  return (
    <ModalFrame
      open={modalOn === "add-contact"}
      close={setModal}
      title={"Add New Contact"}
    >
      <form
        onSubmit={handleSubmit(handleAddContact)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the input to set the new contact CRM ID client.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <Text
            data={{
              label: "PipeDrive ID",
              id: "crm",
              textType: "number",
              placeholder: "Enter PipeDrive ID",
              methods: methods,
              registerValue: "crm",
              registerOptions: { required: true },
            }}
          />
          <ModalCTA cancel={setModal} submitting={addingCustomer} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddClientModal;
