import { useMutation, useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

// REDUCER
import {
  selectClient,
  selectContactCRM,
  selectActiveContact,
  selectActiveAddress,
  setClient,
  setClients,
  setModal,
  setOrders,
  setReps,
  setTax,
  setContactCRM,
  setActiveContact,
  setActiveAddress,
} from "./clientSlice";

//REQUESTS
import {
  CLIENTS,
  CLIENT,
  CLIENT_ORDERS,
  UPDATE_CLIENT,
  SALES_REPS,
  SALES_TAX,
  ADD_LOCATION,
  FETCH_PD_ORG,
  CREATE_CUSTOMER,
  CREATE_ECOM_CODE,
  CREATE_LEAD,
  UPDATE_CUSTOMER,
  UPDATE_ADDRESS,
  EMAIL_INSTRUCTIONS,
  ADD_CREDIT,
} from "../requests";

const useClient = () => {
  const dispatch = useDispatch();
  const methods = useForm();
  const ClientDetails = useSelector(selectClient);
  const ActiveContact = useSelector(selectActiveContact);
  const ActiveAddress = useSelector(selectActiveAddress);
  const contactCRM = useSelector(selectContactCRM);
  const [cookies, setCookie] = useCookies(["user"]);
  let navigate = useNavigate();

  // REQUESTS
  /************************************************************************* */

  const [fetchClients, ClientsResponse] = useLazyQuery(CLIENTS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (d) => {
      dispatch(setClients(d.clients));
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [fetchClient, ClientResponse] = useLazyQuery(CLIENT, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      dispatch(setClient(d.client));
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [fetchClientOrders, ClientOrderResponse] = useLazyQuery(CLIENT_ORDERS, {
    onCompleted: (d) => {
      const { name, type, orders } = d.client;
      if (!ClientDetails) dispatch(setClient({ name, type }));
      dispatch(setOrders(orders));
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [fetchSaleReps, SalesRepResponse] = useLazyQuery(SALES_REPS, {
    onCompleted: (d) => {
      dispatch(setReps(d.users));
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [fetchSalesTax, SalesTaxResponse] = useLazyQuery(SALES_TAX, {
    onCompleted: (d) => {
      dispatch(setTax(d.salesTaxes));
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [fetchPDOrg, PDResponse] = useLazyQuery(FETCH_PD_ORG, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => {
      AddContactFromPD(d.fetchPipedriveOrgData.contacts);
    },
    onError: (e) => {
      alert(e);
    },
  });

  const [updateClient, UpdateClientResponse] = useMutation(UPDATE_CLIENT, {
    onCompleted: (d) => {
      dispatch(setClient(d.updateClient.client));
      handleSetModal(null);
    },
    onError: (e) => alert(e),
  });

  const [updateCustomer, UPDCustomerResponse] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (d) => {
      let Clone = JSON.parse(JSON.stringify(ClientDetails));
      let newContacts = [...Clone.contacts];
      const index = newContacts.findIndex(
        (c) => c.id === d.updateCustomer.customer?.id
      );
      newContacts[index] = d.updateCustomer.customer;
      Clone.contacts = newContacts;
      dispatch(setClient(Clone));
      dispatch(setActiveContact(null));
      handleSetModal(null);
    },
    onError: (e) => alert(e),
  });

  const [createCustomer, CreateCResponse] = useMutation(CREATE_CUSTOMER, {
    onCompleted: (d) => {
      fetchClient({ variables: { id: ClientDetails.id } });
      handleSetModal(null);
    },
    onError: (e) => alert(e),
  });

  const [addLocation, locationResponse] = useMutation(ADD_LOCATION, {
    onCompleted: (d) => {
      fetchClient({ variables: { id: ClientDetails.id } });
      handleSetModal(null);
    },
    onError: (e) => alert(e),
  });

  const [addCredit, creditResponse] = useMutation(ADD_CREDIT, {
    onCompleted: (d) => {
      fetchClient({ variables: { id: ClientDetails.id } });
      handleSetModal(null);
    },
    onError: (e) => alert(e),
  });

  const [updateLocation, updLocationResponse] = useMutation(UPDATE_ADDRESS, {
    onCompleted: (d) => {
      fetchClient({ variables: { id: ClientDetails.id } });
      handleSetModal(null);
    },
    onError: (e) => alert(e),
  });

  const [createEcomCode] = useMutation(CREATE_ECOM_CODE, {
    onCompleted: (d) => {
      fetchClient({ variables: { id: ClientDetails.id } });
    },
    onError: (e) => alert(e),
  });

  const [createLead, LeadResponse] = useMutation(CREATE_LEAD, {
    onCompleted: (d) => {
      let data = d.createLead.lead;
      setCookie("lead", data, {
        path: "/",
        secure: true,
        sameSite: true,
      });
      setCookie("leadCurrency", data.fundingCurrencySymbol, { path: "/" });
      navigate("/estimate", { replace: true });
    },
    onError: (e) => alert(e),
  });

  const [emailClient, emailResponse] = useLazyQuery(EMAIL_INSTRUCTIONS, {
    onCompleted: (d) => {
      handleSetModal(null);
    },
    onError: (e) => alert(e),
  });

  // FUNCTIONS
  /************************************************************************* */

  async function loadPortView() {
    fetchClients();
  }
  async function loadClientView(id) {
    if (id) fetchClient({ variables: { id } });
  }
  async function loadClientOrders(id) {
    if (id) fetchClientOrders({ variables: { id } });
  }
  async function handleSearch(search) {
    if (search.length > 2) ClientsResponse.refetch({ src: search });
    else if (search.length === 0) ClientsResponse.refetch({ src: search });
  }
  async function handleSetModal(value) {
    dispatch(setModal(value));
    switch (value) {
      case "assign-AE":
        fetchSaleReps();
        break;
      case "set-tax":
        fetchSalesTax();
        break;
      default:
        break;
    }
  }

  async function updateContact(data) {
    dispatch(setActiveContact(data));
    dispatch(setModal("update-contact"));
  }

  async function updateAddress(data) {
    dispatch(setActiveAddress(data));
    dispatch(setModal("add-address"));
  }

  async function handleAssignAE(data) {
    if (!data || !data.AE || !ClientDetails) return;
    updateClient({
      variables: {
        id: ClientDetails.id,
        rep: data.AE,
      },
    });
  }

  async function handleSetTax(data) {
    if (!data || !data.tax || !ClientDetails) return;
    updateClient({
      variables: {
        id: ClientDetails.id,
        tax: data.tax,
      },
    });
  }

  async function handleUpdateName(data) {
    if (!data || !data.name || !ClientDetails) return;
    updateClient({
      variables: {
        id: ClientDetails.id,
        name: data.name,
      },
    });
  }

  async function handleSetTaxNumber(data) {
    if (!data || !data.taxNumber || !ClientDetails) return;
    updateClient({
      variables: {
        id: ClientDetails.id,
        taxNumber: data.taxNumber,
      },
    });
  }

  async function handlePricePercentage(data) {
    if (!data || !data.pricePercentage || !ClientDetails) return;
    updateClient({
      variables: {
        id: ClientDetails.id,
        pricePercentage: data.pricePercentage,
      },
    });
  }

  async function handleAddAddress(data) {
    if (!data || !ClientDetails) return;
    const { lineOne, city, state, countryCode, postalCode, isBilling } = data;
    if (!lineOne || !city || !state || !countryCode || !postalCode) return;
    let variables = {
      id: ClientDetails.id,
      locationID: ActiveAddress && ActiveAddress.id,
      address: {
        lineOne,
        countryCode,
        postalCode,
        city,
        state,
        isBilling,
        receiverName: data.managerName,
        receiverPhoneNumber: data.managerNumber,
      },
    };
    if (ActiveAddress) updateLocation({ variables });
    else addLocation({ variables });
  }
  async function handleAddCredit(data) {
    if (!data || !ClientDetails) return;
    const { amount, currency, dateReceived, reasoning } = data;
    if (!amount || !currency || !dateReceived || !reasoning) return;
    let variables = {
      id: ClientDetails.id,
      amount,
      currency,
      dateReceived,
      reasoning,
    };
    addCredit({ variables });
  }

  async function handleAddContact(data) {
    if (!data || !data.crm || !ClientDetails) return;
    dispatch(setContactCRM(data.crm));
    fetchPDOrg({ variables: { id: ClientDetails.crmId } });
  }

  async function handleUpdateContact(data) {
    if (!data || !data.crm || !ClientDetails) return;
    let variables = {
      id: ActiveContact.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      crmID: data.crm,
      type: data.type,
    };
    updateCustomer({
      variables: variables,
    });
  }

  async function AddContactFromPD(data) {
    if (!data) {
      alert(`${ClientDetails.name} has no contacts`);
      return;
    }
    const person = data.find((c) => c.crmId === contactCRM);
    if (!person) {
      alert(`contact ID ${contactCRM} is not found for ${ClientDetails.name}`);
      return;
    }
    const { crmId, email, firstName, lastName, phoneNumber } = person;
    if (!email) {
      alert(`contact ID ${contactCRM} has no email`);
      return;
    }
    if (!firstName) {
      alert(`contact ID ${contactCRM} has no first name`);
      return;
    }
    if (!lastName) {
      alert(`contact ID ${contactCRM} has no last name`);
      return;
    }
    createCustomer({
      variables: {
        company: ClientDetails.id,
        crm: crmId,
        type: "alternative_contact",
        email: email.replace(/\s/g, ""),
        first: firstName,
        last: lastName,
        phone: phoneNumber,
      },
    });
  }

  async function handleEcommerceLink() {
    createEcomCode({
      variables: {
        id: ClientDetails.id,
        refresh: false,
      },
    });
  }

  async function handleEmailContact(data) {
    if (!data || !data.contact || !ClientDetails) return;
    let variables = {
      id: ClientDetails.id,
      contact: data.contact,
      ccEm: [],
    };
    data.ccEmails && (variables.ccEmails = data.ccEmails.split(","));
    emailClient({
      variables,
    });
  }

  async function createNewOrder(data) {
    if (!data || !data.contact || !ClientDetails) return;
    let contact = ClientDetails.contacts.find((entry) => {
      return entry.id === data.contact;
    });
    const { id, name, salesTax } = ClientDetails;
    let currency = salesTax?.taxPercentage === "0.00000" ? "USD" : "CAD";
    const leadInput = {
      companyId: id,
      customerId: contact.id,
      companyName: name,
      contactName: `${contact.firstName} ${contact.lastName}`,
      email: contact.email,
      productionGoal: "Client New Order",
      funding: 100000,
      fundingCurrencySymbol: currency,
    };
    createLead({
      variables: {
        input: leadInput,
      },
    });
  }

  return {
    loadPortView,
    loadClientView,
    loadClientOrders,
    handleSearch,
    handleSetModal,
    handleAssignAE,
    handleEmailContact,
    handleSetTax,
    handleUpdateName,
    handleSetTaxNumber,
    handlePricePercentage,
    handleAddAddress,
    handleAddCredit,
    handleAddContact,
    handleUpdateContact,
    handleEcommerceLink,
    createNewOrder,
    updateContact,
    updateAddress,
    loadingPort: ClientsResponse.loading,
    loadingOrders: ClientOrderResponse.loading,
    loadingClient: ClientResponse.loading,
    loadingSalesReps: SalesRepResponse.loading,
    loadingSalesTax: SalesTaxResponse.loading,
    loadingLead: LeadResponse.loading,
    updatingClient: UpdateClientResponse.loading,
    updatingCustomer: UPDCustomerResponse.loading,
    addingCustomer: CreateCResponse.loading || PDResponse.loading,
    addingAddress: locationResponse.loading || updLocationResponse.loading,
    emailingContact: emailResponse.loading,
    addingCredit: creditResponse.loading,
    methods,
  };
};
export default useClient;
