/* eslint-disable array-callback-return */
import React from "react";
import { Text, View, Image, StyleSheet, Font } from "@react-pdf/renderer";
import logo from "./logo.jpg";
Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  company: {
    fontFamily: "Helvetica-Bold",
  },
  text: {
    fontSize: 9,
  },
  reportTitle: {
    color: "#4F90BB",
    letterSpacing: 1,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 8,
  },
  wayFastTitle: {
    color: "#269A8B",
    letterSpacing: 1,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 8,
  },
  logo: {
    maxWidth: "65%",
    height: "auto",
    marginLeft: "auto",
    // marginRight: "auto",
  },
});

const InvoiceTitle = ({ estimate }) => {
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.company}>Advanced Integrated Robotics</Text>
        <Text style={styles.text}>PO Box 48054, Mississauga, Ontario</Text>
        <Text style={styles.text}> RPO Dundas Shepard, L5A 4G8</Text>
        <Text style={styles.text}>customersuccess@onesupply.co</Text>
        <Text style={styles.reportTitle}>Proforma Invoice</Text>
      </View>
      <View style={styles.headerContainer}>
        <Image style={styles.logo} src={logo} />
      </View>
    </View>
  );
};

export default InvoiceTitle;
