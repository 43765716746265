/* eslint-disable no-unused-vars */
import React from "react";
import "./App.css";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { useIdleTimer } from "react-idle-timer";

//COOKIES
import { useCookies } from "react-cookie";

// VIEWS
import Router from "./router";
import Login from "./authentication/views";
import Validator from "./authentication/views/validator";

function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  const IdleTimer = useIdleTimer({
    onIdle: () => {
      setCookie("user", null, { path: "/" });
      localStorage.removeItem("report");
      window.location.reload();
    },
    debounce: 500,
  });
  const blobLink = createUploadLink({
    uri: process.env.REACT_APP_URI,
  });

  const authLink = setContext((_, { headers }) => {
    const token = cookies.user?.token;
    return {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        authorization: token && cookies.user !== "null" ? `JWT ${token}` : "",
        "X-Csrftoken": "eyJ1c2VybmFtZSI6Im1vaGFtbWFkQGF0Z3BoYUwMjIyMzUsIm9yaWd",
      },
    };
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        alert(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) alert(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: authLink.concat(blobLink, errorLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Validator />
      {/* {cookies.user && cookies.user !== "null" ? <Router /> : <Login />} */}
    </ApolloProvider>
  );
}

export default App;
