/* eslint-disable array-callback-return */
import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
  },
  reportTitle: {
    letterSpacing: 1,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: 8,
  },
  v1: {
    display: "flex",
    flexDirection: "column",
    marginTop: 12,
  },
  grid1: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "between",
    marginTop: 4,
  },
  title: {
    fontSize: 14,
    textAlign: "left",
    fontWeight: "bold",
  },
  gap: {
    marginLeft: "24",
  },
  itemTitle: {
    fontFamily: "Helvetica-Bold",
  },
});

const InvoiceTitle = ({ estimate, isATG }) => {
  if (!estimate) return null;
  return (
    <View style={styles.container}>
      <Text style={styles.reportTitle}>Payment Information</Text>
      <View style={styles.v1}>
        <Text style={styles.title}>Beneficiary Bank Information:</Text>
        <View style={styles.grid1}>
          <View>
            <Text style={styles.itemTitle}>Bank Name:</Text>
            <Text>{isATG ? "Bank of Montreal" : "CENTRAL 1 CREDIT UNION"}</Text>
          </View>
          <View style={styles.gap}>
            <Text style={styles.itemTitle}>SWIFT Code/BIC:</Text>
            <Text>
              {isATG
                ? estimate.taxPercentage === "0.00000"
                  ? "HATRUS44"
                  : "BOFMCAM2"
                : "CUCXCATTVAN"}
            </Text>
          </View>
          {isATG && (
            <View style={styles.gap}>
              <Text style={styles.itemTitle}>Canadian Clearing Code:</Text>
              <Text>CC0002 87296</Text>
            </View>
          )}
        </View>
        <View style={{ marginTop: 12 }}>
          <Text style={styles.itemTitle}>Bank Address:</Text>
          {isATG ? (
            estimate.taxPercentage === "0.00000" ? (
              <View>
                <Text style={styles.block}>BMO Bank N.A</Text>
                <Text style={styles.block}>320 S.Canal St.</Text>
                <Text>Chicago, IL 60606</Text>
              </View>
            ) : (
              <View>
                <Text style={styles.block}>50 Bay St. South</Text>
                <Text style={styles.block}>HAMILTON, ON, L8P 4V9</Text>
                <Text>Canada</Text>
              </View>
            )
          ) : (
            <View>
              <Text style={styles.block}>1441 Creekside Drive</Text>
              <Text style={styles.block}>Vancouver, BC, V6J 4S7</Text>
              <Text>Canada</Text>
            </View>
          )}
        </View>
      </View>
      {!isATG && estimate.taxPercentage === "0.00000" && (
        <View style={styles.v1}>
          <Text style={styles.title}>Correspondent Bank Information:</Text>
          <View style={styles.grid1}>
            <View>
              <Text style={styles.itemTitle}>Bank Name:</Text>
              <Text>Citibank N.A</Text>
            </View>
            <View style={styles.gap}>
              <Text style={styles.itemTitle}>SWIFT Code/BIC:</Text>
              <Text>CITIUS33XXX</Text>
            </View>
            <View style={styles.gap}>
              <Text style={styles.itemTitle}>ABA:</Text>
              <Text>021 0000 89</Text>
            </View>
          </View>
        </View>
      )}
      <View style={styles.v1}>
        <Text style={styles.title}>Beneficiary Account Information:</Text>
        <View style={styles.grid1}>
          <View>
            <Text style={styles.itemTitle}>Account Name:</Text>
            <Text>
              {isATG ? "ATG PHARMA INC." : "Advanced Integrated Robotics Inc."}
            </Text>
          </View>
          <View style={styles.gap}>
            <Text style={styles.itemTitle}>Account Number:</Text>
            <Text>
              {estimate.taxPercentage === "0.00000"
                ? isATG
                  ? "4839107548"
                  : "082806752 72423"
                : isATG
                ? "1976154"
                : "101007242372"}
            </Text>
          </View>
          <View style={styles.gap}>
            <Text style={styles.itemTitle}>Transit:</Text>
            <Text>{isATG ? "00062`" : "06752"}</Text>
          </View>
        </View>
        <View style={{ marginTop: 12 }}>
          <Text style={styles.itemTitle}>Beneficiary Address:</Text>
          <View>
            <Text style={styles.block}>
              {isATG
                ? "474 Iroquois Shore Rd, Unit 2"
                : "PO Box 48054, Mississauga, Ontario"}
              ,
            </Text>
            <Text style={styles.block}>
              {isATG ? "OAKVILLE, ON, L6H 2Y7" : "RPO Dundas Shepard, L5A 4G8"}
            </Text>
            <Text>Canada</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default InvoiceTitle;
