import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    marginTop: 8,
    borderTopColor: "#929292",
    borderStyle: "dashed",
    borderTopWidth: 0.7,
    alignItems: "start",
    fontSize: 12,
    fontStyle: "bold",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "5px",
  },
  description: {
    width: "85%",
    textAlign: "right",
    paddingRight: 8,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingRight: 8,
  },
  totalContainer: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    marginRight: "8px",
    marginLeft: "25px",
  },
  disclaimer: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
  },
  text: {
    fontSize: 7.5,
    flex: 1,
  },
  totalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  pricing: {
    fontSize: 8,
    letterSpacing: 0.5,
    whiteSpace: "nowrap",
  },
  subTotal: {},
  totalPricing: {
    fontSize: 17,
    fontFamily: "Helvetica-Bold",
  },
});

const InvoiceTableFooter = ({ estimate }) => {
  if (!estimate) return null;
  const training = estimate?.lineItems?.find(
    (item) => item.item.title === "SanityCo Training"
  );
  const install = estimate?.lineItems?.find(
    (item) => item.item.title === "G80 Install"
  );
  return (
    <View style={styles.row}>
      <View style={styles.disclaimer}>
        <Text style={styles.text} />
      </View>
      <View style={styles.totalContainer}>
        <View style={styles.totalRow}>
          <Text style={styles.pricing}>SUBTOTAL</Text>
          <Text style={styles.subTotal}>
            {(
              Number.parseFloat(
                estimate?.lineItems.reduce(
                  (sum, r) => sum + parseFloat(r.totalCost),
                  0
                )
              ) -
              (training?.costPerUnit || 0) -
              (install?.costPerUnit || 0)
            )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        </View>
        <View style={styles.totalRow}>
          <Text style={styles.pricing}>
            {`TAXES (${estimate.salesTax?.provinceState}) @ ${(
              Number.parseFloat(estimate.taxPercentage) * 100
            ).toFixed(2)}%`}
          </Text>
          <Text style={styles.subTotal}>
            {(
              Number.parseFloat(
                estimate?.lineItems.reduce(
                  (sum, r) => sum + parseFloat(r.totalCost),
                  0
                )
              ) * Number.parseFloat(estimate.taxPercentage)
            )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        </View>
        <View style={styles.totalRow}>
          <Text style={styles.pricing}>TOTAL</Text>
          <Text style={styles.totalPricing}>
            {estimate.taxPercentage === "0.00000" ? "USD" : "CAD"}{" "}
            {Number.parseFloat(
              (Number.parseFloat(
                estimate?.lineItems.reduce(
                  (sum, r) => sum + parseFloat(r.totalCost),
                  0
                )
              ) -
                (training?.costPerUnit || 0) -
                (install?.costPerUnit || 0)) *
                (1 + Number.parseFloat(estimate.taxPercentage))
            )
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default InvoiceTableFooter;
