import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SimpleText({ state, setState, data }) {
  const {
    label,
    methods: { setValue },
    registerValue,
    off,
    on,
  } = data;

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1">
        <Switch.Group as="div" className="flex items-start justify-start">
          <Switch.Label as="span" className="mr-3">
            <span className="text-sm font-medium text-gray-900">{off}</span>
          </Switch.Label>
          <Switch
            checked={state}
            onChange={(e) => {
              setState(e);
              setValue(registerValue, e);
            }}
            className={classNames(
              state ? "bg-blue-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                state ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3">
            <span className="text-sm font-medium text-gray-900">{on}</span>
          </Switch.Label>
        </Switch.Group>
      </div>
    </div>
  );
}
