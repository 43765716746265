/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { XIcon } from "@heroicons/react/outline";

const EditAddress = ({
  openArchive,
  setOpenArchive,
  archiveOrder,
  id,
  lostReasons,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit } = useForm();
  async function handleArchive(data) {
    setIsSubmitting(true);
    archiveOrder({
      variables: {
        id: parseInt(id),
        reason: data.reason,
      },
    });
  }
  return (
    <Transition.Root show={openArchive} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openArchive}
        onClose={setOpenArchive}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={handleSubmit(handleArchive)}
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setOpenArchive(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-black text-gray-900"
                  >
                    Archive Order
                  </Dialog.Title>
                </div>
              </div>
              <div className="flex flex-col items-start justify-center pt-2 ">
                <p className="text-gray-600">
                  Please provide a reason for archiving this order.
                  <span className="block text-red-700 font-medium animate-pulse">
                    This Action is irreversible and cannot be undone.
                  </span>
                </p>
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Reason:
                  </label>
                  <div className="mt-1">
                    <select
                      id="location"
                      {...register("reason", { required: true })}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base borderborder-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      defaultValue="Canada"
                    >
                      <option value="">Select...</option>
                      {lostReasons.data &&
                        lostReasons.data.orderClosedReasons.map(
                          (entry, index) => (
                            <option key={index} value={entry.id}>
                              {entry.label}
                            </option>
                          )
                        )}
                    </select>
                  </div>

                  <div className="flex flex-row items-end  justify-center  mt-2">
                    <button
                      type="submit"
                      className="flex flex-row gap-1 mt-4 justify-center py-2 px-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      {isSubmitting && (
                        <svg
                          className="animate-spin h-5 w-5 text-white self-center mr-2"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 40 40"
                          enableBackground="new 0 0 40 40"
                          xml="preserve"
                        >
                          <path
                            opacity="0.2"
                            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                          />
                          <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                        </svg>
                      )}
                      Archive Order
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditAddress;
