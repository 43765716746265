import useClient from "../hooks/useClient";

export const SUB_MENUS = [
  {
    title: "Details",
    link: "",
  },
  {
    title: "Orders",
    link: "orders",
  },
  {
    title: "Import",
    link: "import",
  },
];

export const PAGE_HEADER = {
  titleAccessor: "name",
  tagAccessor: "type",
  tagStyling: {
    BLACKLISTED: "text-red-700",
  },
};

export const ORDERS_HEADER = {
  data: {
    name: "Order History",
    button: {
      label: "Create New",
      type: "button",
      clickEvent: { hook: useClient, func: "handleSetModal" },
      value: "new-order",
    },
  },
  map: {
    titleAccessor: "name",
  },
};

export const CLIENT_INFORMATION = [
  {
    label: "Account Executive",
    accessor: "accountExecutive",
    type: "name",
    button: {
      label: "Assign New",
      type: "button",
      clickEvent: { hook: useClient, func: "handleSetModal" },
      value: "assign-AE",
    },
  },
  {
    label: "Sales Tax",
    accessor: "salesTax",
    depth1: "provinceStateName",
    depth1Comp: "taxPercentage",
    button: {
      label: "Set",
      type: "button",
      clickEvent: { hook: useClient, func: "handleSetModal" },
      value: "set-tax",
    },
  },
  {
    label: "Tax Number",
    accessor: "taxId",
    button: {
      label: "Set",
      type: "button",
      clickEvent: { hook: useClient, func: "handleSetModal" },
      value: "set-tax-number",
    },
  },
  {
    label: "Partner Pricing Percentage",
    accessor: "partnerPricingCostPlusPercentage",
    button: {
      label: "Set",
      type: "button",
      clickEvent: { hook: useClient, func: "handleSetModal" },
      value: "set-partner-pricing",
    },
  },
];

export const ADDRESS_INFO = {
  label: "Addresses",
  button: {
    label: "Add New",
    type: "button",
    clickEvent: { hook: useClient, func: "handleSetModal" },
    value: "add-address",
  },
  editButton: {
    label: "Edit",
    type: "button",
    clickEvent: { hook: useClient, func: "updateAddress" },
  },
};

export const CLIENT_CREDITS = {
  label: "Credit",
  button: {
    label: "Add New",
    type: "button",
    clickEvent: { hook: useClient, func: "handleSetModal" },
    value: "add-credit",
  },
  tableStructure: [
    {
      header: "ID",
      accessor: "id",
      style: "font-medium text-gray-900",
    },
    {
      header: "Issued On",
      accessor: "createdAt",
      type: "date",
    },
    {
      header: "Amount",
      accessor: "amount",
      type: "price",
    },
    {
      header: "Reasoning",
      accessor: "reasoning",
      // type: "name",
    },
    {
      header: "Credit Remaining",
      accessor: "amountRemaining",
      type: "price",
    },
  ],
};

export const CLIENT_CONTACTS = {
  label: "Contacts",
  button: {
    label: "Add New",
    type: "button",
    clickEvent: { hook: useClient, func: "handleSetModal" },
    value: "add-contact",
  },
  tableStructure: [
    {
      header: "ID",
      accessor: "id",
      style: "font-medium text-gray-900",
    },
    {
      header: "Name",
      type: "name",
      style: "text-gray-800",
    },
    {
      header: "Email",
      accessor: "email",
      type: "email",
    },
    {
      header: "Phone",
      accessor: "phoneNumber",
      type: "phone",
    },
    {
      header: "PD Connection",
      accessor: "crmId",
      alt: "Not Provided",
      altStyle: "font-medium text-red-700",
    },
    {
      header: "Actions",
      accessor: "id",
      type: "button",
      button: {
        label: "Update",
        type: "button",
        clickEvent: { hook: useClient, func: "updateContact" },
      },
    },
  ],
};

export const CLIENT_ECOMMERCE = {
  label: "E-Commerce",
  button: {
    dependency: "publicEcommerceCode",
    label: "Add New",
    type: "button",
    clickEvent: { hook: useClient, func: "handleEcommerceLink" },
  },
  link: {
    labelPre: "https://www.customer.onesupply.co/client-portal?clientCode=",
    value: "publicEcommerceCode",
  },
  modalButton: {
    label: "Email Client Instructions",
    type: "button",
    clickEvent: { hook: useClient, func: "handleSetModal" },
    value: "email-contact",
  },
};

export const CLIENT_ORDERS = [
  {
    header: "ID",
    accessor: "id",
    style: "font-medium text-gray-900",
    type: "link",
    precedes: "/orders/",
    text: "id",
  },
  {
    header: "Status",
    accessor: "currentStatus",
    depth1: "name",
  },
  {
    header: "Type",
    accessor: "salesType",
  },
  {
    header: "Total",
    accessor: "total",
    type: "price",
    currency: "currency",
  },
  {
    header: "Payment Received",
    accessor: "paymentTransactions",
    type: "loop",
    loopDepth1: "amountReceived",
    compare: "total",
  },
  {
    header: "Created On",
    accessor: "createdAt",
    type: "date",
  },
];
