/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { SALES_REPS, COMMISSION_OWED } from "./queries";

import { Headers } from "./static";
import Loading from "../../Loading/page";

//COOKIES
import { useCookies } from "react-cookie";

export default function SalesRepHomePanel({
  year,
  month,
  update,
  setUpdate,
  user,
}) {
  const [cookies] = useCookies(["user"]);
  const [reportObject, setReportObject] = useState(null);
  const [objStorage, setObjStorage] = useState(null);
  const [allOrders, setAllOrders] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [dateT, setDateT] = useState({ month } - { year });
  const [execAlt, setExecAlt] = useState(user === "Executive");
  const salesReps = useQuery(SALES_REPS);
  const [getCommission, commissionRes] = useLazyQuery(COMMISSION_OWED, {
    fetchPolicy: "cache-and-network",
    onCompleted: (d) => handleCommissions(d.salesRepMonthlyOrderCommissions),
    onError: (e) => alert(e),
  });

  useEffect(() => {
    if (firstRender || update)
      getCommission({
        variables: { selectedMonth: month, selectedYear: year },
      });
  }, [update]);

  async function handleCommissions(data) {
    setFirstRender(false);
    setUpdate(false);
    let report = {
      year: year,
      month: month,
      salesRepsCommissionDetails: data.salesRepsCommissionDetails,
      orders: data.orders,
    };
    setDateT(`${month} - ${year}`);
    setObjStorage(report);
    if (cookies.user?.user?.role?.name === "Sales Representative") {
      filterOrders(cookies.user?.user?.lastName, report);
    } else setReportObject(report);
  }

  async function filterOrders(name, report) {
    let reportClone = JSON.parse(JSON.stringify(objStorage || report));
    if (!name) {
      setReportObject(reportClone);
      return;
    }
    reportClone = {
      ...reportClone,
      orders: reportClone.orders.filter(
        (entry) => entry.createdBy?.lastName === name
      ),
      salesRepsCommissionDetails: reportClone.salesRepsCommissionDetails.filter(
        (entry) => entry.salesRepName.split(" ")[1] === name
      ),
    };
    setReportObject(reportClone);
  }
  if (!reportObject || commissionRes.loading)
    return (
      <div className="w-full flex flex-row items-center justify-center">
        <Loading />
      </div>
    );
  return (
    <div className="bg-connectGray px-4 sm:px-6 lg:max-w-7xl lg:mx-auto">
      <main className="">
        <div className="p-4">
          <div className="grid grid-cols-3 justify-between items-end pb-2">
            <div />
            {user === "Executive" ? (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Sales Rep
                </label>
                <select
                  id="rep"
                  defaultValue={reportObject?.id}
                  onChange={(e) => {
                    if (e.target.value) setExecAlt(false);
                    else setExecAlt(true);
                    filterOrders(e.target.value);
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                >
                  <option value="">All</option>
                  {salesReps.data &&
                    salesReps.data.users.map((entry, index) => (
                      <option key={index} value={entry.lastName}>
                        {entry.firstName} {entry.lastName}
                      </option>
                    ))}
                </select>
              </div>
            ) : (
              <div />
            )}
            <div className="flex flex-col items-end">
              <h3 className="text-2xl text-right leading-6 font-semibold text-indigo-900">
                {dateT}
              </h3>
            </div>
          </div>

          <div className="mt-8">
            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
              <div className="relative bg-white pt-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <p className="text-sm font-medium text-gray-500 truncate mb-1">
                    Canadian Commission Owed
                  </p>
                </dt>
                <dd className="pb-3 flex items-baseline  justify-between w-full  ">
                  <p className="text-xl font-semibold text-gray-900 w-full ">
                    {reportObject.salesRepsCommissionDetails.map(
                      (entry, index) => (
                        <span
                          key={index}
                          className="grid grid-cols-2 gap-12 w-full"
                        >
                          <span>{entry.salesRepName}:</span>
                          <span className="text-right  text-green-800">
                            CAD ${" "}
                            {parseFloat(entry?.totalCommissionCad)
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </span>
                      )
                    )}
                  </p>
                </dd>
              </div>
              <div className="relative bg-white pt-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden">
                <dt>
                  <p className="text-sm font-medium text-gray-500 truncate mb-1">
                    US Commission Owed
                  </p>
                </dt>
                <dd className="pb-3 flex items-baseline  justify-between w-full  ">
                  <p className="text-xl font-semibold text-gray-900 w-full ">
                    {reportObject.salesRepsCommissionDetails.map(
                      (entry, index) => (
                        <span
                          key={index}
                          className="grid grid-cols-2 gap-4 w-full"
                        >
                          <span>{entry.salesRepName}:</span>
                          <span className="text-right text-green-800">
                            US $
                            {parseFloat(entry?.totalCommissionUsd)
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </span>
                      )
                    )}
                  </p>
                </dd>
              </div>
            </dl>
          </div>

          <div className="mt-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {allOrders ? "All Orders" : "Completed Orders"}
            </h3>
            <div className="-mx-4 mt-5 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {Headers.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`py-3.5 pl-4 pr-3 text-${item.align} text-sm font-semibold text-gray-900 sm:pl-6`}
                      >
                        {execAlt && item.execAlt ? item.execAlt : item.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {reportObject &&
                    reportObject?.orders?.map((order, index) => (
                      <tr key={index}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <a
                            href={`/orders/${order.id}?view=${
                              order.salesType === "SPARE_PARTS"
                                ? "spare"
                                : "custom"
                            }`}
                            className="text-blue-700 hover:text-blue-900"
                          >
                            {order.id}
                          </a>
                        </td>
                        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-800 font-medium sm:table-cell">
                          {order.client?.name || order.lead?.companyName}
                        </td>
                        <td className="whitespace-nowrap pr-3 py-4 text-sm  text-right font-medium text-gray-800">
                          {order.currency} $
                          {order.total ? (
                            parseFloat(order.total)
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          ) : order.predictedEstimate ? (
                            <span className="text-yellow-500">
                              {parseFloat(order.predictedEstimate.total)
                                ?.toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="whitespace-nowrap pr-3 py-4 text-sm  text-right font-medium text-green-900">
                          {order.currency} $
                          <span className="text-green-900">
                            {parseFloat(order.selectedEstimate.totalCommission)
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </td>
                        <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-right text-gray-800 font-medium sm:table-cell">
                          {order.vendorShipDate?.split("T")[0]}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-900">
                          {order.createdBy?.firstName}{" "}
                          {order.createdBy?.lastName}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
