/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";

//ATG vendor
//CHRIS vendors
//rep user

//COOKIES
import { useCookies } from "react-cookie";

// COMPONENTS
import VendorInvoices from "./vendorInvoices";
import SalesInvoices from "./salesInvoices";

const InvoiceView = () => {
  const [cookies] = useCookies(["user"]);
  let renderedComponent = null;
  useMemo(() => {
    switch (cookies.user?.user?.role?.name) {
      case "Sales Representative":
        renderedComponent = <SalesInvoices />;
        break;
      case "Vendor Admin":
        renderedComponent = <VendorInvoices />;
        break;
      case "Executive":
        renderedComponent = <VendorInvoices exec={true} />;
        break;
      default:
        break;
    }
  }, [cookies.user]);

  return <div className="w-full">{renderedComponent}</div>;
};

export default InvoiceView;
