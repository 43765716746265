import { configureStore } from "@reduxjs/toolkit";
import estimateReducer from "../Sales/hooks/estimateSlice";
import saleReducer from "../Sales/hooks/saleSlice";
import clientReducer from "../clients/hooks/clientSlice";

export const store = configureStore({
  reducer: {
    estimate: estimateReducer,
    sale: saleReducer,
    client: clientReducer,
  },
});
