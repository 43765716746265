import React, { Fragment, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SpeakerphoneIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { gql, useMutation } from "@apollo/client";

// HOOKS
import { useForm } from "react-hook-form";

//COMPONENTS
import Card from "../../components/Card/wHeader";

const SUBMITORDER = gql`
  mutation submitOrder($estimate: String!, $message: String!, $ccEm: [String]) {
    submitOrder(
      predictedEstimateNumber: $estimate
      salesMessage: $message
      ccEmails: $ccEm
    ) {
      orderId
      status
      publicLink
    }
  }
`;

const SubmitOrder = ({ setOnDetails, selectedEstimate, data, id }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const { handleSubmit, getValues, register } = methods;
  const [submitOrder] = useMutation(SUBMITORDER, {
    onCompleted: (d) => (window.location = `/orders/${id}`),
    onError: (e) => {
      setIsSubmitting(false);
      setOpen(false);
      alert(e);
    },
  });
  async function onSubmit(data) {
    setIsSubmitting(true);
    let variables = {
      estimate: selectedEstimate,
      message: data.statement,
    };
    data.cc && (variables.ccEm = data.cc.split(","));
    submitOrder({
      variables: variables,
    });
  }
  return (
    <Card
      heading={{
        title: "Submit Order",
        subTitle: "Fill in the client information to complete their order",
      }}
    >
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => setOpen(true)}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <SpeakerphoneIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Platform In Beta
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Have you checked all the estimates? Ensure all the
                          information you're presenting to client are correct
                          and valid.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={isSubmitting}
                    onClick={() => onSubmit(getValues())}
                  >
                    {isSubmitting && (
                      <svg
                        className="animate-spin h-5 w-5 text-white self-center mr-2"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 40 40"
                        enableBackground="new 0 0 40 40"
                        xml="preserve"
                      >
                        <path
                          opacity="0.2"
                          d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                        />
                        <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                      </svg>
                    )}
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="shadow sm:rounded-md sm:overflow-hidden ">
          <div
            className={`bg-white py-6 px-4 space-y-6 sm:p-6 ${
              isSubmitting && "opacity-40"
            }`}
          >
            <div className="flex flex-row justify-between">
              <h3 className="text-2xl leading-6 font-medium text-gray-900">
                Order Submission
              </h3>
              <button
                type="button"
                onClick={() => setOnDetails(true)}
                className="inline-flex items-center justify-center px-4 py-2 mr-4 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

                <span>Back</span>
              </button>
            </div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Personal Statement
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                The personalized message you would like to be send to the client
                with links to the estimates created
              </p>
            </div>
            <div>
              <h2 className="mt-0 flex flex-row gap-2 items-end">
                To: <span className="font-semibold">{data.lead?.email}</span>
              </h2>
              <p className="ml-9 text-xs text-gray-500">comma separated</p>
              <h2 className="mt-1 flex flex-row gap-2 items-end">
                CC:{" "}
                <input
                  type="text"
                  placeholder="Type in additional emails..."
                  {...register("cc")}
                  className="block shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2 w-2/3"
                />
              </h2>
            </div>
            <h1>
              Hi <span className="font-semibold">{data.lead?.contactName}</span>
              ,
            </h1>
            <div className="col-span-1">
              <textarea
                id="statement"
                {...register("statement")}
                placeholder="Enter your personal statement here..."
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <p>
              Linked are the estimates for{" "}
              <span className="font-semibold">{data.lead?.companyName}</span>{" "}
              created for your production by ATG Pharma Inc. Please review the
              estimates and select the one you would like to move forward with.
              Please note that all estimates are only valid for 60 days. The due
              date is indicated on each estimate.
              <span className="block pt-4 ">
                We look forward to hearing back from you. When ready to order,
                please use the button below to select estimate and provide your
                required deposit and/or samples info. ATG Pharma will issue you
                an invoice and get your production started ASAP!
              </span>
              <span className="block pt-4">
                Looking forward to our partnership.
              </span>
            </p>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="button"
              id="completeSubmit"
              disabled={isSubmitting}
              onClick={() => setOpen(true)}
              className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isSubmitting && (
                <svg
                  className="animate-spin h-5 w-5 text-white self-center mr-2"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  enableBackground="new 0 0 40 40"
                  xml="preserve"
                >
                  <path
                    opacity="0.2"
                    d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                  />
                  <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                </svg>
              )}
              Submit
            </button>
          </div>
        </div>
      </form>
    </Card>
  );
};

export default SubmitOrder;
