import React from "react";
import { useForm } from "react-hook-form";

//HOOKS
import useClient from "../../hooks/useClient";

//COMPONENTS
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Text from "../../../global/components/inputs/text/simple";

const SetPartnerPricing = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { updatingClient, handlePricePercentage } = useClient();

  return (
    <ModalFrame
      open={modalOn === "set-partner-pricing"}
      close={setModal}
      title={"Set Partner Pricing "}
    >
      <form
        onSubmit={handleSubmit(handlePricePercentage)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the input to set a new tax number this client.
        </p>
        <p className="text-red-700 text-center mb-4">
          Be aware, this client will no longer be able to be sold products at
          desired price points.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <Text
            data={{
              label: "Price Percentage Over Cost",
              id: "pricePercentage",
              textType: "number",
              placeholder: "Enter Price Percentage",
              methods: methods,
              registerValue: "pricePercentage",
              registerOptions: { required: true },
            }}
          />
          <ModalCTA cancel={setModal} submitting={updatingClient} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default SetPartnerPricing;
