import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const rowColor = "#5191BB";
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#DCE9F1",
    backgroundColor: "#DCE9F1",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 18,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  description: {
    width: "50%",
    color: rowColor,
    fontSize: "10px",
    textAlign: "center",
  },
  qty: {
    width: "10%",
    color: rowColor,
    fontSize: "9px",
    textAlign: "right",
    paddingRight: "5px",
  },
  rate: {
    width: "20%",
    color: rowColor,
    fontSize: "9px",
    textAlign: "right",
    paddingRight: "5px",
  },
  amount: {
    width: "20%",
    color: rowColor,
    fontSize: "9px",
    textAlign: "right",
    paddingRight: "5px",
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.description} />
    <Text style={styles.qty}>QTY</Text>
    <Text style={styles.rate}>RATE</Text>
    <Text style={styles.amount}>AMOUNT</Text>
  </View>
);

export default InvoiceTableHeader;
