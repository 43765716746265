import React from "react";
import { setPopOver } from "../../../hooks/sales/saleSlice";
import { useDispatch } from "react-redux";
const SimpleRadio = ({
  props,
  build,
  register,
  getValues,
  setValue,
  formState: { errors },
}) => {
  const dispatch = useDispatch();
  const RadioOptions = props.items?.map((entry, index) => {
    return (
      <div key={index} className="flex items-start mt-3">
        <div className="h-5 flex items-center">
          <input
            key={index}
            type="radio"
            onClick={() =>
              getValues(`${props.id}-${build}`) === entry.skuNumber &&
              setValue(`${props.id}-${build}`, null)
            }
            {...register(`${props.id}-${build}`)}
            value={entry.skuNumber}
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-base">
          <label htmlFor={props.title} className="font-medium text-gray-700">
            {entry.title}
          </label>
          <p className="text-gray-500">{entry.subtitle}</p>
        </div>
      </div>
    );
  });
  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div className="col-span-2">
        <label
          htmlFor="project_name"
          className="block text-lg font-medium text-gray-900 sm:mt-px sm:pt-2"
        >
          {props.label}
        </label>
        <p className="text-gray-500 text-base">{props.description}</p>
        <button
          onClick={() =>
            dispatch(setPopOver({ view: true, item: props.items }))
          }
          type="button"
          className="animate-pulse text-blue-400 hover:text-blue-900 text-base font-semibold"
        >
          View Details
        </button>
        {errors[props.name] && (
          <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
            Selection is Required
          </p>
        )}
      </div>
      <div>{RadioOptions}</div>
    </div>
  );
};

export default SimpleRadio;
