/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { setPopOver } from "../../../hooks/sales/saleSlice";
import { useDispatch } from "react-redux";
import {
  selectBeethoven,
  selectPage,
} from "../../../hooks/estimate/estimateSlice";

const Headers = [" Tray Type", "Price", "Quantity"];
const TraysTable = ({ props, register, getValues, setValue, build }) => {
  const dispatch = useDispatch();
  const beethoven = useSelector(selectBeethoven);
  const pageState = useSelector(selectPage);
  let Number = Object.values(
    beethoven[pageState.build]
  )[0].defaults[0]?.title.split("-")[1][0];
  let items = props.items.filter(
    (entry) =>
      props.items[0].title.split(" - ")[1] &&
      entry.title.split(" - ")[1][0] === Number
  );
  if (items.length === 0) items = props.items;
  let systemBuild;
  switch (props.label) {
    case "Peristaltic Tray Options":
      systemBuild = Object.values(beethoven[pageState.build]).find((entry) => {
        return entry.title === "Peristaltic Filling Options";
      });
      let pump =
        systemBuild.defaults.length !== 0 &&
        Object.values(systemBuild.defaults).find((entry) => {
          return entry.title.split(" – ")[1];
        });
      let nozzle = pump && pump.title.split(" – ")[1][0];
      items = items.filter(
        (entry) => entry.title.split(" - ")[1].split(" ")[1] === nozzle
      );
      let preDefault =
        items.length !== 0 &&
        items.find((entry) => {
          return entry.title.split(" - ")[0] === "CFO3";
        });
      if (items.length === 2) {
        let preName = `${props.id}-${build}-${preDefault.skuNumber}`;
        !getValues(preName) && setValue(preName, "1");
      }
      break;
    case "Volumetric Tray Options":
      systemBuild = Object.values(beethoven[pageState.build]).find((entry) => {
        return entry.title === "Volumetric Filling Options";
      });
      let carts =
        systemBuild.defaults.length !== 0 &&
        Object.values(systemBuild.defaults).find((entry) => {
          return entry.title.split("- ")[0];
        });
      let tip = carts && carts.title.split("- ")[1][0];
      items = items.filter(
        (entry) => entry.title.split(" - ")[1].split(" ")[1] === tip
      );
      let volDefault =
        items.length !== 0 &&
        items.find((entry) => {
          return entry.title.split(" - ")[0] === "CFO3";
        });
      if (items.length === 2) {
        let volName = `${props.id}-${build}-${volDefault.skuNumber}`;
        !getValues(volName) && setValue(volName, "1");
      }
      break;
    default:
      break;
  }
  const TableHeaders = Headers.map((option, i) => {
    return (
      <th
        key={i}
        className="p-3 text-left text-xs font-medium  uppercase text-gray-800  hidden lg:table-cell"
      >
        {option}
      </th>
    );
  });
  let entries = items && items.length !== 0 ? items : props?.items;
  const TableEntries = entries?.map((option, index) => {
    return (
      <tr
        key={index}
        className={`${
          index % 2 === 0 ? "bg-white" : "bg-gray-50"
        } flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 w-full`}
      >
        <td>
          <div className="flex pl-2 text-left flex-col justify-start items-start">
            <span className="text-lg">{option.title}</span>
            <button
              onClick={() =>
                dispatch(setPopOver({ view: true, item: props.items }))
              }
              type="button"
              className="animate-pulse text-blue-400 hover:text-blue-900 text-base font-semibold"
            >
              View Details
            </button>
          </div>
        </td>
        <td>
          <h4>
            ${option.price.pricePerUnit.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h4>
        </td>
        <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
          <input
            {...register(`${props.id}-${build}-${option.skuNumber}`)}
            type="number"
            step="1"
            min={0}
            className="focus:ring-blue-500 focus:border-blue-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300"
          />
        </td>
      </tr>
    );
  });
  return (
    <div className={`px-4 mt-2 h-56 `}>
      <div className="h-full overflow-auto object-contain ">
        <table className="relative h-full border rounded-lg w-full table-auto">
          <thead className="sticky z-10 top-0 bg-gray-100 border border-gray-300 rounded-lg">
            {TableHeaders}
          </thead>
          <tbody>{TableEntries}</tbody>
        </table>
      </div>
    </div>
  );
};

export default TraysTable;
