export default function Price({ value, currency }) {
  return (
    <p className="font-semibold text-green-700">
      {currency} $
      {parseFloat(value)
        ?.toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    </p>
  );
}
