import React, { Fragment } from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "10px",
    borderBottom: "0.7px solid #4F90BB",
  },
  headerContainer: {
    marginTop: 12,
  },
  billTo: {
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 36,
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "between",
  },
  invoiceDate: {
    fontSize: 12,
  },
  text: {
    fontSize: 9,
  },
  label: {
    width: 60,
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
  },
});

const BillTo = ({ invoice, orderDetails }) => (
  <View style={styles.container}>
    <View style={styles.headerContainer}>
      <Text style={styles.billTo}>BILL TO:</Text>
      <Text style={styles.text}>{orderDetails?.name}</Text>
      {orderDetails?.locations && (
        <Fragment>
          <Text style={styles.text}>{orderDetails.locations[0]?.lineOne},</Text>
          <Text style={styles.text}>
            {orderDetails.locations[0]?.city},
            {orderDetails.locations[0]?.postalCode}
          </Text>
        </Fragment>
      )}
    </View>
    <View style={styles.headerContainer}>
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>Invoice #</Text>
        <Text style={styles.invoiceDate}>{invoice?.number}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>DATE: </Text>
        <Text style={styles.invoiceDate}>
          {invoice?.dateIssued?.split("T")[0]}
        </Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>Currency: </Text>
        <Text>{invoice?.currency?.symbol}</Text>
      </View>
    </View>
  </View>
);

export default BillTo;
