/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { useQuery, gql } from "@apollo/client";

// COMPONENTS
import CardWHeader from "../../components/Card/wHeader";
import Table from "../../components/Table/multiLineWLink";

import { useCookies } from "react-cookie";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const SampleHeaders = [
  "ID",
  "Contact",
  "Funding",
  "Production Goal",
  "Created At",
];

const OPEN_LEADS = gql`
  query getLeads($mine: Boolean) {
    leads(status: "received", onlyMine: $mine) {
      id
      companyName
      crmId
      customerCrmId
      contactName
      email
      status
      phoneNumber
      productionGoal
      funding
      fundingCurrencySymbol
      createdAt
      customer {
        id
      }
      client {
        id
        salesTax {
          id
          provinceStateName
          taxPercentage
          provinceState
        }
      }
    }
  }
`;

const GET_LEAD_STATUS = gql`
  query leadStatus {
    leadStatus {
      name
      description
    }
  }
`;

const LeadsView = () => {
  const [cookies, setCookie] = useCookies();
  const [enabled, setEnabled] = useState(
    cookies.user?.user?.role?.name === "Sales Representative" ? true : false
  );
  const { loading, error, data, refetch } = useQuery(OPEN_LEADS, {
    variables: { mine: enabled },
    onError: (e) => alert(e),
  });
  const [showPopover, setShowPopover] = useState(false);
  const allStatus = useQuery(GET_LEAD_STATUS);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    refetch({ mine: enabled });
  }, [enabled]);
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leads | One Supply Connect</title>
      </Helmet>
      <Transition.Root show={status} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={status}
          onClose={setStatus}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-black text-gray-900"
                    >
                      Status Breakdown
                    </Dialog.Title>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center pt-2 ">
                  <ul className="ml-5 list-disc text-left">
                    {allStatus.data &&
                      allStatus.data.leadStatus.map((status) => (
                        <li className="my-1.5">
                          <span className="text-gray-900 font-bold text-sm">
                            {status.name}:
                          </span>
                          <span className="block text-gray-700 text-xs">
                            {status.description}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="flex flex-row items-end  justify-end mx-8 ">
        <Switch.Group as="div" className="flex items-center">
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
              enabled ? "bg-blue-600" : "bg-gray-200",
              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              )}
            />
          </Switch>
          <Switch.Label as="span" className="ml-3">
            <span className="text-sm font-medium text-gray-900">My Leads</span>
          </Switch.Label>
        </Switch.Group>
      </div>
      <CardWHeader
        heading={{
          title: "Received Leads",
        }}
        state={setShowPopover}
      >
        {!loading && (
          <Table
            headers={SampleHeaders}
            data={data.leads}
            linkTo="/estimate"
            setStatus={setStatus}
          />
        )}
      </CardWHeader>
    </div>
  );
};

export default LeadsView;
