import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";

export const SALES_REPS = gql`
  query users {
    users(roleCodename: "sales_rep") {
      firstName
      lastName
      id
    }
  }
`;

const CREATE_VENDOR = gql`
  mutation createVendor(
    $billingAddress: BillingAddressInput!
    $isDirectPayment: Boolean
    $name: String!
    $productManagerId: ID
    $ptcText: String
    $shippingAddress: ShippingAddressInput!
    $taxIndicator: String
    $website: String
  ) {
    createVendor(
      billingAddress: $billingAddress
      isDirectPayment: $isDirectPayment
      name: $name
      productManagerId: $productManagerId
      ptcText: $ptcText
      shippingAddress: $shippingAddress
      taxIndicator: $taxIndicator
      website: $website
    ) {
      vendor {
        id
      }
    }
  }
`;

const ClientView = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [repID, setRepID] = useState(null);
  const [sameBilling, setSameBilling] = useState(true);
  const salesReps = useQuery(SALES_REPS);
  const [createVendor] = useMutation(CREATE_VENDOR, {
    onCompleted: (d) => (window.location = "/vendors"),
    onError: (e) => alert(e),
  });

  async function handleCreate(data) {
    let billing = {
      lineOne: data["lineOne-billing"],
      countryCode: data["country-billing"],
      postalCode: data["postal-billing"],
      city: data["city-billing"],
      state: data["state-billing"],
    };
    let shipping = sameBilling
      ? billing
      : {
          lineOne: data["lineOne"],
          countryCode: data["country"],
          postalCode: data["postal"],
          city: data["city"],
          state: data["state"],
        };

    let body = {
      name: data.name,
      taxIndicator: data.taxIndicator,
      website: data.website,
      isDirectPayment: data.direct,
      productManagerId: repID,
      shippingAddress: shipping,
      billingAddress: billing,
    };
    createVendor({ variables: body });
  }
  return (
    <main
      className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clients | One Supply Connect</title>
      </Helmet>
      <form
        onSubmit={handleSubmit(handleCreate)}
        className="h-full bg-white p-8 overflow-y-auto"
      >
        <div>
          <div className="flex flex-row justify-between">
            <div>
              <h3 className="text-2xl font-semibold text-gray-900">
                Add New Vendor
              </h3>
              <p className="max-w-md text-gray-700 text-sm py-1">
                Utilize the following form to create a new vendor
              </p>
            </div>
            <div className="flex flex-col items-center justify-center ml-8">
              <a
                href="/vendors"
                className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Back
              </a>
            </div>
          </div>
        </div>
        <dl className=" border-b my-2 pb-4  border-gray-200 divide-y divide-gray-200 grid grid-cols-2">
          <div className="py-6 flex  flex-col justify-between text-sm font-medium">
            <dt className="text-xl text-gray-900"> Organization Details:</dt>

            <div className="grid grid-cols-3 gap-x-4 gap-y-1">
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Name:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("name", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.city ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Tax Indicator:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("taxIndicator", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.postal ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Website:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("website")}
                    type="url"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.state ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Product Manager:
                </label>
                <div className="mt-1">
                  <select
                    onChange={(e) => setRepID(e.target.value)}
                    className="block w-full pl-3 pr-10 py-1 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                  >
                    <option value="">Select...</option>
                    {salesReps.data &&
                      salesReps.data.users.map((entry) => (
                        <option key={entry.id} value={entry.id}>
                          {entry.firstName} {entry.lastName}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-span-1 flex flex-row items-end  justify-start gap-3 ">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Direct payment :
                </label>
                <div className="mt-1">
                  <input id="name" {...register("direct")} type="checkbox" />
                </div>
              </div>
            </div>
          </div>
        </dl>
        <dl className=" border-b my-2 pb-4  border-gray-200 divide-y divide-gray-200 grid grid-cols-2 gap-4">
          <div className="py-6 flex  flex-col justify-between text-sm font-medium">
            <dt className="text-xl text-gray-900">Addresses:</dt>
            <dt className="pt-1 text-lg text-gray-900">Billing:</dt>

            <div className="grid grid-cols-3 gap-x-4 gap-y-1">
              <div className="col-span-3">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Line One:
                </label>
                <Autocomplete
                  className="shadow-sm block w-full col-span-3 sm:text-sm border border-gray-300 rounded-md py-1 px-2"
                  apiKey={"AIzaSyDafwTPT_hOxljJ7qHBmaD0rCrYNAS5-jc"}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    setValue("lineOne-billing", e.target.value);
                  }}
                  onPlaceSelected={(place) => {
                    // setViewAddress(true);
                    let number = "";
                    for (const entry of place.address_components) {
                      let type = entry.types[0];
                      switch (type) {
                        case "locality":
                          setValue("city-billing", entry.long_name);
                          break;
                        case "administrative_area_level_1":
                          setValue("state-billing", entry.short_name);
                          break;
                        case "postal_code":
                          setValue("postal-billing", entry.long_name);
                          break;
                        case "country":
                          setValue("country-billing", entry.short_name);
                          break;
                        case "street_number":
                          number = entry.long_name;
                          break;
                        case "route":
                          setValue(
                            "lineOne-billing",
                            `${number}  ${entry.short_name}`
                          );
                          break;
                        default:
                          break;
                      }
                    }
                  }}
                  options={{
                    types: ["address"],
                  }}
                  placeholder="Enter A Street Address..."
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  City:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("city-billing", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.city ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Postal Code:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("postal-billing", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.postal ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  State:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("state-billing", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.state ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Country:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("country-billing", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.country ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
            </div>
            <dt className="pt-1 text-lg text-gray-900">Shipping:</dt>
            <div className="col-span-2 flex flex-row items-end  justify-start gap-3 ">
              <label
                htmlFor="description"
                className="block text-sm pt-2 text-gray-800"
              >
                Same As Billing:
              </label>
              <div className="mt-1">
                <input
                  defaultChecked={sameBilling}
                  onChange={(e) => setSameBilling(e.target.checked)}
                  type="checkbox"
                />
              </div>
            </div>
          </div>
          {!sameBilling && (
            <div className="py-6 flex  flex-col justify-start text-sm font-medium">
              <dt className="mt-8 text-lg text-gray-900">Shipping:</dt>

              <div className="grid grid-cols-3 gap-x-4 gap-y-1">
                <div className="col-span-3">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Line One:
                  </label>
                  <Autocomplete
                    className="shadow-sm block w-full col-span-3 sm:text-sm border border-gray-300 rounded-md py-1 px-2"
                    apiKey={"AIzaSyDafwTPT_hOxljJ7qHBmaD0rCrYNAS5-jc"}
                    style={{ width: "90%" }}
                    onChange={(e) => {
                      setValue("lineOne", e.target.value);
                    }}
                    onPlaceSelected={(place) => {
                      // setViewAddress(true);
                      let number = "";
                      for (const entry of place.address_components) {
                        let type = entry.types[0];
                        switch (type) {
                          case "locality":
                            setValue("city", entry.long_name);
                            break;
                          case "administrative_area_level_1":
                            setValue("state", entry.short_name);
                            break;
                          case "postal_code":
                            setValue("postal", entry.long_name);
                            break;
                          case "country":
                            setValue("country", entry.short_name);
                            break;
                          case "street_number":
                            number = entry.long_name;
                            break;
                          case "route":
                            setValue(
                              "lineOne",
                              `${number}  ${entry.short_name}`
                            );
                            break;
                          default:
                            break;
                        }
                      }
                    }}
                    options={{
                      types: ["address"],
                    }}
                    placeholder="Enter A Street Address..."
                  />
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    City:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("city", { required: true })}
                      type="text"
                      className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                        errors.city ? "border-red-600" : "border-gray-300 "
                      } rounded-md py-1 px-2`}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Postal Code:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("postal", { required: true })}
                      type="text"
                      className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                        errors.postal ? "border-red-600" : "border-gray-300 "
                      } rounded-md py-1 px-2`}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    State:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("state", { required: true })}
                      type="text"
                      className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                        errors.state ? "border-red-600" : "border-gray-300 "
                      } rounded-md py-1 px-2`}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Country:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("country", { required: true })}
                      type="text"
                      className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                        errors.country ? "border-red-600" : "border-gray-300 "
                      } rounded-md py-1 px-2`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </dl>
        <div className="flex flex-row justify-end gap-4">
          <button
            type="submit"
            className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Vendor
          </button>
        </div>
      </form>
    </main>
  );
};

export default ClientView;
