/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";

import { INVOICE, LOG_PAYMENT } from "./queries";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Headers = ["Order Details", "Date Received", "Order Total", "Due"];
const InvoiceView = () => {
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedLines, setSelectedLines] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  let { id } = useParams();
  const [getInvoice, invoiceResponse] = useLazyQuery(INVOICE, {
    onCompleted: (d) => handleInvoice(d.commissionDueInvoice),
    onError: (e) => alert(e),
  });

  const [logPayments] = useMutation(LOG_PAYMENT, {
    onCompleted: (d) => (window.location = "/invoices"),
    onError: (e) => alert(e),
  });
  useEffect(() => {
    if (id) getInvoice({ variables: { id } });
  }, [id]);

  async function handleInvoice(data) {
    setInvoiceDetails(data);
    setLineItems(data.lineItems);
    setSelectedLines(data.lineItems);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }
  useLayoutEffect(() => {
    const isIndeterminate =
      selectedLines.length > 0 && selectedLines.length < lineItems.length;
    setChecked(selectedLines.length === lineItems.length);
    setIndeterminate(isIndeterminate);
  }, [selectedLines]);

  async function handlePayments() {
    setSubmitting(true);
    const ids = selectedLines.map((l) => l.id);
    logPayments({
      variables: {
        id,
        lines: ids,
      },
    });
  }
  if (invoiceResponse.loading) {
    return (
      <div className="flex flex-col items-center justify-center p-8 max-w-6xl mx-auto w-full">
        <svg
          className="animate-spin h-12 w-12 text-blue-600 self-center"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40 40"
          enableBackground="new 0 0 40 40"
          xml="preserve"
        >
          <path
            opacity="0.2"
            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
          />
          <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
        </svg>
      </div>
    );
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="py-4 px-4 sm:px-6 lg:px-8 grid grid-cols-3">
        <div className="flex flex-col col-span-2">
          <div class="flex flex-col md:grid md:grid-cols-2 gap-12">
            <div class="flex flex-col">
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Invoice Number:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {invoiceDetails?.number}
                </span>
              </p>
              <p className=" font-medium text-gray-500 flex flex-row items-center justify-between">
                Currency:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {invoiceDetails?.currency?.symbol}
                </span>
              </p>{" "}
            </div>
            <div class="flex flex-col">
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Issued On:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {invoiceDetails?.dateIssued?.split("T")[0]}
                </span>
              </p>
              <p className=" font-medium text-gray-500  flex flex-row items-center justify-between">
                Due On:{" "}
                <span className="px-4 py-2 inline-flex leading-5 font-semibold rounded-full">
                  {invoiceDetails?.dueDate?.split("T")[0]}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-end">
          <a
            type="button"
            href="/invoices"
            className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />

            <span>Back</span>
          </a>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative w-12 px-6 sm:w-16 sm:px-8"
                    ></th>
                    {Headers.map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {lineItems.map((item, index) => (
                    <tr
                      key={index}
                      className={
                        selectedLines.includes(item) ? "bg-gray-50" : undefined
                      }
                    >
                      <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                        {selectedLines.includes(item) && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                        )}
                        <input
                          type="checkbox"
                          className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 sm:left-6"
                          value={item.email}
                          checked={selectedLines.includes(item)}
                          onChange={(e) =>
                            setSelectedLines(
                              e.target.checked
                                ? [...selectedLines, item]
                                : selectedLines.filter((p) => p !== item)
                            )
                          }
                        />
                      </td>
                      <td
                        className={classNames(
                          "whitespace-nowrap py-4 pr-3 text-sm flex flex-col",
                          selectedLines.includes(item)
                            ? "text-blue-600"
                            : "text-gray-900"
                        )}
                      >
                        <span className="font-bold">
                          {item.paymentTransaction?.order?.client?.name}
                        </span>
                        EST#{" "}
                        {
                          item.paymentTransaction?.order?.selectedEstimate
                            ?.number
                        }
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.paymentTransaction?.receivedDate?.split("T")[0]}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        ${" "}
                        {Number.parseFloat(item.orderSubtotal)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        ${" "}
                        {Number.parseFloat(item.commissionDue)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="py-4  flex flex-row items-end justify-end mt-2 border-t border-gray-300 mb-24 ">
        <div className="flex flex-row items-end justify-end">
          <button
            type="button"
            onClick={() => handlePayments()}
            className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            {submitting && (
              <svg
                className="animate-spin h-5 w-5 text-white self-center mr-2"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                enableBackground="new 0 0 40 40"
                xml="preserve"
              >
                <path
                  opacity="0.2"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
              </svg>
            )}
            <span>Confirm Payment Received</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;
