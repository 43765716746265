import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "start",
    fontStyle: "bold",
    marginTop: "6px",
  },
  description: {
    width: "50%",
    textAlign: "left",
    paddingLeft: 8,
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "10px",
    fontFamily: "Helvetica-Bold",
  },
  promo: {
    fontSize: "9px",
    fontFamily: "Helvetica-Bold",
    color: "#0aa12f",
  },
  qty: {
    width: "10%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: "9px",
    paddingTop: "2px",
  },
  rate: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: "9px",
    paddingTop: "2px",
  },
  amount: {
    width: "20%",
    textAlign: "right",
    paddingRight: 8,
    fontSize: "9px",
    paddingTop: "2px",
  },
  bom: {
    display: "flex",
    flexDirection: "row",
  },
  bomText: {
    fontSize: 9,
    lineHeight: 1.1,
  },
});

const InvoiceTableRow = ({ products, items }) => {
  items =
    items &&
    [...items]?.sort((a, b) => parseInt(a.lineNumber) - parseInt(b.lineNumber));
  const rows = items?.map((item, index) => {
    const isTray =
      item.item.productGroups && item.item.productGroups[0]?.name === "Tray";
    const productID = item.productUuid;
    const parsedProducts = JSON.parse(products);
    let product = parsedProducts && parsedProducts[productID];
    if (item.item.title === "SanityCo Training") return null;
    if (item.item.title === "G80 Install") return null;
    return (
      <View style={styles.row} key={index}>
        <View style={styles.description}>
          <Text style={styles.title}>
            {item.item.title}
            {isTray &&
              product &&
              ` [${product?.container_type}-${product?.amount}${
                product?.container_type !== "cartridges" ? "ml" : "g"
              }]`}
          </Text>
          {item.item?.accountingDescription && (
            <Text style={styles.bomText}>
              {item.item?.accountingDescription}
            </Text>
          )}
          {item.appliedPromotion && (
            <Text style={styles.promo}>
              PROMO: {item.appliedPromotion.name}
            </Text>
          )}
        </View>
        <Text style={styles.qty}>{item.itemQuantity}</Text>
        <View style={styles.rate}>
          <Text>
            {Number.parseFloat(item.costPerUnit)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Text>
        </View>
        <Text style={styles.amount}>
          {Number.parseFloat(item.costPerUnit * item.itemQuantity)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
      </View>
    );
  });
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
