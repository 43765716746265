import React from "react";
import Sales from "./sales";

const HomePanels = ({ user, year, month, update, setUpdate }) => {
  const components = {
    "Sales Representative": (
      <Sales
        year={year}
        month={month}
        update={update}
        setUpdate={setUpdate}
        user={user}
      />
    ),
    Executive: (
      <Sales
        year={year}
        month={month}
        update={update}
        setUpdate={setUpdate}
        user={user}
      />
    ),
  };
  const defaultPanel = <div>Pending</div>;
  return components[user] || defaultPanel;
};

export default HomePanels;
