import { gql } from "@apollo/client";

export const CREATE_LEAD = gql`
  mutation createLead($input: LeadInput!) {
    createLead(leadData: $input) {
      lead {
        id
      }
    }
  }
`;

export const CREATE_ESTIMATE = gql`
  mutation createEstimate(
    $estimateData: EstimateInput!
    $customerId: ID
    $clientId: ID
    $shippingId: ID
    $cost: Decimal
  ) {
    createEstimate(
      isSparePartsOrder: true
      estimateData: $estimateData
      customerId: $customerId
      clientId: $clientId
      clientShippingAddressId: $shippingId
      clientBillingAddressId: $shippingId
      shippingCost: $cost
    ) {
      estimate {
        number
        email
        taxPercentage
        dueDate
        dataJson
        memo
        lineItems {
          lineNumber
          pricePerUnit
          itemQuantity
        }
      }
      leadId
      orderId
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation addLocation(
    $addressData: AddressInput!
    $customer: ID
    $client: ID!
  ) {
    addLocationToClient(
      addressData: $addressData
      customerId: $customer
      clientId: $client
    ) {
      locationId
    }
  }
`;

export const SPARE_ORDERS = gql`
  query allOrders(
    $skip: Int
    $first: Int
    $mine: Boolean
    $order: String
    $idS: ID
    $orgS: String
    $estimateS: String
    $statusS: [String]
    $uuidSearch: ID
  ) {
    orders(
      salesType: "spare_parts"
      skip: $skip
      first: $first
      onlyMine: $mine
      orderBy: $order
      idSearch: $idS
      organizationName: $orgS
      selectedEstimateNumber: $estimateS
      statuses: $statusS
      uuidSearch: $uuidSearch
    ) {
      id
      currency
      client {
        id
      }
      currentStatus {
        name
      }
      paymentTransactions {
        amount
      }
      hasInvoice
      createdAt
      dueDate
      vendorOrderNumber
      # revenueReport {
      #   fullCommission
      # }
      lead {
        companyName
        crmId
      }
      createdBy {
        firstName
        lastName
      }
      selectedEstimate {
        number
        total
        taxPercentage
      }
      vendors {
        name
      }
    }
  }
`;
//TODO
export const CUSTOM_ORDERS = gql`
  query allOrders(
    $skip: Int
    $first: Int
    $mine: Boolean
    $order: String
    $idS: ID
    $orgS: String
    $estimateS: String
    $statusS: [String]
    $shippingOnly: Boolean
    $uuidSearch: ID
  ) {
    orders(
      salesType: "custom"
      skip: $skip
      first: $first
      onlyMine: $mine
      orderBy: $order
      idSearch: $idS
      organizationName: $orgS
      selectedEstimateNumber: $estimateS
      statuses: $statusS
      manualShippingRequired: $shippingOnly
      uuidSearch: $uuidSearch
    ) {
      id
      currency
      client {
        id
      }
      currentStatus {
        name
      }
      paymentTransactions {
        amount
      }
      hasInvoice
      expectedCloseDate
      createdAt
      dueDate
      vendorOrderNumber
      # revenueReport {
      #   fullCommission
      # }
      lead {
        companyName
        crmId
      }
      createdBy {
        firstName
        lastName
      }
      selectedEstimate {
        number
        total
        taxPercentage
      }
      predictedEstimate {
        total
      }
      vendors {
        name
      }
    }
  }
`;

export const VENDOR_ORDERS = gql`
  query allOrders(
    $skip: Int
    $first: Int
    $mine: Boolean
    $order: String
    $idS: ID
    $orgS: String
    $estimateS: String
    $statusS: [String]
    $vendor: ID
    $shippingOnly: Boolean
    $uuidSearch: ID
  ) {
    orders(
      skip: $skip
      first: $first
      orderBy: $order
      idSearch: $idS
      organizationName: $orgS
      selectedEstimateNumber: $estimateS
      statuses: $statusS
      vendorId: $vendor
    ) {
      id
      currency
      client {
        id
        name
      }
      currentStatus {
        name
      }
      paymentTransactions {
        amount
      }
      hasInvoice
      expectedCloseDate
      createdAt
      dueDate
      vendorOrderNumber
      lead {
        companyName
        crmId
      }
      createdBy {
        firstName
        lastName
      }
      selectedEstimate {
        number
        total
        taxPercentage
      }
      predictedEstimate {
        total
      }
      vendors {
        name
      }
    }
  }
`;

export const USER = gql`
  query user {
    user {
      vendor {
        id
      }
    }
  }
`;
