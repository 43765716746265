import { useEffect } from "react";

export default function Simple({
  label,
  methods,
  id,
  registerValue,
  defaultValue,
  registerOptions,
  options,
}) {
  const {
    register,
    setValue,
    formState: { errors },
  } = methods;
  const Options = options?.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  useEffect(() => {
    if (defaultValue) setValue(registerValue, defaultValue);
  }, []);

  return (
    <div>
      <label
        htmlFor={registerValue}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <p className="mt-2 text-sm text-red-600">
        {errors[registerValue] && "Please Select an option "}
      </p>
      <div className="mt-1">
        <select
          id={id}
          {...register(registerValue, registerOptions)}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border ${
            errors[registerValue] ? "border-red-600" : "border-gray-300"
          } focus:outline-none sm:text-sm rounded-md`}
          defaultValue=""
        >
          <option value="">Select.... </option>
          {Options}
        </select>
      </div>
    </div>
  );
}
