import React, { useState, Fragment } from "react";
import { useMutation, gql } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { setManualProgression } from "../hooks/saleSlice";
import { XIcon } from "@heroicons/react/outline";
import { Switch, Listbox, Transition } from "@headlessui/react";
import {
  PlusCircleIcon,
  CheckIcon,
  SelectorIcon,
} from "@heroicons/react/solid";

import ResolveUndefined from "../../global/utils/manipulate/undefined";

//COOKIES
import { useCookies } from "react-cookie";

const REVIEW_ORDER = gql`
  mutation ReviewOrder(
    $id: String!
    $selectedEstimate: ID!
    $shipping: ShippingAddressInput
    $billing: BillingAddressInput
    $signer: String
    $taxNumber: String
    $billingID: ID
    $shippingID: ID
  ) {
    reviewOrder(
      orderUuid: $id
      selectedEstimateId: $selectedEstimate
      billingAddress: $billing
      shippingAddress: $shipping
      signer: $signer
      taxNumber: $taxNumber
      preexistingBillingAddressId: $billingID
      preexistingShippingAddressId: $shippingID
    ) {
      status
    }
  }
`;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

let scheduleData = [
  {
    name: "Sunday",
    open: false,
    start: "00:00",
    end: "00:00",
  },
  {
    name: "Monday",
    open: true,
    start: "09:00",
    end: "17:00",
  },
  {
    name: "Tuesday",
    open: true,
    start: "09:00",
    end: "17:00",
  },
  {
    name: "Wednesday",
    open: true,
    start: "09:00",
    end: "17:00",
  },
  {
    name: "Thursday",
    open: true,
    start: "09:00",
    end: "17:00",
  },
  {
    name: "Friday",
    open: true,
    start: "09:00",
    end: "17:00",
  },
  {
    name: "Saturday",
    open: false,
    start: "00:00",
    end: "00:00",
  },
];
const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const phoneRegex = RegExp(/^[0-9*#+]+$/);

const accountBreakdown = [
  {
    name: "Full Name",
    key: "contact_name",
    type: "text",
    default: "contactName",
  },
  {
    name: "Email",
    key: "email",
    type: "email",
    regex: emailRegex,
    default: "email",
  },
  {
    name: "Phone Number",
    key: "phone_number",
    type: "text",
    regex: phoneRegex,
    default: "phoneNumber",
  },
  {
    name: "Manager Name",
    key: "manager_name",
    type: "text",
  },
  {
    name: "Manager Number",
    key: "manager_number",
    type: "text",
  },
  {
    name: "Tax ID",
    key: "taxID",
    type: "text",
  },
];

const shipping = [
  { name: "Street Address", key: "street_address" },
  { name: "Apartment or Unit", key: "unit", optional: true },
  { name: "City", key: "city", id: "locality" },
  { name: "State/Province", key: "state" },
  { name: "Postal Code", key: "postal_code" },
  { name: "Country/Region", key: "country" },
];

const billing = [
  { name: "Street Address", key: "billing_street_address" },
  { name: "Apartment or Unit", key: "billing_unit", optional: true },
  { name: "City", key: "billing_city" },
  { name: "State/Province", key: "billing_state" },
  { name: "Postal Code", key: "billing_postal_code" },
  { name: "Country/Region", key: "billing_country" },
];

const ManualProgression = ({ data, refetch }) => {
  const dispatch = useDispatch();
  const {
    register,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [setAddressView, setViewAddress] = useState(false);
  const [identicalBilling, setIdenticalBilling] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [schedule, setSchedule] = useState(scheduleData);
  const [selectedAddress, setSelected] = useState(null);
  const [cookies] = useCookies();
  const [reviewOrder] = useMutation(REVIEW_ORDER, {
    onCompleted: (d) => {
      refetch();
      setSubmitting(false);
      dispatch(setManualProgression(false));
    },
    onError: (e) => alert(e),
  });

  async function handleProgress(body) {
    setSubmitting(true);
    let filteredData = Object.fromEntries(
      Object.entries(body).filter(
        ([_, entry]) => entry !== null && entry !== "" && entry !== "0"
      )
    );
    if (!filteredData.country && !selectedAddress?.id) {
      setAddressError(true);
      return;
    }
    let hours = {};
    for (const entry of schedule) {
      let start = entry.start.split(":");
      start[0] = parseInt(start[0]);
      if (start[0] > 12) {
        start[0] -= 12;
        start[2] = "PM";
      } else if (start[0] === 0) {
        start[0] = 12;
        start[2] = "AM";
      } else {
        start[2] = "AM";
      }
      start = `${start[0]}:${start[1]}${start[2]}`;
      let end = entry.end.split(":");
      end[0] = parseInt(end[0]);
      if (end[0] > 12) {
        end[0] -= 12;
        end[2] = "PM";
      } else if (end[0] === 0) {
        end[0] = 12;
        end[2] = "AM";
      } else {
        end[2] = "AM";
      }
      end = `${end[0]}:${end[1]}${end[2]}`;
      hours[entry.name.toLowerCase() + "Start"] = start;
      hours[entry.name.toLowerCase() + "End"] = end;
    }
    let variables = {};
    if (selectedAddress?.id) {
      variables.billingID = selectedAddress.id;
      variables.shippingID = selectedAddress.id;
    } else {
      let billingAddress = {
        lineOne: identicalBilling
          ? `${filteredData.unit ? filteredData.unit : ""} ${
              filteredData.street_address
            }`
          : `${filteredData.billing_unit ? filteredData.billing_unit : ""} ${
              filteredData.billing_street_address
            }`,
        countryCode: identicalBilling
          ? filteredData.country
          : filteredData.billing_country,
        postalCode: identicalBilling
          ? filteredData.postal_code
          : filteredData.billing_postal_code,
        city: identicalBilling ? filteredData.city : filteredData.billing_city,
        state: identicalBilling
          ? filteredData.state
          : filteredData.billing_state,
        isPrimary: true,
      };
      let shippingAddress = {
        lineOne: `${filteredData.unit ? filteredData.unit : ""} ${
          filteredData.street_address
        }`,
        countryCode: filteredData.country,
        postalCode: filteredData.postal_code,
        city: filteredData.city,
        state: filteredData.state,
        isPrimary: true,
        hasLoadingDock: true,
        specialInstructions: "",
        receiverName: filteredData.manager_name,
        receiverPhoneNumber: filteredData.manager_number,
      };
      shippingAddress.hoursOfOperations = hours;
      variables.billing = billingAddress;
      variables.shipping = shippingAddress;
    }

    let queryBody = {
      id: data.linkUuid,
      selectedEstimate: filteredData.estimate,
      billing: variables?.billing,
      shipping: variables?.shipping,
      billingID: variables?.billingID,
      shippingID: variables?.shippingID,
      taxNumber: filteredData?.taxNumber,
      signer: `${cookies.user?.user?.firstName} ${cookies.user?.user?.lastName}`,
    };
    reviewOrder({ variables: queryBody });
  }
  return (
    <form
      onSubmit={handleSubmit(handleProgress)}
      className={`bg-white overflow-hidden  border-t border-gray-300  mx-8 mt-6 ${
        submitting && "opacity-50"
      }`}
    >
      <div className="flex flex-row justify-between mr-4 mt-2">
        <div>
          <h3 className="text-lg py-1 font-medium text-left">
            Manual Progression:
          </h3>
          <p className="text-sm text-gray-500 pb-1 ">
            <span className="text-red-700">
              You are accepting the estimate on their behalf.{" "}
            </span>
            Ensure Client consent is taken for the manual progression of the
            order to avoid issues.
          </p>
        </div>
        <button
          type="button"
          onClick={() => dispatch(setManualProgression(false))}
        >
          <XIcon className="h-6 w-6 text-red-700" aria-hidden="true" />
        </button>
      </div>
      <div className="grid grid-cols-3 gap-8">
        <div className="w-full border-r pr-8  border-blue-200">
          <h3 className="text-lg  tracking-tight font-bold text-gray-900 text-left mt-8">
            Selected Estimate
          </h3>
          <fieldset className="mt-2">
            <legend className="sr-only">Bank account</legend>
            <div className="divide-y divide-gray-200">
              {data.estimates?.map((est, estIdx) => (
                <div key={estIdx} className="relative flex items-start py-4">
                  <div className="min-w-0 flex-1 text-sm">
                    <label className="font-medium text-gray-700">
                      {est.number}
                    </label>
                    <p className="text-green-700">
                      $
                      {parseFloat(est.total)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    </p>
                    <p className="text-gray-700">
                      {est.createdAt.split("T")[0]}
                    </p>
                  </div>{" "}
                  <div className="ml-3 flex items-center h-5">
                    <input
                      {...register("estimate", {
                        required: "An Input is required",
                      })}
                      type="radio"
                      value={est.id}
                      className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-400"
                    />
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
        </div>
        <div className="block col-span-2">
          <h3 className="text-lg  tracking-tight font-bold text-gray-900 text-left mt-8">
            Shipping Details:
          </h3>
          <div className="bg-white overflow-hidden my-2 max-w-7xl mr-auto">
            <div className="grid grid-cols-2  xl:grid-cols-3 gap-3 pb-5 sm:pb-6">
              {accountBreakdown.map((entry, index) => (
                <div key={index} className="col-span-1">
                  <label
                    htmlFor="email_address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {entry.name}
                  </label>
                  <div className="relative mt-1">
                    <input
                      type={entry.type}
                      name={entry.key}
                      autoComplete={entry.key}
                      defaultValue={data.lead[entry.default] || null}
                      className={`shadow-sm p-2 block w-full sm:text-sm  border ${
                        errors[entry.key] ? "border-red-600" : "border-gray-300"
                      } focus:outline-none focus:border-atgBlue rounded-xl`}
                      {...register(entry.key, {
                        required: "An Input is required",
                      })}
                      onBlur={(e) => {
                        if (entry.regex) {
                          if (!entry.regex.test(e.target.value)) {
                            setError(entry.key, {
                              type: "manual",
                              message: `Invalid Input`,
                            });
                          } else {
                            clearErrors(entry.key);
                          }
                        }
                      }}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className={`${
                          errors[entry.key] ? "visible" : "hidden"
                        } h-5 w-5 text-red-500`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  {errors[entry.key] && (
                    <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
                      {errors[entry.key].message}
                    </p>
                  )}
                </div>
              ))}
              {data.client && data.client.locations && (
                <div className="col-span-2">
                  <Listbox value={selectedAddress} onChange={setSelected}>
                    {({ open }) => (
                      <>
                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                          Select Address
                        </Listbox.Label>
                        <div className="relative">
                          <Listbox.Button
                            className={`bg-white relative w-full  border  ${
                              addressError && !selectedAddress
                                ? "border-red-600"
                                : "border-gray-300"
                            } rounded-xl shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                          >
                            <span className="block truncate">
                              {selectedAddress?.lineOne || "Choose Address"}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-xl py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              {data.client.locations.map((location) => (
                                <Listbox.Option
                                  key={location.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "text-white bg-blue-600"
                                        : "text-gray-900",
                                      "cursor-default select-none relative py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={location}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {ResolveUndefined(location.lineOne)},{" "}
                                        {location.city},{location.postalCode}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-blue-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                              <Listbox.Option
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-blue-600"
                                      : "text-green-700",
                                    "cursor-default select-none relative flex flex-row py-2 pl-3 pr-9"
                                  )
                                }
                                value="new"
                              >
                                <PlusCircleIcon
                                  className="-ml-1 mr-2 h-5 w-5"
                                  aria-hidden="true"
                                />
                                Add a new location
                              </Listbox.Option>
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              )}
              {(!data.client || selectedAddress === "new") && (
                <div className="col-span-2">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    New Address
                  </label>
                  <Autocomplete
                    className={`shadow-sm p-2 block w-full sm:text-sm  border ${
                      addressError ? "border-red-600" : "border-gray-300"
                    } focus:outline-none focus:border-atgBlue rounded-xl`}
                    apiKey={"AIzaSyDafwTPT_hOxljJ7qHBmaD0rCrYNAS5-jc"}
                    onPlaceSelected={(place) => {
                      setViewAddress(true);
                      let number = "";
                      for (const entry of place.address_components) {
                        let type = entry.types[0];
                        switch (type) {
                          case "locality":
                            document.querySelector("#locality").value =
                              entry.long_name;
                            setValue("city", entry.long_name);
                            break;
                          case "administrative_area_level_1":
                            document.querySelector("#state").value =
                              entry.short_name;
                            setValue("state", entry.short_name);
                            break;
                          case "postal_code":
                            document.querySelector("#postal_code").value =
                              entry.long_name;
                            setValue("postal_code", entry.long_name);
                            break;
                          case "country":
                            document.querySelector("#country").value =
                              entry.short_name;
                            setValue("country", entry.short_name);
                            break;
                          case "street_number":
                            number = entry.long_name;
                            break;
                          case "route":
                            document.querySelector(
                              "#street_address"
                            ).value = `${number}  ${entry.long_name}`;
                            setValue(
                              "street_address",
                              `${number}  ${entry.short_name}`
                            );
                            break;
                          default:
                            break;
                        }
                      }
                    }}
                    options={{
                      types: ["address"],
                    }}
                    placeholder="Enter A Street Address..."
                  />
                </div>
              )}
            </div>
            {setAddressView && (!data.client || selectedAddress === "new") && (
              <Fragment>
                <div className="grid grid-cols-2 xl:grid-cols-3 gap-3  pb-5 sm:pb-6">
                  <div className="col-span-2 xl:col-span-3">
                    <h3 className="text-lg  tracking-tight font-bold text-gray-900 text-left ">
                      Confirm Shipping Address:
                    </h3>
                  </div>
                  {shipping.map((entry, index) => (
                    <div key={index} className="col-span-1">
                      <label
                        htmlFor="email_address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {entry.name}
                      </label>
                      <div className="relative mt-1">
                        <input
                          type="text"
                          name={entry.key}
                          id={entry.id ? entry.id : entry.key}
                          autoComplete={entry.key}
                          className={`shadow-sm p-2 block w-full sm:text-sm  border ${
                            errors[entry.key]
                              ? "border-red-600"
                              : "border-gray-300"
                          } focus:outline-none focus:border-atgBlue rounded-xl`}
                          {...register(entry.key, {
                            required: !entry.optional && "An Input is required",
                          })}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <svg
                            className={`${
                              errors[entry.key] ? "visible" : "hidden"
                            } h-5 w-5 text-red-500`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                      {errors[entry.key] && (
                        <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
                          {errors[entry.key].message}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
                <div className="pb-5 sm:pb-6 ">
                  <h3 className="flex flex-row items-center gap-4">
                    <span className="text-lg  tracking-tight font-bold text-gray-900 text-left ">
                      Billing Address:
                    </span>
                    <div>
                      <input
                        type="checkbox"
                        checked={identicalBilling}
                        onChange={(e) => setIdenticalBilling(e.target.checked)}
                      />
                      <label className="ml-2 text-gray-600">
                        Same as Shipping Address
                      </label>
                    </div>
                  </h3>
                  {!identicalBilling && (
                    <div className="grid grid-cols-2 xl:grid-cols-3 gap-3 mt-2  pt-0 pr-4 sm:pr-6 ">
                      {billing.map((entry, index) => (
                        <div key={index} className="col-span-1">
                          <label
                            htmlFor="email_address"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {entry.name}
                          </label>
                          <div className="relative mt-1">
                            <input
                              type="text"
                              name={entry.key}
                              autoComplete={entry.key}
                              className={`shadow-sm p-2 block w-full sm:text-sm  border ${
                                errors[entry.key]
                                  ? "border-red-600"
                                  : "border-gray-300"
                              } focus:outline-none focus:border-atgBlue rounded-xl`}
                              {...register(entry.key, {
                                required:
                                  !entry.optional && "An Input is required",
                              })}
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <svg
                                className={`${
                                  errors[entry.key] ? "visible" : "hidden"
                                } h-5 w-5 text-red-500`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          </div>
                          {errors[entry.key] && (
                            <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
                              {errors[entry.key].message}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Fragment>
            )}
            <div className=" col-span-1 pb-5 sm:pb-6">
              <h3 className="text-lg  tracking-tight font-bold text-gray-900 text-left">
                Shipping Availability:
              </h3>
              {schedule.map((entry, index) => (
                <div key={index} className="grid grid-cols-3 pt-2">
                  <h4 className="text-base text-gray-500">{entry.name}</h4>
                  <Switch.Group as="div" className="flex items-center">
                    <Switch
                      checked={entry.open}
                      onChange={() =>
                        setSchedule(
                          schedule.map((e, i) =>
                            i === index ? { ...e, open: !entry.open } : e
                          )
                        )
                      }
                      className={classNames(
                        entry.open ? "bg-blue-600" : "bg-gray-200",
                        "relative inline-flex flex-shrink-0 h-6 w-11  border border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          entry.open ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3 text-right">
                      <span className="text-sm text-gray-500 ">
                        {entry.open ? "Open" : "Closed"}
                      </span>
                    </Switch.Label>
                  </Switch.Group>
                  {entry.open && (
                    <div className="col-span-3 lg:col-span-1 gap-2 flex flex-row items-center pt-2 pb-4  lg:pb-0">
                      <input
                        type="time"
                        defaultValue={entry.start}
                        onChange={(entry) =>
                          setSchedule(
                            schedule.map((e, i) =>
                              i === index
                                ? { ...e, start: entry.target.value }
                                : e
                            )
                          )
                        }
                        className=" px-2 block w-full sm:text-sm border-b border-gray-300 rounded-lg focus:outline-none focus:border-atgBlue "
                      />
                      <p>-</p>
                      <input
                        type="time"
                        defaultValue={entry.end}
                        onChange={(entry) =>
                          setSchedule(
                            schedule.map((e, i) =>
                              i === index
                                ? { ...e, end: entry.target.value }
                                : e
                            )
                          )
                        }
                        className=" px-2 block w-full sm:text-sm border-b border-gray-300  rounded-lg focus:outline-none focus:border-atgBlue"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className=" items-end flex flex-row justify-end">
        <button
          type="submit"
          className="mb-2 mr-4 inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md text-gray-50 bg-green-500 hover:bg-green-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
        >
          {submitting && (
            <svg
              className="animate-spin h-5 w-5 text-white self-center mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              enableBackground="new 0 0 40 40"
              xml="preserve"
            >
              <path
                opacity="0.2"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
            </svg>
          )}
          submit
        </button>
      </div>
    </form>
  );
};
export default ManualProgression;
