/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import {
  setProvince,
  setShipProvince,
} from "../../../hooks/estimate/estimateSlice";
import { CAD_STATES, US_STATES } from "../../../mock/states";

//COOKIES
import { useCookies } from "react-cookie";

const SALES_TAX = gql`
  query salesTaxes {
    salesTaxes {
      id
      provinceState
      provinceStateName
      taxPercentage
    }
  }
`;

const ValidateCompany = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["user"]);
  const [taxId, setTaxID] = useState(null);
  const [taxValue, setTaxValue] = useState(null);
  const [shippingState, setShippingState] = useState(null);
  const [list, setList] = useState([]);
  const salesTaxQuery = useQuery(SALES_TAX, {
    onCompleted: (d) => {
      const [CadLocations, OtherLocations] = d.salesTaxes.reduce(
        ([p, f], e) =>
          e.taxPercentage !== "0.00000" ? [[...p, e], f] : [p, [...f, e]],
        [[], []]
      );
      if (cookies.lead?.fundingCurrencySymbol === "CAD") setList(CadLocations);
      else setList(OtherLocations);
    },
  });
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => setOpen(true)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-3">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Select a Location
                  </Dialog.Title>
                  <div className="mt-4 flex flex-row gap-4 items-end justify-between">
                    <div className="w-2/3">
                      <label className="block text-sm text-left mb-0.5 font-medium text-gray-700">
                        Billing Location:
                      </label>
                      <select
                        onChange={(e) => {
                          setTaxID(e.target.value.split("|")[1]);
                          setTaxValue(e.target.value.split("|")[0]);
                          setShippingState(
                            cookies.lead?.fundingCurrencySymbol === "CAD"
                              ? CAD_STATES[e.target.value.split("|")[2]]
                              : US_STATES[e.target.value.split("|")[2]]
                          );
                        }}
                        className="focus:ring-blue-500 focus:border-blue-500 font-medium  w-full text-left rounded-md sm:text-sm border py-1.5  px-2 border-gray-300"
                      >
                        <option value="">Select...</option>
                        {list.map((entry) => (
                          <option
                            key={entry.id}
                            value={`${entry.provinceState}-${entry.taxPercentage}|${entry.provinceState}|${entry.provinceStateName}`}
                          >
                            {entry.provinceStateName}
                            {" | "} {parseFloat(entry.taxPercentage) * 100}
                            {"%"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="mt-4 flex flex-row gap-4 items-end justify-between">
                    {taxId !== "WLD" && (
                      <div className="w-2/3">
                        <label className="block text-sm text-left mb-0.5 font-medium text-gray-700">
                          Shipping Location:
                        </label>
                        <select
                          value={shippingState}
                          onChange={(e) => setShippingState(e.target.value)}
                          className="focus:ring-blue-500 focus:border-blue-500 font-medium  w-full text-left rounded-md sm:text-sm border py-1.5  px-2 border-gray-300"
                        >
                          <option value="">Select...</option>
                          {(cookies.lead?.fundingCurrencySymbol === "CAD"
                            ? Object.entries(CAD_STATES)
                            : Object.entries(US_STATES)
                          ).map(([key, entry], index) => (
                            <option key={index} value={entry}>
                              {key}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className="">
                      <button
                        type="button"
                        onClick={() => {
                          dispatch(setProvince(taxValue));
                          dispatch(setShipProvince(shippingState));
                          setOpen(false);
                        }}
                        className="inline-flex justify-center w-full rounded-md border-2 border-transparent shadow-sm px-4 py-1.5 hover:text-gray-900  hover:border-gray-900  bg-green-500 hover:bg-transparent text-base font-medium  text-white focus:outline-none sm:text-sm"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ValidateCompany;
