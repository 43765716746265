/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";

//COMPONENTS
import SearchHeader from "./searchHeader";

const GET_ITEMS = gql`
  query getItems(
    $vendor: ID
    $skip: Int
    $first: Int
    $search: String
    $group: [String]
    $tags: [String]
  ) {
    items(
      vendorId: $vendor
      skip: $skip
      first: $first
      search: $search
      productGroups: $group
      productTags: $tags
    ) {
      id
      skuNumber
      title
      productGroups {
        name
      }
      price {
        pricePerUnit
      }
    }
  }
`;

const CREATE_PROMO = gql`
  mutation createVendorPromotion(
    $usage: Int
    $code: String
    $startDate: Date!
    $endDate: Date!
    $name: String!
    $dep: [PromoDependencyItemInput]
    $items: [PromoItemInput]!
    $hasShipping: Boolean
  ) {
    createVendorPromotion(
      allowedUsageQuantityPerClient: $usage
      code: $code
      dependencies: $dep
      items: $items
      endDate: $endDate
      startDate: $startDate
      name: $name
      hasFreeShipping: $hasShipping
    ) {
      success
    }
  }
`;
const HEADERS = ["Title", "Selected"];
const DEPENDANCY_HEADERS = ["Title", "PRICE", "Selected"];

const NewPromoView = ({ setListView }) => {
  const date = new Date();
  const defaultExpiry = new Date();
  defaultExpiry.setMonth(defaultExpiry.getMonth() + 3);
  const [skipValue, setSkipValue] = useState(0);
  const [getProducts, items] = useLazyQuery(GET_ITEMS);
  const [ProductTags, setProductTags] = useState(null);
  const { register, getValues, handleSubmit, setValue } = useForm();
  const [appliedItems, setItems] = useState({});
  const [dependancy, setDependancy] = useState({});
  const [createPromotion] = useMutation(CREATE_PROMO, {
    onCompleted: (d) => window.location.reload(),
    onError: (e) => alert(e),
  });

  useEffect(() => {
    getProducts({
      variables: {
        skip: skipValue,
        first: 5,
        group: getValues("productGroup") ? [getValues("productGroup")] : [],
        tags: getValues("productTag") ? [getValues("productTag")] : [],
        search: getValues("search"),
      },
    });
  }, [skipValue]);

  async function handleItems(checked, product) {
    if (checked) {
      setItems({
        ...appliedItems,
        [product.id]: { discount: 100, qty: 1, title: product.title, group: 0 },
      });
    } else {
      let deepCopy = JSON.parse(JSON.stringify(appliedItems));
      delete deepCopy[product.id];
      setItems(deepCopy);
    }
  }

  async function handleDependancy(checked, product) {
    if (checked) {
      setDependancy({
        ...dependancy,
        [product.id]: {
          price: product.price.pricePerUnit,
          qty: 1,
          title: product.title,
        },
      });
    } else {
      let deepCopy = JSON.parse(JSON.stringify(dependancy));
      delete deepCopy[product.id];
      setDependancy(deepCopy);
    }
  }

  async function createPromo(data) {
    let dep = [];
    for (const [key, value] of Object.entries(dependancy)) {
      dep.push({
        id: key,
        minPrice: value.price,
        minQuantity: value.qty,
        isRequired: value.required,
      });
    }

    let itm = [];
    for (const [key, value] of Object.entries(appliedItems)) {
      itm.push({
        id: key,
        discountPercentage: parseFloat(value.discount) / 100,
        maxQuantity: value.qty,
        optionIndex: value.group,
      });
    }

    let variables = {
      usage: data.usage,
      code: data.code,
      startDate: data.fromDate,
      endDate: data.toDate,
      name: data.title,
      dep: dep,
      items: itm,
      hasShipping: data.freeShip,
    };
    createPromotion({ variables });
  }
  return (
    <form
      onSubmit={handleSubmit(createPromo)}
      className="bg-white m-8 border border-gray-200 rounded-xl"
    >
      <div className="p-4">
        <div className="flex flex-row items-end justify-between pb-2">
          <div>
            <h3 className="text-2xl font-semibold text-gray-900">
              Add New Promotion
            </h3>
            <p className="max-w-md text-gray-700 text-sm py-1">
              Utilize the following form to create a new promotion
            </p>
          </div>
          <button
            type="button"
            onClick={() => setListView(true)}
            className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Back
          </button>
        </div>
        <div className="flex flex-row gap-4 border-t border-b border-gray-200 ">
          <dl className="mt-2  divide-y divide-gray-200">
            <div className="py-6 flex  flex-col justify-between text-sm font-medium">
              <dt className="text-lg text-gray-900">Details:</dt>
              <div className="grid grid-cols-4 gap-x-4 gap-y-1">
                <div className="col-span-2">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Title:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("title", { required: true })}
                      type="text"
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Code:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("code", { required: true })}
                      type="text"
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Type:
                  </label>
                  <div className="mt-1">
                    <select
                      id="name"
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    >
                      <option value="Each">Percentage</option>
                    </select>
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Effective From:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      defaultValue={date.toLocaleDateString("en-CA")}
                      {...register("fromDate", { required: true })}
                      type="date"
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Effective To:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      defaultValue={defaultExpiry.toLocaleDateString("en-CA")}
                      {...register("toDate", { required: true })}
                      type="date"
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Usage Per Client:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      defaultValue={1}
                      {...register("usage", { required: true })}
                      type="number"
                      className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Free Shipping:
                  </label>
                  <div className="mt-1">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        defaultChecked={true}
                        {...register("freeShip")}
                        className="focus:ring-blue-500 h-4 w-4 rounded-md text-blue-600 border-gray-300"
                      />
                      <label className="ml-3 block text-sm font-medium text-gray-700">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dl>
        </div>
      </div>
      <dl className=" p-4 border-b pb-4  border-gray-200 divide-y divide-gray-200 grid grid-cols-2">
        <div className="flex  flex-col justify-between text-sm font-medium">
          <dt className="text-lg text-gray-900">Applies To:</dt>
          <SearchHeader
            register={register}
            setValue={setValue}
            setSkipValue={setSkipValue}
            getValues={getValues}
            ProductTags={ProductTags}
            setProductTags={setProductTags}
            getProducts={getProducts}
          />
          <div className="flex flex-col  overflow-auto my-4">
            <table className="border rounded-lg">
              <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
                <tr>
                  {HEADERS.map((entry, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {entry}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {items &&
                  items.data &&
                  items.data.items.map((product, pIndex) => (
                    <tr
                      key={product?.id}
                      className={
                        pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {product?.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleItems(e.target.checked, product)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm   border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium text-blue-900 ">
                    {1 + skipValue}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium text-blue-900 ">
                    {5 + skipValue}
                  </span>{" "}
                  of <span className="font-medium text-green-900">625</span>{" "}
                  Items
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                {skipValue > 4 && (
                  <button
                    type="button"
                    onClick={() => setSkipValue(skipValue - 5)}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Previous
                  </button>
                )}
                {items.data?.items.length === 5 && (
                  <button
                    type="button"
                    onClick={() => setSkipValue(skipValue + 5)}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                  >
                    Next
                  </button>
                )}
              </div>
            </nav>
          </div>
        </div>
        <div className="bg-white overflow-hidden  ml-8 pt-32">
          <div className="col-span-1 align-middle inline-block min-w-full z-40 ">
            <div className=" border-b overflow-auto h-full max-h-96  sm:rounded-lg ">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-blue-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Title
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Discount
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Max Qty
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Group
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(appliedItems)?.map(([key, entry], index) => (
                    <>
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-white" : "bg-blue-50"}
                      >
                        <td className="px-6 py-4 text-sm font-medium text-gray-700">
                          <span className="block">{entry.title}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                          <div className="flex flex-row items-center justify-center h-full">
                            <input
                              type="number"
                              defaultValue={entry.discount}
                              onChange={(e) => {
                                let copy = JSON.parse(
                                  JSON.stringify(appliedItems)
                                );
                                copy[key].discount = e.target.value;
                                setItems(copy);
                              }}
                              className="block w-1/2 shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                            />
                            %
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <input
                            type="number"
                            defaultValue={entry.qty}
                            onChange={(e) => {
                              let copy = JSON.parse(
                                JSON.stringify(appliedItems)
                              );
                              copy[key].qty = e.target.value;
                              setItems(copy);
                            }}
                            className="block w-1/2 shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <div className="flex flex-row items-center justify-between ">
                            <input
                              type="number"
                              defaultValue={entry.group}
                              onChange={(e) => {
                                let copy = JSON.parse(
                                  JSON.stringify(appliedItems)
                                );
                                copy[key].group = e.target.value;
                                setItems(copy);
                              }}
                              className="block w-1/2 shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                            />
                            <button
                              type="button"
                              className="ml-1 text-red-700 hover:text-red-900 text-lg"
                              onClick={() => {
                                let copy = JSON.parse(
                                  JSON.stringify(appliedItems)
                                );
                                delete copy[key];
                                setItems(copy);
                              }}
                            >
                              X
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </dl>
      <dl className=" p-4 border-b pb-4  border-gray-200 divide-y divide-gray-200 grid grid-cols-2">
        <div className="flex  flex-col justify-between text-sm font-medium">
          <dt className="text-lg text-gray-900">Dependant On:</dt>
          <SearchHeader
            register={register}
            setValue={setValue}
            setSkipValue={setSkipValue}
            getValues={getValues}
            ProductTags={ProductTags}
            setProductTags={setProductTags}
            getProducts={getProducts}
          />
          <div className="flex flex-col  overflow-auto my-4">
            <table className="border rounded-lg">
              <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
                <tr>
                  {DEPENDANCY_HEADERS.map((entry, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {entry}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {items &&
                  items.data &&
                  items.data.items.map((product, pIndex) => (
                    <tr
                      key={product?.id}
                      className={
                        pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {product?.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        CAD{" "}
                        {parseFloat(product?.price?.pricePerUnit)
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleDependancy(e.target.checked, product)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm   border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium text-blue-900 ">
                    {1 + skipValue}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium text-blue-900 ">
                    {5 + skipValue}
                  </span>{" "}
                  of <span className="font-medium text-green-900">625</span>{" "}
                  Items
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                {skipValue > 4 && (
                  <button
                    type="button"
                    onClick={() => setSkipValue(skipValue - 5)}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Previous
                  </button>
                )}
                {items.data?.items.length === 5 && (
                  <button
                    type="button"
                    onClick={() => setSkipValue(skipValue + 5)}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                  >
                    Next
                  </button>
                )}
              </div>
            </nav>
          </div>
        </div>
        <div className="bg-white overflow-hidden  ml-8 pt-32">
          <div className="col-span-1 align-middle inline-block min-w-full z-40 ">
            <div className=" border-b overflow-auto h-full max-h-96  sm:rounded-lg ">
              <table className="min-w-full divide-y divide-gray-200 ">
                <thead className="bg-blue-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Title
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Min Price
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Min Qty
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Required
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(dependancy)?.map(([key, entry], index) => (
                    <>
                      <tr
                        key={index}
                        className={index % 2 === 0 ? "bg-white" : "bg-blue-50"}
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                          <span className="block">{entry.title}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap flex flex-row items-center text-sm font-medium">
                          CAD
                          <input
                            type="number"
                            step="2"
                            onChange={(e) => {
                              let copy = JSON.parse(JSON.stringify(dependancy));
                              copy[key].price = e.target.value;
                              setDependancy(copy);
                            }}
                            defaultValue={entry.price}
                            className="block w-1/2 shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <input
                            type="number"
                            step="2"
                            defaultValue={entry.qty}
                            onChange={(e) => {
                              let copy = JSON.parse(JSON.stringify(dependancy));
                              copy[key].qty = e.target.value;
                              setDependancy(copy);
                            }}
                            className="block w-1/2 shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <input
                            type="checkbox"
                            defaultChecked={true}
                            onChange={(e) => {
                              let copy = JSON.parse(JSON.stringify(dependancy));
                              copy[key].required = e.target.checked;
                              setDependancy(copy);
                            }}
                            className="focus:ring-blue-500 h-4 w-4 rounded-md text-blue-600 border-gray-300"
                          />
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-700">
                          <button
                            type="button"
                            onClick={() => {
                              let copy = JSON.parse(JSON.stringify(dependancy));
                              delete copy[key];
                              setDependancy(copy);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </dl>
      <div className="p-4 flex flex-row justify-end gap-4 border-t border-gray-200 bg-gray-100">
        <button
          type="submit"
          className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Create Promotion
        </button>
      </div>
    </form>
  );
};

export default NewPromoView;
