import React, { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";

//COOKIES
import { useCookies } from "react-cookie";

//VIEW
import Loading from "../../global/components/loading/page";
import Router from "../../router";
import Login from "./index";

const LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn
  }
`;

const LoginPage = () => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [logged, response] = useLazyQuery(LOGGED_IN, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      console.log(d);
    },
  });

  useEffect(() => {
    logged();
  }, []);

  if (!response.called || response.loading) return <Loading />;
  if (response.data?.isLoggedIn) return <Router />;
  return <Login />;
  // return cookies.user && cookies.user !== "null" ? <Router /> : <Login />;
  // {cookies.user && cookies.user !== "null" ? <Router /> : <Login />}
};

export default LoginPage;
