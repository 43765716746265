/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { gql, useLazyQuery } from "@apollo/client";
import { setPopOver, selectPopOver } from "../../hooks/sales/saleSlice";
import { useDispatch, useSelector } from "react-redux";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

const GET_ITEM = gql`
  query getItem($id: String!) {
    item(skuNumber: $id) {
      skuNumber
      title
      leadTimeDaysInStock
      leadTimeDaysOutOfStock
      weightPounds
      commissionPercentage
      overrideCommissionPercentage
      price {
        pricePerUnit
      }
      usdPrice {
        pricePerUnit
      }
      bomProduct {
        partTitle
        itemSkuNumber
        quantity
      }
      vendor {
        name
      }
      images {
        title
        url
      }
    }
  }
`;

function MasterOverLay({ item, setView }) {
  return (
    <div className="h-full bg-white p-8 overflow-y-auto">
      <div className="pb-16 space-y-6">
        <div className="mb-4 flex items-end w-full align-end justify-start text-left">
          <div>
            <h2 className="text-lg self-end font-medium text-gray-900">
              <span className="sr-only">Details for </span>
              Detailed Breakdown
            </h2>
          </div>
        </div>
        <div>
          <div className="mt-2 flex items-center justify-between gap-6">
            <p className="text-sm text-gray-500 italic">
              Explore further details on items pertaining to this category
            </p>
          </div>
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Variants</h3>
          <div className="mt-2 flex items-center justify-between gap-6">
            <div className="bg-white shadow overflow-hidden sm:rounded-md w-full ">
              <ul className="divide-y divide-gray-200 w-full">
                {item.length !== 0 &&
                  item.map((variant) => (
                    <li
                      key={variant.id}
                      onClick={() => setView([variant])}
                      className="w-full"
                    >
                      <p className="block hover:bg-gray-50">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between gap-6">
                            <p className="text-sm font-medium text-blue-600 truncate">
                              {variant.title}
                            </p>
                            <div className="ml-5 flex-shrink-0">
                              <ChevronRightIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-start">
                            <div className="m-0 flex-shrink-0 flex">
                              <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-50 text-blue-800">
                                SKU: {variant.skuNumber}
                              </p>
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-50 text-green-800">
                                Price: ${" "}
                                {variant.price.pricePerUnit.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </p>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function VariantOverLay({ item, setView, allItems }) {
  const [bom, setBom] = useState([]);
  const [itemDetails, setItemDetails] = useState(null);
  const [getItem, queryItem] = useLazyQuery(GET_ITEM, {
    onCompleted: (d) => {
      setItemDetails(d.item);
      setBom(d.item.bomProduct);
    },
  });

  useEffect(() => {
    if (item && item[0].skuNumber) {
      getItem({
        variables: { id: item[0].skuNumber },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  if (!item || !itemDetails) return null;
  return (
    <div className="h-full bg-white p-8 overflow-y-auto">
      <div className=" flex flex-row justify-between items-center mb-4 ">
        {allItems && allItems.length > 1 && (
          <div
            onClick={() => setView(allItems)}
            className="flex-shrink-0 flex items-end"
          >
            <ChevronLeftIcon
              className="h-8 w-8 text-gray-400 hover:text-gray-900"
              aria-hidden="true"
            />
          </div>
        )}
        <div className="flex flex-col items-start w-full justify-between text-right border-b pb-2 border-gray-300">
          {itemDetails.images[0] && (
            <img
              className="mx-auto h-48 w-auto"
              src={itemDetails.images[0]?.url}
              alt={itemDetails.images[0]?.title}
            />
          )}
          <h2 className="text-lg font-bold text-gray-900 flex flex-row justify start">
            {itemDetails.title}
          </h2>
        </div>
      </div>
      <div className="ml-2 border-b">
        <div className="my-2 flex items-center justify-between gap-6">
          <h3 className="font-medium text-sm text-gray-900">Price CAD:</h3>
          <p className="text-lg font-semibold text-green-500">
            ${" "}
            {itemDetails.price.pricePerUnit.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}{" "}
          </p>
        </div>
        <div className="my-2 flex items-center justify-between gap-6">
          <h3 className="font-medium text-sm text-gray-900">Price USD:</h3>
          <p className="text-lg font-semibold text-green-500">
            ${" "}
            {itemDetails.usdPrice?.pricePerUnit.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}{" "}
          </p>
        </div>
        <div className="my-2 flex items-center justify-between gap-6">
          <h3 className="font-medium text-sm text-gray-900">SKU Number:</h3>
          <p className="text-sm font-semibold text-blue-500">
            {itemDetails.skuNumber}{" "}
          </p>
        </div>
        <div className="my-2 flex items-center justify-between gap-6">
          <h3 className="font-medium text-sm text-gray-900">
            Commission Percentage:
          </h3>
          <p className="text-sm font-semibold text-gray-600">
            {parseFloat(itemDetails.commissionPercentage) * 100}%
          </p>
        </div>
        <div className="my-2 flex items-center justify-between gap-6">
          <h3 className="font-medium text-sm text-gray-900">
            Item Specific Commission:
          </h3>
          <p className="text-sm font-semibold text-gray-600">
            {itemDetails.overrideCommissionPercentage ? "YES" : "NO"}
          </p>
        </div>
        <div className="my-2 flex items-center justify-between gap-6">
          <h3 className="font-medium text-sm text-gray-900">Vendor:</h3>
          <p className="text-sm font-semibold text-gray-600">
            {itemDetails.vendor?.name}
          </p>
        </div>
      </div>
      <div className="pt-4 pb-16 space-y-6 ml-2">
        {bom.length !== 0 && (
          <div>
            <h3 className="text-gray-900 font-semibold">Bill of Materials</h3>
            <div className="flex flex-col mt-2">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {bom.map((material) => (
                          <tr key={material.itemSkuNumber}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {material.partTitle}
                              <span className="block text-xs text-gray-500">
                                SKU: {material.itemSkuNumber}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                              {material.quantity}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <h3 className="font-medium text-gray-900">Information</h3>
          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">
                Lead Time <span className="block text-xs">If in stock</span>
              </dt>
              <dd className="text-gray-900">
                {`${parseInt(itemDetails.leadTimeDaysInStock)} Days` || "-"}
              </dd>
            </div>
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">
                Lead Time <span className="block text-xs">If out of stock</span>
              </dt>
              <dd className="text-gray-900">
                {`${parseInt(itemDetails.leadTimeDaysOutOfStock)} Days` || "-"}
              </dd>
            </div>
            <div className="py-3 flex justify-between text-sm font-medium">
              <dt className="text-gray-500">Weight</dt>
              <dd className="text-gray-900">
                {`${itemDetails.weightPounds} Ibs` || "-"}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default function OverLayRightForm() {
  const dispatch = useDispatch();
  const popOver = useSelector(selectPopOver);
  const [dialog, setDialog] = useState(popOver.view);
  const [view, setView] = useState(null);
  useEffect(() => {
    setDialog(popOver.view);
    setView(popOver.item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popOver]);
  return (
    <Transition.Root show={dialog} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden z-40"
        open={dialog}
        onClose={() => {}}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-2 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-50 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() =>
                        dispatch(setPopOver({ view: false, item: null }))
                      }
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {view && view.length > 1 ? (
                  <MasterOverLay item={view} setView={setView} />
                ) : (
                  <VariantOverLay
                    item={view}
                    setView={setView}
                    allItems={popOver.item}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
