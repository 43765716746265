/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */ // import React, { useState, useEffect } from "react";
import React from "react";
import { useFormContext } from "react-hook-form";

// HOOKS
// import useEstimate from "../../../hooks/useEstimate";

const SimpleCheckbox = ({ props }) => {
  if (true) {
    return (
      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
        <div className="col-span-2">
          <label
            htmlFor="project_name"
            className="block text-lg font-medium text-gray-900 sm:mt-px sm:pt-2"
          >
            {props.label}
          </label>
          <p className="text-gray-500 text-base">{props.subtitle}</p>
          <button
            // onClick={() => handleProductOverlay(true, props)}
            type="button"
            className="animate-pulse text-blue-400 hover:text-blue-900 text-base font-semibold"
          >
            View Details
          </button>
        </div>
        {/* <div>{CheckboxOptions}</div> */}
      </div>
    );
  } else {
    return null;
  }
};

export default SimpleCheckbox;
