import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

// HOOKS
import { selectClient } from "../hooks/clientSlice";

//COMPONENTS
import Directory from "./directory";
import NavigationHeader from "../../global/components/headers/navigation";
import PageHeader from "../../global/components/headers/pageWTag";

//DATA
import { PAGE_HEADER, SUB_MENUS } from "../data/data_port";

const ClientPort = () => {
  const ClientDetails = useSelector(selectClient);
  return (
    <div className="flex-1 relative z-0 flex overflow-hidden pb-5 px-4 py-4 sm:pb-0 sm:px-6 lg:px-8">
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none order-last m-4">
        <PageHeader data={ClientDetails} map={PAGE_HEADER} />
        <NavigationHeader subMenus={SUB_MENUS} />
        <Outlet />
      </main>
      <aside className="relative order-first flex flex-col flex-shrink-0 w-1/4 pr-2 border-r border-gray-200">
        <Directory />
      </aside>
    </div>
  );
};

export default ClientPort;
