/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { useSearchParams } from "react-router-dom";
import { Switch } from "@headlessui/react";

//COOKIES
import { useCookies } from "react-cookie";

//HOOKS
import { useSelector } from "react-redux";
import { selectOrderList } from "../hooks/saleSlice";
import useOrder from "../hooks/useOrder";

// COMPONENTS
import Table from "../../global/components/tables/filterable";
import Loading from "../../global/components/loading/page";

const OrdersView = () => {
  const {
    SpareHeaders,
    refetch,
    skipValue,
    setSkipValue,
    setSearchTerm,
    setStatuses,
    switchRequiresShipping,
    loadOrders,
    requiredShipping,
    isLoading,
    addSpare,
  } = useOrder();
  const [cookies] = useCookies(["user"]);
  const orderList = useSelector(selectOrderList);
  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || null;

  useEffect(() => {
    let token = cookies.user?.token;
    if (token) loadOrders();
  }, [cookies.user]);

  return (
    <div>
      <div className="bg-white overflow-hidden  border-gray-100 mx-1">
        <div className="border-b border-gray-200 py-4 mb-2 grid grid-cols-8  justify-between items-center mx-6">
          <div className="col-span-6">
            <h3 className="text-2xl leading-6 font-medium text-gray-900 py-2">
              {view === "spare" && view[0].toUpperCase() + view.slice(1)} Orders
            </h3>
          </div>
          <div className="flex flex-row  items-center justify-end col-span-2">
            {addSpare && (
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                <a
                  href="store"
                  className="inline-flex ml-3  items-center px-5 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Create New
                </a>
              </div>
            )}
            {cookies.user?.user?.role?.name === "Vendor Admin" && (
              <div className="flex flex-row items-end  justify-end mx-2 ">
                <Switch.Group as="div" className="flex items-center">
                  <Switch.Label as="span" className="mr-3">
                    <span className="text-sm font-medium text-gray-900">
                      All Orders
                    </span>
                  </Switch.Label>
                  <Switch
                    checked={requiredShipping}
                    onChange={switchRequiresShipping}
                    className={`
                      ${requiredShipping ? "bg-blue-600" : "bg-gray-200"}
                      relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${
                        requiredShipping ? "translate-x-5" : "translate-x-0"
                      } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                    />
                  </Switch>
                  <Switch.Label as="span" className="ml-3">
                    <span className="text-sm font-medium text-gray-900">
                      Has Payment
                    </span>
                  </Switch.Label>
                </Switch.Group>
              </div>
            )}
          </div>
        </div>
        <div className="max-w-7xl mx-auto flex-1 md:px-8 lg:px-0 xl:col-span-6">
          <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
            <div className="w-full">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="search"
                  onChange={(e) => {
                    if (e.target.value.length > 2) {
                      if (requiredShipping) switchRequiresShipping(false);
                      setSearchTerm(e.target.value);
                    }
                    if (e.target.value.length === 0) setSearchTerm(null);
                  }}
                  className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 py-6">
          {isLoading ? (
            <Loading />
          ) : (
            <Table
              columns={SpareHeaders}
              data={orderList}
              refetch={refetch}
              skipValue={skipValue}
              setSkipValue={setSkipValue}
              setStatuses={setStatuses}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdersView;
