import React from "react";

// COMPONENTS
import NavigationHeader from "../../components/Headers/navigationHeader";

const SalesView = () => {
  const subMenus = [
    {
      title: "Products",
      link: "/products",
    },
    // {
    //   title: "Samples",
    //   link: "/samples",
    // },
    {
      title: "Promos",
      link: "/promo",
    },
  ];
  return <NavigationHeader pageTitle={"Inventory"} subMenus={subMenus} />;
};

export default SalesView;
