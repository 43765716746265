import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setImportInvoice,
  setCompanyName,
  selectCompanyName,
} from "../../../../hooks/client/clientSlice";

const Header = ({
  selectedClient,
  setIsLoading,
  fetchCustomer,
  importInvoice,
}) => {
  const dispatch = useDispatch();
  const companyName = useSelector(selectCompanyName);
  return (
    <main
      // className="flex flex-col relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <div className="flex flex-col px-4 sm:px-6 lg:px-8 h-full ">
        <div className="border-b border-gray-200 py-2 mb-2 grid grid-cols-8  gap-4 justify-between items-center">
          <div className="col-span-5 grid grid-cols-3 gap-4">
            <h3 className="text-2xl leading-6 font-medium text-gray-900 py-2">
              Client Fetch
            </h3>
            <input
              type="text"
              defaultValue={selectedClient?.name}
              onChange={(e) => dispatch(setCompanyName(e.target.value))}
              className="block col-span-2 bg-white border border-gray-300 rounded-md p-1 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            />
          </div>
          <div className="flex lg:items-center lg:justify-between xl:col-span-3">
            <button
              onClick={() => {
                setIsLoading(true);
                console.log(companyName || selectedClient?.name);
                console.log(selectedClient);
                fetchCustomer({
                  variables: {
                    company: companyName || selectedClient?.name,
                  },
                });
              }}
              className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Fetch
            </button>
            {importInvoice && (
              <button
                onClick={() => dispatch(setImportInvoice(null))}
                className="inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Back
              </button>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Header;
