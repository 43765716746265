import React from "react";

// VIEWS
import HomeView from "../../views/home";
import SalesView from "../../Sales/views";
import SalesOrderView from "../../Sales/views/orders";
import SalesOrderPortView from "../../Sales/views/ordersPort";
import SalesLeadsView from "../../Sales/views/leads";
import SalesShipmentsView from "../../views/Sales/shipments";
import SalesShipmentsPort from "../../views/Sales/shipments/list";
import UniqueSalesShipment from "../../views/Sales/shipments/unique";
import SalesEstimateView from "../../Sales/views/estimate";
import SalesStoreView from "../../views/Sales/store";
import UniqueSalesView from "../../Sales/views/orders:id";
import UniqueSalesPortView from "../../Sales/views/orders:id/port";
import UniqueOrderEstimateView from "../../Sales/views/orders:id/estimate";
import ManualOrderShipping from "../../Sales/views/orders:id/shippingCost";
import TrackOrderShipment from "../../Sales/views/orders:id/trackShipment";
import InventoryView from "../../views/Inventory";
import InventoryProductsView from "../../views/Inventory/products";
import ClientView from "../../clients/views";
import ClientDirectory from "../../clients/views/port";
import ClientIDView from "../../clients/views/:id";
import ClientIDPort from "../../clients/views/idPort";
import ClientOrdersView from "../../clients/views/orders";
import ClientImportView from "../../clients/views/import";
import SettingsView from "../../views/Profile/settings";
import InventorySamplesView from "../../views/Inventory/samples";
import NewClient from "../../views/Clients/directory/newClient";
import NewProduct from "../../views/Inventory/products/newProduct";
import InvoicesView from "../../views/Invoices";
import InvoicePt from "../../views/Invoices/list";
import UniInvoice from "../../views/Invoices/unique";
import PromoView from "../../views/Inventory/promo";
import VendorsView from "../../views/Vendors";
import VendorsList from "../../views/Vendors/list";
import NewVendor from "../../views/Vendors/new";
import Reset from "../../authentication/views/reset";
import NotFoundView from "../../notFound";
import CommissionView from "../../views/Commission";

const DynamicRoutesList = {
  Home: <HomeView />,
  Sales: <SalesView />,
  SalesPort: <SalesLeadsView />,
  SalesOrder: <SalesOrderView />,
  SalesOrderPort: <SalesOrderPortView />,
  UniqueSalesOrder: <UniqueSalesView />,
  UniqueSalesOrderPortView: <UniqueSalesPortView />,
  UniqueOrderEstimate: <UniqueOrderEstimateView />,
  UniqueOrderEstimatePortView: <UniqueOrderEstimateView />,
  OrderManualShipping: <ManualOrderShipping />,
  OrderShipment: <TrackOrderShipment />,
  SalesLeads: <SalesLeadsView />,
  Shipments: <SalesShipmentsView />,
  ShipmentPort: <SalesShipmentsPort />,
  UniqueShipment: <UniqueSalesShipment />,
  SaleSpareStore: <SalesStoreView />,
  Estimate: <SalesEstimateView />,
  UniqueEstimate: <SalesEstimateView />,
  Commission: <CommissionView />,
  Products: <InventoryView />,
  NewProduct: <NewProduct />,
  InventoryPort: <InventoryProductsView />,
  InventorySamples: <InventorySamplesView />,
  VendorPromo: <PromoView />,
  Clients: <ClientView />,
  ClientsPort: <ClientDirectory />,
  newClient: <NewClient />,
  UniqueClient: <ClientIDView />,
  UniqueClientPort: <ClientIDPort />,
  UniqueClientOrders: <ClientOrdersView />,
  UniqueClientImport: <ClientImportView />,
  Invoices: <InvoicesView />,
  InvoicePort: <InvoicePt />,
  UniqueInvoice: <UniInvoice />,
  Vendors: <VendorsView />,
  VendorPort: <VendorsList />,
  newVendor: <NewVendor />,
  Settings: <SettingsView />,
  Reset: <Reset />,
  NotFound: <NotFoundView />,
};

export default DynamicRoutesList;
