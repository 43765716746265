/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTable } from "react-table";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useSelector, useDispatch } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import {
  selectSortBy,
  setSortBy,
  selectHiddenColumns,
  setVendorID,
} from "../../../Sales/hooks/saleSlice";

// COMPONENTS
import PaginationPanel from "./paginationPanel";

export default function Sortable({
  columns,
  data,
  refetch,
  skipValue,
  setSkipValue,
  setStatuses,
}) {
  const dispatch = useDispatch();
  const sortBy = useSelector(selectSortBy);
  const hiddenColumns = useSelector(selectHiddenColumns);
  const {
    getTableBodyProps,
    setHiddenColumns,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  useEffect(() => {
    setHiddenColumns(Object.keys(hiddenColumns));
  }, [hiddenColumns]);

  async function handleSort(item) {
    setSkipValue(0);
    if (sortBy === item) {
      dispatch(setSortBy(`-${item}`));
    } else {
      dispatch(setSortBy(item));
    }
  }
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className=" align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-100 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  {headerGroups[0].headers.map((header) => (
                    <th
                      className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-start`}
                    >
                      {header.isSortable ? (
                        <button
                          onClick={() => handleSort(header.sortField)}
                          className="uppercase tracking-wider font-medium flex flex-row gap-1 items-center"
                        >
                          {header.Header}
                          {sortBy === header.sortField && (
                            <ChevronUpIcon
                              className=" h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                          {sortBy === `-${header.sortField}` && (
                            <ChevronDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      ) : (
                        <span>{header.Header}</span>
                      )}
                      {header.searchable && (
                        <SearchBy
                          type={header.searchable}
                          field={header.searchField}
                          refetch={refetch}
                          minimumLength={header.minimumLength}
                          options={header.options}
                          setStatuses={setStatuses}
                          setSkipValue={setSkipValue}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                {...getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            className="px-5 py-4 whitespace-nowrap text-sm text-gray-900"
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <PaginationPanel
              skipValue={skipValue}
              setSkipValue={setSkipValue}
              rows={rows}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const SearchBy = ({
  type,
  field,
  refetch,
  minimumLength,
  options,
  setStatuses,
  setSkipValue,
}) => {
  const dispatch = useDispatch();
  let renderedComponent;
  switch (type) {
    case "text":
      renderedComponent = (
        <input
          type="text"
          onChange={(e) => {
            if (!(e.target.value.length > minimumLength)) return;
            let variables = {};
            variables[field] = e.target.value;
            refetch(variables);
          }}
          className="block shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
          placeholder="Search"
        />
      );
      break;
    case "select":
      renderedComponent = (
        <select
          onChange={(e) => {
            setSkipValue(0);
            dispatch(setVendorID(e.target.value));
          }}
          className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
        >
          <option value="">Select</option>
          {options.map((option, index) => (
            <option key={index} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      );
      break;
    case "multiSelect":
      renderedComponent = (
        <Multiselect
          options={options}
          onSelect={(values) => {
            setSkipValue(0);
            let sort = values.map((entry) =>
              entry.name.toLowerCase().split(" ").join("_")
            );
            let variables = {};
            variables[field] = sort;
            setStatuses(sort);
            // refetch(variables);
          }}
          displayValue="name"
          showCheckbox
          style={{
            searchBox: {
              "border-radius": "0.6vh",
              "z-index": "7",
              "background-color": "white",
              height: "3.5vh",
              display: "flex",
            },
          }}
        />
      );
      break;
    default:
      break;
  }
  return renderedComponent;
};
