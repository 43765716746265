/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//COOKIES
import { useCookies } from "react-cookie";
import {
  selectBeethoven,
  selectProvince,
  selectBeethovenProducts,
  selectParameters,
  selectPage,
  selectPageData,
  selectView,
  setPageData,
  setPageState,
  setView,
  setBeethoven,
  selectShipProvince,
  selectPrimaryVendor,
  selectShippingDiscount,
  selectExpiryDate,
} from "./estimateSlice";
import useConfigureEstimate from "./useConfigureEstimate";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { CREATE_ESTIMATE, GET_ESTIMATE, TRAY_ITEMS, PROMOS } from "./queries";

const useEstimatePages = () => {
  const dispatch = useDispatch();
  const {
    handleBeethovenEstimate,
    configureFetchedEstimate,
    handleBeethovenTotal,
  } = useConfigureEstimate();
  const beethoven = useSelector(selectBeethoven);
  const params = useSelector(selectParameters);
  const pageState = useSelector(selectPage);
  const beethovenProducts = useSelector(selectBeethovenProducts);
  const province = useSelector(selectProvince);
  const shipState = useSelector(selectShipProvince);
  const shippingDiscount = useSelector(selectShippingDiscount);
  const expiryDate = useSelector(selectExpiryDate);
  const primaryVendor = useSelector(selectPrimaryVendor);
  const pageView = useSelector(selectView);
  const [watchFields, setWatchFields] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [trayItems, setTrayItems] = useState(null);
  const [promotionID, setPromotionID] = useState(null);
  const [getEstimate] = useLazyQuery(GET_ESTIMATE, {
    onCompleted: (d) => {
      configureFetchedEstimate(d.estimate);
    },
  });
  const trayResponse = useQuery(TRAY_ITEMS, {
    onCompleted: (d) => {
      const items = Object.assign(
        {},
        ...d.items.map((object) => ({ [object.skuNumber]: true }))
      );
      setTrayItems(items);
    },
  });
  const [getPromos, promoResponse] = useLazyQuery(PROMOS, {
    onError: (e) => alert(e),
  });
  const [cookies] = useCookies(["user"]);
  const methods = useForm({
    shouldUnregister: false,
  });
  const { getValues, setValue, watch } = methods;
  const watchAllFields = watch();
  const [createEstimate] = useMutation(CREATE_ESTIMATE, {
    onCompleted: (d) =>
      (window.location = `/orders/${d.createEstimate.orderId}`),

    onError: (e) => {
      alert(e);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (primaryVendor) getPromos({ variables: { id: primaryVendor.id } });
  }, [primaryVendor]);

  useEffect(() => {
    if (pageView === "page") {
      dispatch(
        setPageData(Object.values(beethoven[pageState.build])[pageState.page])
      );
      Object.values(beethoven[pageState.build])[
        pageState.page
      ].defaults.forEach((entry) => {
        if (entry.table) {
          let name = `${entry.form}-${pageState.build}-${entry.skuNumber}`;
          !getValues(name) && setValue(name, entry.quantity);
        } else {
          let name = `${entry.form}-${pageState.build}`;
          !getValues(name) && setValue(name, entry.skuNumber);
        }
      });
    }
  }, [pageState.build, pageState.page]);

  useEffect(() => {
    async function handleDefaults() {
      let dupedBeethoven = JSON.parse(JSON.stringify(beethoven));
      let beethovenTotal = Object.values(dupedBeethoven[pageState.build])[
        pageState.page
      ].total;
      const current = Object.values(dupedBeethoven[pageState.build])[
        pageState.page
      ];
      let pageTotal = 0;
      let defaults = [];
      for (const [form, value] of Object.entries(current.forms)) {
        if (value.input === "ModularTable" || value.input === "TraysTable") {
          for (const tableItem of value.items) {
            let field =
              watchAllFields[
                `${form}-${pageState.build}-${tableItem.skuNumber}`
              ];
            if (field) {
              defaults.push({
                form: parseInt(form),
                build: pageState.build,
                skuNumber: tableItem.skuNumber,
                price: parseFloat(tableItem.price.pricePerUnit),
                title: tableItem.title,
                quantity: field,
                table: true,
              });
              pageTotal +=
                parseFloat(tableItem.price.pricePerUnit) * parseFloat(field);
            }
          }
        } else if (watchAllFields[`${form}-${pageState.build}`]) {
          const item = value.items.find(
            (element) =>
              element.skuNumber === watchAllFields[`${form}-${pageState.build}`]
          );
          defaults.push({
            form: parseInt(form),
            build: pageState.build,
            skuNumber: item.skuNumber,
            price: parseFloat(item.price.pricePerUnit),
            title: item.title,
            quantity: "1",
          });
          pageTotal += parseFloat(item.price.pricePerUnit);
        }
      }
      dupedBeethoven[pageState.build][parseInt(current.id)].defaults = defaults;
      if (beethovenTotal !== pageTotal) {
        dupedBeethoven[pageState.build][parseInt(current.id)].total = pageTotal;
        handleBeethovenTotal(dupedBeethoven);
      }
      handleBeethovenEstimate(dupedBeethoven, beethovenProducts);
      dispatch(setBeethoven(dupedBeethoven));
    }
    if (JSON.stringify(watchFields) !== JSON.stringify(watchAllFields)) {
      setWatchFields(watchAllFields);
      if (Object.entries(watchAllFields).length !== 0) {
        handleDefaults();
      }
    }
  }, [watchAllFields]);

  async function handleDecrement() {
    if (parseInt(pageState.build) === 1 && pageState.page === 0) {
      dispatch(setView("parameters"));
    } else if (pageState.page === 0) {
      dispatch(
        setPageState({
          build: pageState.build - 1,
          page: Object.keys(beethoven[pageState.build - 1]).length - 1,
        })
      );
    } else {
      dispatch(
        setPageState({
          build: pageState.build,
          page: pageState.page - 1,
        })
      );
    }
  }

  async function handleIncrement() {
    if (
      !beethoven[pageState.build + 1] &&
      Object.keys(beethoven[pageState.build]).length === pageState.page + 1
    ) {
      handleBeethovenEstimate(beethoven, beethovenProducts);
      dispatch(setView("review"));
    } else if (
      Object.keys(beethoven[pageState.build]).length ===
      pageState.page + 1
    ) {
      dispatch(
        setPageState({
          build: pageState.build + 1,
          page: 0,
        })
      );
    } else {
      dispatch(
        setPageState({
          build: pageState.build,
          page: pageState.page + 1,
        })
      );
    }
  }

  async function handleSubmitEstimate(blocks) {
    setSubmitting(true);
    let constructedEstimate = {};
    for (const [i, value] of blocks.entries()) {
      let build = value.build;
      for (const [li, value] of build.entries()) {
        delete build[li].chosen;
        delete build[li].selected;
        build[li].originalPrice && delete build[li].originalPrice;
        !build[li].discountPerUnit && delete build[li].discountPerUnit;
        build[li].lineNumber = parseInt(build[li].lineNumber).toString();
        build[li].pricePerUnit = Number(
          parseFloat(build[li].pricePerUnit).toFixed(2)
        );
      }
      constructedEstimate[i] = {
        build: build,
        total: value.total,
      };
    }
    let location = province && province.split("-")[0];
    if (!province) {
      location = "US";
    }
    let lineItems = [];
    for (const build of Object.values(constructedEstimate)) {
      for (const entry of build.build) {
        let buildEntry = Object.assign({}, entry);
        delete buildEntry.title;
        delete buildEntry.__typename;
        delete buildEntry.form;
        delete buildEntry.chosen;
        delete buildEntry.selected;
        if (
          buildEntry.productUuid &&
          !beethovenProducts.find(
            (entry) => entry.productUuid === buildEntry.productUuid
          )
        )
          buildEntry.productUuid = null;
        buildEntry.discountPerUnit &&
          (buildEntry.discountPerUnit = (
            parseFloat(buildEntry.discountPerUnit) /
            parseFloat(buildEntry.pricePerUnit)
          ).toString());
        buildEntry.lineNumber = buildEntry.lineNumber.toString();
        buildEntry.pricePerUnitCad = buildEntry.pricePerUnit.toString();
        delete buildEntry.pricePerUnit;
        lineItems.push(buildEntry);
      }
    }

    let variables = {
      estimateData: {
        leadId: cookies.lead?.id,
        lineItems: lineItems,
        email: cookies.lead?.email,
        locationAbbreviation: location,
        shippingStateAbbreviation: shipState || "WORLD",
        daysTimedDiscountValid: expiryDate,
      },
      timedDiscountAppliedToShipping: shippingDiscount,
      productsData: beethovenProducts || [],
    };
    promotionID && (variables.vendorPromotionId = promotionID);
    cookies.lead &&
      cookies.lead.client &&
      (variables.clientId = cookies.lead.client.id);
    cookies.lead &&
      cookies.lead.customer &&
      (variables.customerId = cookies.lead.customer.id);
    createEstimate({
      variables: variables,
    });
  }

  async function getEstimateEdit(number) {
    getEstimate({
      variables: {
        num: number,
      },
    });
  }

  async function handlePromotion(id) {
    setPromotionID(id);
  }

  return {
    handleSubmitEstimate,
    getEstimateEdit,
    handleDecrement,
    handleIncrement,
    handlePromotion,
    pageData: useSelector(selectPageData),
    promos: promoResponse.data,
    submitting,
    beethoven,
    params,
    pageState,
    methods,
    trayItems,
  };
};
export default useEstimatePages;
