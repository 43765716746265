import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery, gql } from "@apollo/client";
import { PlusCircleIcon } from "@heroicons/react/outline";

import AddMerchantModal from "../../components/modals/vendors/addMerchant";

const VENDORS = gql`
  query vendors {
    vendors {
      id
      name
      taxIndicator
      createdAt
      merchants {
        firstName
        lastName
      }
    }
  }
`;
const ADD_MERCHANT = gql`
  mutation addMerchantToVendor(
    $firstName: String!
    $lastName: String!
    $email: ID!
    $role: String!
    $vendorId: ID!
  ) {
    addMerchantToVendor(
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      vendorId: $vendorId
    ) {
      vendor {
        id
      }
    }
  }
`;

const VendorsView = () => {
  const vendors = useQuery(VENDORS);
  const [openMerchant, setOpenMerchant] = useState(false);
  const [vendorId, setVendorId] = useState(false);
  const [addMerchant] = useMutation(ADD_MERCHANT, {
    onCompleted: (d) => {
      vendors.refetch();
      setOpenMerchant(false);
    },
    onError: (e) => alert(e),
  });

  async function handleMerchant(data) {
    let body = {
      firstName: data.first,
      lastName: data.last,
      role: data.role,
      email: data.email,
      vendorId: vendorId,
    };
    addMerchant({ variables: body });
  }

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vendors | One Supply Connect</title>
      </Helmet>
      <AddMerchantModal
        open={openMerchant}
        setOpen={setOpenMerchant}
        handleMerchant={handleMerchant}
      />
      <div className="px-4 sm:px-6 lg:px-8 mt-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Vendors</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the Vendors on-boarded onto Connect
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <a
              href="vendors/new"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Vendor
            </a>
          </div>
        </div>
        <div className="my-8 flex flex-col ">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Vendor Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Merchants
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                      >
                        Added On
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {vendors.data?.vendors.map((vendor) => (
                      <tr key={vendor.email}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3  flex items-start font-semibold text-gray-900 sm:pl-6">
                          {vendor.name}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="flex flex-col items-start gap-4">
                            <div className="flex flex-col">
                              {vendor.merchants.map((merchant, idx) => (
                                <p className="text-gray-600" key={idx}>
                                  - {merchant.firstName} {merchant.lastName}
                                </p>
                              ))}
                            </div>
                            <div>
                              <button
                                type="button"
                                onClick={() => {
                                  setVendorId(vendor.id);
                                  setOpenMerchant(true);
                                }}
                                className="inline-flex items-center justify-center rounded-md border gap-2 border-transparent bg-blue-600 px-2 py-1 font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                              >
                                <PlusCircleIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                                Add Merchant
                              </button>
                            </div>
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-gray-500 font-medium sm:pr-6">
                          {vendor.createdAt.split("T")[0]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default VendorsView;
