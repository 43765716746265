import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ResolveUndefined from "../../global/utils/manipulate/undefined";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "10px",
    borderBottom: "0.7px solid #4F90BB",
  },
  headerContainer: {
    marginTop: 12,
  },
  billTo: {
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 36,
    justifyContent: "between",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "between",
  },
  invoiceDate: {
    fontSize: 12,
  },
  text: {
    fontSize: 9,
  },
  label: {
    width: 60,
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
  },
});

const BillTo = ({ estimate, orderDetails }) => (
  <View style={styles.container}>
    <View style={styles.headerContainer}>
      <Text style={styles.billTo}>Bill To:</Text>
      <Text style={styles.text}>Advanced Integrated Robotics Inc.</Text>
      <Text style={styles.text}>PO Box 48054, Mississauga, Ontario</Text>
      <Text style={styles.text}> RPO Dundas Shepard, L5A 4G8</Text>
    </View>
    {orderDetails?.shippingAddress && (
      <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Ship To:</Text>
        <Text style={styles.text}>{estimate?.lead?.companyName}</Text>
        <Text style={styles.text}>
          {ResolveUndefined(orderDetails.shippingAddress.lineOne)},
        </Text>
        <Text style={styles.text}>
          {orderDetails.shippingAddress.city},
          {orderDetails.shippingAddress.postalCode}
        </Text>
      </View>
    )}
    <View style={styles.headerContainer}>
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.label}>PO #</Text>
        <Text style={styles.invoiceDate}>{estimate?.number}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.label}>DATE: </Text>
        <Text>{estimate?.createdAt?.split("T")[0]}</Text>
      </View>
    </View>
  </View>
);

export default BillTo;
