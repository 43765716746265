/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { useQuery, gql } from "@apollo/client";

const GROUPQUERY = gql`
  query getProductGroups {
    productGroups {
      name
      accessor
      associatedTags {
        name
        accessor
      }
    }
  }
`;

const VENDORS = gql`
  query vendors {
    vendors {
      name
      id
    }
  }
`;

const Header = ({
  getProducts,
  skipValue,
  setSkipValue,
  vendor,
  setVendor,
  methods,
  user,
}) => {
  useEffect(() => {
    getProducts({
      variables: {
        skip: skipValue,
        first: 50,
        group: getValues().productGroup ? [getValues().productGroup] : [],
        tags: getValues().productTag ? [getValues().productTag] : [],
        search: getValues().search,
        vendor: vendor || user?.user?.vendor?.id,
      },
    });
  }, [user, skipValue]);

  const { data } = useQuery(GROUPQUERY);
  const vendors = useQuery(VENDORS);
  const [ProductTags, setProductTags] = useState(null);
  const { setValue, register, getValues } = methods;
  return (
    <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8 ">
      <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-1 " />
      <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-9 ">
        <div className="grid grid-cols-9 gap-4  px-6 pb-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
          {!user?.user?.vendor?.id && (
            <div className="col-span-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Vendor
              </label>
              <select
                id="vendor"
                onChange={(e) => {
                  document.getElementById("productGroup").value = "";
                  document.getElementById("productTag").value = "";
                  setValue("productGroup", null);
                  setValue("productTag", null);
                  setVendor(e.target.value);
                  setValue("search", "");
                  setSkipValue(0);
                  getProducts({
                    variables: {
                      skip: 0,
                      first: 50,
                      vendor: e.target.value,
                    },
                  });
                }}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="">All</option>
                {vendors.data &&
                  vendors.data.vendors.map((entry, index) => (
                    <option key={index} value={entry.id}>
                      {entry.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <div className="col-span-4 flex flex-col justify-end">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                id="search"
                name="search"
                className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Search"
                type="search"
                {...register("search")}
                onChange={(e) => {
                  document.getElementById("productGroup").value = "";
                  document.getElementById("productTag").value = "";
                  document.getElementById("vendor") &&
                    (document.getElementById("vendor").value = "");
                  setValue("productGroup", null);
                  setValue("productTag", null);
                  setSkipValue(0);
                  if (!e.target.value.length) {
                    getProducts({
                      variables: {
                        skip: skipValue,
                        first: 50,
                        vendor: user?.user?.vendor?.id,
                      },
                    });
                  }
                  e.target.value.length > 2 &&
                    getProducts({
                      variables: {
                        skip: 0,
                        first: 50,
                        search: e.target.value,
                        vendor: user?.user?.vendor?.id,
                      },
                    });
                }}
              />
            </div>
          </div>
          <div className="col-span-2">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Categories
            </label>
            <select
              id="productGroup"
              onChange={(e) => {
                setValue(
                  "productGroup",
                  data.productGroups[e.target.value]?.accessor
                );
                document.getElementById("vendor") &&
                  (document.getElementById("vendor").value = "");
                setProductTags(data.productGroups[e.target.value]);
                setValue("search", "");
                setSkipValue(0);
                getProducts({
                  variables: {
                    skip: 0,
                    first: 50,
                    vendor: user?.user?.vendor?.id,
                    group: e.target.value
                      ? [data.productGroups[e.target.value]?.accessor]
                      : [],
                    tags: getValues().productTag
                      ? [getValues().productTag]
                      : [],
                  },
                });
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="">Select...</option>
              {data &&
                data.productGroups.map((entry, index) => (
                  <option value={index}>{entry.name}</option>
                ))}
            </select>
          </div>
          <div className="col-span-1">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Tags
            </label>
            <select
              id="productTag"
              onChange={(e) => {
                document.getElementById("vendor") &&
                  (document.getElementById("vendor").value = "");
                setValue("productTag", e.target.value);
                setValue("search", "");
                setSkipValue(0);
                getProducts({
                  variables: {
                    skip: 0,
                    first: 50,
                    group: [getValues().productGroup],
                    tags: e.target.value ? [e.target.value] : [],
                  },
                });
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="">All</option>
              {ProductTags &&
                ProductTags.associatedTags.map((entry) => (
                  <option value={entry.accessor}>{entry.name}</option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-2">
        <a
          href="products/new"
          className="ml-6 inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Add New Product
        </a>
      </div>
    </div>
  );
};

export default Header;
