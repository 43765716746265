import { gql } from "@apollo/client";
export const CONTAINERS = gql`
  query allContainerTypes {
    allContainerTypes {
      name
      frontendIdentifier
      minValue
      maxValue
      unitOfMeasurement {
        name
        units
      }
      inputFields {
        name
      }
    }
  }
`;

export const GET_BUILD = gql`
  query calculateBeethovenFlow($data: [FlowInputType]) {
    calculateBeethovenFlow(productionData: $data) {
      buildNumber
      buildPages
      productsSetup
      estimateItems {
        lineNumber
        skuNumber
        title
        pricePerUnit
        quantity
        productUuid
      }
    }
  }
`;

export const GET_ITEM = gql`
  query getItem($id: String!) {
    item(skuNumber: $id) {
      skuNumber
      title
      price {
        pricePerUnit
        overridePriceCap
      }
    }
  }
`;

export const GET_PAGE = gql`
  query getPageNode($id: ID!) {
    page(id: $id) {
      id
      title
      description
      layout {
        name
        numRows
        numColumns
      }
      forms {
        id
        productGroup {
          accessor
        }
        tags {
          accessor
        }
      }
    }
  }
`;

export const GET_FORM = gql`
  query getForm($id: ID!, $page: ID!, $addon: Boolean) {
    form(id: $id) {
      id
      label
      identifier
      description
      row
      column
      page {
        id
      }
      productGroup {
        accessor
      }
      tags {
        accessor
      }
      input {
        name
      }
    }
    pageFormDefaults(pageId: $page, formId: $id, hasAddon: $addon) {
      item {
        skuNumber
        price {
          pricePerUnit
        }
      }
      quantity
    }
  }
`;

export const GET_ITEMS = gql`
  query getItems($group: [String], $tags: [String]) {
    items(productGroups: $group, productTags: $tags) {
      skuNumber
      title
      description
      accountingDescription
      price {
        pricePerUnit
      }
    }
  }
`;

export const CREATE_ESTIMATE = gql`
  mutation createEstimate(
    $estimateData: EstimateInput!
    $productsData: [FlowInputType]!
    $customerId: ID
    $clientId: ID
    $vendorPromotionId: ID
    $timedDiscountAppliedToShipping: Decimal
  ) {
    createEstimate(
      estimateData: $estimateData
      productsData: $productsData
      customerId: $customerId
      clientId: $clientId
      vendorPromotionId: $vendorPromotionId
      timedDiscountAppliedToShipping: $timedDiscountAppliedToShipping
    ) {
      estimate {
        id
        number
        email
        taxPercentage
        dueDate
        dataJson
        memo
        lineItems {
          lineNumber
          pricePerUnit
          itemQuantity
        }
      }
      leadId
      orderId
    }
  }
`;

export const GET_TEMPLATES = gql`
  query templates {
    estimateTemplates {
      fromEstimate {
        template
        total
        productsJson
        lineItems {
          id
          lineNumber
          productUuid
          item {
            title
            skuNumber
            vendor {
              id
              name
            }
          }
          pricePerUnit
          itemQuantity
          discountPerUnit
        }
      }
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation create(
    $id: ID!
    $crm: String!
    $type: String!
    $email: String!
    $phone: String
    $firstName: String!
    $lastName: String!
  ) {
    addCustomerToClient(
      companyId: $id
      crmId: $crm
      customerType: $type
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phone
    ) {
      customer {
        id
      }
    }
  }
`;

export const GET_ITEM_PRICE = gql`
  query getItem($sku: String!) {
    item(skuNumber: $sku) {
      priceLimit
      price {
        pricePerUnit
        overridePriceCap
      }
    }
  }
`;

export const GET_ESTIMATE = gql`
  query estimate($num: ID!) {
    estimate(id: $num) {
      total
      taxPercentage
      productsJson
      currencyConversionRate
      lineItems {
        lineNumber
        pricePerUnit
        itemQuantity
        productUuid
        item {
          title
          skuNumber
          vendor {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_ESTIMATE = gql`
  mutation deleteEs($id: String!) {
    deleteEstimate(id: $id) {
      estimateNumber
    }
  }
`;

export const FROM_USD = gql`
  query fromUSD {
    currency(symbol: "USD") {
      conversionRateFromCad
    }
  }
`;

export const TRAY_ITEMS = gql`
  query items {
    items(productGroups: ["tray"]) {
      skuNumber
    }
  }
`;

export const PROMOS = gql`
  query vendor($id: ID!) {
    vendor(id: $id) {
      promotions {
        id
        name
        currentStatus
      }
    }
  }
`;
