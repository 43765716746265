import React from "react";
import { View, StyleSheet, Font } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableRow from "./InvoiceTableRow";
import InvoiceTableFooter from "./InvoiceTableFooter";

Font.register({
  family: "Roboto",
  src: "/roboto.ttf",
});

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 0,
    borderWidth: 0,
    borderColor: "#fffff",
    width: "100%",
  },
});

const InvoiceItemsTable = ({ estimate, orderDetails, isWayFast }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader isWayFast={isWayFast} />
    <InvoiceTableRow
      products={estimate?.productsJson}
      items={estimate?.lineItems}
    />
    <InvoiceTableFooter isWayFast={isWayFast} estimate={estimate} />
  </View>
);

export default InvoiceItemsTable;
