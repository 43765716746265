import { gql } from "@apollo/client";

export const CREATE_PAYMENT = gql`
  mutation payment(
    $id: String!
    $type: String!
    $confirmation: String!
    $value: Decimal!
    $creditID: ID
  ) {
    createPublicPaymentSentLog(
      orderUuid: $id
      method: $type
      referenceAttribute: $confirmation
      amount: $value
      creditId: $creditID
    ) {
      paymentTransaction {
        success
      }
    }
  }
`;

export const LOG_PAYMENT = gql`
  mutation payment($id: ID!, $amount: Decimal!, $date: Date!) {
    logPayment(
      amountReceived: $amount
      paymentTransactionId: $id
      dateReceived: $date
    ) {
      paymentTransaction {
        success
      }
    }
  }
`;

export const UPDATE_RECIEVED_DATE = gql`
  mutation updatePaymentReceivedDate($id: ID!, $date: Date!) {
    updatePaymentReceivedDate(paymentTransactionId: $id, dateReceived: $date) {
      paymentTransaction {
        success
      }
    }
  }
`;

export const LOST_REASONS = gql`
  query orderClosedReasons {
    orderClosedReasons {
      id
      label
    }
  }
`;

export const MARK_SHIPPING = gql`
  mutation shipOrder($orderId: ID!, $shippedOn: DateTime!) {
    shipOrder(orderId: $orderId, shippedOn: $shippedOn) {
      success
    }
  }
`;

export const REVISE_ORDER = gql`
  mutation salesRequestToReviseOrder($id: ID!) {
    salesRequestToReviseOrder(orderId: $id) {
      success
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($id: ID!, $addressData: AddressInput!) {
    updateAddress(addressId: $id, addressData: $addressData) {
      address {
        id
      }
    }
  }
`;

export const ADD_INVOICE = gql`
  mutation addInvoiceToOrder($id: ID!, $form: Upload!) {
    addInvoiceToOrder(orderId: $id, invoice: $form) {
      success
    }
  }
`;
export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id: ID!) {
    deleteInvoiceFromOrder(orderId: $id) {
      success
    }
  }
`;

export const PUSH_PRODUCTION = gql`
  mutation vendorMoveOrderToProduction($id: ID!, $vendorID: ID!) {
    vendorMoveOrderToProduction(orderId: $id, vendorId: $vendorID) {
      success
    }
  }
`;

export const ARCHIVE_ORDER = gql`
  mutation archiveOrder($id: ID!, $reason: Int!) {
    archiveOrder(orderId: $id, reasonId: $reason) {
      archived
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($id: Int!) {
    order(id: $id) {
      id
      currency
      salesType
      currentStatus {
        name
      }
      paymentReceivedDate
      dueDate
      purchaseTermsSigner
      purchaseTermsSignDate
      linkUuid
      profitMargin
      purchaseTerms
      invoiceLink
      purchaseOrderLink
      vendorOrderNumber
      vendorShipDate
      revisionComments
      vendorHandOffDate
      commissionQuotaReached
      createdBy {
        firstName
        lastName
      }
      affiliate {
        firstName
        lastName
      }
      lead {
        id
        companyName
        crmId
        customerCrmId
        contactName
        email
        status
        phoneNumber
        productionGoal
        funding
        fundingCurrencySymbol
        createdAt
        customer {
          id
        }
        client {
          id
          salesTax {
            id
            provinceStateName
            taxPercentage
            provinceState
          }
        }
      }
      predictedEstimate {
        id
      }
      billingAddress {
        lineOne
        city
        state
        postalCode
        countryCode
      }
      shippingAddress {
        id
        lineOne
        state
        postalCode
        countryCode
        city
        shippingDetail {
          hasLoadingDock
        }
      }
      client {
        id
        name
        crmId
        taxId
        accountExecutive {
          firstName
          lastName
        }
        salesTax {
          provinceState
          taxPercentage
        }
        contacts {
          id
          crmId
          firstName
          lastName
          email
        }
        locations {
          id
          lineOne
          city
          state
          countryCode
          postalCode
        }
        credit {
          id
          reasoning
          amountRemaining
          currency {
            symbol
          }
        }
      }
      estimates {
        lead {
          companyName
        }
        salesTax {
          provinceState
        }
        id
        number
        subtotal
        totalCost
        total
        taxTotal
        createdAt
        taxPercentage
        productsJson
        lineItems {
          id
          lineNumber
          totalCost
          item {
            skuNumber
            title
            accountingDescription
            productGroups {
              name
            }
            unitOfMeasurement {
              name
            }
            vendor {
              id
              name
            }
            images {
              url
            }
          }
          productUuid
          pricePerUnit
          costPerUnit
          itemQuantity
          salesRepNote
          discountPerUnit
          appliedPromotion {
            name
          }
        }
        vendorsData {
          name
          # logoImageUrl
          taxIndicator
          locations {
            isBilling
            lineOne
            city
            state
            postalCode
          }
        }
      }
      selectedEstimate {
        number
        id
        subtotal
        taxTotal
        shippingCost
        total
        lineItems {
          totalProfit
        }
        currencyConversionRate
        vendorsShippingCosts {
          vendor {
            name
            id
          }
          costCad
        }
      }
      createdAt
      paymentTransactions {
        id
        createdAt
        receivedDate
        success
        amount
        referenceAttribute
        amountReceived
        method
      }
      emails {
        status
        email
        createdAt
        template {
          templateName
          html
          recommendedResendFrequencyInDays
        }
      }
      vendors {
        name
      }
      statusHistory {
        status {
          name
          description
        }
        createdAt
      }
      clientRequestedReQuoteOf {
        # id
        selectedEstimate {
          lead {
            companyName
          }
          salesTax {
            provinceState
          }
          id
          number
          subtotal
          total
          taxTotal
          createdAt
          taxPercentage
          productsJson
          lineItems {
            id
            item {
              title
              accountingDescription
              productGroups {
                name
              }
              unitOfMeasurement {
                name
              }
              vendor {
                id
                name
              }
            }
            productUuid
            pricePerUnit
            itemQuantity
            discountPerUnit
            appliedPromotion {
              name
            }
          }
          vendorsData {
            name
            taxIndicator
            locations {
              isBilling
              lineOne
              city
              state
              postalCode
            }
          }
        }
      }
    }
  }
`;

export const DELETE_LINE = gql`
  mutation deleteEstimateLineItem($id: ID!) {
    deleteEstimateLineItem(id: $id) {
      lineItemId
    }
  }
`;

export const EDIT_LINE = gql`
  mutation updateEstimateLineItem($id: ID!, $qty: Decimal!) {
    updateEstimateLineItem(id: $id, quantity: $qty) {
      estimateLineItem {
        id
        itemQuantity
      }
    }
  }
`;

export const CREATE_LEAD = gql`
  mutation createLead($input: LeadInput!) {
    createLead(leadData: $input) {
      lead {
        id
      }
    }
  }
`;

export const UPDATE_LEAD = gql`
  mutation createLead($input: LeadInput!) {
    updateLead(leadData: $input) {
      lead {
        id
      }
    }
  }
`;

export const CREATE_ESTIMATE = gql`
  mutation createEstimate(
    $estimateData: EstimateInput!
    $customerId: ID
    $clientId: ID
    $shippingId: ID
    $cost: Decimal
  ) {
    createEstimate(
      isSparePartsOrder: true
      estimateData: $estimateData
      customerId: $customerId
      clientId: $clientId
      clientShippingAddressId: $shippingId
      shippingCost: $cost
    ) {
      estimate {
        number
        email
        taxPercentage
        dueDate
        dataJson
        memo
        lineItems {
          lineNumber
          pricePerUnit
          itemQuantity
        }
      }
      leadId
      orderId
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation addLocation(
    $addressData: AddressInput!
    $customer: ID
    $client: ID!
  ) {
    addLocationToClient(
      addressData: $addressData
      customerId: $customer
      clientId: $client
    ) {
      locationId
    }
  }
`;
export const SPARE_ORDERS = gql`
  query allOrders(
    $skip: Int
    $first: Int
    $mine: Boolean
    $order: String
    $idS: ID
    $orgS: String
    $estimateS: String
    $statusS: [String]
    $uuidSearch: ID
  ) {
    orders(
      salesTypes: ["spare_parts"]
      skip: $skip
      first: $first
      onlyMine: $mine
      orderBy: $order
      idSearch: $idS
      organizationName: $orgS
      selectedEstimateNumber: $estimateS
      statuses: $statusS
      uuidSearch: $uuidSearch
    ) {
      id
      currency
      client {
        id
        name
      }
      salesType
      currentStatus {
        name
      }
      paymentTransactions {
        amount
        amountReceived
      }
      hasInvoice
      createdAt
      dueDate
      vendorOrderNumber
      lead {
        companyName
        crmId
      }
      createdBy {
        firstName
        lastName
      }
      selectedEstimate {
        number
        total
        taxPercentage
      }
      vendors {
        name
      }
    }
  }
`;
//TODO

export const ALL_ORDERS = gql`
  query allOrders(
    $skip: Int
    $first: Int
    $mine: Boolean
    $order: String
    $idS: ID
    $orgS: String
    $estimateS: String
    $statusS: [String]
    $paid: Boolean
    $vendor: ID
    $uuidSearch: ID
  ) {
    orders(
      salesTypes: ["e_commerce", "spare_parts", "custom"]
      skip: $skip
      first: $first
      onlyMine: $mine
      orderBy: $order
      idSearch: $idS
      organizationName: $orgS
      selectedEstimateNumber: $estimateS
      statuses: $statusS
      paidOrdersOnly: $paid
      vendorId: $vendor
      uuidSearch: $uuidSearch
    ) {
      id
      salesType
      currency
      client {
        id
        name
      }
      currentStatus {
        name
      }
      paymentTransactions {
        amount
        amountReceived
      }
      hasInvoice
      expectedCloseDate
      createdAt
      dueDate
      vendorOrderNumber
      salesType
      lead {
        companyName
        crmId
      }
      createdBy {
        firstName
        lastName
      }
      selectedEstimate {
        number
        total
        taxPercentage
      }
      predictedEstimate {
        total
      }
      vendors {
        name
      }
    }
  }
`;

export const CUSTOM_ORDERS = gql`
  query allOrders(
    $skip: Int
    $first: Int
    $mine: Boolean
    $order: String
    $idS: ID
    $orgS: String
    $estimateS: String
    $statusS: [String]
    $paid: Boolean
    $vendor: ID
    $uuidSearch: ID
  ) {
    orders(
      salesTypes: ["e_commerce", "custom"]
      skip: $skip
      first: $first
      onlyMine: $mine
      orderBy: $order
      idSearch: $idS
      organizationName: $orgS
      selectedEstimateNumber: $estimateS
      statuses: $statusS
      paidOrdersOnly: $paid
      vendorId: $vendor
      uuidSearch: $uuidSearch
    ) {
      id
      salesType
      currency
      client {
        id
        name
      }
      currentStatus {
        name
      }
      paymentTransactions {
        amount
        amountReceived
      }
      hasInvoice
      expectedCloseDate
      createdAt
      dueDate
      vendorOrderNumber
      salesType
      lead {
        companyName
        crmId
      }
      createdBy {
        firstName
        lastName
      }
      selectedEstimate {
        number
        total
        taxPercentage
      }
      predictedEstimate {
        total
      }
      vendors {
        name
      }
    }
  }
`;

export const VENDOR_ORDERS = gql`
  query allOrders(
    $skip: Int
    $first: Int
    $order: String
    $idS: ID
    $orgS: String
    $estimateS: String
    $statusS: [String]
    $paid: Boolean
    $vendor: ID
    $uuidSearch: ID
  ) {
    orders(
      skip: $skip
      first: $first
      orderBy: $order
      idSearch: $idS
      organizationName: $orgS
      selectedEstimateNumber: $estimateS
      statuses: $statusS
      paidOrdersOnly: $paid
      vendorId: $vendor
      uuidSearch: $uuidSearch
    ) {
      id
      salesType
      currency
      client {
        id
        name
      }
      currentStatus {
        name
      }
      paymentTransactions {
        amount
        amountReceived
      }
      hasInvoice
      expectedCloseDate
      createdAt
      dueDate
      vendorOrderNumber
      salesType
      lead {
        companyName
        crmId
      }
      createdBy {
        firstName
        lastName
      }
      selectedEstimate {
        number
        total
        taxPercentage
      }
      predictedEstimate {
        total
      }
      vendors {
        name
      }
    }
  }
`;

export const USER = gql`
  query user {
    user {
      vendor {
        id
      }
    }
  }
`;
