import DescriptionList from "../../global/components/descriptionList/colWButton";

const ClientInfo = ({ data, map }) => {
  return (
    <div>
      <DescriptionList data={data} map={map} />
    </div>
  );
};

export default ClientInfo;
