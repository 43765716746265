import LinkButton from "../../global/components/inputs/button/linkStyle";
import Link from "../../global/components/inputs/link/simple";
import Button from "../../global/components/inputs/button/simple";

const ClientContacts = ({ data, map }) => {
  const { label, button, link, modalButton } = map;
  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-end mt-2">
        <h4 className="text-gray-500 ">{label}</h4>
        {!data[button.dependency] && <LinkButton data={button} />}
      </div>
      <div className=" flex flex-col mx-4 py-2">
        <div className="flex flex-row gap-2 ">
          <p>Live Link:</p>
          <Link
            value={`${link.labelPre}${data[link.value]}`}
            text={data[link.value] && `${link.labelPre}${data[link.value]}`}
          />
        </div>
        {data[button.dependency] && (
          <div className="w-full lg:w-1/5 mt-2">
            <Button data={modalButton} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientContacts;
