import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { selectModalOn, setModalOn } from "../../../hooks/client/clientSlice";
import { XIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";

const AddMerchant = ({ open, setOpen, handleMerchant }) => {
  const dispatch = useDispatch();
  const modalOn = useSelector(selectModalOn);
  const { register, handleSubmit } = useForm();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={modalOn === "assign-AE"}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              onSubmit={handleSubmit(handleMerchant)}
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6"
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-black text-gray-900"
                  >
                    Assign New Vendor Merchant
                  </Dialog.Title>
                </div>
              </div>
              <div className="flex flex-col items-start justify-center pt-2 ">
                <p className="text-gray-600 text-center">
                  Utilize the form to assign a new contact merchant for the
                  vendor.
                </p>
                <div className="w-full grid grid-cols-2 gap-x-4  items-center justify-center">
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-4 text-gray-800"
                    >
                      First Name:
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("first", {
                          required: true,
                        })}
                        className="block w-full  py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-4 text-gray-800"
                    >
                      Last Name:
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("last", {
                          required: true,
                        })}
                        className="block w-full  py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-4 text-gray-800"
                    >
                      Email:
                    </label>
                    <div className="mt-1">
                      <input
                        type="email"
                        {...register("email", {
                          required: true,
                        })}
                        className="block w-full  py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-4 text-gray-800"
                    >
                      Role:
                    </label>
                    <div className="mt-1">
                      <select
                        {...register("role", {
                          required: true,
                        })}
                        className="block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                      >
                        <option value="">Select...</option>
                        <option value="vendor_general">General</option>
                        <option value="vendor_admin">Admin</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-span-2 flex flex-row items-end  justify-center gap-3  mt-8">
                    <button
                      type="submit"
                      className="justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Add Merchant
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddMerchant;
