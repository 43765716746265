import ResolveUndefined from "../../utils/manipulate/undefined.js";

export default function AddressDisplay({ data }) {
  if (!data) return null;
  const { lineOne, city, state, countryCode, postalCode } = data;
  return (
    <div>
      <p>{ResolveUndefined(lineOne)},</p>
      <p>
        {city}, {state}, {countryCode}
      </p>
      <p>{postalCode}</p>
    </div>
  );
}
