import { Helmet } from "react-helmet";
import React, { useState } from "react";

import ComissionPanels from "../../components/panels/comissionPanels";
//COOKIES
import { useCookies } from "react-cookie";

const CommissionView = () => {
  const currentDate = new Date();
  const [cookies, setCookie] = useCookies();
  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth());
  const [update, setUpdate] = useState(false);

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Commission | One Supply Connect</title>
      </Helmet>
      <div className="bg-connectGray h-screen">
        <div className="flex flex-col flex-1 ">
          <main className="flex-1 pb-8 ">
            {/* Page header */}
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-7xl lg:mx-auto ">
                <div className="py-4 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                  <div className="flex-1 min-w-0">
                    {/* Profile */}
                    <div className="flex items-center justify-between">
                      <div>
                        <div className="flex items-center">
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            Commission Owed
                          </h1>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-4">
                          <div>
                            <label
                              htmlFor="location"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Month
                            </label>
                            <select
                              onChange={(e) => setMonth(e.target.value)}
                              defaultValue={currentDate.getMonth()}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            >
                              <option value={1}>January</option>
                              <option value={2}>February</option>
                              <option value={3}>March</option>
                              <option value={4}>April</option>
                              <option value={5}>May</option>
                              <option value={6}>June</option>
                              <option value={7}>July</option>
                              <option value={8}>August</option>
                              <option value={9}>September</option>
                              <option value={10}>October</option>
                              <option value={11}>November</option>
                              <option value={12}>December</option>
                            </select>
                          </div>
                          <div>
                            <label
                              htmlFor="location"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Year
                            </label>
                            <select
                              onChange={(e) => setYear(e.target.value)}
                              defaultValue={currentDate.getFullYear()}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                            >
                              <option value={2021}>2021</option>
                              <option value={2022}>2022</option>
                              <option value={2023}>2023</option>
                              <option value={2024}>2024</option>
                            </select>
                          </div>
                          <div className="flex items-end justify-end">
                            <button
                              type="button"
                              onClick={() => setUpdate(true)}
                              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-2 bg-connectGray">
              <div className=" px-4 sm:px-6 lg:px-8">
                <ComissionPanels
                  user={cookies.user?.user?.role?.name}
                  year={year}
                  month={month}
                  update={update}
                  setUpdate={setUpdate}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </main>
  );
};

export default CommissionView;
