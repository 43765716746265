/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { setPopOver } from "../../../hooks/sales/saleSlice";
import { useDispatch } from "react-redux";
const TextWTag = ({ props, inputs }) => {
  const dispatch = useDispatch();

  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
      <div className="col-span-2">
        <label className="block text-base font-medium text-gray-900 ">
          {props.label}
        </label>
        <p className="text-gray-500 text-sm">{props.subtitle}</p>
        <button
          onClick={() =>
            dispatch(setPopOver({ view: true, item: props.items }))
          }
          type="button"
          className="animate-pulse text-blue-400 hover:text-blue-900 text-sm font-medium"
        >
          View Details
        </button>
      </div>
      <div className="flex flex-row w-full">
        <input
          type="number"
          className="shadow-sm h-2/3 sm:text-sm p-1.5 pr-0 border border-gray-300 rounded-l-md w-2/5 "
          id={props.name}
          // {...register(registrationState)}
          placeholder={`0`}
        />
        <span className="flex w-3/5 h-2/3 items-center px-3  pl-2 rounded-r-md border border-l-0 border-gray-400 bg-gray-50 text-gray-500 sm:text-xs">
          {/* {currentLabel} nozzle(s) */}
          nozzle(s)
        </span>
      </div>
    </div>
  );
};

export default TextWTag;
