import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
  query getAllClients($mine: Boolean, $src: String) {
    clients(onlyMine: $mine, search: $src) {
      id
      name
      website
      isSeedValidated
      crmId
    }
  }
`;

export const GET_CLIENT = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      id
      name
      crmId
      website
      isSeedValidated
      logoImageUrl
      type
      taxId
      publicEcommerceCode
      salesTax {
        id
        provinceStateName
        taxPercentage
      }
      orders {
        currency
        id
        total
        dueDate
        expectedCloseDate
        salesType
        paymentTransactions {
          amount
        }
        currentStatus {
          name
        }
      }
      accountExecutive {
        id
        firstName
        lastName
      }
      contacts {
        id
        firstName
        lastName
        email
        phoneNumber
        crmId
        type
      }
      locations {
        id
        lineOne
        countryCode
        city
        state
        isPrimary
        postalCode
        isBilling
        shippingDetail {
          hasLoadingDock
        }
      }
    }
  }
`;
export const SALES_REPS = gql`
  query users {
    users(roleCodename: "sales_rep") {
      firstName
      lastName
      id
    }
  }
`;

export const SALES_TAX = gql`
  query salesTaxes {
    salesTaxes {
      id
      provinceState
      provinceStateName
      taxPercentage
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation updateC($id: ID!, $crm: String!) {
    updateCustomer(id: $id, crmId: $crm) {
      customer {
        id
      }
    }
  }
`;

export const ADD_LOCATION = gql`
  mutation updateA($id: ID!, $address: AddressInput!) {
    addLocationToClient(clientId: $id, addressData: $address) {
      locationId
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: ID!
    $code: String
    $rep: ID
    $tax: String
    $validated: Boolean
    $taxNumber: String
  ) {
    updateClient(
      id: $id
      crmId: $code
      salesRepId: $rep
      salesTaxState: $tax
      isSeedValidated: $validated
      taxId: $taxNumber
    ) {
      client {
        id
        crmId
      }
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation createClient(
    $company: ID!
    $crm: String!
    $type: String
    $email: String!
    $first: String!
    $last: String!
    $phone: String
  ) {
    addCustomerToClient(
      companyId: $company
      crmId: $crm
      customerType: $type
      email: $email
      firstName: $first
      lastName: $last
      phoneNumber: $phone
    ) {
      createdCustomer
    }
  }
`;

export const CREATE_LEAD = gql`
  mutation createLead($input: LeadInput!) {
    createLead(leadData: $input) {
      lead {
        id
        companyName
        crmId
        customerCrmId
        contactName
        email
        status
        phoneNumber
        productionGoal
        funding
        fundingCurrencySymbol
        createdAt
        customer {
          id
        }
        client {
          id
          salesTax {
            id
            provinceStateName
            taxPercentage
            provinceState
          }
        }
      }
    }
  }
`;

export const FETCH_CUSTOMER = gql`
  query fetchCustomerInvoices($company: String!) {
    fetchCustomerInvoices(companyName: $company) {
      id
      number
      alreadyImported
    }
  }
`;

export const FETCH_INVOICE = gql`
  query fetchCustomerInvoiceDetails($number: String!) {
    fetchCustomerInvoiceDetails(invoiceNumber: $number) {
      id
      dateCreated
      dueDate
      number
      companyName
      totalTax
      totalAmount
      balance
      currency
      lineItems {
        lineNumber
        itemName
        description
        quantity
        pricePerUnit
        totalAmount
        skuNumber
      }
      shippingAddress {
        lineOne
        city
        postalCode
        country
      }
      billingAddress {
        lineOne
        city
        postalCode
        country
      }
    }
  }
`;

export const GET_ORG = gql`
  query fetch($id: String!) {
    fetchPipedriveOrgData(crmId: $id) {
      name
      crmId
      address
      contacts {
        firstName
        lastName
        email
        phoneNumber
        crmId
      }
    }
  }
`;

export const INVOICE_UPLOAD = gql`
  mutation importQboInvoiceToConnect(
    $client: ID!
    $dateCreated: Date!
    $dateShipped: Date!
    $invoiceNumber: String!
    $lineItems: [LineItemInput]
    $orderCurrencySymbol: String!
    $salesRepId: ID!
    $location: String!
    $amount: Decimal!
    $method: String!
  ) {
    importQboInvoiceToConnect(
      clientId: $client
      dateCreated: $dateCreated
      dateShipped: $dateShipped
      invoiceNumber: $invoiceNumber
      lineItems: $lineItems
      orderCurrencySymbol: $orderCurrencySymbol
      orderType: "imported"
      salesRepId: $salesRepId
      location: $location
      paidAmount: $amount
      paymentMethod: $method
    ) {
      orderNumber
    }
  }
`;

export const ECOMMERCE_CODE = gql`
  mutation createClientEcommerceCode($id: ID!, $refresh: Boolean) {
    createClientEcommerceCode(clientId: $id, refresh: $refresh) {
      publicEcommerceCode
    }
  }
`;

export const EMAIL_INSTRUCTIONS = gql`
  query emailClientEcommerceInstructions($id: ID!, $ccEm: [String]!) {
    emailClientEcommerceInstructions(clientId: $id, ccEmails: $ccEm)
  }
`;
