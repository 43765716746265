import React from "react";

import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import ResolveUndefined from "../../global/utils/manipulate/undefined";

Font.register({
  family: "Roboto",
  src: "/roboto.ttf",
});
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "10px",
  },
  headerContainer: {
    marginTop: 12,
  },
  billTo: {
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 12,
    display: "flex",
    justifyContent: "start",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "start",
  },
  invoiceDate: {
    fontSize: 12,
  },
  text: {
    fontSize: 9,
  },
  label: {
    width: 60,
    fontFamily: "Helvetica-Bold",
    fontSize: 9,
  },

  HeaderS: {
    fontFamily: "Helvetica-Bold",
    color: "#1BABF2",
    fontSize: 9,
  },
  textS: {
    fontFamily: "Roboto",
    fontSize: 9,
  },
});

const BillTo = ({ estimate, orderDetails, isATG }) => (
  <View style={styles.container}>
    <View style={styles.headerContainer}>
      <Text style={styles.HeaderS}>
        {isATG ? "ATG Pharma Inc." : "Advanced Integrated Robotics"}
      </Text>
      <Text style={styles.textS}>
        {isATG
          ? "474 Iroquois Shore Rd, Unit 2"
          : " PO Box 48054, Mississauga, Ontario"}
      </Text>
      <Text style={styles.textS}>
        {isATG ? "Oakville, L6H 2Y7" : "RPO Dundas Shepard, L5A 4G8"}
      </Text>
      <Text style={styles.textS}>customersuccess@onesupply.co</Text>
      <Text style={styles.textS}>
        GST/HST Registration No:
        {isATG ? "883688202" : "717044895RT0001"}
      </Text>
    </View>
    <View style={styles.headerContainer}>
      <Text style={styles.HeaderS}>Bill To:</Text>
      <Text style={styles.textS}>{estimate?.lead?.companyName}</Text>
      {orderDetails.billingAddress && (
        <>
          <Text style={styles.textS}>
            {ResolveUndefined(orderDetails.billingAddress?.lineOne)},
          </Text>
          <Text style={styles.textS}>
            {orderDetails.billingAddress?.city},
            {orderDetails.billingAddress?.postalCode}
          </Text>
        </>
      )}
      <View style={styles.invoiceNoContainer}>
        <Text style={styles.textS}>ESTIMATE </Text>
        <Text style={styles.textS}>#{estimate?.number}</Text>
      </View>
      <View style={styles.invoiceDateContainer}>
        <Text style={styles.textS}>DATE: </Text>
        <Text style={styles.textS}>{estimate?.createdAt?.split("T")[0]}</Text>
      </View>
    </View>
  </View>
);

export default BillTo;
