/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useLazyQuery, useQuery, useMutation, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { PlusCircleIcon, XIcon } from "@heroicons/react/outline";
import { useCookies } from "react-cookie";

// COMPONENTS
import Header from "./header";
import Nav from "../nav";
import EditItemModal from "../../../components/modals/products/editItem";
import ImagesModal from "../../../components/modals/products/images";

const PRODUCTS = gql`
  query items(
    $vendor: ID
    $skip: Int
    $first: Int
    $search: String
    $group: [String]
    $tags: [String]
  ) {
    items(
      vendorId: $vendor
      skip: $skip
      first: $first
      search: $search
      productGroups: $group
      productTags: $tags
    ) {
      id
      skuNumber
      title
      accountingDescription
      versionNumber
      productGroups {
        name
        productGroupsCommission {
          percentage
        }
      }
      productTags {
        name
      }
      unitOfMeasurement {
        name
        units
      }
      isActive
      isArchived
      isAvailableOnEcommerce
      isSerialized
      leadTimeDaysInStock
      leadTimeDaysOutOfStock
      reliabilityPercentage
      price {
        pricePerUnit
      }
      defaultPricing {
        pricePerUnit
        costPerUnit
        nonCommissionPricePerUnitMarkup
        nonCommissionCostPerUnitMarkupCad
        nonCommissionCostPerUnitMarkupUsd
        overrideUsdPricePerUnit
      }
      usdPrice {
        pricePerUnit
        costPerUnit
      }
      images {
        id
        title
        url
      }
      commissionPercentage
      overrideCommissionPercentage
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation update($input: UpdateItemInput!) {
    updateItem(itemData: $input) {
      item {
        id
        isAvailableOnEcommerce
        isSerialized
      }
    }
  }
`;

const DELETE_IMAGE = gql`
  mutation deleteItemImage($id: ID) {
    deleteItemImage(imageId: $id) {
      success
    }
  }
`;

const ADD_IMAGE = gql`
  mutation addItemImage(
    $description: String!
    $file: Upload!
    $itemId: ID!
    $imageTitle: String!
  ) {
    addItemImage(
      description: $description
      file: $file
      itemId: $itemId
      imageTitle: $imageTitle
    ) {
      success
    }
  }
`;
const USER = gql`
  query user {
    user {
      vendor {
        id
      }
    }
  }
`;

const InventoryProductsView = () => {
  const user = useQuery(USER);
  const [getProducts, prod] = useLazyQuery(PRODUCTS, {
    fetchPolicy: "cache-and-network",
  });
  const { loading, data } = prod;
  const [open, setOpen] = useState(false);
  const [create, setCreate] = useState(false);
  const [openImages, setOpenImages] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [activeProduct, setActiveProduct] = useState(null);
  const methods = useForm();
  const { getValues } = methods;
  const [ecommerceActive, setEcommerceActive] = useState(false);
  const [serialized, setSerialized] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [uploadingImage, setUploading] = useState(false);
  const [skipValue, setSkipValue] = useState(0);
  const [cookies] = useCookies();

  const [uploadForm] = useMutation(ADD_IMAGE, {
    onCompleted: (d) => {
      setOpenImages(false);
      getProducts({
        variables: {
          vendor: vendor,
          skip: skipValue,
          first: 50,
          group: getValues().productGroup ? [getValues().productGroup] : [],
          tags: getValues().productTag ? [getValues().productTag] : [],
          search: null,
        },
      });
    },
    onError: (e) => alert(e),
  });

  const [deleteImage] = useMutation(DELETE_IMAGE, {
    onCompleted: (d) => {
      getProducts({
        variables: {
          vendor: vendor,
          skip: skipValue,
          first: 50,
          group: getValues().productGroup ? [getValues().productGroup] : [],
          tags: getValues().productTag ? [getValues().productTag] : [],
          search: null,
        },
      });
    },
    onError: (e) => alert(e),
  });

  const [updateItem] = useMutation(UPDATE_ITEM, {
    onCompleted: (d) => {
      setOpen(false);
      setActiveProduct(null);
      getProducts({
        variables: {
          vendor: vendor,
          skip: skipValue,
          first: 50,
          group: getValues().productGroup ? [getValues().productGroup] : [],
          tags: getValues().productTag ? [getValues().productTag] : [],
          search: null,
        },
      });
    },
    onError: (e) => alert(e),
  });

  const ProductHeaders = [
    { name: "SKU" },
    { name: "Title" },
    { name: "Description" },
    { name: "Price [CAD]" },
    { name: "Price [USD]" },
    { name: "commission %" },
    { name: "Profit Margin", exec: true },
    { name: "Group" },
    { name: "Images" },
  ];

  async function updateItemForm(data) {
    let variables = {
      id: activeProduct.id,
      title: data.title,
      description: data.description,
      accountingDescription: data.accountingDescription,
      costPerUnit: data.cost,
      pricePerUnit: data.price,
      isActive: editActive,
      isAvailableOnEcommerce: ecommerceActive,
      isSerialized: serialized,
      overrideUsdCostPerUnit:
        parseFloat(activeProduct?.usdPrice?.costPerUnit) !==
        parseFloat(data.costUSD)
          ? data.costUSD
          : null,
      //  subtract markup if exists * by fetched USD factor
      overrideUsdPricePerUnit:
        parseFloat(activeProduct?.usdPrice?.pricePerUnit) !==
        parseFloat(data.priceUSD)
          ? data.priceUSD
          : null,
      nonCommissionPricePerUnitMarkup: data.nonCommissionMarkup || "0.00",
      nonCommissionCostPerUnitMarkupCad: data.ncCostCAD || "0.00",
      nonCommissionCostPerUnitMarkupUsd: data.ncCostUSD || "0.00",
      overrideCommissionPercentage: data.commission || "0.00",
    };

    updateItem({
      variables: {
        input: variables,
      },
    });
  }

  const addImage = React.useCallback(
    (event) => {
      event.preventDefault();
      setUploading(true);
      const [file] = event.target.fileUpload.files || [];
      const id = activeProduct?.id;
      if (file)
        uploadForm({
          variables: {
            imageTitle: getValues("imageTitle"),
            itemId: getValues("itemID"),
            description: getValues("imageDescription"),
            file: file,
          },
        });
    },
    [ADD_IMAGE]
  );

  return (
    <main
      className="flex-1 relative z-0 overflow-y-auto focus:outline-none h-full "
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products | One Supply Connect</title>
      </Helmet>
      <Nav />
      <div className="flex flex-col px-4 sm:px-6 lg:px-8 h-full ">
        <Header
          getProducts={getProducts}
          skipValue={skipValue}
          setSkipValue={setSkipValue}
          setCreate={setCreate}
          vendor={vendor}
          setVendor={setVendor}
          methods={methods}
          user={user.data}
        />
        <EditItemModal
          open={open}
          setOpen={setOpen}
          editActive={editActive}
          setEditActive={setEditActive}
          activeProduct={activeProduct}
          updateItemForm={updateItemForm}
          ecommerceActive={ecommerceActive}
          setEcommerceActive={setEcommerceActive}
          setActiveProduct={setActiveProduct}
          serialized={serialized}
          setSerialized={setSerialized}
        />
        <ImagesModal
          openImages={openImages}
          setOpenImages={setOpenImages}
          addImage={addImage}
          methods={methods}
          activeProduct={activeProduct}
          loading={uploadingImage}
        />
        <div className="flex h-3/4 overflow-auto flex-col mt-4">
          <table className="border rounded-lg">
            <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
              <tr>
                {ProductHeaders.map((entry, index) => {
                  return (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {!entry.exec && entry.name}
                      {entry.exec &&
                        cookies.user?.user?.role?.name === "Executive" &&
                        entry.name}
                    </th>
                  );
                })}
              </tr>
            </thead>
            {loading || !data ? (
              <tbody>
                <tr>
                  <td />
                  <td />
                  <td />
                  <svg
                    className="animate-spin h-24 w-24 text-blue-200 self-center mr-2 text-center"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 40 40"
                    enableBackground="new 0 0 40 40"
                    xml="preserve"
                  >
                    <path
                      opacity="0.2"
                      d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                    />
                    <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                  </svg>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {data.items.map((product, pIndex) => (
                  <tr
                    key={product?.skuNumber}
                    className={
                      pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <button
                        type="button"
                        onClick={() => {
                          setEditActive(product.isActive ? true : false);
                          setEcommerceActive(
                            product.isAvailableOnEcommerce ? true : false
                          );
                          setSerialized(product.isSerialized ? true : false);
                          setActiveProduct(product);
                          setOpen(true);
                        }}
                        className="text-left gap-3 w-full text-blue-600 hover:text-blue-900"
                      >
                        {product?.skuNumber}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product?.title}
                    </td>
                    <td className="px-6 py-4 max-w-md text-sm text-gray-500">
                      {/*apply new line if string has "\n" */}
                      {product &&
                        product.accountingDescription &&
                        product.accountingDescription
                          .split("\n")
                          .map((entry, index) => (
                            <span key={index}>
                              {entry}
                              <br />
                            </span>
                          ))}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      $
                      {product?.price?.pricePerUnit?.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      $
                      {parseFloat(product?.usdPrice?.pricePerUnit)
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product.overrideCommissionPercentage
                        ? parseFloat(product.overrideCommissionPercentage) * 100
                        : parseFloat(product.commissionPercentage) * 100}
                      %
                    </td>
                    {cookies.user?.user?.role?.name === "Executive" && (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
                          {(
                            ((parseFloat(product.defaultPricing?.pricePerUnit) -
                              parseFloat(product.defaultPricing?.costPerUnit)) /
                              parseFloat(
                                product.defaultPricing?.pricePerUnit
                              )) *
                            100
                          ).toFixed(0)}{" "}
                          %
                        </span>
                      </td>
                    )}
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          product?.isActive
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {product?.productGroups[0]?.name}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 ">
                      <div className="flex flex-row gap-4 items-center justify-center ">
                        <button
                          type="button"
                          onClick={() => {
                            setActiveProduct(product);
                            setOpenImages(true);
                          }}
                          className="text-blue-600 flex flex-row gap-1 items-center hover:text-blue-800"
                        >
                          <PlusCircleIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>
                        <div className="flex flex-col gap-y-2">
                          {product?.images.map((image) => (
                            <div className="flex flex-row justify-between">
                              <a
                                key={image.title}
                                href={image.url}
                                rel="noreferrer"
                                target="_blank"
                                className="text-gray-500 text-xs flex flex-row gap-1 items-center hover:text-blue-800"
                              >
                                - {image.title}
                              </a>
                              <button
                                type="button"
                                onClick={() => {
                                  deleteImage({ variables: { id: image.id } });
                                }}
                                className="text-blue-600 flex flex-row gap-1 items-center hover:text-blue-800"
                              >
                                <XIcon
                                  className="h-4 w-4 text-red-600 ml-2"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        {data && (
          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm  bg-gray-50 border-gray-200 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium text-blue-900 ">
                  {1 + skipValue}
                </span>{" "}
                to{" "}
                <span className="font-medium text-blue-900 ">
                  {50 + skipValue}
                </span>{" "}
                of <span className="font-medium text-green-900">625</span> Items
              </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
              {skipValue > 49 && (
                <button
                  onClick={() => setSkipValue(skipValue - 50)}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Previous
                </button>
              )}
              {data.items.length === 50 && (
                <button
                  onClick={() => setSkipValue(skipValue + 50)}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                >
                  Next
                </button>
              )}
            </div>
          </nav>
        )}
      </div>
    </main>
  );
};

export default InventoryProductsView;
