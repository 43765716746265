import { SearchCircleIcon } from "@heroicons/react/outline";

export default function NotAvailable({ label }) {
  return (
    <div className="h-full border-2 border-gray-200 border-dashed rounded-lg">
      <div className="w-full my-48 flex flex-col justify-center items-center ">
        <SearchCircleIcon
          className="h-8 w-8 text-gray-500"
          aria-hidden="true"
        />
        <h2 className="text-lg text-gray-500">
          There are no {label} available
        </h2>
      </div>
    </div>
  );
}
