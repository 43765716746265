import { Fragment, useState, useEffect } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  CurrencyDollarIcon,
  HomeIcon,
  SupportIcon,
  LightBulbIcon,
  UsersIcon,
  ArchiveIcon,
  XIcon,
  MenuAlt2Icon,
  CollectionIcon,
} from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";

// MOCK
import RoutesData from "../../mock/routesData";

//COOKIES
import { useCookies } from "react-cookie";

const Icons = {
  HomeIcon: HomeIcon,
  CurrencyDollarIcon: CurrencyDollarIcon,
  SupportIcon: SupportIcon,
  LightBulbIcon: LightBulbIcon,
  UsersIcon: UsersIcon,
  ArchiveIcon: ArchiveIcon,
  CollectionIcon: CollectionIcon,
};

const userNavigation = [
  // { name: "Your Profile", href: "#" },
  // { name: "Settings", href: "/settings" },
  { name: "Sign out", href: "/", signOut: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Base({ View }) {
  const { routes } = RoutesData;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navRoutes = routes.filter((route) => route.is_main);
  const [cookies, setCookie] = useCookies();
  const [url, setUrl] = useState(null);
  const navigate = useNavigate();
  async function handleSignOut() {
    localStorage.removeItem("report");
    setCookie("user", null, { path: "/" });
    window.location.reload();
  }
  useEffect(() => {
    switch (window.location.href.split("/")[3]) {
      case "clients":
        setUrl("Clients");
        break;
      case "products":
      case "samples":
        setUrl("Products");
        break;
      case "leads":
      case "orders":
      case "spare":
      case "estimate":
        setUrl("Sales");
        break;
      default:
        break;
    }
  }, []);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-100">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex flex-row items-center justify-between px-6 py-3 border-b border-gray-300">
                  <a href="/">
                    <img
                      className="h-14 w-auto"
                      src="/logo.png"
                      alt="ATG Logo"
                    />
                  </a>
                  <p className="text-xs font-extrabold  text-gray-600 ">
                    Version: 0.5.0 Beta
                  </p>
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  {navRoutes.map((item) => {
                    const NavIcon = Icons[item.icon_code];
                    return (
                      <a
                        key={item.label}
                        href={item.path}
                        className={classNames(
                          url === item.label
                            ? "bg-gray-400 text-white"
                            : "text-gray-700 hover:bg-gray-500 hover:text-white",
                          "group flex items-center px-2 py-3 font-medium rounded-md"
                        )}
                      >
                        <NavIcon
                          className={classNames(
                            url === item.label
                              ? "text-white"
                              : "text-gray-600 group-hover:text-white",
                            "mr-3 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.label}
                      </a>
                    );
                  })}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" />
        </Dialog>
      </Transition.Root>

      {/*DESKTOP */}

      <div className="hidden">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1 bg-gray-100 border-r border-gray-300">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex flex-row items-center justify-between px-4 py-2">
                <a href="/">
                  <img
                    className="h-8 w-auto"
                    src="https://atg-online-resources.s3.amazonaws.com/logo.png"
                    alt="ATG Logo"
                  />
                </a>
                <p className="text-xs font-extrabold  text-gray-600 ">
                  Version: 0.5.0 Beta
                </p>
              </div>
              <div className="p-4 my-4  flex items-center justify-between border-t border-b border-gray-300">
                <div className="flex flex-row item-center justify-start">
                  <div>
                    <img
                      className="inline-block h-10 w-10 rounded-full"
                      src={cookies.user?.user?.image}
                      alt={"profile"}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="font-medium text-gray-700">
                      {cookies.user?.user?.firstName}{" "}
                      {cookies.user?.user?.lastName}
                    </p>
                    {/* <p className="text-sm font-medium text-blue-400 group-hover:text-white">
                      View profile
                    </p> */}
                  </div>
                </div>
                <Menu as="div" className="relative inline-block text-left">
                  {({ open }, index) => (
                    <Fragment>
                      <div>
                        <Menu.Button className="bg-transparent rounded-full flex items-center text-gray-900 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                          <span className="sr-only">Open options</span>
                          <DotsVerticalIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <div className="py-1">
                            {userNavigation.map((entry) => (
                              <Menu.Item key={entry.name}>
                                {({ active }) => (
                                  <a
                                    href={entry.href}
                                    onClick={() =>
                                      entry.signOut && handleSignOut()
                                    }
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    {entry.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Fragment>
                  )}
                </Menu>
              </div>
              <nav className="mt-5 flex-1 px-2 bg-gray-100 space-y-1">
                {navRoutes.map((item) => {
                  const NavIcon = Icons[item.icon_code];
                  return (
                    <a
                      key={item.label}
                      href={item.path}
                      className={classNames(
                        url === item.label
                          ? "bg-gray-400 text-white"
                          : "text-gray-700 hover:bg-gray-500 hover:text-white",
                        "group flex items-center px-2 py-3 font-medium rounded-md"
                      )}
                    >
                      <NavIcon
                        className={classNames(
                          url === item.label
                            ? "text-white"
                            : "text-gray-600 group-hover:text-white",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.label}
                    </a>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/*NEW Desktop BAR */}
      <div className="hidden md:flex flex-col w-0 flex-1 overflow-auto">
        <div className=" bg-connectGray py-2 px-4 sm:px-6 lg:px-8  flex flex-row justify-between items-center border-b border-gray-300">
          <div className="flex flex-row items-end justify-between pr-4 gap-2 ">
            <a href="/">
              <img
                className="h-14 w-auto"
                // src="https://atg-online-resources.s3.amazonaws.com/logo.png"
                src="/logo.png"
                alt="ATG Logo"
              />
            </a>
            <p className="text-xs font-bold  text-gray-600 ">
              Version: 0.5.0 Beta
            </p>
          </div>
          <div className="  flex items-center justify-between gap-4 ">
            <div className="flex flex-row items-center justify-center">
              <p className="font-medium text-gray-700 text-center mr-4">
                {cookies.user?.user?.firstName} {cookies.user?.user?.lastName}
              </p>
              <div>
                <img
                  className="inline-block h-10 w-10 rounded-full"
                  src={
                    cookies.user?.user?.profileimageSet &&
                    cookies.user?.user?.profileimageSet[0]
                      ? cookies.user?.user?.profileimageSet[0]?.user
                          ?.profileImageUrl
                      : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  }
                  alt={"tim cook"}
                />
              </div>
            </div>
            <Menu as="div" className="relative inline-block text-left">
              {({ open }, index) => (
                <Fragment>
                  <div>
                    <Menu.Button className="bg-transparent rounded-full flex items-center text-gray-900 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <div className="py-1">
                        {userNavigation.map((entry) => (
                          <Menu.Item key={entry.name}>
                            {({ active }) => (
                              <a
                                href={entry.href}
                                onClick={() => entry.signOut && handleSignOut()}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                {entry.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Fragment>
              )}
            </Menu>
          </div>
        </div>
        <div className=" bg-connectGray py-1 sm:py-3 px-4 sm:px-6 lg:px-8  flex flex-row text-gray-500 justify-start gap-6 items-center border-b border-gray-300">
          {/* <button
            className="-ml-0.5 -mt-0.5 inline-flex items-center text-base justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            <p className="pl-1">All</p>
          </button> */}
          <a href="/" className="hover:text-gray-900">
            Home
          </a>
          {cookies.user?.user?.role?.name !== "Vendor Admin" && (
            <a href="/clients" className="hover:text-gray-900">
              Clients
            </a>
          )}
          <a href="/products" className="hover:text-gray-900">
            Products
          </a>
          <button
            type="button"
            onClick={() =>
              navigate({
                pathname: "/orders",
                search: "?view=custom",
              })
            }
            className="hover:text-gray-900"
          >
            Sales
          </button>
          {(cookies.user?.user?.role?.name !== "Vendor Admin" ||
            cookies.user?.user?.firstName === "Gary") && (
            <a href="/invoices" className="hover:text-gray-900">
              Invoices
            </a>
          )}
          {cookies.user?.user?.role?.name !== "Vendor Admin" && (
            <a href="/vendors" className="hover:text-gray-900">
              Vendors
            </a>
          )}
          {cookies.user?.user?.role?.name !== "Vendor Admin" && (
            <a href="/commission" className="hover:text-gray-900">
              Commission
            </a>
          )}
        </div>
        <View />
      </div>

      {/*MOBILE BAR */}
      <div className=" md:hidden flex flex-col w-0 flex-1 overflow-auto">
        <div className=" bg-gray-100 px-1 py-1 sm:px-3 sm:py-3 flex flex-row justify-between items-center border-b border-gray-300">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-8 w-8" aria-hidden="true" />
          </button>
          <Menu as="div" className="mr-3 relative">
            {({ open }) => (
              <Fragment>
                <div>
                  <Menu.Button className="max-w-xs flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-10 w-10 rounded-full"
                      src={
                        cookies.user?.user?.profileimageSet
                          ? cookies.user?.user?.profileimageSet[0]?.user
                              ?.profileImageUrl
                          : "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      }
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none z-40"
                  >
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            onClick={() => item.signOut && handleSignOut()}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block py-2 px-4 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Fragment>
            )}
          </Menu>
        </div>
        <View />
      </div>
    </div>
  );
}
