import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { useDispatch } from "react-redux";

import {
  setShippingDiscount,
  setExpiryDate,
} from "../../../Sales/hooks/estimateSlice";

const DiscountExpiry = ({
  setExpiryModal,
  showExpiry,
  handleSubmitEstimate,
  blocks,
  setAlertCrating,
  alertCondition,
  submitting,
}) => {
  const dispatch = useDispatch();
  const [expiryAlert, setAlert] = useState(false);
  return (
    <Transition.Root show={showExpiry} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setExpiryModal}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon
                    className="h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Discount Expiry
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Please provide the expiration date for the discount
                      applied as well as selecting a shipping discount
                      percentage if applicable.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4 ml-12 border-t border-gray-200 w-full grid grid-cols-4">
                <div className="col-span-2 ">
                  <label
                    htmlFor="description"
                    className="block  font-medium pt-2 text-gray-800"
                  >
                    Discount Expires In:
                  </label>
                  <div className="mt-1 grid grid-cols-3 gap-2">
                    <input
                      type="number"
                      id="expiryDate"
                      onChange={(e) => dispatch(setExpiryDate(e.target.value))}
                      className="block col-span-2 w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                    <span>Days</span>
                  </div>
                </div>
                <div />
                <div className="col-span-2 ">
                  <label
                    htmlFor="description"
                    className="block  font-medium pt-2 text-gray-800"
                  >
                    Discount Shipping{" "}
                    <span className="text-xs">[optional]:</span>
                  </label>
                  <div className="mt-1 grid grid-cols-3 gap-2">
                    <input
                      type="number"
                      id="expiryDate"
                      onChange={(e) => {
                        if (e.target.value > 100) e.target.value = 100;
                        if (e.target.value < 0) e.target.value = 0;
                        dispatch(
                          setShippingDiscount(parseFloat(e.target.value) / 100)
                        );
                      }}
                      className="col-span-2 block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
              {expiryAlert && (
                <p className="text-right mt-4 text-red-600 text-sm">
                  An Expiry Date is Required
                </p>
              )}
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  onClick={() => {
                    if (document.getElementById("expiryDate").value) {
                      if (alertCondition) {
                        setExpiryModal(false);
                        setAlertCrating(true);
                      } else {
                        handleSubmitEstimate(blocks);
                      }
                    } else {
                      setAlert(true);
                    }
                  }}
                  disabled={submitting}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {submitting && (
                    <svg
                      className="animate-spin h-5 w-5 text-white self-center mr-2"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40 40"
                      enableBackground="new 0 0 40 40"
                      xml="preserve"
                    >
                      <path
                        opacity="0.2"
                        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                      />
                      <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                    </svg>
                  )}
                  Save Estimate
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setExpiryModal(false)}
                >
                  Return
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DiscountExpiry;
