/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useApolloClient, gql, useMutation } from "@apollo/client";
import {
  setLineItems,
  setPrice,
  selectTaxPercentage,
  selectClient,
  selectContact,
  selectLineItems,
  selectCurrency,
} from "./saleSlice";
import { selectBeethovenProducts } from "../estimate/estimateSlice";
import { useDispatch, useSelector } from "react-redux";
import { CREATE_LEAD, CREATE_ESTIMATE, ADD_LOCATION } from "./queries";

const useEstimateSubstances = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const taxPercentage = useSelector(selectTaxPercentage);
  const selectedClient = useSelector(selectClient);
  const selectedContact = useSelector(selectContact);
  const currency = useSelector(selectCurrency);
  const lineItems = useSelector(selectLineItems);
  const beethovenProducts = useSelector(selectBeethovenProducts);
  const [value, setValue] = useState(null);
  const [expected, setExpected] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shipping, setShippingId] = useState(null);
  const [addLocation, location] = useMutation(ADD_LOCATION, {
    onCompleted: (d) => {
      setShippingId(d.addLocationToClient.locationId);
      handleLead();
    },
    onError: (e) => alert(e),
  });
  const [createLead, lead] = useMutation(CREATE_LEAD, {
    onCompleted: (d) => {
      handleCreateEstimate(d.createLead.lead.id);
    },
    onError: (e) => alert(e),
  });

  const [createEstimate, estimate] = useMutation(CREATE_ESTIMATE, {
    onCompleted: (d) => (window.location = `/orders?view=spare`),
    onError: (e) => alert(e),
  });

  async function handleCart(data) {
    let filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, entry]) =>
          entry !== undefined &&
          entry !== null &&
          entry !== "" &&
          entry !== "0" &&
          entry !== false
      )
    );
    let lineItems = [];
    let index = 1;
    let total = 0;
    for (const sku of Object.keys(filteredData)) {
      if (sku !== "search") {
        try {
          const item = (
            await client.query({
              query: gql`
                query itemBySKU($sku: String!) {
                  item(skuNumber: $sku) {
                    title
                    productGroups {
                      name
                    }
                    price {
                      pricePerUnit
                    }
                    ecommercePrice {
                      pricePerUnit
                    }
                    ecommerceUsdPrice {
                      pricePerUnit
                    }
                    unitOfMeasurement {
                      units
                    }
                  }
                }
              `,
              variables: { sku: sku },
            })
          ).data.item;
          let itemPrice =
            currency === "USD"
              ? item?.ecommerceUsdPrice.pricePerUnit
              : item?.ecommercePrice.pricePerUnit;
          let lineItemPrice = parseFloat(itemPrice).toFixed(2);
          let lineItemTotal =
            parseFloat(lineItemPrice) * parseFloat(filteredData[sku]);
          lineItems.push({
            lineNumber: index,
            skuNumber: sku,
            title: item.title,
            pricePerUnit: lineItemPrice,
            pricePerUnitCad: item?.ecommercePrice.pricePerUnit,
            quantity: filteredData[sku],
            total: lineItemTotal,
            tray: item.productGroups[0].name === "Tray",
          });
          total += lineItemTotal;
        } catch {
          return false;
        }
        index++;
      }
    }
    let tax = total * taxPercentage;

    await dispatch(setLineItems(lineItems));
    await dispatch(
      setPrice({
        price: total,
        tax: tax,
        total: total + tax,
      })
    );
    return true;
  }

  async function handleCreateEstimate(id) {
    let parsedLineItems = JSON.parse(JSON.stringify(lineItems));
    parsedLineItems.forEach((item) => {
      delete item.title;
      delete item.tray;
      delete item.pricePerUnit;
    });
    let location =
      shipping &&
      selectedClient.locations.find((entry) => {
        return entry.id === shipping;
      });
    let code = "ON";
    if (location) {
      if (
        location.countryCode === "US" ||
        location.countryCode === "USA" ||
        location.countryCode === "WLD"
      ) {
        code = "US";
      } else {
        code = location.state;
      }
    }
    let body = {
      estimateData: {
        leadId: id,
        lineItems: parsedLineItems,
        email: selectedContact.email,
        locationAbbreviation: selectedClient.salesTax.provinceState,
        shippingStateAbbreviation: location.state,
      },
      shippingId: shipping,
      clientId: parseInt(selectedClient.id),
      customerId: parseInt(selectedContact.id),
      productsData: beethovenProducts || [],
    };
    value && (body.cost = parseFloat(value));
    value && (body.shippingCost = parseFloat(value));

    createEstimate({
      variables: body,
    });
  }

  async function handleLead(dealValue, shippingID) {
    let ID = shipping || shippingID;
    let location =
      ID &&
      selectedClient.locations.find((entry) => {
        return entry.id === ID;
      });
    const leadInput = {
      companyId: selectedClient.id,
      customerId: selectedContact.id,
      companyName: selectedClient.name,
      contactName: `${selectedContact.firstName} ${selectedContact.lastName}`,
      email: selectedContact.email,
      productionGoal: "Spare Parts Order",
      funding: dealValue,
      fundingCurrencySymbol:
        location && location.countryCode === "CA" ? "CAD" : "USD",
      isSpareParts: true,
    };
    createLead({
      variables: {
        input: leadInput,
      },
    });
  }
  async function handleIssue(
    shippingId,
    newAddress,
    formData,
    overrideValue,
    total
  ) {
    setShippingId(shippingId);
    setValue(overrideValue);
    setIsSubmitting(true);
    // setExpected(formData.expected);
    if (newAddress) {
      let addressVariables = {
        addressData: {
          lineOne: formData.lineOne,
          countryCode: formData.country,
          postalCode: formData.postalCode,
          city: formData.city,
          state: formData.state,
        },
        customer: selectedContact.id,
        client: selectedClient.id,
      };
      addLocation({
        variables: addressVariables,
      });
    } else handleLead(total, shippingId);
  }
  return {
    handleCart,
    handleIssue,
    isSubmitting,
  };
};
export default useEstimateSubstances;
