/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { XIcon } from "@heroicons/react/outline";

const EditAddress = ({
  openAddress,
  setOpenAddress,
  address,
  handleEditAddress,
}) => {
  const { register, getValues, setValue } = useForm();
  useEffect(() => {
    setValue("id", address?.id);
    setValue("lineOne", address?.lineOne);
    setValue("city", address?.city);
    setValue("state", address?.state);
    setValue("countryCode", address?.countryCode);
    setValue("postalCode", address?.postalCode);
  }, [address]);
  return (
    <Transition.Root show={openAddress} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={openAddress}
        onClose={setOpenAddress}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  onClick={() => setOpenAddress(false)}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-black text-gray-900"
                  >
                    Edit Address Information
                  </Dialog.Title>
                </div>
              </div>
              <div className="flex flex-col items-start justify-center pt-2 ">
                <ul className="ml-5 list-disc text-left">
                  <p className="text-gray-600">
                    Please populate the form with the Address Information.
                  </p>
                  <div className="grid grid-cols-4">
                    <div className="col-span-4">
                      <div className="grid grid-cols-3 gap-x-4 gap-y-1">
                        <div>
                          <label
                            htmlFor="description"
                            className="block text-sm pt-2 text-gray-800"
                          >
                            Line One:
                          </label>
                          <div className="mt-1">
                            <input
                              id="firstName"
                              {...register("lineOne")}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="description"
                            className="block text-sm pt-2 text-gray-800"
                          >
                            City:
                          </label>
                          <div className="mt-1">
                            <input
                              id="lastName"
                              {...register("city")}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <label
                            htmlFor="description"
                            className="block text-sm pt-2 text-gray-800"
                          >
                            State:
                          </label>
                          <div className="mt-1">
                            <input
                              id="name"
                              {...register("state")}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <label
                            htmlFor="description"
                            className="block text-sm pt-2 text-gray-800"
                          >
                            Country Code:
                          </label>
                          <div className="mt-1">
                            <input
                              id="name"
                              {...register("countryCode")}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                            />
                          </div>
                        </div>
                        <div className="col-span-1">
                          <label
                            htmlFor="description"
                            className="block text-sm pt-2 text-gray-800"
                          >
                            Postal Code:
                          </label>
                          <div className="mt-1">
                            <input
                              id="name"
                              {...register("postalCode")}
                              type="text"
                              className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-span-3 flex flex-row items-end  justify-center gap-3  mt-8">
                        <button
                          type="button"
                          onClick={() => handleEditAddress(getValues())}
                          className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          Edit Address
                        </button>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EditAddress;
