import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

//HOOKS
import useClient from "../../hooks/useClient";
import { selectActiveContact } from "../../hooks/clientSlice";

//COMPONENTS
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Text from "../../../global/components/inputs/text/simple";
import Select from "../../../global/components/inputs/select/simple";

const AddClientModal = ({ modalOn, setModal }) => {
  const methods = useForm();
  const activeContact = useSelector(selectActiveContact);
  const { handleSubmit, setValue } = methods;
  const { updatingCustomer, handleUpdateContact } = useClient();

  useEffect(() => {
    if (modalOn === "update-contact" && activeContact) {
      setValue("firstName", activeContact.firstName);
      setValue("lastName", activeContact.lastName);
      setValue("email", activeContact.email);
      setValue("phoneNumber", activeContact.phoneNumber);
      setValue("crm", activeContact.crmId);
      setValue("type", activeContact.type);
    }
  }, [activeContact]);

  return (
    <ModalFrame
      open={modalOn === "update-contact"}
      close={setModal}
      title={"UpdateContact"}
    >
      <form
        onSubmit={handleSubmit(handleUpdateContact)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the input to update contact
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-full grid grid-cols-2 gap-4">
            <Text
              data={{
                label: "First Name",
                textType: "name",
                methods: methods,
                registerValue: "firstName",
                registerOptions: { required: true },
              }}
            />
            <Text
              data={{
                label: "Last Name",
                textType: "name",
                methods: methods,
                registerValue: "lastName",
                registerOptions: { required: true },
              }}
            />
            <Text
              data={{
                label: "Email",
                textType: "email",
                methods: methods,
                registerValue: "email",
                registerOptions: { required: true },
              }}
            />
            <Text
              data={{
                label: "Phone",
                textType: "phone",
                methods: methods,
                registerValue: "phoneNumber",
                registerOptions: { required: true },
              }}
            />
            <Select
              label="Type"
              methods={methods}
              registerValue="type"
              registerOptions={{ required: true }}
              options={[
                { label: "Buyer", value: "buyer" },
                { label: "Alt Purchaser", value: "alternative_purchaser" },
                { label: "Alt Contact", value: "alternative_contact" },
                { label: "AP", value: "accounts_payable" },
              ]}
            />
            <Text
              data={{
                label: "PipeDrive ID",
                textType: "number",
                placeholder: "Enter PipeDrive ID",
                methods: methods,
                registerValue: "crm",
                registerOptions: { required: true },
              }}
            />
          </div>
          <ModalCTA cancel={setModal} submitting={updatingCustomer} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddClientModal;
