import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  view: "parameters",
  substances: [
    {
      product: { name: "product-1", value: "1" },
      label: "Liquid 1",
      value: "1",
    },
  ],
  parameters: [
    {
      container: { name: `container-1}`, value: null },
      volume: { name: `volume-1}`, value: null, label: null },
      heating: { name: `heating-1`, value: null },
      product: { name: `product-1`, value: "1" },
      system: { name: `system-1}`, value: null },
      machine: { name: `machine-1`, value: null },
    },
  ],
  estimate: [],
  beethovenResponse: null,
  beethovenProducts: null,
  totalPrice: 0,
  taxes: 0,
  province: null,
  shipProvince: null,
  pageState: { build: 1, page: 0 },
  pageData: null,
  fromTemplate: null,
  conversion: 1,
  forBuild: null,
  activeMenuIndex: "Templates",
  sampleModal: false,
  forItem: null,
  sampleError: false,
  primaryVendor: null,
  cratingPresent: false,
};

export const counterSlice = createSlice({
  name: "sales",
  initialState,

  reducers: {
    setView: (state, action) => {
      state.view = action.payload;
    },
    setSubstances: (state, action) => {
      state.substances = action.payload;
    },
    setParameters: (state, action) => {
      state.parameters = action.payload;
    },
    setEstimate: (state, action) => {
      state.estimate = action.payload;
    },
    setBeethoven: (state, action) => {
      state.beethovenResponse = action.payload;
    },
    setPageState: (state, action) => {
      state.pageState = action.payload;
    },
    setTotal: (state, action) => {
      state.totalPrice = action.payload;
    },
    setBeethovenProducts: (state, action) => {
      state.beethovenProducts = action.payload;
    },
    setProvince: (state, action) => {
      state.province = action.payload;
    },
    setTaxes: (state, action) => {
      state.taxes = action.payload;
    },
    setPageData: (state, action) => {
      state.pageData = action.payload;
    },
    setTemplate: (state, action) => {
      state.fromTemplate = action.payload;
    },
    setConversion: (state, action) => {
      state.conversion = action.payload;
    },
    setForBuild: (state, action) => {
      state.forBuild = action.payload;
    },
    setActiveMenuIndex: (state, action) => {
      state.activeMenuIndex = action.payload;
    },
    setSampleModal: (state, action) => {
      state.sampleModal = action.payload;
    },
    setForItem: (state, action) => {
      state.forItem = action.payload;
    },
    setShipProvince: (state, action) => {
      state.shipProvince = action.payload;
    },
    setSampleError: (state, action) => {
      state.sampleError = action.payload;
    },
    setPrimaryVendor: (state, action) => {
      state.primaryVendor = action.payload;
    },
    setCratingPresent: (state, action) => {
      state.cratingPresent = action.payload;
    },
  },
});

export const {
  setView,
  setSubstances,
  setParameters,
  setEstimate,
  setBeethoven,
  setPageState,
  setPageData,
  setTotal,
  setProvince,
  setTaxes,
  setShipProvince,
  setBeethovenProducts,
  setTemplate,
  setConversion,
  setForBuild,
  setActiveMenuIndex,
  setSampleModal,
  setForItem,
  setSampleError,
  setPrimaryVendor,
  setCratingPresent,
} = counterSlice.actions;

export const selectView = (state) => state.estimate.view;
export const selectSubstances = (state) => state.estimate.substances;
export const selectParameters = (state) => state.estimate.parameters;
export const selectEstimate = (state) => state.estimate.estimate;
export const selectBeethoven = (state) => state.estimate.beethovenResponse;
export const selectPage = (state) => state.estimate.pageState;
export const selectPageData = (state) => state.estimate.pageData;
export const selectTotal = (state) => state.estimate.totalPrice;
export const selectProvince = (state) => state.estimate.province;
export const selectTaxes = (state) => state.estimate.taxes;
export const selectTemplate = (state) => state.estimate.fromTemplate;
export const selectConversion = (state) => state.estimate.conversion;
export const selectForBuild = (state) => state.estimate.forBuild;
export const selectForItem = (state) => state.estimate.forItem;
export const selectActiveMenuIndex = (state) => state.estimate.activeMenuIndex;
export const selectSampleModal = (state) => state.estimate.sampleModal;
export const selectShipProvince = (state) => state.estimate.shipProvince;
export const selectSampleError = (state) => state.estimate.sampleError;
export const selectPrimaryVendor = (state) => state.estimate.primaryVendor;
export const selectCratingPresent = (state) => state.estimate.cratingPresent;
export const selectBeethovenProducts = (state) =>
  state.estimate.beethovenProducts;

export default counterSlice.reducer;
