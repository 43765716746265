import React, { useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { gql, useQuery, useLazyQuery } from "@apollo/client";

import useConfigureEstimate from "../hooks/useConfigureEstimate";

export const GET_TEMPLATE = gql`
  query estimateTemplate($template: ID!) {
    estimateTemplate(id: $template) {
      fromEstimate {
        taxPercentage
        currencyConversionRate
        template
        total
        productsJson
        lineItems {
          id
          lineNumber
          productUuid
          item {
            title
            skuNumber
            price {
              pricePerUnit
            }
            vendor {
              id
              name
            }
          }
          pricePerUnit
          itemQuantity
          discountPerUnit
        }
      }
    }
  }
`;

export const GET_TEMPLATES = gql`
  query estimateTemplates {
    estimateTemplates {
      id
      fromEstimate {
        template
      }
    }
  }
`;

const Templates = ({ open }) => {
  const templates = useQuery(GET_TEMPLATES);
  const { configureTemplate } = useConfigureEstimate();
  const [getTemplate] = useLazyQuery(GET_TEMPLATE, {
    onCompleted: (d) => configureTemplate(d.estimateTemplate.fromEstimate),
  });
  const [view, setView] = useState(true);

  if (!open) return null;
  return (
    <div className="w-full border-t border-b border-gray-400 py-2 bg-gray-50">
      <div className="flex w-full items-end justify-end text-gray-500 hover:text-gray-900">
        <button
          className="flex flex-row gap-1 font-bold mr-4"
          onClick={() => setView(!view)}
        >
          <p>Templates</p>
          {view ? (
            <ChevronUpIcon className="h-6 w-6 " aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-6 w-6 " aria-hidden="true" />
          )}
        </button>
      </div>
      {view && (
        <div className="max-w-lg mx-auto">
          <label
            htmlFor="templates"
            className="block text-sm font-medium text-gray-700"
          >
            Templates
          </label>
          <select
            id="templates"
            name="templates"
            onChange={(e) => {
              if (e.target.value)
                getTemplate({ variables: { template: e.target.value } });
            }}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            defaultValue="Canada"
          >
            <option value="">Select...</option>
            {templates.data &&
              templates.data.estimateTemplates.map((template, index) => (
                <option key={index} value={template.id}>
                  {template.fromEstimate?.template}
                </option>
              ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default Templates;
