import React from "react";

import { StyleSheet, Document, Page } from "@react-pdf/renderer";
import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceItemsTable from "./InvoiceItemsTable";
import {
  selectVendorInvoice,
  selectOrderDetails,
} from "../../hooks/sales/saleSlice";
import { useSelector } from "react-redux";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  acceptance: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 40,
  },
  accept: {
    fontSize: 9,
  },
  date: {
    fontSize: 9,
    marginLeft: "175px",
  },
});
const CreatePDF = () => {
  const invoice = useSelector(selectVendorInvoice);
  const orderDetails = useSelector(selectOrderDetails);

  const PDF = (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle invoice={invoice} />
        <BillTo orderDetails={orderDetails} invoice={invoice} />
        <InvoiceItemsTable orderDetails={orderDetails} invoice={invoice} />
      </Page>
    </Document>
  );
  return {
    PDF,
  };
};
export default CreatePDF;
