/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Dialog, Transition } from "@headlessui/react";
import {
  gql,
  useMutation,
  useLazyQuery,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import { useForm } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import Autocomplete from "react-google-autocomplete";

// COMPONENTS
// import NavigationHeader from "../../components/Headers/navigationHeader";

const CREATE_CLIENT = gql`
  mutation updateClient($input: ClientInput!) {
    createClient(clientData: $input) {
      client {
        id
      }
    }
  }
`;

const GET_ORG = gql`
  query fetch($id: String!) {
    fetchPipedriveOrgData(crmId: $id) {
      name
      crmId
      address
      contacts {
        firstName
        lastName
        email
        phoneNumber
        crmId
      }
    }
  }
`;
const CREATE_CONTACT = gql`
  mutation create(
    $id: ID!
    $crm: String!
    $type: String!
    $email: String!
    $phone: String
    $firstName: String!
    $lastName: String!
  ) {
    addCustomerToClient(
      companyId: $id
      crmId: $crm
      customerType: $type
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phone
    ) {
      customer {
        id
      }
    }
  }
`;
const Weekdays = [
  {
    name: "Sunday",
    id: 1,
  },
  {
    name: "Monday",
    id: 2,
  },
  {
    name: "Tuesday",
    id: 3,
  },
  {
    name: "Wednesday",
    id: 4,
  },
  {
    name: "Thursday",
    id: 5,
  },
  {
    name: "Friday",
    id: 6,
  },
  {
    name: "Saturday",
    id: 7,
  },
];
const ClientView = () => {
  const {
    register,
    getValues,
    trigger,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [error, setErrosr] = useState(false);
  const [client, setClient] = useState(null);
  const [addresses, setAddress] = useState([]);
  const [importPipe, setImportPipe] = useState(false);
  const [skipped, setSkipped] = useState(0);
  const [validateContacts, setValidateContacts] = useState(null);
  const [checkContact, setCheckContact] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orgError, setOrgError] = useState(false);
  const [selected, setSelected] = useState([]);
  const [createClient, created] = useMutation(CREATE_CLIENT, {
    // onCompleted: (d) => (window.location = "clients"),
    onCompleted: (d) => handleSetContacts(d),
    onError: (e) => alert(e),
  });
  const [getOrg, org] = useLazyQuery(GET_ORG, {
    onCompleted: (d) => {
      setIsSubmitting(false);
      if (d.fetchPipedriveOrgData) handlePreFils(d.fetchPipedriveOrgData);
      else setOrgError(true);
    },
    onError: (d) => {
      setIsSubmitting(false);
      setOrgError(true);
    },
  });

  async function handleAddress() {
    let values = getValues();
    const { lineOne, country, postal, city, state, from, to, loadingDock } =
      values;
    if (!lineOne || !country || !postal || !city || !state) {
      trigger("lineOne");
      trigger("country");
      trigger("postal");
      trigger("city");
      trigger("state");
      return;
    }
    let address = {
      lineOne: lineOne,
      countryCode: country,
      postalCode: postal,
      city: city,
      state: state,
      isPrimary: values.primary || false,
      isBilling: values.billing || false,
    };
    if (from && to) {
      let start = from.split(":");
      start[0] = parseInt(start[0]);
      if (start[0] > 12) {
        start[0] -= 12;
        start[2] = "PM";
      } else {
        start[2] = "AM";
      }
      start = `${start[0]}:${start[1]}${start[2]}`;
      let end = to.split(":");
      end[0] = parseInt(end[0]);
      if (end[0] > 12) {
        end[0] -= 12;
        end[2] = "PM";
      } else {
        end[2] = "AM";
      }
      end = `${end[0]}:${end[1]}${end[2]}`;
      let hours = {
        mondayStart: "12:00AM",
        mondayEnd: "12:00AM",
        tuesdayStart: "12:00AM",
        tuesdayEnd: "12:00AM",
        wednesdayStart: "12:00AM",
        wednesdayEnd: "12:00AM",
        thursdayStart: "12:00AM",
        thursdayEnd: "12:00AM",
        fridayStart: "12:00AM",
        fridayEnd: "12:00AM",
        saturdayStart: "12:00AM",
        saturdayEnd: "12:00AM",
        sundayStart: "12:00AM",
        sundayEnd: "12:00AM",
      };
      if (selected.length > 0) {
        for (const entry of selected) {
          hours[entry.name.toLowerCase() + "Start"] = start;
          hours[entry.name.toLowerCase() + "End"] = end;
        }
      }
      address.shippingDetails = {
        hoursOfOperations: hours,
        hasLoadingDock: loadingDock,
      };
    }
    setAddress([...addresses, address]);
    setValue("lineOne", "");
    setValue("country", "");
    setValue("postal", "");
    setValue("city", "");
    setValue("state", "");
    setValue("primary", "");
    setValue("billing", "");
    setValue("from", "");
    setValue("to", "");
    setValue("loadingDock", "");
    setSelected([]);
  }

  async function handleContact(fetched, skip) {
    if (skip) {
      let index = contacts.length + skipped + 1;
      if (validateContacts && validateContacts.length > index) {
        setCheckContact(true);
        setValue("contactCrm", validateContacts[index].crmId);
        setValue("contactType", "alternative_purchaser");
        setValue("email", validateContacts[index].email);
        setValue("firstName", validateContacts[index].firstName);
        setValue("lastName", validateContacts[index].lastName);
        setValue("phone", validateContacts[index].phoneNumber);
      } else {
        setCheckContact(false);
      }
      setSkipped(skipped + 1);
    } else {
      let values = getValues();
      const { contactCrm, contactType, email, firstName, lastName, phone } =
        values;
      if (!contactCrm || !contactType || !email || !firstName || !lastName) {
        trigger("contactCrm");
        trigger("contactType");
        trigger("email");
        trigger("firstName");
        trigger("lastName");
        return;
      }
      let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (format.test(firstName)) {
        setError("firstName", {
          type: "manual",
          message: "No Special Characters or Spaces",
        });
        return;
      }
      if (format.test(lastName)) {
        setError("lastName", {
          type: "manual",
          message: "No Special Characters or Spaces",
        });
        return;
      }
      clearErrors();
      let contact = {
        crmId: values.contactCrm,
        customerType: values.contactType,
        email: values.email.replace(/\s/g, ""),
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phone,
      };
      let newContacts = [...contacts, contact];
      setContacts(newContacts);
      setValue("contactCrm", "");
      setValue("contactType", "");
      setValue("email", "");
      setValue("firstName", "");
      setValue("lastName", "");
      setValue("phone", "");
      let index = newContacts.length + skipped;

      if (
        validateContacts &&
        validateContacts.length > index &&
        contacts.length !== validateContacts.length
      ) {
        setCheckContact(true);
        setValue("contactCrm", validateContacts[index].crmId);
        setValue("contactType", "alternative_purchaser");
        setValue("email", validateContacts[index].email);
        setValue("firstName", validateContacts[index].firstName);
        setValue("lastName", validateContacts[index].lastName);
        setValue("phone", validateContacts[index].phoneNumber);
      } else {
        setCheckContact(false);
      }
    }
  }

  const addressHeaders = ["Address", "is Primary", "is Billing", "Edit"];
  const contactHeaders = ["Contact", "CRM", "Type", "Edit"];

  async function handleSetContacts(d) {
    const client = new ApolloClient({
      uri: process.env.REACT_APP_URI,
      credentials: "same-origin",
      cache: new InMemoryCache(),
    });
    const clientRequest = (apolloClient, input) =>
      apolloClient.mutate({
        mutation: CREATE_CONTACT,
        variables: {
          id: input.companyId,
          crm: input.crmId,
          type: input.customerType,
          email: input.email,
          firstName: input.firstName,
          lastName: input.lastName,
          phone: input.phoneNumber,
        },
        onError: (e) => alert(e),
      });
    for (let entry of contacts) {
      entry.companyId = d.createClient.client.id;
      const pageResponse = await clientRequest(client, entry);
    }
    window.location = `/clients/${d.createClient.client.id}`;
  }
  async function handleCreate() {
    let triggerR = false;
    let values = getValues();

    if (addresses.length === 0) {
      trigger("lineOne");
      trigger("country");
      trigger("postal");
      trigger("city");
      trigger("state");
      triggerR = true;
    }
    if (contacts.length === 0) {
      trigger("contactCrm");
      trigger("contactType");
      trigger("email");
      trigger("firstName");
      trigger("lastName");
      triggerR = true;
    }
    if (!values["organization-name"] || !values.type || !values.crm) {
      trigger("organization-name");
      trigger("type");
      trigger("crm");
      triggerR = true;
    }
    if (triggerR) return;

    let client = {
      name: values["organization-name"],
      type: values.type,
      website: values.website || null,
      logoImageUrl: values.url || null,
      crmId: values.crm,
      locations: addresses,
    };
    createClient({
      variables: {
        input: client,
      },
    });
  }

  async function fetchOrg(data) {
    setIsSubmitting(true);
    getOrg({
      variables: {
        id: data,
      },
    });
  }

  async function handlePreFils(data) {
    setImportPipe(false);
    setValue("organization-name", data.name);
    setValue("type", "company");
    setValue("crm", data.crmId);
    // contacts
    if (
      data.contacts &&
      data.contacts.length > 0 &&
      contacts.length !== data.contacts.length
    ) {
      setValidateContacts(data.contacts);
      setCheckContact(true);
      setValue("contactCrm", data.contacts[0].crmId);
      setValue("contactType", "alternative_purchaser");
      setValue("email", data.contacts[0].email);
      setValue("firstName", data.contacts[0].firstName);
      setValue("lastName", data.contacts[0].lastName);
      setValue("phone", data.contacts[0].phoneNumber);
    }
  }
  return (
    <main
      className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Clients | One Supply Connect</title>
      </Helmet>
      <Transition.Root show={checkContact} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={checkContact}
          onClose={() => {
            setCheckContact(true);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-black text-gray-900"
                    >
                      Confirm Client Information
                    </Dialog.Title>
                  </div>
                </div>
                <div className="flex flex-col items-start justify-center pt-2 ">
                  <ul className="ml-5 list-disc text-left">
                    <p className="text-gray-600">
                      A customer with the following details does not exist for
                      this client. please confirm their details.
                    </p>
                    <div className="grid grid-cols-4">
                      <div className="col-span-4">
                        <div className="grid grid-cols-3 gap-x-4 gap-y-1">
                          <div>
                            <label
                              htmlFor="description"
                              className="block text-sm pt-2 text-gray-800"
                            >
                              First Name:
                            </label>
                            <div className="mt-1">
                              <input
                                id="firstName"
                                {...register("firstName")}
                                onChange={(e) => {
                                  setValue("firstName", e.target.value);
                                }}
                                type="text"
                                className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 ${
                                  errors.firstName
                                    ? "border-red-600"
                                    : "border-gray-300 "
                                } border rounded-md py-1 px-2`}
                              />
                              {errors.firstName && (
                                <p className="text-sm text-red-600">
                                  {errors.firstName.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="description"
                              className="block text-sm pt-2 text-gray-800"
                            >
                              Last Name:
                            </label>
                            <div className="mt-1">
                              <input
                                id="lastName"
                                {...register("lastName")}
                                onChange={(e) => {
                                  setValue("lastName", e.target.value);
                                }}
                                type="text"
                                className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 ${
                                  errors.lastName
                                    ? "border-red-600"
                                    : "border-gray-300 "
                                } border rounded-md py-1 px-2`}
                              />
                              {errors.lastName && (
                                <p className="text-sm text-red-600">
                                  {errors.lastName.message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-span-1">
                            <label
                              htmlFor="description"
                              className="block text-sm pt-2 text-gray-800"
                            >
                              Contact Email:
                            </label>
                            <div className="mt-1">
                              <input
                                id="name"
                                {...register("email")}
                                onChange={(e) =>
                                  setValue("email", e.target.value)
                                }
                                type="text"
                                className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                                  errors.email
                                    ? "border-red-600"
                                    : "border-gray-300 "
                                } rounded-md py-1 px-2`}
                              />
                            </div>
                          </div>
                          <div className="col-span-1">
                            <label
                              htmlFor="description"
                              className="block text-sm pt-2 text-gray-800"
                            >
                              Contact Phone:
                            </label>
                            <div className="mt-1">
                              <input
                                id="name"
                                {...register("phone")}
                                onChange={(e) => {
                                  setValue("phone", e.target.value);
                                }}
                                type="text"
                                className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                              />
                            </div>
                          </div>
                          <div className="col-span-1">
                            <label
                              htmlFor="description"
                              className="block text-sm pt-2 text-gray-800"
                            >
                              Type:
                            </label>
                            <div className="mt-1">
                              <select
                                id="name"
                                {...register("contactType")}
                                onChange={(e) => {
                                  setValue("contactType", e.target.value);
                                }}
                                className="block w-full shadow-sm  text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                              >
                                <option value="">Select...</option>
                                <option value="buyer">Buyer </option>
                                <option value="alternative_purchaser">
                                  Alternative Buyer
                                </option>
                                <option value="alternative_contact">
                                  Alternative Contact
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-span-1">
                            <label
                              htmlFor="description"
                              className=" text-sm pt-2 text-gray-800 gap-2 flex flex-row"
                            >
                              Pipedrive CRM ID:{" "}
                              <div className="relative z-40 flex flex-col justify-center items-center group">
                                <svg
                                  className="w-5 h-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                <div className="absolute bottom-0 w-full z-50 flex flex-col items-center hidden  mb-6 group-hover:flex">
                                  <span className="relative lowercase p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                                    The CRM ID is obtained via the URL of the
                                    person on PipeDrive; for example,
                                    <span className="block pt-1 text-red-400">
                                      https://atgpharma.pipedrive.com/person/XXXX
                                    </span>
                                  </span>
                                  <div className="w-3 h-3 -mt-2 transform rotate-45 bg-gray-600" />
                                </div>
                              </div>
                            </label>
                            <div className="mt-1">
                              <input
                                id="name"
                                {...register("contactCrm")}
                                type="text"
                                className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-span-3 flex flex-row items-end  justify-center gap-3  mt-8">
                          <button
                            type="button"
                            onClick={() => handleContact(getValues(), true)}
                            className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Skip Contact
                          </button>
                          <button
                            type="button"
                            onClick={() => handleContact(getValues())}
                            className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Add Contact
                          </button>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={importPipe} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-50 inset-0 overflow-y-auto"
          open={importPipe}
          onClose={setImportPipe}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:mt-8 sm:align-middle sm:max-w-3xl sm:w-full sm:pt-6">
                <div>
                  <div className="px-4 sm:px-6">
                    <div className="mt-1 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-xl mb-2 whitespace-nowrap leading-6 font-bold pb-2 border-b  text-gray-900 grid grid-cols-3"
                      >
                        <span />
                        Fetch Company Details
                      </Dialog.Title>
                      <div className="w-full flex flex-col items-start border-b my-2 ">
                        <div className="w-full  grid grid-cols-4 items-center my-2 mb-4 ">
                          <div className="col-span-1 flex flex-col items-start justify-center gap-4 px-2">
                            <h3 className="leading-6 text-gray-900">
                              PipeDrive CRM ID:{" "}
                            </h3>
                          </div>
                          <div className="col-span-1 relative w-full rounded-md shadow-sm border border-gray-300">
                            <input
                              type="number"
                              min="100"
                              {...register("code")}
                              className="focus:ring-blue-500 focus:border-blue-500  w-full p-1 sm:text-sm  rounded-md"
                              placeholder="XXXX"
                            />
                          </div>
                          {errors.code && (
                            <p className="text-xs text-red-600 text-center">
                              CRM ID needs to be a 4 digit code
                            </p>
                          )}
                        </div>
                        <p className="text-lg font-medium">Instructions:</p>
                        <p className="text-gray-600 text-sm ">
                          The CRM ID is obtained via the URL of the organization
                          on PipeDrive
                        </p>
                        <h4 className="font-medium">Steps:</h4>
                        <p className="text-left text-gray-500 text-sm">
                          <span className="font-bold">1. </span>Search the name
                          of the organization on PipeDrive
                        </p>
                        <p className="text-left text-gray-500 text-sm">
                          <span className="font-bold">2. </span>Upon finding the
                          organization, the url should resemble this:
                          <span className="italic block">
                            https://atgpharma.pipedrive.com/organization/XXXX
                          </span>
                        </p>
                        <p className="text-left text-gray-500 text-sm">
                          <span className="font-bold">3. </span>the last Code is
                          the CRM ID sought for
                        </p>
                      </div>
                    </div>
                  </div>
                  {orgError && (
                    <p className="text-right px-4 py-3 sm:px-6 text-red-600 ">
                      Invalid CRM ID! please try again
                    </p>
                  )}
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t">
                    <button
                      type="button"
                      onClick={() => fetchOrg(getValues("code"))}
                      disabled={isSubmitting}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-700 text-base font-medium text-white hover:bg-green-800 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      {isSubmitting && (
                        <svg
                          className="animate-spin h-5 w-5 text-white self-center mr-2"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 40 40"
                          enableBackground="new 0 0 40 40"
                          xml="preserve"
                        >
                          <path
                            opacity="0.2"
                            d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                          />
                          <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
                        </svg>
                      )}
                      Fetch
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={async () => {
                        await setImportPipe(false);
                        // SetActiveProduct(null);
                      }}
                    >
                      Return
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="h-full bg-white p-8 overflow-y-auto">
        <div>
          <h3 className="text-2xl font-semibold text-gray-900">
            Add New Client
          </h3>
          <p className="max-w-md text-gray-700 text-sm py-1">
            Utilize the following form to create a new client
          </p>
          <div className="flex flex-row gap-4 border-t border-b border-gray-200 ">
            <dl className="mt-2  divide-y divide-gray-200">
              <div className="py-6 flex  flex-col justify-between text-sm font-medium">
                <dt className="text-lg text-gray-900">Organization:</dt>
                <div className="flex flex-row">
                  <div className="grid grid-cols-4 gap-x-4 gap-y-1">
                    {/* <div className="col-span-2 flex flex-row justify-between items-end"></div> */}
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Name:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("organization-name", { required: true })}
                          type="text"
                          className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                            errors["organization-name"]
                              ? "border-red-600"
                              : "border-gray-300 "
                          } rounded-md py-1 px-2`}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Type:
                      </label>
                      <div className="mt-1">
                        <select
                          id="name"
                          {...register("type", { required: true })}
                          className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                            errors.type ? "border-red-600" : "border-gray-300 "
                          } rounded-md py-1 px-2`}
                        >
                          <option value="">Select...</option>
                          <option value="individual">Individual</option>
                          <option value="company">Company</option>
                          <option value="government_agency">
                            Government Agency
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Phone Number:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("organization-phone")}
                          type="text"
                          className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                            errors["organization-phone"]
                              ? "border-red-600"
                              : "border-gray-300 "
                          } rounded-md py-1 px-2`}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Website:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("website")}
                          type="text"
                          className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                            errors.website
                              ? "border-red-600"
                              : "border-gray-300 "
                          } rounded-md py-1 px-2`}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className=" text-sm pt-2 text-gray-800 gap-2 flex flex-row"
                      >
                        Pipedrive CRM ID:{" "}
                        <div className="relative z-40 flex flex-col justify-center items-center group">
                          <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <div className="absolute bottom-0 w-full z-50 flex flex-col items-center hidden  mb-6 group-hover:flex">
                            <span className="relative lowercase p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                              The CRM ID is obtained via the URL of the person
                              on PipeDrive; for example,
                              <span className="block pt-1 text-red-400">
                                https://atgpharma.pipedrive.com/organization/XXXX
                              </span>
                            </span>
                            <div className="w-3 h-3 -mt-2 transform rotate-45 bg-gray-600" />
                          </div>
                        </div>
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("crm", { required: true })}
                          type="text"
                          className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                            errors.crm ? "border-red-600" : "border-gray-300 "
                          } rounded-md py-1 px-2`}
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="description"
                        className="block text-sm pt-2 text-gray-800"
                      >
                        Image URL:
                      </label>
                      <div className="mt-1">
                        <input
                          id="name"
                          {...register("url")}
                          type="text"
                          className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                            errors.url ? "border-red-600" : "border-gray-300 "
                          } rounded-md py-1 px-2`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center ml-8">
                    <button
                      type="button"
                      onClick={() => setImportPipe(true)}
                      className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Import From PipeDrive
                    </button>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>
        <dl className=" border-b my-2 pb-4  border-gray-200 divide-y divide-gray-200 grid grid-cols-2">
          <div className="py-6 flex  flex-col justify-between text-sm font-medium">
            <dt className="text-lg text-gray-900">Address:</dt>
            <div className="grid grid-cols-3 gap-x-4 gap-y-1">
              <div className="col-span-3">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Line One:
                </label>
                <Autocomplete
                  className="shadow-sm block w-full col-span-3 sm:text-sm border border-gray-300 rounded-md py-1 px-2"
                  apiKey={"AIzaSyDafwTPT_hOxljJ7qHBmaD0rCrYNAS5-jc"}
                  style={{ width: "90%" }}
                  onChange={(e) => {
                    setValue("lineOne", e.target.value);
                  }}
                  onPlaceSelected={(place) => {
                    // setViewAddress(true);
                    let number = "";
                    for (const entry of place.address_components) {
                      let type = entry.types[0];
                      switch (type) {
                        case "locality":
                          setValue("city", entry.long_name);
                          break;
                        case "administrative_area_level_1":
                          setValue("state", entry.short_name);
                          break;
                        case "postal_code":
                          setValue("postal", entry.long_name);
                          break;
                        case "country":
                          setValue("country", entry.short_name);
                          break;
                        case "street_number":
                          number = entry.long_name;
                          break;
                        case "route":
                          setValue("lineOne", `${number}  ${entry.short_name}`);
                          break;
                        default:
                          break;
                      }
                    }
                  }}
                  options={{
                    types: ["address"],
                  }}
                  placeholder="Enter A Street Address..."
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  City:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("city", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.city ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Postal Code:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("postal", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.postal ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  State:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("state", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.state ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block text-sm pt-2 text-gray-800"
                >
                  Country:
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    {...register("country", { required: true })}
                    type="text"
                    className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                      errors.country ? "border-red-600" : "border-gray-300 "
                    } rounded-md py-1 px-2`}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="col-span-2 flex flex-row items-end  justify-between gap-3 ">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Is Primary:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("primary")}
                      type="checkbox"
                      // className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                  </div>
                </div>
                <div className="col-span-1 flex flex-row items-start  justify-between gap-3 ">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Is Billing:
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      {...register("billing")}
                      type="checkbox"
                      // className="block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-span-1 mt-4">
              <h3 className="text-lg text-gray-900">
                Shipping Availability:{" "}
                <span className="text-sm">(optional)</span>
              </h3>
              <div className=" flex flex-row gap-8 w-full flex-wrap">
                <div className="flex my-2 flex-col items-start">
                  <label className="text-sm font-medium text-gray-700">
                    From:
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="time"
                      {...register("from", {
                        required: "An Input is required",
                      })}
                      name="from"
                      className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                        errors.from ? "border-red-600" : "border-gray-300 "
                      } rounded-md py-1 px-2`}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className={`${
                          errors.from ? "visible" : "hidden"
                        } h-5 w-5 text-red-500`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  {errors.from && (
                    <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
                      {errors.from.message}
                    </p>
                  )}
                </div>
                <div className="flex my-2 flex-col items-start">
                  <label className="text-sm font-medium text-gray-700">
                    To:
                  </label>
                  <div className="relative mt-1">
                    <input
                      type="time"
                      {...register("to", {
                        required: "An Input is required",
                      })}
                      name="to"
                      className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                        errors.to ? "border-red-600" : "border-gray-300 "
                      } rounded-md py-1 px-2`}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <svg
                        className={`${
                          errors.to ? "visible" : "hidden"
                        } h-5 w-5 text-red-500`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  {errors.to && (
                    <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
                      {errors.to.message}
                    </p>
                  )}
                </div>
                <div className="flex my-2 flex-col items-start">
                  <label className="text-sm font-medium text-gray-700">
                    For:
                  </label>
                  <Multiselect
                    options={Weekdays}
                    onSelect={(values) => setSelected(values)}
                    displayValue="name"
                    showCheckbox
                    style={{
                      searchBox: {
                        "border-radius": "1vh",
                        "z-index": "7",
                      },
                    }}
                  />
                </div>
                <div className="col-span-2 flex flex-row items-end  justify-between gap-3 mb-4">
                  <label
                    htmlFor="description"
                    className="block text-sm pt-2 text-gray-800"
                  >
                    Available Loading Dock:
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      {...register("loadingDock")}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 flex flex-row items-end  justify-end gap-3 ">
              <button
                type="button"
                onClick={() => handleAddress()}
                className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add Address
              </button>
            </div>
          </div>
          <div className="bg-white overflow-hidden shadow border border-gray-50 rounded-lg ml-8 mt-8 ">
            <div className="col-span-1 align-middle inline-block min-w-full z-40">
              <div className=" border-b overflow-auto h-full max-h-80  border-gray-200 sm:rounded-lg ">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-gray-50">
                    <tr>
                      {addressHeaders.map((header, headerIndex) => (
                        <th
                          key={headerIndex}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {addresses.map((entry, index) => (
                      <>
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                            <span className="block">{entry.lineOne},</span>
                            <span className="block">
                              {entry.city},{entry.postalCode},
                            </span>
                            <span className="block">
                              {entry.state},{entry.countryCode}
                            </span>
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${
                              entry.isPrimary
                                ? "text-green-700"
                                : "text-yellow-700"
                            } `}
                          >
                            {entry.isPrimary ? "Yes" : "No"}
                          </td>
                          <td
                            className={`px-6 py-4 whitespace-nowrap text-sm font-medium ${
                              entry.isBilling
                                ? "text-green-700"
                                : "text-yellow-700"
                            } `}
                          >
                            {entry.isBilling ? "Yes" : "No"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-700">
                            <button
                              onClick={() => {
                                let newAddresses = [...addresses];
                                newAddresses.splice(index, 1);
                                setAddress(newAddresses);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </dl>
        <dl className=" my-2 border-gray-200 divide-y divide-gray-200">
          <div className="py-6 flex  flex-col justify-between text-sm font-medium">
            <dt className="text-lg text-gray-900">Contacts:</dt>
            <div className="grid grid-cols-4">
              <div className="col-span-2">
                <div className="grid grid-cols-3 gap-x-4 gap-y-1">
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-2 text-gray-800"
                    >
                      First Name:
                    </label>
                    <div className="mt-1">
                      <input
                        id="firstName"
                        {...register("firstName", {
                          required: "An Input is required",
                        })}
                        type="text"
                        className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                          errors.firstName
                            ? "border-red-600"
                            : "border-gray-300 "
                        } rounded-md py-1 px-2`}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block text-sm pt-2 text-gray-800"
                    >
                      Last Name:
                    </label>
                    <div className="mt-1">
                      <input
                        id="lastName"
                        {...register("lastName", {
                          required: "An Input is required",
                        })}
                        type="text"
                        className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                          errors.lastName
                            ? "border-red-600"
                            : "border-gray-300 "
                        } rounded-md py-1 px-2`}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="description"
                      className="block text-sm pt-2 text-gray-800"
                    >
                      Contact Email:
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        {...register("email", {
                          required: "An Input is required",
                        })}
                        type="text"
                        className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                          errors.email ? "border-red-600" : "border-gray-300 "
                        } rounded-md py-1 px-2`}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="description"
                      className="block text-sm pt-2 text-gray-800"
                    >
                      Contact Phone:
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        {...register("phone")}
                        type="text"
                        className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                          errors.phone ? "border-red-600" : "border-gray-300 "
                        } rounded-md py-1 px-2`}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="description"
                      className="block text-sm pt-2 text-gray-800"
                    >
                      Type:
                    </label>
                    <div className="mt-1">
                      <select
                        id="name"
                        {...register("contactType", {
                          required: "An Input is required",
                        })}
                        className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                          errors.contactType
                            ? "border-red-600"
                            : "border-gray-300 "
                        } rounded-md py-1 px-2`}
                      >
                        <option value="">Select...</option>
                        <option value="buyer">Buyer </option>
                        <option value="alternative_purchaser">
                          Alternative Buyer
                        </option>
                        <option value="alternative_contact">
                          Alternative Contact
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="description"
                      className=" text-sm pt-2 text-gray-800 gap-2 flex flex-row"
                    >
                      Pipedrive CRM ID:{" "}
                      <div className="relative z-40 flex flex-col justify-center items-center group">
                        <svg
                          className="w-5 h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <div className="absolute bottom-0 w-full z-50 flex flex-col items-center hidden  mb-6 group-hover:flex">
                          <span className="relative lowercase p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">
                            The CRM ID is obtained via the URL of the person on
                            PipeDrive; for example,
                            <span className="block pt-1 text-red-400">
                              https://atgpharma.pipedrive.com/person/XXXX
                            </span>
                          </span>
                          <div className="w-3 h-3 -mt-2 transform rotate-45 bg-gray-600" />
                        </div>
                      </div>
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        {...register("contactCrm", {
                          required: "An Input is required",
                        })}
                        type="text"
                        className={`block w-full shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border ${
                          errors.contactCrm
                            ? "border-red-600"
                            : "border-gray-300 "
                        } rounded-md py-1 px-2`}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-span-3 flex flex-row items-end  justify-end gap-3 ">
                  <button
                    type="button"
                    onClick={() => handleContact()}
                    className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Add Contact
                  </button>
                </div>
              </div>
              <div className="col-span-2 bg-white overflow-hidden shadow border border-gray-50 rounded-lg ml-8 mt-8 ">
                <div className="col-span-1 align-middle inline-block min-w-full z-40">
                  <div className=" border-b overflow-auto h-full max-h-80  border-gray-200 sm:rounded-lg ">
                    <table className="min-w-full h-full divide-y divide-gray-200 ">
                      <thead className="bg-gray-50">
                        <tr>
                          {contactHeaders.map((header, headerIndex) => (
                            <th
                              key={headerIndex}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase "
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {contacts.map((entry, index) => (
                          <>
                            <tr
                              key={index}
                              className={
                                index % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }
                            >
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                                <span className="block">
                                  {entry.firstName} {entry.lastName}
                                </span>
                                <span className="block">{entry.email}</span>
                                <span className="block">
                                  {entry.phoneNumber}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-green-700">
                                {entry.crmId}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-green-700">
                                {entry.customerType}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-700">
                                <button
                                  onClick={() => {
                                    let newContacts = [...addresses];
                                    newContacts.splice(index, 1);
                                    setContacts(newContacts);
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dl>
        <div className="flex flex-row justify-end gap-4">
          {error && (
            <a
              href={`clients/${client.id}`}
              className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Validate Client
            </a>
          )}
          <button
            onClick={() => handleCreate()}
            className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Client
          </button>
        </div>
      </div>
    </main>
  );
};

export default ClientView;
