import React from "react";

const LoginPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-connectGray py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-24 w-auto"
            src="/logo.png"
            alt="ATG Pharma"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome Back to Connect
          </h2>
        </div>
        <h3 className="text-center text-lg font-medium text-gray-700">
          An email with reset instructions has been sent, If a user with the
          provided email exists.
        </h3>
        <div>
          <p className="mt-2 text-center text-sm text-gray-600">
            Powered by{" "}
            <a
              className="text-blue-700 hover:text-blue-800"
              target="_blank"
              rel="noreferrer"
              href="https://castleclock.com/"
            >
              CastleClock Inc
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
