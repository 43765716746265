import React from "react";
import { useForm } from "react-hook-form";

//HOOKS
import useClient from "../../hooks/useClient";

//COMPONENTS
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Text from "../../../global/components/inputs/text/simple";
import Select from "../../../global/components/inputs/select/simple.jsx";

const AddAddress = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { handleAddCredit, addingCredit } = useClient();

  return (
    <ModalFrame
      open={modalOn === "add-credit"}
      close={setModal}
      title="Add Client Credit"
    >
      <form
        onSubmit={handleSubmit(handleAddCredit)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the form below to add credit for this client.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <div className="grid grid-cols-3 gap-4">
            <Text
              data={{
                label: "Amount",
                id: "amount",
                textType: "number",
                placeholder: "Enter $ amount",
                methods: methods,
                registerValue: "amount",
                registerOptions: { required: true },
              }}
            />
            <Select
              label="Sales Currency"
              id="currency"
              methods={methods}
              registerValue="currency"
              registerOptions={{ required: true }}
              options={[
                { value: "USD", label: "USD" },
                { value: "CAD", label: "CAD" },
              ]}
            />
            <Text
              data={{
                label: "Received data",
                id: "dateReceived",
                textType: "date",
                methods: methods,
                registerValue: "dateReceived",
                registerOptions: { required: true },
              }}
            />
            <div className="col-span-3">
              <Text
                data={{
                  label: "Reason",
                  id: "reasoning",
                  textType: "text",
                  placeholder: "enter reason for credit",
                  methods: methods,
                  registerValue: "reasoning",
                  registerOptions: { required: true },
                }}
              />
            </div>
          </div>
          <ModalCTA cancel={setModal} submitting={addingCredit} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddAddress;
