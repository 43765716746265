/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NewGroup = ({
  open,
  setOpen,
  editActive,
  setEditActive,
  activeProduct,
  ecommerceActive,
  setEcommerceActive,
  serialized,
  setSerialized,
  updateItemForm,
  setActiveProduct,
}) => {
  const [cookies] = useCookies();
  const { register, getValues, setValue, handleSubmit } = useForm();
  const [totals, setTotals] = useState({ c: 0, cUSD: 0, p: 0, pUSD: 0 });

  useEffect(() => {
    if (activeProduct) {
      let usdPrice = activeProduct?.usdPrice?.pricePerUnit;
      let usdCost = activeProduct?.usdPrice?.costPerUnit;
      let cadCost = parseFloat(activeProduct?.defaultPricing?.costPerUnit);
      let cadPrice = parseFloat(activeProduct?.defaultPricing?.pricePerUnit);
      let ncUSD =
        activeProduct?.defaultPricing?.nonCommissionCostPerUnitMarkupUsd;
      let ncCAD =
        activeProduct?.defaultPricing?.nonCommissionCostPerUnitMarkupCad;
      let markup =
        activeProduct?.defaultPricing?.nonCommissionPricePerUnitMarkup;
      setValue("price", cadPrice);
      setValue("cost", cadCost);
      setValue("title", activeProduct?.title);
      setValue("description", activeProduct?.description);
      setValue("accountingDescription", activeProduct?.accountingDescription);
      setValue("costUSD", usdCost);
      setValue("priceUSD", usdPrice);
      setValue("ncCostCAD", ncCAD);
      setValue("ncCostUSD", ncUSD);
      setValue("commission", activeProduct?.overrideCommissionPercentage);
      // subtract markup if exists * by fetched USD factor
      setValue("nonCommissionMarkup", markup);
      setTotals({
        c: (parseFloat(ncCAD) || 0) + (parseFloat(cadCost) || 0),
        cUSD: (parseFloat(ncUSD) || 0) + (parseFloat(usdCost) || 0),
        pUSD: parseFloat(usdPrice) || 0,
        p: (parseFloat(cadPrice) || 0) + (parseFloat(markup) || 0),
      });
    }
  }, [activeProduct]);

  useEffect(() => {
    let usdPrice = parseFloat(getValues("priceUSD"));
    let usdCost = parseFloat(getValues("costUSD"));
    let cadCost = parseFloat(getValues("cost"));
    let cadPrice = parseFloat(getValues("price"));
    let ncUSD = parseFloat(getValues("ncCostUSD"));
    let ncCAD = parseFloat(getValues("ncCostCAD"));
    let markup = parseFloat(getValues("nonCommissionMarkup"));
    setTotals({
      c: (ncCAD || 0) + (cadCost || 0),
      cUSD: (ncUSD || 0) + (usdCost || 0),
      pUSD: usdPrice || 0,
      p: (cadPrice || 0) + (markup || 0),
    });
  }, [
    getValues("price"),
    getValues("cost"),
    getValues("ncCostCAD"),
    getValues("ncCostUSD"),
    getValues("nonCommissionMarkup"),
    getValues("costUSD"),
    getValues("priceUSD"),
  ]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:mt-8 sm:align-middle sm:max-w-4xl sm:w-full sm:pt-6">
              <form onSubmit={handleSubmit(updateItemForm)}>
                <div className="px-4 sm:px-6">
                  <div className="mt-1 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-xl mb-2 leading-6 font-bold pb-2 border-b  text-gray-900 grid grid-cols-3"
                    >
                      <span />
                      Product Details
                      <div className="flex flex-col items-end justify-start gap-2">
                        <Switch.Group
                          as="div"
                          className="flex items-start justify-start"
                        >
                          <Switch.Label as="span" className="mr-3">
                            <span className="text-sm font-medium text-gray-900">
                              Inactive
                            </span>
                          </Switch.Label>
                          <Switch
                            checked={editActive}
                            onChange={setEditActive}
                            className={classNames(
                              editActive ? "bg-blue-600" : "bg-gray-200",
                              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                editActive ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              )}
                            />
                          </Switch>
                          <Switch.Label as="span" className="ml-3">
                            <span className="text-sm font-medium text-gray-900">
                              Active
                            </span>
                          </Switch.Label>
                        </Switch.Group>
                      </div>
                    </Dialog.Title>
                    <div className="w-full flex flex-col items-start border-b my-2 ">
                      <div className="flex flex-row items-start justify-between w-full ">
                        <div className="flex flex-col items-start justify-start">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Information
                          </h3>
                          <p className="mt-1 pb-3 max-w-2xl text-xs text-gray-500">
                            How the product is presented to clients
                          </p>
                        </div>
                        <div className="flex flex-col">
                          <p className="font-medium text-lg">
                            SKU:{" "}
                            <span className="text-blue-800">
                              {activeProduct?.skuNumber}
                            </span>
                          </p>
                          <p className="font-medium text-xs">
                            Group:{" "}
                            <span className="text-blue-800">
                              {activeProduct?.productGroups &&
                                activeProduct?.productGroups[0]
                                  ?.productGroupsCommission &&
                                `${activeProduct?.productGroups[0]?.name} 
                              ${
                                activeProduct?.productGroups[0]
                                  ?.productGroupsCommission &&
                                parseFloat(
                                  activeProduct?.productGroups[0]
                                    ?.productGroupsCommission[0]?.percentage
                                ) * 100
                              }
                              %`}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="w-full grid grid-cols-4 items-center my-2 ">
                        <div className="col-span-1 flex flex-col items-start justify-center gap-4 px-2">
                          <h3 className="leading-6 text-gray-900">Title: </h3>
                        </div>
                        <div className="col-span-2 relative w-full rounded-md shadow-sm ">
                          <input
                            type="text"
                            className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  w-full p-1 sm:text-sm  rounded-md"
                            placeholder="0"
                            {...register("title")}
                          />
                        </div>
                        <p className="text-sm text-gray-400">
                          Version: {activeProduct?.versionNumber}
                        </p>
                      </div>
                      <div className="w-full  grid grid-cols-4 items-center my-2 mb-4 ">
                        <div className="col-span-1 flex flex-col items-start justify-center gap-4 px-2">
                          <h3 className="leading-6 text-gray-900 text-left">
                            Accounting Description:{" "}
                          </h3>
                        </div>
                        <div className="col-span-2 relative w-full rounded-md">
                          <textarea
                            className="focus:ring-blue-500 focus:border-blue-500 border border-gray-300  h-auto w-full p-1 sm:text-sm  rounded-md"
                            placeholder="Type.."
                            {...register("accountingDescription")}
                          />
                        </div>
                      </div>
                      <div className="w-full my-2 mb-4  px-2 ">
                        <Switch.Group
                          as="div"
                          className="flex items-center justify-start"
                        >
                          <Switch.Label as="span" className="mr-3">
                            <span className="text-sm font-medium text-gray-900">
                              Not Serialized Item
                            </span>
                          </Switch.Label>
                          <Switch
                            checked={serialized}
                            onChange={setSerialized}
                            className={classNames(
                              serialized ? "bg-blue-600" : "bg-gray-200",
                              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                serialized ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              )}
                            />
                          </Switch>
                          <Switch.Label as="span" className="ml-3">
                            <span className="text-sm font-medium text-gray-900">
                              Serialized Item
                            </span>
                          </Switch.Label>
                        </Switch.Group>
                      </div>
                      <div className="w-full my-2 mb-4  px-2 ">
                        <Switch.Group
                          as="div"
                          className="flex items-center justify-start"
                        >
                          <Switch.Label as="span" className="mr-3">
                            <span className="text-sm font-medium text-gray-900">
                              Not E-Comm Item
                            </span>
                          </Switch.Label>
                          <Switch
                            checked={ecommerceActive}
                            onChange={setEcommerceActive}
                            className={classNames(
                              ecommerceActive ? "bg-blue-600" : "bg-gray-200",
                              "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                ecommerceActive
                                  ? "translate-x-5"
                                  : "translate-x-0",
                                "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              )}
                            />
                          </Switch>
                          <Switch.Label as="span" className="ml-3">
                            <span className="text-sm font-medium text-gray-900">
                              E-Comm Item
                            </span>
                          </Switch.Label>
                        </Switch.Group>
                      </div>
                    </div>
                    <div className="w-full flex flex-col items-start border-b my-2 ">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Breakdown
                      </h3>
                      <p className="mt-1 pb-3 max-w-2xl text-xs text-gray-500">
                        Product parameters utilized by Connect. Price fields for
                        both USD and CAD are inclusive of markup. When a product
                        is saved the new prices reflect the new prices + markup.
                        ( when inputting a price do not include markup they
                        should be added in their corresponding fields)
                      </p>
                      {(cookies.user?.user?.role?.name === "Executive" ||
                        cookies.user?.user?.role?.name === "Vendor Admin") && (
                        <div className="w-full flex flex-col lg:grid grid-cols-6 items-center my-2 text-left text-sm">
                          <div className="col-span-1 w-full flex flex-col items-start justify-center gap-4 px-2">
                            <h3 className="leading-6 text-gray-900">Cost:</h3>
                          </div>
                          <div className="col-span-2  w-full flex flex-row items-center gap-2 ">
                            <span className="flex flex-row">CAD $</span>
                            <input
                              type="number"
                              className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                              placeholder="0"
                              step="0.01"
                              {...register("cost")}
                            />
                          </div>
                          <div className="col-span-2  w-full  flex flex-row items-center gap-2 ">
                            USD $
                            <input
                              type="number"
                              className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                              placeholder="0"
                              step="0.01"
                              {...register("costUSD")}
                            />
                          </div>
                        </div>
                      )}
                      <div className="w-full flex flex-col lg:grid grid-cols-6 items-center my-2 text-left text-sm">
                        <div className="col-span-1 w-full flex flex-col items-start justify-center gap-4 px-2">
                          <h3 className="leading-6 text-gray-900">Price:</h3>
                        </div>
                        <div className="col-span-2  w-full flex flex-row items-center gap-2 ">
                          <span className="flex flex-row">CAD $</span>
                          <input
                            type="number"
                            className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                            placeholder="0"
                            step="0.01"
                            {...register("price")}
                          />
                        </div>
                        <div className="col-span-2  w-full  flex flex-row items-center gap-2 ">
                          USD $
                          <input
                            type="number"
                            className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                            placeholder="0"
                            step="0.01"
                            {...register("priceUSD")}
                          />
                        </div>
                      </div>

                      <div className="w-full flex flex-col lg:grid grid-cols-6 items-center my-2 text-left text-sm">
                        <div className="w-full col-span-1 flex flex-col items-start justify-center gap-4 px-2">
                          <h3 className="leading-6 text-gray-900">
                            Cost Markup{" "}
                            <span className="italic text-xs block">
                              No Commission{" "}
                            </span>
                            <span className="text-xs text-red-500 block">
                              To null a value set it to 0.01
                            </span>
                          </h3>
                        </div>
                        <div className="col-span-2 w-full  flex flex-row items-center gap-2 ">
                          <span className="flex flex-row">CAD $</span>
                          <input
                            type="number"
                            className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                            placeholder="0"
                            step="0.01"
                            {...register("ncCostCAD")}
                          />
                        </div>
                        <div className="col-span-2  w-full  flex flex-row items-center gap-2 ">
                          USD $
                          <input
                            type="number"
                            className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                            placeholder="0"
                            step="0.01"
                            {...register("ncCostUSD")}
                          />
                        </div>
                      </div>

                      <div className="w-full flex flex-col lg:grid grid-cols-6 items-center my-2 text-sm text-left">
                        <div className="col-span-1 w-full flex flex-col items-start justify-center gap-4 px-2">
                          <h3 className="leading-6 text-gray-900 text-left">
                            Price Markup
                            <span className="italic text-xs block">
                              No Commission{" "}
                            </span>
                            <span className="text-xs text-red-500 block">
                              To null a value set it to 0.01
                            </span>
                          </h3>
                        </div>
                        <div className="col-span-2 w-full flex flex-row items-center gap-2 ">
                          <span className="flex flex-row">CAD $</span>
                          <input
                            type="number"
                            className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                            placeholder="0"
                            step="0.01"
                            {...register("nonCommissionMarkup")}
                          />
                        </div>
                      </div>

                      <div className="w-full flex flex-col lg:grid grid-cols-6 items-center my-2 text-sm text-left">
                        <div className="col-span-1 w-full flex flex-col items-start justify-center gap-4 px-2">
                          <h3 className="leading-6 text-gray-900 text-left">
                            Override Commission percentage
                            <span className="italic text-xs block">
                              Between 0 and 1
                            </span>
                          </h3>
                        </div>
                        <div className="col-span-2 w-full flex flex-row items-center gap-2 ">
                          <input
                            type="number"
                            className="focus:ring-blue-500 focus:border-blue-500  border border-gray-300  p-1 sm:text-sm   rounded-md shadow-sm "
                            placeholder="0"
                            step="0.01"
                            {...register("commission")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-4 items-start justify-start px-2 text-sm font-semibold gap-2 text-left">
                      <p>TOTAL COST CAD</p>
                      <p>
                        $
                        {totals?.c
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                      <p>TOTAL COST USD </p>
                      <p>
                        $
                        {totals?.cUSD
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                      <p>TOTAL PRICE CAD </p>
                      <p>
                        $
                        {totals?.p
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                      <p>TOTAL PRICE USD</p>
                      <p>
                        $
                        {totals?.pUSD
                          ?.toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-700 text-base font-medium text-white hover:bg-green-800 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      await setOpen(false);
                      setActiveProduct(null);
                    }}
                  >
                    Return
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NewGroup;
