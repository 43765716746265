import { gql } from "@apollo/client";

export const GROUPQUERY = gql`
  query getProductGroups {
    productGroups {
      name
      accessor
      associatedTags {
        name
        accessor
      }
    }
  }
`;

export const GETCLIENTS = gql`
  query getAllClients {
    clients {
      id
      name
      crmId
      salesTax {
        taxPercentage
        provinceState
      }
      contacts {
        id
        firstName
        lastName
        email
        crmId
      }
      locations {
        id
        lineOne
        countryCode
        city
        state
        isPrimary
        postalCode
        isBilling
      }
    }
  }
`;

export const GET_ITEMS = gql`
  query getItems(
    $vendor: ID
    $skip: Int
    $first: Int
    $search: String
    $group: [String]
    $tags: [String]
  ) {
    items(
      vendorId: $vendor
      skip: $skip
      first: $first
      search: $search
      productGroups: $group
      productTags: $tags
    ) {
      skuNumber
      title
      description
      versionNumber
      productGroups {
        name
      }
      unitOfMeasurement {
        name
        units
      }
      price {
        pricePerUnit
      }
      usdPrice {
        pricePerUnit
      }
      ecommercePrice {
        pricePerUnit
      }
      ecommerceUsdPrice {
        pricePerUnit
      }
    }
  }
`;
