import Button from "../../global/components/inputs/button/linkStyle";
import Table from "../../global/components/tables/simple";

const ClientContacts = ({ data, map }) => {
  const { label, button, tableStructure } = map;
  return (
    <div>
      <div className="flex flex-row justify-between items-end mt-2">
        <h4 className="text-gray-500 ">{label}</h4>
        <Button data={button} />
      </div>
      <Table
        TableStructure={tableStructure}
        data={data?.contacts}
        label="contacts"
      />
    </div>
  );
};

export default ClientContacts;
