import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

//HOOKS
import useClient from "../../hooks/useClient";
import { selectClient } from "../../hooks/clientSlice";

//COMPONENTS
import LoadingPage from "../../../global/components/loading/page";
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Select from "../../../global/components/inputs/select/simple.jsx";
import TextArea from "../../../global/components/inputs/text/area";

const AddClientModal = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { emailingContact, loadingSalesReps, handleEmailContact } = useClient();
  const ClientDetails = useSelector(selectClient);

  if (loadingSalesReps) return <LoadingPage />;
  if (!ClientDetails) return null;

  const options = ClientDetails?.contacts?.map((s) => ({
    label: `${s.firstName} ${s.lastName}`,
    value: s.email,
  }));

  return (
    <ModalFrame
      open={modalOn === "email-contact"}
      close={setModal}
      title={"Email Client E-Commerce Instructions"}
    >
      <form
        onSubmit={handleSubmit(handleEmailContact)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Please select a client to send instructions to.
        </p>
        <div className="w-full flex flex-col items-left justify-center gap-2">
          <div className="w-1/3">
            <Select
              label="Contacts"
              id="contact"
              methods={methods}
              registerValue="contact"
              registerOptions={{ required: true }}
              options={options}
            />
          </div>
          <TextArea
            data={{
              label: "CC Emails",
              id: "cc",
              placeholder: "Type emails here [separated by commas]",
              methods: methods,
              registerValue: "ccEmails",
            }}
          />
          <ModalCTA cancel={setModal} submitting={emailingContact} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddClientModal;
