import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";

//COMPONENTS
import Nav from "../components/nav";

const OrdersView = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Orders | One Supply Connect</title>
      </Helmet>
      <Nav />
      <Outlet />
    </div>
  );
};

export default OrdersView;
