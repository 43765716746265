/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { selectView } from "../../hooks/estimateSlice";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";

import Parameters from "./parameters";
import Page from "./page";
const BuildStepByStep = ({ open }) => {
  const estimateView = useSelector(selectView);
  let renderedComponent;
  switch (estimateView) {
    case "parameters":
      renderedComponent = <Parameters />;
      break;
    case "page":
      renderedComponent = <Page />;
      break;
    default:
      break;
  }
  const [store, setStore] = useState(true);

  if (!open) return null;
  return (
    <div className="w-full border-t border-b border-gray-400 py-2 bg-gray-50">
      <div className="flex w-full items-end justify-end text-gray-500 hover:text-gray-900">
        <button
          className="flex flex-row gap-1 font-bold mr-4"
          onClick={() => setStore(!store)}
        >
          <p>Build Step-By-Step</p>
          {store ? (
            <ChevronUpIcon className="h-6 w-6 " aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-6 w-6 " aria-hidden="true" />
          )}
        </button>
      </div>
      {store && <>{renderedComponent}</>}
    </div>
  );
};

export default BuildStepByStep;
