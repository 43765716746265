/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, SearchCircleIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { useLazyQuery, useQuery, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { setImportInvoice } from "../../hooks/client/clientSlice";

const GET_ITEMS = gql`
  query getItems(
    $vendor: String
    $skip: Int
    $first: Int
    $search: String
    $group: [String]
    $tags: [String]
  ) {
    items(
      vendorName: $vendor
      skip: $skip
      first: $first
      search: $search
      productGroups: $group
      productTags: $tags
      isActive: false
    ) {
      skuNumber
      title
      description
      versionNumber
      productGroups {
        name
      }
      price {
        pricePerUnit
      }
      usdPrice {
        pricePerUnit
      }
    }
  }
`;

const GROUPQUERY = gql`
  query getProductGroups {
    productGroups {
      name
      accessor
      associatedTags {
        name
        accessor
      }
    }
  }
`;
const ProductHeaders = ["Title", "Price", "Select", "QTY"];
function SystemOverLay({ invoice, lineNumber, setShowPopover }) {
  const dispatch = useDispatch();
  const [skipValue, setSkipValue] = useState(0);
  const { data } = useQuery(GROUPQUERY);
  const [getProducts, items] = useLazyQuery(GET_ITEMS);
  const [ProductTags, setProductTags] = useState(null);
  const [itemList, setItems] = useState({});
  const { setValue, getValues, register, handleSubmit } = useForm();

  useEffect(() => {
    getProducts({
      variables: {
        skip: skipValue,
        first: 15,
        group: getValues().productGroup ? [getValues().productGroup] : [],
        tags: getValues().productTag ? [getValues().productTag] : [],
        search: getValues().search,
      },
    });
  }, [skipValue]);

  async function addItem() {
    let deepCopy = JSON.parse(JSON.stringify(invoice));
    const lineIndex = deepCopy.lineItems.findIndex(
      (e) => e.lineNumber === lineNumber
    );
    for (const [key, value] of Object.entries(itemList)) {
      deepCopy.lineItems.splice(lineIndex + 1, 0, {
        skuNumber: key,
        itemName: value.title,
        quantity: value.value,
        pricePerUnit: value.price,
        for: lineNumber,
      });
    }

    dispatch(setImportInvoice(deepCopy));
    setShowPopover(false);
  }
  return (
    <form
      onSubmit={handleSubmit(addItem)}
      className="h-full bg-white p-8 overflow-y-auto"
    >
      <div>
        <h3 className="text-2xl font-semibold text-gray-900">
          Add Item to Build
        </h3>
        <p className="max-w-md text-gray-700 text-sm py-1">
          Utilize the following form to add a new item to your build.
        </p>
      </div>
      <form className="grid grid-cols-6 gap-8 items-end">
        <div className="col-span-6 flex items-end gap-4  px-6 pt-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
          <div className="w-2/3">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                onFocus={(e) => e.target.setAttribute("autocomplete", "off")}
                className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                placeholder="Search"
                type="search"
                {...register("search")}
                onChange={(e) => {
                  document.getElementById("productGroup").value = "";
                  document.getElementById("productTag").value = "";
                  setValue("productGroup", null);
                  setValue("productTag", null);
                  setSkipValue(0);
                  e.target.value.length > 2 &&
                    getProducts({
                      variables: {
                        skip: 0,
                        first: 15,
                        search: e.target.value,
                      },
                    });
                }}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Categories
            </label>
            <select
              id="productGroup"
              onChange={(e) => {
                setValue(
                  "productGroup",
                  data.productGroups[e.target.value]?.accessor
                );
                setProductTags(data.productGroups[e.target.value]);
                setValue("search", "");
                setSkipValue(0);
                getProducts({
                  variables: {
                    skip: 0,
                    first: 15,
                    group: e.target.value
                      ? [data.productGroups[e.target.value]?.accessor]
                      : [],
                    tags: getValues().productTag
                      ? [getValues().productTag]
                      : [],
                  },
                });
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="">Select...</option>
              {data &&
                data.productGroups.map((entry, index) => (
                  <option value={index}>{entry.name}</option>
                ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Tags
            </label>
            <select
              id="productTag"
              onChange={(e) => {
                setValue("productTag", e.target.value);
                setValue("search", "");
                setSkipValue(0);
                getProducts({
                  variables: {
                    skip: 0,
                    first: 15,
                    group: [getValues().productGroup],
                    tags: e.target.value ? [e.target.value] : [],
                  },
                });
              }}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
              <option value="">All</option>
              {ProductTags &&
                ProductTags.associatedTags.map((entry) => (
                  <option value={entry.accessor}>{entry.name}</option>
                ))}
            </select>
          </div>
        </div>
      </form>
      {items.data ? (
        <div className="flex flex-col  overflow-auto my-4">
          <table className="border rounded-lg">
            <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
              <tr>
                {ProductHeaders.map((entry, index) => {
                  return (
                    <th
                      key={index}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {entry}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {items.data.items.map((product, pIndex) => (
                <tr
                  key={product?.skuNumber}
                  className={
                    pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "
                  }
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {product?.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    ${" "}
                    {parseFloat(
                      invoice.currency === "USD"
                        ? product?.usdPrice.pricePerUnit
                        : product?.price.pricePerUnit
                    )
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <input
                      type="checkbox"
                      value={product?.skuNumber}
                      onChange={(e) => {
                        let deepCopy = JSON.parse(JSON.stringify(itemList));
                        let price =
                          invoice.currency === "USD"
                            ? product?.usdPrice.pricePerUnit
                            : product?.price.pricePerUnit;
                        if (e.target.checked) {
                          deepCopy[product?.skuNumber] = {
                            title: product?.title,
                            price: price,
                            value: 1,
                          };
                        } else delete deepCopy[product?.skuNumber];
                        setItems(deepCopy);
                      }}
                    />
                  </td>
                  <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                    <input
                      type="number"
                      step="1"
                      min={0}
                      onChange={(e) => {
                        let deepCopy = JSON.parse(JSON.stringify(itemList));
                        let price =
                          invoice.currency === "USD"
                            ? product?.usdPrice.pricePerUnit
                            : product?.price.pricePerUnit;
                        if (!deepCopy[product?.skuNumber])
                          deepCopy[product?.skuNumber] = {
                            title: product?.title,
                            price: price,
                          };
                        deepCopy[product?.skuNumber].value = e.target.value;
                        setItems(deepCopy);
                      }}
                      className="focus:ring-blue-500 focus:border-blue-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav
            className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm   border-gray-200 sm:px-6"
            aria-label="Pagination"
          >
            <div className="hidden sm:block">
              <p className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium text-blue-900 ">
                  {1 + skipValue}
                </span>{" "}
                to{" "}
                <span className="font-medium text-blue-900 ">
                  {15 + skipValue}
                </span>{" "}
                of <span className="font-medium text-green-900">625</span> Items
              </p>
            </div>
            <div className="flex-1 flex justify-between sm:justify-end">
              {skipValue > 4 && (
                <button
                  onClick={() => setSkipValue(skipValue - 15)}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Previous
                </button>
              )}
              {items.data.items.length === 15 && (
                <button
                  type="button"
                  onClick={() => setSkipValue(skipValue + 15)}
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                >
                  See More
                </button>
              )}
            </div>
          </nav>
        </div>
      ) : (
        <div className="w-full my-48 flex flex-col justify-center items-center ">
          <SearchCircleIcon
            className="h-8 w-8 text-gray-500"
            aria-hidden="true"
          />
          <h2 className="text-lg text-gray-500">Selecting Search Criteria</h2>
          <svg
            className="animate-spin h-24 w-24 text-blue-200 self-center mr-2 text-center"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            enableBackground="new 0 0 40 40"
            xml="preserve"
          >
            <path
              opacity="0.2"
              d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
            />
            <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
          </svg>
        </div>
      )}
      <div className="flex flex-row justify-end gap-4">
        <button
          // onClick={() => handleSubmit()}
          className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Add Item
        </button>
      </div>
    </form>
  );
}

export default function OverLayRightForm({
  showPopover,
  setShowPopover,
  invoice,
  lineNumber,
}) {
  const toggleShow = (e) => {
    e.preventDefault();
    setShowPopover(false);
  };

  return (
    <Transition.Root show={showPopover} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 overflow-hidden"
        open={showPopover}
        onClose={setShowPopover}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-full">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={toggleShow}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <SystemOverLay
                  invoice={invoice}
                  lineNumber={lineNumber}
                  setShowPopover={setShowPopover}
                />
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
