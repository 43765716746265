import React from "react";

const statusHeader = ["Value", "Description", "Date"];

const StatusesTable = ({ statuses }) => {
  return (
    <div className="bg-white overflow-hidden shadow border border-gray-100 rounded-lg mr-8 ml-8 mt-6">
      <div className="border-b border-gray-200 py-4 mb-2 flex flex-row justify-between items-center mx-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Status Log
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            History of order status log.
          </p>
        </div>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-col">
          <div className="-my-2  sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow border-b border-gray-200 sm:rounded-lg">
                <div>
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        {statusHeader.map((entry, index) => {
                          return (
                            <th
                              key={index}
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {entry}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {statuses &&
                        statuses.map((entry, index) => {
                          return (
                            <tr key={index}>
                              <td className="px-6 py-3 text-left text-sm  text-gray-700 font-bold uppercase tracking-wider">
                                {entry.status?.name}
                              </td>
                              <td className="px-6 py-3 text-left text-xs  text-gray-500  tracking-wider">
                                {entry.status?.description}
                              </td>
                              <td className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                {entry.createdAt.split("T")[0]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatusesTable;
