import React from "react";
import { useFormContext } from "react-hook-form";
import { setPopOver } from "../../../hooks/sales/saleSlice";
import { useDispatch } from "react-redux";
const SimpleDropDown = ({ props, build }) => {
  let isRequired = false;
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const dropDownOptions = props.items?.map((option, index) => {
    return (
      <option key={index} value={option.skuNumber}>
        {option.title}
      </option>
    );
  });

  return (
    <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 sm:py-5">
      <div className="col-span-2">
        <label
          htmlFor="project_name"
          className="block text-lg font-medium text-gray-900 sm:mt-px"
        >
          {props.label}
        </label>
        <p className="text-gray-500 text-sm">{props.description}</p>
        <button
          onClick={() =>
            dispatch(setPopOver({ view: true, item: props.items }))
          }
          type="button"
          className="animate-pulse text-blue-400 hover:text-blue-900 text-base font-semibold"
        >
          View Details
        </button>
        {errors[props.name] && (
          <p className=" text-xs tracking-wide  pt-2 lg:pt-1` text-red-600">
            Selection is Required
          </p>
        )}
      </div>
      <div className="col-span-2">
        <select
          {...register(`${props.id}-${build}`, {
            required: isRequired,
          })}
          className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm p-1.5 mt-1 bg-white border border-gray-300 rounded-md"
        >
          <option value="">Select...</option>
          {dropDownOptions}
        </select>
      </div>
    </div>
  );
};

export default SimpleDropDown;
