import Button from "../../global/components/inputs/button/linkStyle";
import AddressDisplay from "../../global/components/address/display";

const AddressCards = ({ data, map }) => {
  const { label, button, editButton } = map;
  return (
    <div className="my-2">
      <div className="flex flex-row justify-between items-end mt-2">
        <h4 className="text-gray-500 ">{label}</h4>
        <Button data={button} />
      </div>
      <div className="grid grid-cols-3 gap-8 py-2 mx-4">
        {data?.locations?.map((entry, index) => {
          return (
            <div
              key={index}
              className="text-gray-800 border border-gray-100 rounded-xl p-4 flex flex-row items-start justify-between"
            >
              <AddressDisplay data={entry} />
              <div className="flex flex-col justify-between gap-1 h-full items-end">
                <div className="flex flex-row gap-1 items-end">
                  {entry.isBilling && (
                    <span className="w-min ml-auto flex justify-end items-center  py-1 px-3 text-xs leading-5 font-semibold rounded-full  bg-green-100 text-green-800">
                      Billing
                    </span>
                  )}
                  {entry.isPrimary && (
                    <span className="w-min ml-auto flex justify-end items-center  py-1 px-3 text-xs  leading-5 font-semibold rounded-full  bg-yellow-100 text-yellow-800">
                      Primary
                    </span>
                  )}
                </div>
                <p className="text-sm text-gray-600">
                  ID: <span className="font-medium">{entry.id}</span>
                </p>
                <Button data={editButton} input={entry} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddressCards;
