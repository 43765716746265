import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
  reportTitle: {
    color: "#000000",
    fontFamily: "Roboto",
    letterSpacing: 0.5,
    fontSize: 15,
    textAlign: "center",
  },
});

const InvoiceTableHeader = ({ isWayFast }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.reportTitle}>Your Cart</Text>
  </View>
);

export default InvoiceTableHeader;
