/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  useLazyQuery,
  useQuery,
  useMutation,
  ApolloClient,
  InMemoryCache,
} from "@apollo/client";
import { useForm } from "react-hook-form";
import Multiselect from "multiselect-react-dropdown";
import { SearchIcon } from "@heroicons/react/solid";

//COOKIES
import { useCookies } from "react-cookie";

import NewGroupModal from "../../../components/modals/products/newGroup";
import NewTagModal from "../../../components/modals/products/newTag";
import Text from "../../../global/components/inputs/text/simple";
import Select from "../../../global/components/inputs/select/simple";
import TextArea from "../../../global/components/inputs/text/area";
import Toggle from "../../../global/components/inputs/toggle/simple";

import {
  GET_ITEMS,
  GROUPQUERY,
  CREATEITEM,
  VENDORS,
  CREATE_TAG,
  CREATE_GROUP,
  UPDATE_GROUP,
} from "./requests";

const ClientView = () => {
  const ApClient = new ApolloClient({
    uri: process.env.REACT_APP_URI,
    credentials: "same-origin",
    cache: new InMemoryCache(),
  });
  const [cookies, setCookie] = useCookies(["user"]);
  const [skipValue, setSkipValue] = useState(0);
  const [price, setPrice] = useState(0);
  const [markUp, setMarkup] = useState(0);
  const [ncCost, setNcCost] = useState(0);

  const [getProducts, items] = useLazyQuery(GET_ITEMS);
  const groups = useQuery(GROUPQUERY);
  const vendors = useQuery(VENDORS);
  const [ProductTags, setProductTags] = useState(null);
  const [selectedTags, setSelectedTags] = useState(null);
  const [newProductGroup, setNewProductGroup] = useState(false);
  const [newProductTag, setNewProductTag] = useState(false);
  const [serialized, setSerialized] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createItemMutation, item] = useMutation(CREATEITEM, {
    onCompleted: (d) => (window.location = "/products"),
    onError: (e) => alert(e),
  });
  const [createProductGroup] = useMutation(CREATE_GROUP, {
    onCompleted: (d) => {
      groups.refetch();
      setNewProductGroup(false);
    },
    onError: (e) => alert(e),
  });
  const [updateProductGroup] = useMutation(UPDATE_GROUP, {
    onCompleted: (d) => {
      groups.refetch();
      setNewProductTag(false);
    },
    onError: (e) => alert(e),
  });

  const [boms, setBoms] = useState({});
  const methods = useForm();
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const selectedVendor = watch("vendor");

  useEffect(() => {
    getProducts({
      variables: {
        skip: skipValue,
        first: 5,
        search: getValues().search,
      },
    });
  }, [skipValue]);

  useEffect(() => {
    if (!selectedVendor) return;
    const desc = vendors.data.vendors.find((v) => v.name === selectedVendor);
    const { nextSkuNumber } = desc;
    setValue("sku", nextSkuNumber);
  }, [selectedVendor]);

  async function handleItems(item, qty) {
    setBoms({ ...boms, [item.id]: { qty: qty, title: item.title } });
  }
  async function createItem() {
    const tags =
      selectedTags?.length !== 0 && selectedTags?.map((entry) => entry.id);
    const bomItems = Object.entries(boms).map(([key, entry]) => {
      return { id: key, qty: entry.qty };
    });
    let variables = {
      groups: [getValues("productGroup")],
      boms: bomItems,
      item: {
        skuNumber: getValues("sku"),
        title: getValues("title"),
        description: getValues("description"),
        accountingDescription: getValues("description"),
        leadTimeDaysInStock: 30,
        leadTimeDaysOutOfStock: 60,
        unitOfMeasurement: getValues("uom"),
        vendorName: getValues("vendor"),
        costPerUnit: getValues("cost"),
        pricePerUnit: getValues("price"),
        overrideUsdCostPerUnit: getValues("usdcost") || null,
        overrideUsdPricePerUnit: getValues("usdprice") || null,
        nonCommissionPricePerUnitMarkup: getValues("markup") || null,
        nonCommissionCostPerUnitMarkupCad: getValues("ncCostCAD") || null,
        nonCommissionCostPerUnitMarkupUsd: getValues("ncCostUSD") || null,
        isSerialized: serialized,
        overrideCommissionPercentage: getValues("commission") || null,
      },
    };
    tags && (variables.tags = tags);
    createItemMutation({
      variables: variables,
    });
  }

  async function handleNewProductGroup(data) {
    setIsSubmitting(true);
    if (!data.groupName) return alert("A Name is Required");
    let tags = data.newTags.replace(/\s/g, "");
    let tagIds = [];
    if (tags.length === 0) {
      tagIds = null;
    } else {
      const addTag = (apolloClient, variables) =>
        apolloClient.mutate({
          mutation: CREATE_TAG,
          variables: variables,
          onError: (e) => alert(e),
        });
      let tagIds = [];
      for (const entry of tags.split(",")) {
        let variables = {
          name: entry,
          accessor: entry.toLowerCase().replace(/\s/g, "_"),
        };
        const tagResponse = await addTag(ApClient, variables);
        tagIds.push(tagResponse.data.createProductTag.tag.id);
      }
    }
    let body = {
      name: data.groupName,
      accessor: data.groupName.toLowerCase().replace(/\s/g, "_"),
      associatedTags: tagIds,
      commission_percentage: data.commission,
    };
    createProductGroup({ variables: body });
  }

  async function handleNewProductTag(data) {
    setIsSubmitting(true);
    if (!data.newTags || !data.tagFor) return alert("All fields are required");
    let tags = data.newTags.replace(/\s/g, "");
    const addTag = (apolloClient, variables) =>
      apolloClient.mutate({
        mutation: CREATE_TAG,
        variables: variables,
        onError: (e) => alert(e),
      });
    let tagIds = [];
    for (const entry of tags.split(",")) {
      let variables = {
        name: entry,
        accessor: entry.toLowerCase().replace(/\s/g, "_"),
      };
      const tagResponse = await addTag(ApClient, variables);
      tagIds.push(tagResponse.data.createProductTag.tag.id);
    }
    let body = {
      id: data.tagFor,
      associatedTagIds: tagIds,
    };
    updateProductGroup({ variables: body });
  }
  const ProductHeaders = ["Title", "Price", "Units", "Quantity"];
  return (
    <main
      className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products | One Supply Connect</title>
      </Helmet>
      <NewGroupModal
        openGroup={newProductGroup}
        setOpenGroup={setNewProductGroup}
        handleNewProductGroup={handleNewProductGroup}
        isSubmitting={isSubmitting}
      />
      <NewTagModal
        openTag={newProductTag}
        setOpenTag={setNewProductTag}
        groups={groups}
        handleNewProductTag={handleNewProductTag}
        isSubmitting={isSubmitting}
      />
      <form
        onSubmit={handleSubmit(createItem)}
        className="h-full bg-white p-8 overflow-y-auto"
      >
        <div>
          <h3 className="text-2xl font-semibold text-gray-900">
            Add New Product
          </h3>
          <p className="max-w-md text-gray-700 text-sm py-1">
            Utilize the following form to create a new product
          </p>
          <div className="flex flex-row gap-4 border-t border-b border-gray-200 ">
            <dl className="mt-2  divide-y divide-gray-200">
              <div className="py-6 flex  flex-col justify-between text-sm font-medium">
                <dt className="text-lg text-gray-900">Information:</dt>
                <div className="grid grid-cols-4 gap-4">
                  <Select
                    label="vendor"
                    id="vendor"
                    methods={methods}
                    registerValue="vendor"
                    registerOptions={{ required: true }}
                    options={
                      vendors.data &&
                      vendors.data.vendors.map((entry, index) => ({
                        label: entry.name,
                        value: entry.name,
                      }))
                    }
                  />
                  <Text
                    data={{
                      label: "SKU Number",
                      id: "sku",
                      textType: "sku",
                      placeholder: "Enter sku",
                      methods: methods,
                      registerValue: "sku",
                      registerOptions: { required: true },
                    }}
                  />
                  <div className="col-span-3">
                    <Text
                      data={{
                        label: "Title",
                        id: "title",
                        textType: "Title",
                        placeholder: "Enter Title",
                        methods: methods,
                        registerValue: "title",
                        registerOptions: { required: true },
                      }}
                    />
                  </div>
                  <Select
                    label="Unit of Measure"
                    id="uom"
                    methods={methods}
                    registerValue="uom"
                    defaultValue="Each"
                    registerOptions={{ required: true }}
                    options={[
                      {
                        label: "Each",
                        value: "Each",
                      },
                      {
                        label: "Feet",
                        value: "Feet",
                      },
                      {
                        label: "Metre",
                        value: "Metre.",
                      },
                    ]}
                  />
                  <div className="col-span-4">
                    <TextArea
                      data={{
                        label: "Description",
                        id: "description",
                        textType: "description",
                        placeholder: "Enter description",
                        methods: methods,
                        registerValue: "description",
                        registerOptions: { required: true },
                      }}
                    />
                  </div>
                  <Text
                    data={{
                      label: "Cost CAD",
                      id: "cost",
                      textType: "number",
                      placeholder: "Enter cost",
                      methods: methods,
                      registerValue: "cost",
                      registerOptions: { required: true },
                    }}
                  />
                  <Text
                    data={{
                      label: "Price CAD",
                      id: "price",
                      textType: "number",
                      placeholder: "Enter price",
                      methods: methods,
                      registerValue: "price",
                      onChange: (e) => setPrice(parseFloat(e.target.value)),
                      registerOptions: { required: true },
                    }}
                  />
                  {cookies.user?.user?.role?.name === "Executive" && (
                    <Text
                      data={{
                        label: "Non Commission Price Markup CAD [optional]",
                        id: "cost",
                        textType: "number",
                        placeholder: "Enter markup",
                        methods: methods,
                        onChange: (e) => setMarkup(parseFloat(e.target.value)),
                        registerValue: "markup",
                      }}
                    />
                  )}
                  <div>
                    <p className="text-yellow-800">
                      Total Price: CAD${" "}
                      {((price || 0) + (markUp || 0))
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </p>
                  </div>
                  <Text
                    data={{
                      label: "Unique Cost USD [optional]",
                      id: "cost",
                      textType: "number",
                      placeholder: "Enter cost",
                      methods: methods,
                      registerValue: "usdcost",
                    }}
                  />
                  <Text
                    data={{
                      label: "Unique Price USD [optional]",
                      id: "price",
                      textType: "number",
                      placeholder: "Enter price",
                      methods: methods,
                      registerValue: "usdprice",
                    }}
                  />
                  <Text
                    data={{
                      label: "Non Commission Cost Markup CAD [optional]",
                      id: "cost",
                      textType: "number",
                      placeholder: "Enter cost",
                      methods: methods,
                      registerValue: "ncCostCAD",
                      onChange: (e) => setNcCost(parseFloat(e.target.value)),
                    }}
                  />
                  <Text
                    data={{
                      label: "Non Commission Cost Markup USD [optional]",
                      id: "price",
                      textType: "number",
                      placeholder: "Enter price",
                      methods: methods,
                      registerValue: "ncCostUSD",
                    }}
                  />
                  <div />
                  <div />
                  <Toggle
                    state={serialized}
                    setState={setSerialized}
                    data={{
                      label: "Type",
                      off: "Not Serialized Item",
                      on: "Serialized Item",
                      methods: methods,
                      registerValue: "serialized",
                    }}
                  />
                </div>
              </div>
            </dl>
          </div>
        </div>
        <dl className=" my-2 border-gray-200 divide-y divide-gray-200">
          <div className="py-6 flex w-2/3  flex-col justify-between text-sm font-medium">
            <dt className="text-lg text-gray-900">
              Group{" "}
              {cookies.user?.user?.role?.name === "Executive" && "& Commission"}
              :
            </dt>
            <div className="w-full grid grid-cols-4 items-center my-2 ">
              <div className="col-span-1 flex flex-col items-start justify-center gap-4 px-2">
                <h3 className="leading-6 text-gray-900">Product Group :</h3>
              </div>
              <div className="col-span-2 relative w-full rounded-md ">
                <select
                  id="productGroup"
                  onChange={(e) => {
                    setValue(
                      "productGroup",
                      groups.data.productGroups[e.target.value]?.id
                    );
                    setProductTags(
                      groups.data?.productGroups[
                        e.target.value
                      ]?.associatedTags.map((entry) => {
                        return {
                          name: entry.name,
                          id: entry.accessor,
                        };
                      })
                    );
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="">Select...</option>
                  {groups.data &&
                    groups.data.productGroups.map((entry, index) => (
                      <option value={index}>
                        {entry.name}{" "}
                        {cookies.user?.user?.role?.name === "Executive" &&
                          ` | Commission: ${
                            parseFloat(
                              entry.productGroupsCommission[0]?.percentage
                            ) * 100
                          } %`}
                      </option>
                    ))}
                </select>
              </div>
              <div className="ml-4">
                <button
                  type="button"
                  onClick={() => setNewProductGroup(true)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  Add New
                </button>
              </div>
            </div>
            <div className="w-full grid grid-cols-4 items-center my-2 ">
              <div className="col-span-1 flex flex-col items-start justify-center gap-4 px-2">
                <h3 className="leading-6 text-gray-900">Product Tags: </h3>
              </div>
              <div className="col-span-2 relative w-full rounded-md ">
                <Multiselect
                  options={ProductTags || []}
                  onSelect={(values) => setSelectedTags(values)}
                  displayValue="name"
                  showCheckbox
                  style={{
                    searchBox: {
                      "border-radius": "0.6vh",
                      "z-index": "7",
                    },
                  }}
                />
              </div>
              <div className="ml-4">
                <button
                  type="button"
                  onClick={() => setNewProductTag(true)}
                  className="text-blue-600 hover:text-blue-800"
                >
                  Add New
                </button>
              </div>
            </div>
            <p className="text-xs text-gray-700">
              This will override product group commission percentage for this
              item. Enter value between 0 and 1
            </p>
            <Text
              data={{
                label: "Override Commission Percentage",
                id: "commission",
                textType: "commission",
                placeholder: "Enter commission",
                methods: methods,
                registerValue: "commission",
              }}
            />
          </div>
        </dl>
        <dl className=" border-b my-2 pb-4  border-gray-200 divide-y divide-gray-200 grid grid-cols-2">
          <div className="py-6 flex  flex-col justify-between text-sm font-medium">
            <dt className="text-lg text-gray-900">BOM:</dt>
            <div className="grid grid-cols-6 gap-8 items-end">
              <div className="col-span-5 flex items-end gap-4 justify-center  px-6 pt-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                <div className="w-2/3">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                      <SearchIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      placeholder="Search"
                      type="search"
                      {...register("search")}
                      onChange={(e) => {
                        setSkipValue(0);
                        e.target.value.length > 2 &&
                          getProducts({
                            variables: {
                              skip: 0,
                              first: 5,
                              search: e.target.value,
                            },
                          });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col  overflow-auto my-4">
              <table className="border rounded-lg">
                <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
                  <tr>
                    {ProductHeaders.map((entry, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {entry}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {items &&
                    items.data &&
                    items.data.items.map((product, pIndex) => (
                      <tr
                        key={product?.skuNumber}
                        className={
                          pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {product?.title}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          ${" "}
                          {parseFloat(product?.ecommercePrice.pricePerUnit)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {product?.unitOfMeasurement.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <input
                            type="number"
                            {...register(product.skuNumber)}
                            onChange={(e) =>
                              handleItems(product, e.target.value)
                            }
                            min={0}
                            className="focus:ring-blue-500 focus:border-blue-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm   border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium text-blue-900 ">
                      {1 + skipValue}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium text-blue-900 ">
                      {5 + skipValue}
                    </span>{" "}
                    of <span className="font-medium text-green-900">625</span>{" "}
                    Items
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  {skipValue > 4 && (
                    <button
                      onClick={() => setSkipValue(skipValue - 5)}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </button>
                  )}
                  {items.data?.items.length === 5 && (
                    <button
                      onClick={() => setSkipValue(skipValue + 5)}
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                    >
                      Next
                    </button>
                  )}
                </div>
              </nav>
            </div>
          </div>
          <div className="bg-white overflow-hidden  ml-8 pt-24">
            <div className="col-span-1 align-middle inline-block min-w-full z-40 ">
              <div className=" border-b overflow-auto h-full max-h-96  sm:rounded-lg ">
                <table className="min-w-full divide-y divide-gray-200 ">
                  <thead className="bg-blue-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                        Title
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                        QTY
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-blue-500 uppercase ">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(boms)?.map(([key, entry], index) => (
                      <>
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-white" : "bg-blue-50"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-700">
                            <span className="block">{entry.title}</span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            {entry.qty}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-700">
                            <button
                              onClick={() => {
                                let newBombs = JSON.parse(JSON.stringify(boms));
                                delete newBombs[key];
                                setBoms(newBombs);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </dl>
        <div className="flex flex-row justify-end gap-4">
          <button
            type="submit"
            className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Create Product
          </button>
        </div>
      </form>
    </main>
  );
};

export default ClientView;
