import React from "react";
import { Link } from "react-router-dom";
import { XCircleIcon } from "@heroicons/react/outline";

const ClientList = ({ data }) => {
  return data.map((client, index) => {
    const { crmId, name, id, type } = client;
    return (
      <li className="bg-white" key={index}>
        <Link
          to={`/clients/${id}`}
          className="relative pl-2 pr-6 py-5 w-full flex items-center justify-between space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500"
        >
          <p className="text-sm  text-left text-gray-900 flex flex-col">
            {name}
            {type === "BLACKLISTED" && (
              <span className="mt-1 flex flex-row items-center gap-1 text-xs text-red-700">
                <XCircleIcon className="h-4 w-4" aria-hidden="true" />
                Blacklisted
              </span>
            )}
          </p>
          {!crmId && (
            <span className="w-min ml-auto flex justify-end items-center  py-1 px-3 text-xs  animate-pulse leading-5 font-semibold rounded-full  bg-red-100 text-red-800">
              invalidated
            </span>
          )}
        </Link>
      </li>
    );
  });
};

export default ClientList;
