import React from "react";
import { useForm } from "react-hook-form";

//HOOKS
import useClient from "../../hooks/useClient";

//COMPONENTS
import ModalFrame from "../../../global/components/modals/frame";
import ModalCTA from "../../../global/components/modals/cta";
import Text from "../../../global/components/inputs/text/simple";

const AddClientModal = ({ modalOn, setModal }) => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const { updatingClient, handleSetTaxNumber } = useClient();

  return (
    <ModalFrame
      open={modalOn === "set-tax-number"}
      close={setModal}
      title={"Set New Tax Number"}
    >
      <form
        onSubmit={handleSubmit(handleSetTaxNumber)}
        className="flex flex-col items-center justify-center pt-2 "
      >
        <p className="text-gray-600 text-center mb-4">
          Utilize the input to set a new tax number this client.
        </p>
        <div className="w-full flex flex-col items-center justify-center">
          <Text
            data={{
              label: "Tax Number",
              id: "taxNumber",
              textType: "tax",
              placeholder: "Enter new tax number",
              methods: methods,
              registerValue: "taxNumber",
              registerOptions: { required: true },
            }}
          />
          <ModalCTA cancel={setModal} submitting={updatingClient} />
        </div>
      </form>
    </ModalFrame>
  );
};

export default AddClientModal;
