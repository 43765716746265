/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

//API
import { useQuery, useLazyQuery } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import useSaleItems from "../../../hooks/sales/useSaleItems";
import {
  selectCurrency,
  selectPrice,
  setReview,
  setPrice,
  selectTaxPercentage,
  selectCart,
  setPopOver,
  setCart,
} from "../../../hooks/sales/saleSlice";
import { GROUPQUERY, GETCLIENTS, GET_ITEMS } from "./queries";

//ASSETS
import { SearchIcon } from "@heroicons/react/solid";
import { ShoppingCartIcon, SearchCircleIcon } from "@heroicons/react/outline";
import ItemPopOver from "../../../components/PopeOver/item";
import ClientModal from "../../../components/modals/spare/selectClient";

const SpareView = () => {
  const { data } = useQuery(GROUPQUERY);
  const clientResponse = useQuery(GETCLIENTS);
  const currency = useSelector(selectCurrency);
  const totalPrice = useSelector(selectPrice);
  const cart = useSelector(selectCart);
  const taxPercentage = useSelector(selectTaxPercentage);
  const dispatch = useDispatch();
  const { handleCart } = useSaleItems();
  const [ProductTags, setProductTags] = useState(null);
  const [shoppingCartError, setShoppingCartError] = useState(false);
  const { setValue, watch, getValues, register } = useForm();
  const watchAllFields = watch();
  const [skipValue, setSkipValue] = useState(0);
  const [getProducts, items] = useLazyQuery(GET_ITEMS);
  const [itemsCount, setItemCount] = useState(0);

  useEffect(() => {
    getProducts({
      variables: {
        skip: skipValue,
        first: 10,
        group: getValues().productGroup ? [getValues().productGroup] : [],
        tags: getValues().productTag ? [getValues().productTag] : [],
        search: getValues().search,
      },
    });
  }, [getProducts, getValues, skipValue]);

  useEffect(() => {
    let price = totalPrice.price;
    let tax = price * taxPercentage;
    dispatch(
      setPrice({
        price: price,
        tax: tax,
        total: price + tax,
      })
    );
  }, [taxPercentage]);

  useEffect(() => {
    async function setData(data) {
      delete data.productGroup;
      delete data.productTag;
      delete data.search;
      let newCount = Object.keys(data).length;
      setItemCount(newCount);
    }
    let filteredData = Object.fromEntries(
      Object.entries(watchAllFields).filter(
        ([_, entry]) =>
          entry !== undefined &&
          entry !== null &&
          entry !== "" &&
          entry !== "0" &&
          entry !== false
      )
    );
    if (filteredData !== watchAllFields) {
      setData(filteredData);
    }
  }, [watchAllFields]);

  useEffect(() => {
    if (
      Object.keys(cart).length > 0 &&
      Object.keys(watchAllFields).length === 0
    ) {
      setItemCount(Object.keys(cart).length);
      for (const entry of Object.entries(cart)) {
        setValue(entry[0], entry[1]);
      }
    }
  }, [cart]);

  const ProductHeaders = ["Title", `Price [${currency}]`, "Units", "Quantity"];
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spare | One Supply Connect</title>
      </Helmet>
      <ItemPopOver />
      <ClientModal clientResponse={clientResponse} />
      <div className="max-w-7xl mx-auto h-full">
        <form className="grid grid-cols-3 gap-8 items-end">
          <div className="col-span-2 flex items-end gap-4  px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
            <div className="w-2/3">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  id="search"
                  onFocus={(e) => e.target.setAttribute("autocomplete", "off")}
                  name="search"
                  className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Search"
                  type="search"
                  {...register("search")}
                  onChange={(e) => {
                    document.getElementById("productGroup").value = "";
                    document.getElementById("productTag").value = "";
                    setValue("productGroup", null);
                    setValue("productTag", null);
                    setSkipValue(0);
                    e.target.value.length > 2 &&
                      getProducts({
                        variables: {
                          skip: 0,
                          first: 10,
                          search: e.target.value,
                        },
                      });
                  }}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Categories
              </label>
              <select
                id="productGroup"
                onChange={(e) => {
                  setValue(
                    "productGroup",
                    data.productGroups[e.target.value]?.accessor
                  );
                  setProductTags(data.productGroups[e.target.value]);
                  setValue("search", "");
                  setSkipValue(0);
                  getProducts({
                    variables: {
                      skip: 0,
                      first: 10,
                      group: e.target.value
                        ? [data.productGroups[e.target.value]?.accessor]
                        : [],
                      tags: getValues().productTag
                        ? [getValues().productTag]
                        : [],
                    },
                  });
                }}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="">Select...</option>
                {data &&
                  data.productGroups.map((entry, index) => (
                    <option value={index}>{entry.name}</option>
                  ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Tags
              </label>
              <select
                id="productTag"
                onChange={(e) => {
                  setValue("productTag", e.target.value);
                  setValue("search", "");
                  setSkipValue(0);
                  getProducts({
                    variables: {
                      skip: 0,
                      first: 10,
                      group: [getValues().productGroup],
                      tags: e.target.value ? [e.target.value] : [],
                    },
                  });
                }}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value="">All</option>
                {ProductTags &&
                  ProductTags.associatedTags.map((entry) => (
                    <option value={entry.accessor}>{entry.name}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col items-end justify-end gap-1">
            <div className="flex flex-row items-end justify-between ">
              <div className="w-2/5 h-2/3 ml-auto  pr-4 border-r-2 border-gray-200" />
              <button
                type="button"
                onClick={async () => {
                  if (itemsCount > 0) {
                    dispatch(setCart(watchAllFields));
                    await handleCart(watchAllFields);
                    dispatch(setReview());
                  } else {
                    setShoppingCartError(true);
                  }
                }}
                className="border-l-2 text-gray-400 hover:text-gray-900 flex flex-row items-end"
              >
                <ShoppingCartIcon className="h-8 w-8 ml-4" aria-hidden="true" />
                {itemsCount !== 0 &&
                  (!shoppingCartError || setShoppingCartError(false)) && (
                    <p className="text-xs font-bold">{itemsCount}</p>
                  )}
              </button>
            </div>
            {shoppingCartError && (
              <p className="text-sm text-red-500">Add Items to Cart</p>
            )}
          </div>
        </form>
        {items.data ? (
          <div className="flex flex-col h-full overflow-auto my-4">
            <table className="border rounded-lg">
              <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
                <tr>
                  {ProductHeaders.map((entry, index) => {
                    return (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {entry}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {items.data.items.map((product, pIndex) => (
                  <tr
                    key={product?.skuNumber}
                    className={
                      pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <button
                        onClick={() =>
                          dispatch(setPopOver({ view: true, item: [product] }))
                        }
                        type="button"
                        className="text-blue-600 hover:text-blue-900 text-base font-semibold"
                      >
                        {product?.title}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      ${" "}
                      {parseFloat(
                        currency === "USD"
                          ? product?.ecommerceUsdPrice.pricePerUnit
                          : product?.ecommercePrice.pricePerUnit
                      )
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {product?.unitOfMeasurement.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <input
                        type="number"
                        {...register(product.skuNumber)}
                        min={0}
                        className="focus:ring-blue-500 focus:border-blue-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-300"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <nav
              className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm   border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium text-blue-900 ">
                    {1 + skipValue}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium text-blue-900 ">
                    {10 + skipValue}
                  </span>{" "}
                  of <span className="font-medium text-green-900">625</span>{" "}
                  Items
                </p>
              </div>
              <div className="flex-1 flex justify-between sm:justify-end">
                {skipValue > 9 && (
                  <button
                    onClick={() => setSkipValue(skipValue - 10)}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Previous
                  </button>
                )}
                {items.data.items.length === 10 && (
                  <button
                    onClick={() => setSkipValue(skipValue + 10)}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                  >
                    Next
                  </button>
                )}
              </div>
            </nav>
          </div>
        ) : (
          <div className="w-full my-48 flex flex-col justify-center items-center ">
            <SearchCircleIcon
              className="h-8 w-8 text-gray-500"
              aria-hidden="true"
            />
            <h2 className="text-lg text-gray-500">Selecting Search Criteria</h2>
            <svg
              className="animate-spin h-24 w-24 text-blue-200 self-center mr-2 text-center"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              enableBackground="new 0 0 40 40"
              xml="preserve"
            >
              <path
                opacity="0.2"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpareView;
