/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import { useMutation, useLazyQuery, gql } from "@apollo/client";

//COOKIES
import { useCookies } from "react-cookie";

// HELPER
import changeRoutesStructure from "./global/utils/resolveRoutes";

// BASE WINDOW
import BaseWindow from "./components/Base";

// MOCK
import RoutesData from "./global/data/routesStructure";

const REFRESH = gql`
  mutation refreshToken($token: String!) {
    refreshToken(refreshToken: $token) {
      token
      errors
      payload
      refreshToken
    }
  }
`;

const Router = () => {
  return (
    <BrowserRouter>
      <BaseWindow View={RouterView} />
    </BrowserRouter>
  );
};

export default Router;

const RouterView = () => {
  const { routes } = RoutesData;
  const MINUTE_MS = 7000;
  const [routeList, setRouteList] = useState([]);
  const [cookies, setCookie] = useCookies(["user"]);
  const [refresh, user] = useMutation(REFRESH, {
    onCompleted: (d) => {
      let profile = cookies.user;
      profile.token = d.refreshToken.token;
      profile.refreshToken = d.refreshToken.refreshToken;
      let exp = d.refreshToken.payload.exp;
      setCookie("user", profile, { path: "/" });
      setCookie("exp", exp, { path: "/" });
    },
    onError: (e) => {
      setCookie("user", null, { path: "/" });
      window.location.reload();
    },
  });
  useMemo(() => {
    async function getRoute() {
      let temp = await changeRoutesStructure(routes);
      routeList.length === 0 && setRouteList(temp);
    }
    getRoute();
  }, [routes]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!cookies.user) return;
      if (!cookies.exp || cookies.exp === "null") {
        refresh({ variables: { token: cookies.user.refreshToken } });
      } else {
        let UTCt = Number(new Date().getTime().toString().slice(0, -3));
        let expiry = Number(cookies.exp) - 10;
        if (UTCt > expiry && cookies.user.refreshToken) {
          setCookie("exp", null, { path: "/" });
          refresh({ variables: { token: cookies.user.refreshToken } });
        }
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, [cookies.exp]);

  let element = useRoutes(routeList);
  return element;
};
