import React, { useState, useMemo } from "react";

// COMPONENTS
import NavigationHeader from "../../components/Headers/navigationHeader";

//COOKIES
import { useCookies } from "react-cookie";

const SalesView = () => {
  const [cookies] = useCookies(["user"]);
  const [navigation, setNavigation] = useState([]);
  useMemo(() => {
    switch (cookies.user?.user?.role?.name) {
      case "Sales Representative":
      case "Executive":
      case "Customer Success Representative":
        setNavigation([
          {
            title: "Leads",
            link: "/leads",
          },
          {
            title: "Orders",
            link: "/orders?view=custom",
            default: true,
            view: "custom",
          },
          {
            title: "Spare Parts",
            link: "/orders?view=spare",
            view: "spare",
          },
        ]);
        break;
      case "Vendor Admin":
        setNavigation([
          {
            title: "Orders",
            link: "/orders",
          },
        ]);
        break;
      default:
        break;
    }
  }, [cookies.user]);
  return <NavigationHeader pageTitle={"Sales"} subMenus={navigation} />;
};

export default SalesView;
