import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// HOOKS
import useClient from "../hooks/useClient";
import { selectClient, selectModal } from "../hooks/clientSlice";

//COMPONENTS
import LoadingPage from "../../global/components/loading/page";
import NotAvailable from "../../global/components/notAvailable/data";
import ClientInfo from "../components/clientInfo";
import ClientAddresses from "../components/clientAddresses";
import ClientContacts from "../components/clientContacts";
import ClientCredits from "../components/clientCredits";
import ClientEcommerce from "../components/clientEcommerce";
import ModalAssignAE from "../components/modals/modalAssignAE";
import ModalSetTax from "../components/modals/modalSetTax";
import ModalSetNumber from "../components/modals/modalSetTaxNumber";
import ModalAddAddr from "../components/modals/modalAddAddress";
import ModalAddCnt from "../components/modals/modalAddContact";
import ModalUpdCnt from "../components/modals/modalUpdateContact";
import ModalEmailCnt from "../components/modals/modalEmailContact";
import ModalCusPrice from "../components/modals/modalSetPartnerPricing";
import ModalAddCredit from "../components/modals/modalAddCredit";

//DATA
import {
  CLIENT_INFORMATION,
  ADDRESS_INFO,
  CLIENT_CONTACTS,
  CLIENT_CREDITS,
  CLIENT_ECOMMERCE,
} from "../data/data_port";

const ClientPort = () => {
  const { loadClientView, handleSetModal, loadingClient } = useClient();
  const ClientDetails = useSelector(selectClient);
  const ModalOn = useSelector(selectModal);
  const { id } = useParams();

  useEffect(() => {
    if (id) loadClientView(id);
  }, [id]);

  if (loadingClient) return <LoadingPage />;
  if (!ClientDetails) return <NotAvailable />;
  return (
    <div className="pb-5 px-4 py-4 sm:pb-0 sm:px-6 lg:px-8 divide-y divide-gray-200 mb-16">
      <ModalAssignAE modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalSetTax modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalSetNumber modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalAddAddr modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalAddCnt modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalUpdCnt modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalEmailCnt modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalCusPrice modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ModalAddCredit modalOn={ModalOn} setModal={() => handleSetModal(null)} />
      <ClientInfo data={ClientDetails} map={CLIENT_INFORMATION} />
      <ClientAddresses data={ClientDetails} map={ADDRESS_INFO} />
      <ClientContacts data={ClientDetails} map={CLIENT_CONTACTS} />
      <ClientCredits data={ClientDetails} map={CLIENT_CREDITS} />
      <ClientEcommerce data={ClientDetails} map={CLIENT_ECOMMERCE} />
    </div>
  );
};

export default ClientPort;
