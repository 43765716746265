export const Stats = [
  {
    id: 1,
    name: "# of Orders Created",
    stat: "currentOrdersCount",
    allOrders: true,
  },
  {
    id: 2,
    name: "# of Orders with Payment",
    stat: "paymentReceivedOrdersCount",
  },
  {
    id: 3,
    name: "Avg. Time to Close",
    stat: "averageTimeToCloseInDays",
    unit: "Days",
  },
  {
    id: 3,
    name: "Gross Revenue",
    stat: "subtotalRevenueCad",
    currency: true,
  },
  {
    id: 3,
    name: "Commission Gained",
    execAlt: "Gross Profit Gained",
    stat: "commissionGainedWithFullPaymentCad",
    currency: true,
  },
];

export const Headers = [
  {
    name: "ID",
    align: "left",
  },
  {
    name: "Organization",
    align: "left",
  },
  {
    name: "Order Total",
    align: "right",
  },
  {
    name: "Commission Total",
    align: "right",
  },
  {
    name: "Shipped On",
    align: "right",
  },
  {
    name: "Sales Rep",
    align: "right",
  },
];
