import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PopeOver from "../../components/PopeOver/item";
import { setPopOver } from "../hooks/saleSlice";

import { DELETE_LINE, EDIT_LINE } from "../requests";

// COMPONENTS
import Card from "../../components/Card/simple";

const ProductHeaders = [
  "Title",
  "Price Per Unit",
  "Quantity",
  "Total",
  "Actions",
];

const UniqueOrdersView = ({ data, setShowPopover, refetch }) => {
  const dispatch = useDispatch();
  const [onEdit, setOnEdit] = useState(null);
  const [newQty, setNewQty] = useState(0);
  const { register } = useForm();
  const [editLine] = useMutation(EDIT_LINE, {
    onCompleted: (d) => {
      refetch();
      setOnEdit(null);
      setNewQty(0);
    },
    onError: (e) => alert(e),
  });

  const [deleteLine] = useMutation(DELETE_LINE, {
    onCompleted: (d) => {
      refetch();
    },
    onError: (e) => alert(e),
  });

  return (
    <Card>
      <PopeOver />
      <div className="border-b border-gray-200 pb-4 mb-4 flex flex-row justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Line Items
          </h3>
        </div>
        <div className="px-4 sm:px-6 lg:px-8 ">
          {!data.vendorOrderNumber && (
            <button
              type="button"
              onClick={() => setShowPopover(true)}
              className="inline-flex items-center justify-center px-4 py-2 shadow-sm text-lg font-medium rounded-md bg-green-600  text-white hover:bg-green-800  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <span>Add to Estimate</span>
            </button>
          )}
        </div>
      </div>
      <table className="border rounded-lg w-full">
        <thead className="sticky z-20 top-0 bg-gray-50 border border-gray-300 rounded-lg">
          <tr>
            {ProductHeaders.map((entry, index) => {
              return (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {entry}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.estimates[0]?.lineItems.map((product, pIndex) => (
            <tr
              key={product?.skuNumber}
              className={pIndex % 2 === 0 ? "bg-white" : "bg-gray-50 w-full "}
            >
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                <button
                  type="button"
                  onClick={() =>
                    dispatch(setPopOver({ view: true, item: [product?.item] }))
                  }
                  className="text-blue-600 hover:text-blue-900 text-base font-semibold"
                >
                  {product?.item?.title}
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                ${" "}
                {parseFloat(product?.pricePerUnit)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {onEdit === product.id && !data.vendorOrderNumber ? (
                  <input
                    type="number"
                    defaultValue={product?.itemQuantity}
                    {...register("quantity", {
                      value: product?.itemQuantity,
                    })}
                    onChange={(e) =>
                      e.target.value > 0 && setNewQty(e.target.value)
                    }
                    className="shadow-sm sm:text-sm focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded-md py-1 px-2"
                  />
                ) : (
                  product?.itemQuantity
                )}
                {product?.item?.unitOfMeasurement?.name !== "Each"
                  ? ` x ${product?.item?.unitOfMeasurement?.name}`
                  : " Units"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                ${" "}
                {(
                  parseFloat(product?.pricePerUnit) *
                  (onEdit === product.id
                    ? parseFloat(newQty)
                    : parseFloat(product?.itemQuantity))
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              {!(product?.item?.title === "Shipping Cost") ? (
                onEdit === product.id ? (
                  <td className="px-6 py-4 whitespace-nowrap text-sm flex flex-row gap-2">
                    <button
                      onClick={() =>
                        editLine({
                          variables: { id: product.id, qty: newQty },
                        })
                      }
                      className="text-green-600 hover:text-green-800"
                    >
                      Save
                    </button>
                  </td>
                ) : (
                  <td className="px-6 py-4 whitespace-nowrap text-sm flex flex-row gap-2">
                    <button
                      onClick={() => {
                        setNewQty(product?.itemQuantity);
                        setOnEdit(product?.id);
                      }}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() =>
                        deleteLine({ variables: { id: product.id } })
                      }
                      className="text-red-600 hover:text-red-800"
                    >
                      Delete
                    </button>
                  </td>
                )
              ) : (
                <td className="px-6 py-4 whitespace-nowrap text-sm flex flex-row gap-2" />
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};
export default UniqueOrdersView;
