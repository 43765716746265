import React from "react";
import { StyleSheet, Document, Text, Page, Link } from "@react-pdf/renderer";

import InvoiceTitle from "./InvoiceTitle";
import BillTo from "./BillTo";
import InvoiceItemsTable from "./InvoiceItemsTable";

import {
  selectEstimate,
  selectOrderDetails,
} from "../../hooks/sales/saleSlice";
import { useSelector } from "react-redux";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  acceptance: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: 40,
  },
  accept: {
    fontSize: 9,
  },
  date: {
    fontSize: 9,
    marginLeft: "175px",
  },
  pay: {
    fontSize: 9,
    paddingTop: 10,
  },
  link: {
    fontSize: 8,
    marginTop: 5,
    textColor: "#0066ff",
  },
  ptc: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    display: "flex",
    flexDirection: "row",
  },
});
const CreatePDF = () => {
  const estimate = useSelector(selectEstimate);
  const orderDetails = useSelector(selectOrderDetails);
  const PDF = (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle estimate={estimate} />
        <BillTo orderDetails={orderDetails} estimate={estimate} />
        <InvoiceItemsTable orderDetails={orderDetails} estimate={estimate} />
      </Page>
    </Document>
  );
  return {
    PDF,
  };
};
export default CreatePDF;
