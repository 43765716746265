import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

//HOOKS
import useClient from "../hooks/useClient";
import { selectClients } from "../hooks/clientSlice";

// COMPONENTS
import AsideLoadingData from "../../global/components/loading/asideData";
import ClientList from "../components/clientList";
import Searchbar from "../../global/components/inputs/text/simple";
import ButtonLink from "../../global/components/inputs/link/buttonStyle";

// DATA
import { SEARCH_PROPERTIES, BUTTON_LINK } from "../data/data_outlet";

const ClientOutlet = () => {
  const { loadPortView, handleSearch, loadingPort, methods } = useClient();
  const clients = useSelector(selectClients);

  useEffect(() => {
    loadPortView();
  }, []);

  return (
    <Fragment>
      <Searchbar
        data={{
          ...SEARCH_PROPERTIES,
          onChange: (e) => handleSearch(e.target.value),
          methods,
        }}
      />
      <ButtonLink data={BUTTON_LINK} />
      <ul className="border-t relative z-0 divide-y divide-gray-200 overflow-auto mt-2 ">
        {loadingPort ? <AsideLoadingData /> : <ClientList data={clients} />}
      </ul>
    </Fragment>
  );
};

export default ClientOutlet;
