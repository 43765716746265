export default function SimpleText({ data }) {
  const {
    label,
    methods: { formState, register },
    placeholder,
    onChange,
    registerOptions,
    registerValue,
    id,
    autoComplete,
    textType,
  } = data;
  const { errors } = formState;

  return (
    <div>
      <label
        htmlFor={registerValue}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <p className="mt-2 text-sm text-red-600">
        {errors[registerValue] && "Please enter a valid " + textType}
      </p>
      <div className="mt-1">
        <input
          {...register(registerValue, registerOptions)}
          id={id}
          onChange={onChange}
          type={textType}
          step="0.01"
          autoComplete={autoComplete}
          className={`appearance-none relative block w-full px-3 py-2 border ${
            errors[registerValue] ? "border-red-600" : "border-gray-300"
          } placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
