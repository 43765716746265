/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { SearchCircleIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { gql, useQuery, useLazyQuery } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import useConfigureEstimate from "../hooks/useConfigureEstimate";
import {
  selectForBuild,
  selectEstimate,
  setForBuild,
  selectConversion,
} from "../hooks/estimateSlice";

const GET_ITEMS = gql`
  query getItems(
    $vendor: ID
    $skip: Int
    $first: Int
    $search: String
    $group: [String]
    $tags: [String]
  ) {
    items(
      vendorId: $vendor
      skip: $skip
      first: $first
      search: $search
      productGroups: $group
      productTags: $tags
    ) {
      skuNumber
      title
      description
      versionNumber
      productGroups {
        name
      }
      unitOfMeasurement {
        name
        units
      }
      price {
        pricePerUnit
      }
      usdPrice {
        pricePerUnit
      }
      vendor {
        id
        name
      }
    }
  }
`;

const GROUPQUERY = gql`
  query getProductGroups {
    productGroups {
      name
      accessor
      associatedTags {
        name
        accessor
      }
    }
  }
`;

const VENDORS = gql`
  query vendors {
    vendors {
      name
      id
    }
  }
`;

const Store = ({ open }) => {
  const dispatch = useDispatch();
  const [view, setView] = useState(true);
  const [skipValue, setSkipValue] = useState(0);
  const { data } = useQuery(GROUPQUERY);
  const [getProducts, items] = useLazyQuery(GET_ITEMS);
  const [ProductTags, setProductTags] = useState(null);
  const forBuild = useSelector(selectForBuild);
  const vendors = useQuery(VENDORS);
  const { configureStore } = useConfigureEstimate();
  const estimate = useSelector(selectEstimate);
  const conversion = useSelector(selectConversion);
  const { setValue, watch, getValues, register, reset } = useForm();
  const watchAllFields = watch();
  const [length, setLength] = useState(0);
  const [intermittent, setIntermittent] = useState({});
  const ProductHeaders = [
    "Title",
    `Unit price ${conversion === 1 ? "[CAD]" : "[USD]"}`,
    "Units",
    "Quantity",
  ];

  useEffect(() => {
    getProducts({
      variables: {
        skip: skipValue,
        first: 5,
        group: getValues().productGroup ? [getValues().productGroup] : [],
        tags: getValues().productTag ? [getValues().productTag] : [],
        search: getValues().search,
      },
    });
  }, [skipValue]);

  useEffect(() => {
    async function setData(data) {
      delete data.productGroup;
      delete data.productTag;
      delete data.search;
      delete data.build;
      setLength(Object.keys(data).length);
    }
    let filteredData = Object.fromEntries(
      Object.entries(watchAllFields).filter(
        ([_, entry]) =>
          entry !== undefined &&
          entry !== null &&
          entry !== "" &&
          entry !== "0" &&
          entry !== false
      )
    );
    if (filteredData !== watchAllFields) {
      setData(filteredData);
    }
  }, [watchAllFields]);

  async function handleItems(entry, value) {
    let build = JSON.parse(JSON.stringify(intermittent));
    build[entry.skuNumber] = { item: entry, quantity: value };
    let filteredData = Object.fromEntries(
      Object.entries(build).filter(
        ([_, entry]) =>
          entry.quantity !== undefined &&
          entry.quantity !== null &&
          entry.quantity !== "" &&
          entry.quantity !== "0" &&
          entry.quantity !== false
      )
    );
    setIntermittent(filteredData);
  }

  async function handleSubmit() {
    configureStore(intermittent, forBuild, getValues("product"));
    reset({});
    setIntermittent({});
    document.getElementById("store")?.reset();
  }

  if (!getValues("build") && forBuild) setValue("build", forBuild);
  if (!open) return null;
  return (
    <div className="w-full border-t border-b border-gray-400 py-2 bg-gray-50">
      <div className="flex w-full items-end justify-end text-gray-500 hover:text-gray-900">
        <button
          type="button"
          className="flex flex-row gap-1 font-bold mr-4"
          onClick={() => setView(!view)}
        >
          <p>Store</p>
          {view ? (
            <ChevronUpIcon className="h-6 w-6 " aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-6 w-6 " aria-hidden="true" />
          )}
        </button>
      </div>
      {view && (
        <div>
          <div className="grid grid-cols-9 gap-16 items-end">
            {/* <div className="col-span-1" /> */}
            <div className="grid grid-cols-9 col-span-7 items-end gap-4 px-6 pt-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-1   xl:px-0">
              <div className="col-span-2">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-700"
                >
                  Vendor
                </label>
                <select
                  id="vendor"
                  onChange={(e) => {
                    document.getElementById("productGroup").value = "";
                    document.getElementById("productTag").value = "";
                    setValue("productGroup", null);
                    setValue("productTag", null);
                    setValue("search", "");
                    setSkipValue(0);
                    getProducts({
                      variables: {
                        skip: 0,
                        first: 50,
                        vendor: e.target.value,
                      },
                    });
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="">All</option>
                  {vendors.data &&
                    vendors.data.vendors.map((entry, index) => (
                      <option key={index} value={entry.id}>
                        {entry.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-span-4">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    onFocus={(e) =>
                      e.target.setAttribute("autocomplete", "off")
                    }
                    className="block w-full bg-white border border-gray-600 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Search"
                    type="search"
                    {...register("search")}
                    onChange={(e) => {
                      document.getElementById("productGroup").value = "";
                      document.getElementById("productTag").value = "";
                      document.getElementById("vendor").value = "";
                      setValue("productGroup", null);
                      setValue("productTag", null);
                      setSkipValue(0);
                      e.target.value.length > 2 &&
                        getProducts({
                          variables: {
                            skip: 0,
                            first: 5,
                            search: e.target.value,
                          },
                        });
                    }}
                  />
                </div>
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-700"
                >
                  Categories
                </label>
                <select
                  id="productGroup"
                  onChange={(e) => {
                    setValue(
                      "productGroup",
                      data.productGroups[e.target.value]?.accessor
                    );
                    setProductTags(data.productGroups[e.target.value]);
                    setValue("search", "");
                    document.getElementById("vendor").value = "";
                    setSkipValue(0);
                    getProducts({
                      variables: {
                        skip: 0,
                        first: 5,
                        group: e.target.value
                          ? [data.productGroups[e.target.value]?.accessor]
                          : [],
                        tags: getValues().productTag
                          ? [getValues().productTag]
                          : [],
                      },
                    });
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="">Select...</option>
                  {data &&
                    data.productGroups.map((entry, index) => (
                      <option value={index}>{entry.name}</option>
                    ))}
                </select>
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-700"
                >
                  Tags
                </label>
                <select
                  id="productTag"
                  onChange={(e) => {
                    setValue("productTag", e.target.value);
                    setValue("search", "");
                    setSkipValue(0);
                    document.getElementById("vendor").value = "";
                    getProducts({
                      variables: {
                        skip: 0,
                        first: 5,
                        group: [getValues().productGroup],
                        tags: e.target.value ? [e.target.value] : [],
                      },
                    });
                  }}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="">All</option>
                  {ProductTags &&
                    ProductTags.associatedTags.map((entry) => (
                      <option value={entry.accessor}>{entry.name}</option>
                    ))}
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                For Build #:
              </label>
              <select
                {...register("build")}
                onChange={(e) => dispatch(setForBuild(e.target.value))}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              >
                <option value={0}>Build 1</option>
                {estimate &&
                  Object.keys(estimate).map((entry) => {
                    if (entry !== "0")
                      return (
                        <option value={parseInt(entry)}>
                          Build {parseInt(entry) + 1}
                        </option>
                      );
                  })}
              </select>
            </div>
            <div className="flex flex-row justify-start gap-4">
              {/* <div className="flex items-end ">
                <ShoppingCartIcon
                  className="h-6 w-6 mx-2 text-gray-500"
                  aria-hidden="true"
                />
                <p className="text-xs font-bold">{length}</p>
              </div> */}
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add Item(s)
              </button>
            </div>
          </div>
          {items.data ? (
            <form
              name="store"
              id="store"
              className="flex flex-col  overflow-auto my-4"
            >
              <table className="border rounded-lg">
                <thead className="sticky z-10 top-0 bg-gray-100 border border-gray-300 rounded-lg">
                  <tr>
                    {ProductHeaders.map((entry, index) => {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {entry}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {items.data.items.map((product, pIndex) => (
                    <tr
                      key={product?.skuNumber}
                      className={
                        pIndex % 2 === 0 ? "bg-white" : "bg-gray-100 w-full "
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {product?.title}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        ${" "}
                        {conversion === 1
                          ? parseFloat(product?.price.pricePerUnit)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : parseFloat(product?.usdPrice.pricePerUnit)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {product?.unitOfMeasurement.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <input
                          type="number"
                          {...register(product.skuNumber)}
                          onInput={(e) => handleItems(product, e.target.value)}
                          min={0}
                          className="focus:ring-blue-500 focus:border-blue-500 w-24 font-bold text-center rounded-md sm:text-sm border py-1 border-gray-600"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <nav
                className="bg-white px-4 py-3 flex items-center justify-between border rounded-sm   border-gray-200 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium text-blue-900 ">
                      {1 + skipValue}
                    </span>{" "}
                    to{" "}
                    <span className="font-medium text-blue-900 ">
                      {5 + skipValue}
                    </span>{" "}
                    of <span className="font-medium text-green-900">625</span>{" "}
                    Items
                  </p>
                </div>
                <div className="flex-1 flex justify-between sm:justify-end">
                  {skipValue > 4 && (
                    <button
                      type="button"
                      onClick={() => setSkipValue(skipValue - 5)}
                      className="relative inline-flex items-center px-4 py-2 border border-gray-600 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Previous
                    </button>
                  )}
                  {items.data.items.length === 5 && (
                    <button
                      type="button"
                      onClick={() => setSkipValue(skipValue + 5)}
                      className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-600 text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-900"
                    >
                      See More
                    </button>
                  )}
                </div>
              </nav>
            </form>
          ) : (
            <div className="w-full my-48 flex flex-col justify-center items-center ">
              <SearchCircleIcon
                className="h-8 w-8 text-gray-500"
                aria-hidden="true"
              />
              <h2 className="text-lg text-gray-500">
                Selecting Search Criteria
              </h2>
              <svg
                className="animate-spin h-24 w-24 text-blue-200 self-center mr-2 text-center"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
                enableBackground="new 0 0 40 40"
                xml="preserve"
              >
                <path
                  opacity="0.2"
                  d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                />
                <path d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" />
              </svg>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Store;
