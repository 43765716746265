import React from "react";

const SimpleCard = ({ children }) => {
  return (
    <div className="bg-white overflow-hidden shadow border border-gray-100 rounded-lg mr-8 ml-8 mt-8">
      <div className="px-4 py-5 sm:p-6">{children}</div>
    </div>
  );
};

export default SimpleCard;
