export const SUB_MENUS = [
  {
    title: "Directory",
    link: "",
  },
];

export const SEARCH_PROPERTIES = {
  placeholder: "Type to search",
  id: "search",
  autoComplete: "off",
  textType: "search",
  registerValue: "search",
};

export const BUTTON_LINK = {
  label: "Create New Client",
  link: "/clients/new",
};
