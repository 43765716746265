import React, { useState } from "react";
import { Helmet } from "react-helmet";

// COMPONENTS
import Nav from "../nav";
import List from "./list";
import New from "./new";

const PromoView = () => {
  const [listView, setListView] = useState(true);
  return (
    <main
      className="flex flex-col relative z-0 overflow-y-auto focus:outline-none h-full"
      tabIndex="0"
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Products | One Supply Connect</title>
      </Helmet>
      <Nav />
      {listView ? (
        <List setListView={setListView} />
      ) : (
        <New setListView={setListView} />
      )}
    </main>
  );
};

export default PromoView;
