import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
  query orders($year: Int!, $month: Int!) {
    paymentTransactions(yearReceived: $year, monthReceived: $month) {
      amountReceived
      receivedDate
      totalPercentage
      order {
        id
        id
        salesType
        currentStatus {
          name
        }
        client {
          name
          id
        }
        selectedEstimate {
          number
          id
        }
        total
        profit
        currency
        vendorShipDate
        vendorHandOffDate
      }
    }
  }
`;

export const QUOTE = gql`
  query quoteLevels {
    quotaLevels {
      id
      commissionPrecentage
      thresholdValue
    }
  }
`;

export const COMMISSION_OWED = gql`
  query salesRepMonthlyOrderCommissions(
    $selectedMonth: Int!
    $selectedYear: Int!
  ) {
    salesRepMonthlyOrderCommissions(
      selectedMonth: $selectedMonth
      selectedYear: $selectedYear
    ) {
      orders {
        id
        total
        status
        vendorShipDate
        client {
          name
        }
        lead {
          companyName
        }
        selectedEstimate {
          totalCommission
        }
        currency
        salesType
        createdBy {
          firstName
          lastName
        }
      }
      salesRepsCommissionDetails {
        salesRepName
        totalCommissionCad
        totalCommissionUsd
      }
    }
  }
`;

export const PERIOD = gql`
  query period($year: Int!, $month: [Int]!) {
    periodRevenueReport(year: $year, months: $month) {
      year
      months
      salesRep {
        firstName
        lastName
      }
      createdOrdersCount
      handedOffOrdersCount
      shippedOrdersCount
      incomingUsRevenue
      incomingCadRevenue
      shippedUsRevenue
      shippedCadRevenue
      incomingTotalCadRevenue
      commissionQuotaReached
      shippedTotalCadRevenue
      commissionDueTotalCad
      commissionPotentialTotalCad
      handedOffOrders {
        id
        dueDate
        salesType
        currentStatus {
          name
        }
        lead {
          companyName
        }
        predictedEstimate {
          taxPercentage
          total
        }
      }
      shippedOrders {
        id
        dueDate
        salesType
        currentStatus {
          name
        }
        lead {
          companyName
        }
        predictedEstimate {
          taxPercentage
          total
        }
      }
    }
  }
`;

export const SALES_REPS = gql`
  query users {
    users(roleCodename: "sales_rep") {
      firstName
      lastName
      id
    }
  }
`;
