import React, { useState } from "react";
import Details from "../../components/orderDetails";
import Submission from "../../components/orderSubmission";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setOrderDetails } from "../../hooks/saleSlice";
import { useParams } from "react-router-dom";
import { GET_ORDER, USER } from "../../requests";

const UniqueOrdersView = () => {
  const dispatch = useDispatch();
  const [onDetails, setOnDetails] = useState(true);
  const [selectedEstimate, setSelectedEstimate] = useState(null);
  const [isSpare, setIsSpare] = useState(false);
  const [data, setData] = useState(null);
  const [vendorID, setVendorID] = useState(null);
  const [error, setError] = useState(null);
  let { id } = useParams();

  const order = useQuery(GET_ORDER, {
    fetchPolicy: "cache-and-network",
    variables: {
      id: parseInt(id),
    },
    onCompleted: (d) => {
      setData(d.order);
      setSelectedEstimate(d.order.estimates[0]?.number);
      dispatch(
        setOrderDetails({
          billingAddress: d.order.billingAddress,
          shippingAddress: d.order.shippingAddress,
          client: d.order.client,
          link: d.order.linkUuid,
          purchaseTerms: d.order.purchaseTerms,
          paymentReceived:
            d.order.paymentTransactions[0]?.receivedDate?.split("T")[0] ||
            "pending",
        })
      );
    },
    onError: (e) => {
      alert(e);
      setError(true);
    },
  });

  const userQ = useQuery(USER, {
    onCompleted: (d) => setVendorID(d.user?.vendor?.id),
  });

  if (error) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center text-red-500">
        Page Not Found
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-20 w-20"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <a
          href={`/orders${window.location.search}`}
          className="mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Back to Orders
        </a>
      </div>
    );
  } else if (onDetails) {
    return (
      <Details
        setSelectEstimate={setSelectedEstimate}
        setOnDetails={setOnDetails}
        setIsSpare={setIsSpare}
        data={data}
        refetch={order.refetch}
        vendorID={vendorID}
      />
    );
  } else {
    return (
      <Submission
        selectedEstimate={selectedEstimate}
        setOnDetails={setOnDetails}
        isSpare={isSpare}
        data={data}
        id={id}
      />
    );
  }
};

export default UniqueOrdersView;
