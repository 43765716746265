/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { usePDF } from "@react-pdf/renderer";
import createInvoice from "../../helper/createVendorInvoice";
import { useDispatch, useSelector } from "react-redux";
import {
  setVendorInvoice,
  selectVendorInvoice,
  setOrderDetails,
} from "../../hooks/sales/saleSlice";
import { USER } from "./queries";

const headers = ["Number", "Currency", "Date Issued", "Due Date", "View"];
const InvoiceView = () => {
  const dispatch = useDispatch();
  const selectedInvoice = useSelector(selectVendorInvoice);
  const [tableData, setTableData] = useState([]);
  const [clicked, setClicked] = useState(false);
  const { PDF } = createInvoice();
  const [instance, updateInstance] = usePDF({ document: PDF });

  useEffect(() => {
    updateInstance();
  }, [selectedInvoice]);

  useEffect(() => {
    if (clicked) window.open(instance.url, "_blank").focus();
    setClicked(false);
  }, [instance.url]);

  const userResponse = useQuery(USER, {
    onCompleted: (d) => {
      handleSortTable(d.user);
    },
  });

  async function handleSortTable(data) {
    dispatch(setOrderDetails(data));
    setTableData(data.salesRepCommissionInvoices || []);
  }
  return (
    <div className="flex flex-col p-8 max-w-6xl mx-auto w-full">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className=" align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow-sm overflow-hidden border border-gray-100 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  {headers.map((header, index) => (
                    <th
                      key={index}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider items-start"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tableData.map((entry, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                      {entry.number}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                      {entry.currency?.symbol}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                      {entry.dateIssued?.split("T")[0]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-red-600">
                      {entry.dueDate?.split("T")[0]}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium ">
                      <button
                        type="button"
                        className="text-blue-600 hover:text-blue-900 focus:outline-none focus:shadow-outline"
                        onClick={async () => {
                          await dispatch(setVendorInvoice(entry));
                          setClicked(true);
                        }}
                      >
                        View invoice PDF <span aria-hidden="true"> &rarr;</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;
