import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { useForm } from "react-hook-form";
import { ChevronLeftIcon } from "@heroicons/react/solid";

//COMPONENTS
import LinkSent from "./linkSent";

const RESET = gql`
  mutation reset($email: String!, $isSignUp: Boolean) {
    resetPasswordRequest(email: $email, isSignUp: $isSignUp) {
      success
    }
  }
`;

const LoginPage = ({ setReset }) => {
  const { register, handleSubmit, setValue } = useForm();
  const [signAttempt, setSignAttempt] = useState(false);
  const [sent, setSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [reset, user] = useMutation(RESET, {
    onCompleted: (d) => setSent(d.resetPasswordRequest.success),
    onError: (err) => {
      setSignAttempt(false);
      setErrorMessage("Server Error! Please Contact Admin.");
    },
  });

  const onLogin = async (data) => {
    setSignAttempt(true);
    const { email } = data;
    reset({
      variables: {
        email: email,
        isSignUp: false,
      },
    });
  };

  if (sent) return <LinkSent />;
  return (
    <div className="min-h-screen flex items-center justify-center bg-connectGray py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-24 w-auto"
            src="/logo.png"
            alt="ATG Pharma"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome Back to Connect
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            New here? Please contact your manager to receive your credentials
          </p>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onLogin)}>
          <input type="hidden" name="remember" value="true" />
          <div className="flex items-center">
            <button
              type="button"
              onClick={() => setReset(false)}
              className="text-sm text-blue-600 hover:text-blue-800 flex flex-row items-end"
            >
              <ChevronLeftIcon className="h-5 w-5 " aria-hidden="true" />
              Back
            </button>
          </div>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                {...register("email", { required: true })}
                id="email-address"
                onChange={(e) => {
                  setErrorMessage(null);
                  setValue("email", e.target.value);
                }}
                type="text"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
          </div>
          {errorMessage && (
            <p className="text-red-600 text-xs font-bold animate-pulse">
              {errorMessage}
            </p>
          )}
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className={`${
                    signAttempt && "animate-spin"
                  } h-5 w-5 text-blue-400 group-hover:text-blue-400`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Reset Password
            </button>
          </div>
        </form>
        <div>
          <p className="mt-2 text-center text-sm text-gray-600">
            Powered by{" "}
            <a
              className="text-blue-700 hover:text-blue-800"
              target="_blank"
              rel="noreferrer"
              href="https://castleclock.com/"
            >
              CastleClock Inc
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
