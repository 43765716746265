import React, { Fragment } from "react";
import Button from "../inputs/button/linkStyle";

const ColWButton = ({ data, map }) => {
  return map.map((entry, index) => {
    const { label, accessor, depth1, type, button, depth1Comp } = entry;
    let value = data[accessor];
    value && depth1 && (value = value[depth1]);
    value &&
      depth1Comp &&
      (value = `${value} (${parseFloat(data[accessor][depth1Comp] * 100)}%)`);
    value &&
      type === "name" &&
      (value = value.firstName + " " + value.lastName);

    return (
      <Fragment key={index}>
        <div className="flex flex-row justify-between items-end mt-2">
          <h4 className="text-gray-500 ">{label}</h4>
          <Button data={button} />
        </div>
        <p className="mb-4 font-bold">
          {value ? (
            <span className="text-green-700">{value}</span>
          ) : (
            <span className="text-red-600 ">Not Available</span>
          )}
        </p>
      </Fragment>
    );
  });
};

export default ColWButton;
