import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  client: {},
  contact: {},
  inReview: false,
  lineItems: [],
  taxPercentage: 0,
  price: { price: 0, tax: 0, total: 0 },
  popeOver: false,
  item: null,
  currency: "USD",
  spareList: [],
  orderList: [],
  globalFilter: null,
  hiddenColumns: {},
  clientModal: true,
  cart: {},
  selectedEstimate: null,
  orderDetails: null,
  sortBy: "-id",
  report: localStorage.getItem("report")
    ? JSON.parse(localStorage.getItem("report"))
    : null,
  renderedHeader: null,
  vendorInvoice: null,
  requiredShipping: null,
  manualProgression: false,
  vendorID: null,
};

export const saleSlice = createSlice({
  name: "sales",
  initialState,

  reducers: {
    setReview: (state) => {
      state.inReview = !state.inReview;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    },
    setContact: (state, action) => {
      state.contact = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setLineItems: (state, action) => {
      state.lineItems = action.payload;
    },
    setTaxPercentage: (state, action) => {
      state.taxPercentage = action.payload;
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setPopOver: (state, action) => {
      state.popeOver = action.payload.view;
      state.item = action.payload.item;
    },
    setSpareList: (state, action) => {
      state.spareList = action.payload;
    },
    setOrderList: (state, action) => {
      state.orderList = action.payload;
    },
    setGlobalFilter: (state, action) => {
      state.globalFilter = action.payload;
    },
    setHiddenColumns: (state, action) => {
      state.hiddenColumns = action.payload;
    },
    setClientModal: (state, action) => {
      state.clientModal = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setSelectedEstimate: (state, action) => {
      state.selectedEstimate = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setReport: (state, action) => {
      state.report = action.payload;
    },
    setRenderedHeader: (state, action) => {
      state.renderedHeader = action.payload;
    },
    setVendorInvoice: (state, action) => {
      state.vendorInvoice = action.payload;
    },
    setRequiredShipping: (state, action) => {
      state.requiredShipping = action.payload;
    },
    setManualProgression: (state, action) => {
      state.manualProgression = action.payload;
    },
    setVendorID: (state, action) => {
      state.vendorID = action.payload;
    },
  },
});

export const {
  setReview,
  setClient,
  setCurrency,
  setLineItems,
  setPrice,
  setTaxPercentage,
  setContact,
  setPopOver,
  setSpareList,
  setOrderList,
  setGlobalFilter,
  setHiddenColumns,
  setClientModal,
  setCart,
  setSelectedEstimate,
  setOrderDetails,
  setSortBy,
  setReport,
  setRenderedHeader,
  setVendorInvoice,
  setRequiredShipping,
  setManualProgression,
  setVendorID,
} = saleSlice.actions;

export const selectReview = (state) => state.sale.inReview;
export const selectClient = (state) => state.sale.client;
export const selectContact = (state) => state.sale.contact;
export const selectLineItems = (state) => state.sale.lineItems;
export const selectPrice = (state) => state.sale.price;
export const selectTaxPercentage = (state) => state.sale.taxPercentage;
export const selectCurrency = (state) => state.sale.currency;
export const selectPopOver = (state) => {
  return { view: state.sale.popeOver, item: state.sale.item };
};
export const selectSpareList = (state) => state.sale.spareList;
export const selectOrderList = (state) => state.sale.orderList;
export const selectGlobalFilter = (state) => state.sale.globalFilter;
export const selectHiddenColumns = (state) => state.sale.hiddenColumns;
export const selectClientModal = (state) => state.sale.clientModal;
export const selectCart = (state) => state.sale.cart;
export const selectEstimate = (state) => state.sale.selectedEstimate;
export const selectOrderDetails = (state) => state.sale.orderDetails;
export const selectSortBy = (state) => state.sale.sortBy;
export const selectReport = (state) => state.sale.report;
export const selectRenderedHeader = (state) => state.sale.renderedHeader;
export const selectVendorInvoice = (state) => state.sale.vendorInvoice;
export const selectRequiredShipping = (state) => state.sale.requiredShipping;
export const selectManualProgression = (state) => state.sale.manualProgression;
export const selectVendorID = (state) => state.sale.vendorID;

export default saleSlice.reducer;
