import { useState } from "react";
import { PencilIcon } from "@heroicons/react/outline";

//HOOKS
import useClient from "../../../clients/hooks/useClient";
export default function Page({ data, map }) {
  const { handleUpdateName } = useClient();
  const [onEdit, setOnEdit] = useState(false);
  const [name, setName] = useState(data ? data[map.titleAccessor] : null);

  if (!data) return null;
  return (
    <div className="border-b border-gray-200 py-4 mb-2 flex flex-row  justify-between items-center ">
      <h3 className="text-2xl leading-6 font-medium text-gray-900 flex flex-row items-end w-4/5">
        {onEdit ? (
          <>
            <input
              onChange={(e) => setName(e.target.value)}
              className={`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm`}
              defaultValue={data[map.titleAccessor]}
            />

            <button
              onClick={() => {
                handleUpdateName({ name });
                setOnEdit(!onEdit);
              }}
              className="text-sm ml-4 bg-blue-700 hover:bg-white hover:border-blue-600 border p-1 px-2 rounded-lg text-white hover:text-blue-600"
            >
              <span>Save</span>
            </button>
          </>
        ) : (
          <>
            {data[map.titleAccessor]}
            <button
              onClick={() => setOnEdit(!onEdit)}
              className="ml-4 bg-blue-700 hover:bg-white hover:border-blue-600 border p-1 rounded-lg text-white hover:text-blue-600"
            >
              <PencilIcon className="h-4 w-4 " aria-hidden="true" />
            </button>
          </>
        )}
      </h3>
      <div className="flex flex-row  items-center justify-end col-span-2">
        <span
          className={`${
            map.tagStyling[data[map.tagAccessor]] || "text-green-700"
          }`}
        >
          {data[map.tagAccessor]}
        </span>
      </div>
    </div>
  );
}
