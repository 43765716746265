import Loading from "../../loading/button";

export default function Button({ data, submitting, ref }) {
  if (!data) return null;
  const { type, label, event, coloring, clickEvent, value } = data;
  let color = "bg-blue-500 hover:bg-blue-600";
  coloring === "red" && (color = "bg-red-500 hover:bg-red-600");
  coloring === "green" && (color = "bg-green-500 hover:bg-green-600");
  let hookEvent = () => {};
  if (clickEvent) {
    const { hook, func } = clickEvent;
    const { [func]: funcToCall } = hook();
    hookEvent = () => funcToCall(value);
  }
  return (
    <div>
      <button
        ref={ref}
        type={type}
        onClick={event || hookEvent}
        disabled={submitting}
        className={`w-full  flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${color} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        {submitting && <Loading />}
        {label}
      </button>
    </div>
  );
}
